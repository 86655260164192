// prettier-ignore
export const quotes = [
  ["'Tis the time's plague when madmen lead the blind.", "William Shakespeare"],
  ["'Tis with our judgements as our watches: none Go just alike, yet each believes his own.", "Alexander Pope"],
  ["'Tis very certain that each man carries in his eye the exact indication of his rank in the immense scale of men, and we are always learning to read it. A complete man should need no auxiliaries to his personal presence.", "Ralph Waldo Emerson"],
  ["...it is difficult to picture the great Creator conceiving of a program of one creature (which He has made) using another living creature for purposes of experimentation. There must be other, less cruel ways of obtaining knowledge.", "Adlai Stevenson"],
  ["[Destroying rain forest for economic gain] is like burning a Renaissance painting to cook a meal.", "E.O. Wilson"],
  ["\"Did God have a mother?\" Children, when told that God made the heavens and the earth, innocently ask whether God had a mother. This deceptively simple question has stumped the elders of the church and embarrassed the finest theologians, precipitating some of the thorniest theological debates over the centuries. All the great religions have elaborate mythologies surrounding the divine act of Creation, but none of them adequately confronts the logical paradoxes inherent in the question that even children ask.", "Michio Kaku"],
  ["\"Do you ever read any of the books you burn?\" \"That's against the law!\" \"Oh. Of course.\"", "Ray Bradbury"],
  ["\"Don't think of it as dying,\" said Death. \"Just think of it as leaving early to avoid the rush.\"", "Terry Pratchett"],
  ["\"Faith\" is a fine invention / For gentlemen who see - / But microscopes are prudent / In an emergency.", "Emily Dickinson"],
  ["\"Respect for religion\" has become a code phrase meaning \"fear of religion\". Religions, like all other ideas, deserve criticism, satire, and, yes, our fearless disrespect.", "Salman Rushdie"],
  ["\"That's a great deal to make one word mean,\" Alice said in a thoughtful tone. \"When I make a word do a lot of work like that,\" said Humpty Dumpty, \"I always pay it extra.\"", "Lewis Carroll"],
  ["\"The last word\" is the most dangerous of infernal machines; and husband and wife should no more fight to get it than they would struggle for the possession of a lighted bomb-shell.", "Douglas William Jerrold"],
  ["\"The question is,\" said Alice, \"whether you CAN make words mean so many different things.\" \"The question is,\" said Humpty Dumpty, \"which is to be master - that's all.\"", "Lewis Carroll"],
  ["\"Writing\" is the Latin of our times. The modern language of the people is video and sound.", "Lawrence Lessig"],
  ["A belief in a supernatural source of evil is not necessary; men alone are quite capable of every wickedness.", "Joseph Conrad"],
  ["A belief which leaves no place for doubt is not a belief; it is a superstition.", "Jose Bergamin"],
  ["A bit beyond perception's reach / I sometimes believe I see / that life is two locked boxes / each containing the other's key.", "Piet Hein"],
  ["A book is a version of the world. If you do not like it, ignore it; or offer your own version in return.", "Salman Rushdie"],
  ["A book lying idle on a shelf is wasted ammunition. Like money, books must be kept in constant circulation. Lend and borrow to the maximum.", "Henry Miller"],
  ["A book must be an axe for the frozen sea inside of us.", "Franz Kafka"],
  ["A book, once it is printed and published, becomes individual. It is by its publication as decisively severed from its author as in parturition a child is cut off from its parent. The book \"means\" thereafter, perforce, - both grammatically and actually, - whatever meaning this or that reader gets out of it.", "James Branch Cabell"],
  ["A bookstore is one of the only pieces of evidence we have that people are still thinking.", "Jerry Seinfeld"],
  ["A brother is a friend given by nature.", "Gabriel Legouve"],
  ["A business that makes nothing but money is a poor business.", "Henry Ford"],
  ["A career is wonderful, but you can't curl up with it on a cold night.", "Marilyn Monroe"],
  ["A certain amount of opposition is a great help to a man. Kites rise against, not with, the wind.", "John Neal"],
  ["A change in perspective is worth 80 IQ points.", "Alan Kay"],
  ["A chief event of life is the day in which we have encountered a mind that startled us.", "Ralph Waldo Emerson"],
  ["A child's education should begin at least one hundred years before he is born.", "Oliver Wendell Holmes, Sr"],
  ["A child, like your stomach, doesn't need all you can afford to give it.", "Frank A. Clark"],
  ["A city that outdistances man's walking powers is a trap for man.", "Arnold J. Toynbee"],
  ["A civilized society is one which tolerates eccentricity to the point of doubtful sanity.", "Robert Frost"],
  ["A compliment is something like a kiss through a veil.", "Victor Hugo"],
  ["A conservative is one who admires radicals centuries after they're dead.", "Leo Rosten"],
  ["A couple of months in the laboratory can save a couple of hours in the library.", "Frank H. Westheimer"],
  ["A coward is a hero with a wife, kids, and a mortgage.", "Marvin Kitman"],
  ["A cult is a religion with no political power.", "Tom Wolfe"],
  ["A cynic knows the price of everything and the value of nothing.", "Oscar Wilde"],
  ["A decent provision for the poor is the true test of civilisation.", "Samuel Johnson"],
  ["A designer knows he has achieved perfection not when there is nothing left to add, but when there is nothing left to take away.", "Antoine de Saint-Exupery"],
  ["A different language is a different vision of life.", "Federico Fellini"],
  ["A faith that cannot survive collision with the truth is not worth many regrets.", "Arthur C. Clarke"],
  ["A fellow of mediocre talent will remain a mediocrity, whether he travels or not; but one of superior talent (which without impiety I cannot deny that I possess) will go to seed if he always remains in the same place.", "Wolfgang Amadeus Mozart"],
  ["A few minutes ago every tree was excited, bowing to the roaring storm, waving, swirling, tossing their branches in glorious enthusiasm like worship. But though to the outer ear these trees are now silent, their songs never cease. Every hidden cell is throbbing with music and life, every fiber thrilling like harp strings, while incense is ever flowing from the balsam bells and leaves. No wonder the hills and groves were God's first temples, and the more they are cut down and hewn into cathedrals and churches, the farther off and dimmer seems the Lord himself.", "John Muir"],
  ["A first-rate soup is more creative than a second-rate painting.", "Abraham Maslow"],
  ["A fixed idea is like the iron rod which sculptors put in their statues. It impales and sustains.", "Hippolyte Taine"],
  ["A fool always finds some greater fool to admire him.", "Nicholas Boileau-Despreaux"],
  ["A foolish consistency is the hobgoblin of little minds, adored by little statesmen and philosophers and divines.", "Ralph Waldo Emerson"],
  ["A free society is a place where it's safe to be unpopular.", "Adlai Stevenson"],
  ["A friend is a person with whom I may be sincere. Before him I may think aloud.", "Ralph Waldo Emerson"],
  ["A full belly to the labourer is, in my opinion, the foundation of public morals and the only source of real public peace.", "William Cobbett"],
  ["A good cook is like a sorceress who dispenses happiness.", "Elsa Schiaparelli"],
  ["A good end cannot sanctify evil means; nor must we ever do evil, that good may come of it.", "William Penn"],
  ["A good heart is better than all the heads in the world.", "Edward Bulwer-Lytton"],
  ["A good leader can't get too far ahead of his followers.", "Franklin D. Roosevelt"],
  ["A good listener helps us overhear ourselves.", "Yahia Lababidi"],
  ["A good scare is worth more to a man than good advice.", "E.W. Howe"],
  ["A good storyteller is the conscience-keeper of a nation.", "Sunjoy Shekhar"],
  ["A grain of poetry suffices to season a century.", "José Martí"],
  ["A grass-blade's no easier to make than an oak.", "James Russell Lowell"],
  ["A great book should leave you with many experiences, and slightly exhausted at the end. You live several lives while reading it.", "William Styron"],
  ["A great deal of intelligence can be invested in ignorance when the need for illusion is deep.", "Saul Bellow"],
  ["A great secret of success is to go through life as a man who never gets used up.", "Albert Schweitzer"],
  ["A great writer is, so to speak, a second government in his country. And for that reason no regime has ever loved great writers, only minor ones.", "Aleksandr Solzhenitsyn"],
  ["A guidance counselor who has made a fetish of security, or who has unwittingly surrendered his thinking to economic determinism, may steer a youth away from his dream of becoming a poet, an artist, a musician or any other of thousands of things, because it offers no security, it does not pay well, there are no vacancies, it has no \"future\".", "Henry M. Wriston"],
  ["A gun gives you the body, not the bird.", "Henry David Thoreau"],
  ["A hair in the head is worth two in the brush.", "Oliver Herford"],
  ["A handful of sand is an anthology of the universe.", "David McCord"],
  ["A happy marriage is the union of two good forgivers.", "Robert Quillen"],
  ["A house is no home unless it contains food and fire for the mind as well as the body.", "Margaret Fuller"],
  ["A hungry man is not a free man.", "Adlai Stevenson"],
  ["A king can stand people fighting but he can't last long if people start thinking.", "Will Rogers"],
  ["A kiss can be a comma, a question mark or an exclamation point.", "Mistinguett"],
  ["A language is a dialect that has an army and a navy.", "Max Weinreich"],
  ["A language is an exact reflection of the character and growth of its speakers.", "Mahatma Gandhi"],
  ["A language is never in a state of fixation, but is always changing; we are not looking at a lantern-slide but at a moving picture.", "Arthur Lloyd James"],
  ["A lawn is nature under totalitarian rule.", "Michael Pollan"],
  ["A lexicographer's business is solely to collect, arrange, and define the words that usage presents to his hands. He has no right to proscribe words; he is to present them as they are.", "Noah Webster"],
  ["A library is thought in cold storage.", "Herbert Samuel"],
  ["A living language is like a man suffering incessantly from small haemorrhages, and what it needs above all else is constant transactions of new blood from other tongues. The day the gates go up, that day it begins to die.", "H.L. Mencken"],
  ["A man builds a fine house; and now he has a master, and a task for life; he is to furnish, watch, show it, and keep it in repair, the rest of his days.", "Ralph Waldo Emerson"],
  ["A man can't ride on your back unless it's bent.", "Martin Luther King, Jr"],
  ["A man cannot be comfortable without his own approval.", "Mark Twain"],
  ["A man does not have to be an angel in order to be saint.", "Albert Schweitzer"],
  ["A man does not look behind the door unless he has stood there himself.", "William Edward Burghardt Du Bois"],
  ["A man finds room in the few square inches of the face for the traits of all his ancestors; for the expression of all his history, and his wants.", "Ralph Waldo Emerson"],
  ["A man has to live with himself, and he should see to it that he always has good company.", "Charles Evans Hughes"],
  ["A man is a success if he gets up in the morning and gets to bed at night, and in between he does what he wants to do.", "Bob Dylan"],
  ["A man is like a fraction whose numerator is what he is and whose denominator is what he thinks of himself. The larger the denominator, the smaller the fraction.", "Leo Tolstoy"],
  ["A man is not idle because he is absorbed in thought. There is a visible labor and there is an invisible labor.", "Victor Hugo"],
  ["A man is not old until his regrets take the place of dreams.", "Yiddish proverb"],
  ["A man may be very industrious, and yet not spend his time well. There is no more fatal blunderer than he who consumes the greater part of life getting his living.", "Henry David Thoreau"],
  ["A man needs a little madness, or else he never dares cut the rope and be free.", "Nikos Kazantzakis"],
  ["A man of courage never needs weapons, but he may need bail.", "Lewis Mumford"],
  ["A man said to the universe: \"Sir I exist!\" \"However,\" replied the universe, \"The fact has not created in me a sense of obligation.\"", "Stephen Crane"],
  ["A man should hear a little music, read a little poetry, and see a fine picture every day of his life, in order that worldly cares may not obliterate the sense of the beautiful which God has implanted in the human soul.", "Johann Wolfgang von Goethe"],
  ["A man should live with his superiors as he does with his fire: not too near, lest he burn; nor too far off, lest he freeze.", "Diogenes"],
  ["A man should never be ashamed to own he has been in the wrong, which is but saying, in other words, that he is wiser today than he was yesterday.", "Alexander Pope"],
  ["A man that is ashamed of passions that are natural and reasonable is generally proud of those that are shameful and silly.", "Lady Mary Wortley Montagu"],
  ["A man there was, tho' some did count him mad / The more he cast away, the more he had.", "John Bunyan"],
  ["A man too busy to take care of his health is like a mechanic too busy to take care of his tools.", "Spanish proverb"],
  ["A man who dares to waste one hour of time has not discovered the value of life.", "Charles Darwin"],
  ["A man who has never gone to school may steal from a freight car; but if he has a university education, he may steal the whole railroad.", "Theodore Roosevelt"],
  ["A man who is \"ill-adjusted\" to the world is always on the verge of finding himself. One who is adjusted to the world never finds himself, but gets to be a cabinet minister.", "Hermann Hesse"],
  ["A man who lies to himself, and believes his own lies, becomes unable to recognize truth, either in himself or in anyone else, and he ends up losing respect for himself and for others.", "Fyodor Dostoyevsky"],
  ["A man who takes away another man's freedom is a prisoner of hatred, he is locked behind the bars of prejudice and narrow-mindedness.", "Nelson Mandela"],
  ["A man who uses a great many words to express his meaning is like a bad marksman who, instead of aiming a single stone at an object, takes up a handful and throws at it in hopes he may hit.", "Samuel Johnson"],
  ["A man who works with his hands is a laborer; a man who works with his hands and his brain is a craftsman; but a man who works with his hands and his brain and his heart is an artist.", "Louis Nizer"],
  ["A man you can bait with a tweet is not a man we can trust with nuclear weapons. ... America's strength doesn't come from lashing out. Strength relies on smarts, judgment, cool resolve, and the precise and strategic application of power. That's the kind of Commander-in-Chief I pledge to be.", "Hillary Clinton"],
  ["A man's ethical behavior should be based effectually on sympathy, education, and social ties; no religious basis is necessary. Man would indeed be in a poor way if he had to be restrained by fear of punishment and hope of reward after death.", "Albert Einstein"],
  ["A man's first care should be to avoid the reproaches of his own heart, his next to escape the censures of the world.", "Joseph Addison"],
  ["A man's library is a sort of harem.", "Ralph Waldo Emerson"],
  ["A man's life is interesting primarily when he has failed - I well know. For it is a sign that he has tried to surpass himself.", "Georges Clemenceau"],
  ["A man's name is not like a mantle which merely hangs about him, and which one perchance may safely twitch and pull, but a perfectly fitting garment, which, like the skin, has grown over and over him, at which one cannot rake and scrape without injuring the man himself.", "Johann Wolfgang von Goethe"],
  ["A man's style in any art should be like his dress--it should attract as little attention as possible.", "Samuel Butler"],
  ["A mass of Latin words falls upon the facts like soft snow, blurring the outline and covering up all the details. The great enemy of clear language is insincerity. When there is a gap between one's real and one's declared aims, one turns as it were instinctively to long words and exhausted idioms, like a cuttlefish spurting out ink. In our age there is no such thing as \"keeping out of politics\". All issues are political issues, and politics itself is a mass of lies, evasions, folly, hatred, and schizophrenia. When the general atmosphere is bad, language must suffer.", "George Orwell"],
  ["A mature person is one who does not think only in absolutes, who is able to be objective even when deeply stirred emotionally, who has learned that there is both good and bad in all people and in all things, and who walks humbly and deals charitably with the circumstances of life, knowing that in this world no one is all knowing and therefore all of us need both love and charity.", "Eleanor Roosevelt"],
  ["A mind all logic is like a knife all blade. It makes the hand bleed that uses it.", "Rabindranath Tagore"],
  ["A misery is not to be measured from the nature of the evil, but from the temper of the sufferer.", "Joseph Addison"],
  ["A morning-glory at my window satisfies me more than the metaphysics of books.", "Walt Whitman"],
  ["A musician must make music, an artist must paint, a poet must write, if he is to be ultimately at peace with himself. What one can be, one must be.", "Abraham Maslow"],
  ["A myth is a fixed way of looking at the world which cannot be destroyed because, looked at through the myth, all evidence supports the myth.", "Edward de Bono"],
  ["A myth is a religion in which no one any longer believes.", "James Kern Feibleman"],
  ["A nation, like a tree, does not thrive well till it is engrafted with a foreign stock.", "Ralph Waldo Emerson"],
  ["A neurosis is a secret that you don't know you're keeping.", "Kenneth Tynan"],
  ["A new word is like a fresh seed sown on the ground of the discussion.", "Ludwig Wittgenstein"],
  ["A note of music gains significance from the silence on either side.", "Anne Morrow Lindbergh"],
  ["A painter is a man who paints what he sells; an artist, on the other hand, is a man who sells what he paints.", "Pablo Picasso"],
  ["A patriot must always be ready to defend his country against its government.", "Edward Abbey"],
  ["A pedestal is as much a prison as any small space.", "Gloria Steinem"],
  ["A person is never happy except at the price of some ignorance.", "Anatole France"],
  ["A person may cause evil to others not only by his actions but by his inaction, and in either case he is justly accountable to them for the injury.", "John Stuart Mill"],
  ["A person usually has two reasons for doing something: a good reason and the real reason.", "Thomas Carlyle"],
  ["A person without a sense of humor is like a wagon without springs - jolted by every pebble in the road.", "Henry Ward Beecher"],
  ["A physician is not angry at the intemperance of a mad patient; nor does he take it ill to be railed at by a man in a fever. Just so should a wise man treat all mankind, as a physician does his patient; and looking upon them only as sick and extravagant.", "Lucius Annaeus Seneca"],
  ["A poem begins in delight and ends in wisdom.", "Robert Frost"],
  ["A poem begins with a lump in the throat.", "Robert Frost"],
  ["A poem is never finished, only abandoned.", "Paul Valery"],
  ["A poet looks at the world as a man looks at a woman.", "Wallace Stevens"],
  ["A poet should be of the / old-fashioned meaningless brand: / obscure, esoteric, symbolic, -- / the critics demand it; / so if there's a poem of mine / that you do understand / I'll gladly explain what it means / till you don't understand it.", "Piet Hein"],
  ["A politician is a man who thinks of the next election; while the statesman thinks of the next generation.", "James Freeman Clarke"],
  ["A poor idea well written is more likely to be accepted than a good idea poorly written.", "Isaac Asimov"],
  ["A problem well stated is a problem half solved.", "Charles F. Kettering"],
  ["A profound unmitigated loneliness is the only truth of life.", "R.K. Narayan"],
  ["A proverb is a short sentence based on long experience.", "Miguel de Cervantes"],
  ["A quiet conscience sleeps in thunder.", "English proverb"],
  ["A real book is not one that we read, but one that reads us.", "W.H. Auden"],
  ["A real patriot is the fellow who gets a parking ticket and rejoices that the system works.", "Bill Vaughan"],
  ["A rock pile ceases to be a rock pile the moment a single man contemplates it, bearing within him the image of a cathedral.", "Antoine de Saint-Exupery"],
  ["A room without books is like a body without a soul.", "Marcus Tullius Cicero"],
  ["A root is a flower that disdains fame.", "Khalil Gibran"],
  ["A scholar is just a library's way of making another library.", "Daniel Dennett"],
  ["A scholar knows no boredom.", "Jean Paul Richter"],
  ["A sect or party is an elegant incognito devised to save a man from the vexation of thinking.", "Ralph Waldo Emerson"],
  ["A sense of duty is useful in work but offensive in personal relations.", "Bertrand Russell"],
  ["A ship in port is safe; but that is not what ships are built for.", "Grace Hopper"],
  ["A ship ought not to be held by one anchor, nor life by a single hope.", "Epictetus"],
  ["A single rose can be my garden... a single friend, my world.", "Leo Buscaglia"],
  ["A sneer is the weapon of the weak.", "James Russell Lowell"],
  ["A society that gets rid of all its troublemakers goes downhill.", "Robert A. Heinlein"],
  ["A society that has more justice is a society that needs less charity.", "Ralph Nader"],
  ["A society that presumes a norm of violence and celebrates aggression, whether in the subway, on the football field, or in the conduct of its business, cannot help making celebrities of the people who would destroy it.", "Lewis H. Lapham"],
  ["A sound mind in a sound body, is a short but full description of a happy state in this world.", "John Locke"],
  ["A speech belongs half to the speaker and half to the listener.", "Michel de Montaigne"],
  ["A stereotyped but unconscious despair is concealed even under what are called the games and amusements of mankind.", "Henry David Thoreau"],
  ["A stiff apology is a second insult. The injured party does not want to be compensated because he has been wronged; he wants to be healed because he has been hurt.", "G.K. Chesterton"],
  ["A story should have a beginning, a middle, and an end, but not necessarily in that order.", "Jean-Luc Godard"],
  ["A straw vote only shows which way the hot air blows.", "O. Henry"],
  ["A strong nation, like a strong person, can afford to be gentle, firm, thoughtful, and restrained. It can afford to extend a helping hand to others. It is a weak nation, like a weak person, that must behave with bluster and boasting and rashness and other signs of insecurity.", "Jimmy Carter"],
  ["A successful man is one who makes more money than a wife can spend. A successful woman is one who can find such a man.", "Lana Turner"],
  ["A superior man is modest in his speech, but exceeds in his actions.", "Confucius"],
  ["A teacher affects eternity; he can never tell where his influence stops.", "Henry Adams"],
  ["A teacher is one who makes himself progressively unnecessary.", "Thomas Jefferson Caruthers"],
  ["A teacher who is attempting to teach, without inspiring the pupil with a desire to learn, is hammering on a cold iron.", "Horace Mann"],
  ["A thing is not necessarily true because a man dies for it.", "Oscar Wilde"],
  ["A thing long expected takes the form of the unexpected when at last it comes.", "Mark Twain"],
  ["A time will come when a politician who has wilfully made war and promoted international dissension will be as sure of the dock and much surer of the noose than a private homicide. It is not reasonable that those who gamble with men's lives should not stake their own.", "H.G. Wells"],
  ["A timid question will always receive a confident answer.", "Charles John Darling"],
  ["A tragic indicator of the values of our civilization is that there's no business like war business.", "Douglas Mattern"],
  ["A true measure of your worth includes all the benefits others have gained from your successes.", "Cullen Hightower"],
  ["A truly great book should be read in youth, again in maturity and once more in old age, as a fine building should be seen by morning light, at noon and by moonlight.", "Robertson Davies"],
  ["A tyrant must put on the appearance of uncommon devotion to religion. Subjects are less apprehensive of illegal treatment from a ruler whom they consider god-fearing and pious. On the other hand, they do less easily move against him, believing that he has the gods on his side.", "Aristotle"],
  ["A very popular error: having the courage of one's convictions; rather it is a matter of having the courage for an attack on one's convictions.", "Friedrich Nietzsche"],
  ["A vigorous five-mile walk will do more good for an unhappy but otherwise healthy adult than all the medicine and psychology in the world.", "Paul Dudley White"],
  ["A visitor from Mars could easily pick out the civilized nations. They have the best implements of war.", "Herbert V. Prochnow"],
  ["A wise man fights to win, but he is twice a fool who has no plan for possible defeat.", "Louis L'Amour"],
  ["A wise man will make haste to forgive, because he knows the true value of time, and will not suffer it to pass away in unnecessary pain.", "Samuel Johnson"],
  ["A woman whom we truly love is a religion.", "Emile de Girardin"],
  ["A wonderful time--the War: / when money rolled in / and blood rolled out. / But blood / was far away / from here-- / Money was near.", "Langston Hughes"],
  ["A word after a word after a word is power.", "Margaret Atwood"],
  ["A word has its use, / Or, like a man, it will soon have a grave.", "Edwin Arlington Robinson"],
  ["A word in a dictionary is very much like a car in a mammoth motorshow - full of potential, but temporarily inactive.", "Anthony Burgess"],
  ["A word in earnest is as good as a speech.", "Charles Dickens"],
  ["A word is dead / When it is said, / Some say. / I say it just / Begins to live / That day.", "Emily Dickinson"],
  ["A word is not a crystal, transparent and unchanged, it is the skin of a living thought and may vary greatly in color and content according to the circumstances and the time in which it is used.", "Oliver Wendell Holmes, Jr"],
  ["A word is not the same with one writer as with another. One tears it from his guts. The other pulls it out of his overcoat pocket.", "Charles Peguy"],
  ["A writer - and, I believe, generally all persons - must think that whatever happens to him or her is a resource. All things have been given to us for a purpose, and an artist must feel this more intensely. All that happens to us, including our humiliations, our misfortunes, our embarrassments, all is given to us as raw material, as clay, so that we may shape our art.", "Jorge Luis Borges"],
  ["A writer is somebody for whom writing is more difficult than it is for other people.", "Thomas Mann"],
  ["A writer is, after all, only half his book. The other half is the reader and from the reader the writer learns.", "P.L. Travers"],
  ["A writer must refuse to allow himself to be transformed into an institution.", "Jean-Paul Sartre"],
  ["A writer needs three things, experience, observation, and imagination, any two of which, at times any one of which, can supply the lack of the others.", "William Faulkner"],
  ["A youth is to be regarded with respect. How do you know that his future will not be equal to our present?", "Confucius"],
  ["Absence diminishes commonplace passions and increases great ones, as the wind extinguishes candles and kindles fire.", "François de La Rochefoucauld"],
  ["Absence in love is like water upon fire; a little quickens, but much extinguishes it.", "Hannah More"],
  ["Absolute faith corrupts as absolutely as absolute power.", "Eric Hoffer"],
  ["Absolute freedom mocks at justice. Absolute justice denies freedom.", "Albert Camus"],
  ["Absolute justice is achieved by the suppression of all contradiction: therefore it destroys freedom.", "Albert Camus"],
  ["Absurdity, n. a statement or belief manifestly inconsistent with one's own opinion.", "Ambrose Bierce"],
  ["Access to power must be confined to those who are not in love with it.", "Plato"],
  ["Act as if what you do makes a difference. It does.", "William James"],
  ["Action is eloquence.", "William Shakespeare"],
  ["Activism is the rent I pay for living on the planet.", "Alice Walker"],
  ["Adding manpower to a late software project makes it later.", "Fred Brooks"],
  ["Adopt the pace of nature: her secret is patience.", "Ralph Waldo Emerson"],
  ["Adulthood is the ever-shrinking period between childhood and old age. It is the apparent aim of modern industrial societies to reduce this period to a minimum.", "Thomas Szasz"],
  ["Advertising is legalized lying.", "H.G. Wells"],
  ["Affliction, like the iron-smith, shapes as it smites.", "Christian Bovee"],
  ["After I'm dead I'd rather have people ask why I have no monument than why I have one.", "Cato the Elder"],
  ["After the game, the king and pawn go into the same box.", "Italian proverb"],
  ["After two years in Washington, I often long for the realism and sincerity of Hollywood.", "Fred Thompson"],
  ["After you have exhausted what there is in business, politics, conviviality, and so on - have found that none of these finally satisfy, or permanently wear - what remains? Nature remains.", "Walt Whitman"],
  ["Against my will, in the course of my travels, the belief that everything worth knowing was known at Cambridge gradually wore off. In this respect my travels were very useful to me.", "Bertrand Russell"],
  ["Ah! what a divine religion might be found out if charity were really made the principle of it instead of faith.", "Percy Bysshe Shelley"],
  ["Ah, good taste, what a dreadful thing! Taste is the enemy of creativeness.", "Pablo Picasso"],
  ["Alas, after a certain age every man is responsible for his face.", "Albert Camus"],
  ["All are lunatics, but he who can analyze his delusion is called a philosopher.", "Ambrose Bierce"],
  ["All art is autobiographical; the pearl is the oyster's autobiography.", "Federico Fellini"],
  ["All change is not growth, as all movement is not forward.", "Ellen Glasgow"],
  ["All changes, even the most longed for, have their melancholy; for what we leave behind us is a part of ourselves; we must die to one life before we can enter another.", "Anatole France"],
  ["All crime is a kind of disease and should be treated as such.", "Mahatma Gandhi"],
  ["All envy would be extinguished, if it were universally known that there are none to be envied.", "Samuel Johnson"],
  ["All great masters are chiefly distinguished by the power of adding a second, a third, and perhaps a fourth step in a continuous line. Many a man had taken the first step. With every additional step you enhance immensely the value of your first.", "Ralph Waldo Emerson"],
  ["All great truths begin as blasphemies.", "George Bernard Shaw"],
  ["All high truth is poetry. Take the results of science: they glow with beauty, cold and hard as are the methods of reaching them.", "Charles Buxton"],
  ["All human beings should try to learn before they die what they are running from, and to, and why.", "James Thurber"],
  ["All I ask is this: Do something. Try something. Speaking out, showing up, writing a letter, a check, a strongly worded e-mail. Pick a cause - there are few unworthy ones. And nudge yourself past the brink of tacit support to action. Once a month, once a year, or just once.", "Joss Whedon"],
  ["All I really need to know about how to live and what to do and how to be, I learned in kindergarten. Wisdom was not at the top of the graduate-school mountain, but there in the sand pile at Sunday School. These are the things I learned. These are the things you already know: Share everything. Play fair. Don't hit people. Put things back where you found them. Clean up your own mess. Don't take things that aren't yours. Say you're sorry when you hurt somebody.", "Robert Fulghum"],
  ["All ideas are already in the brain, just as all statues are in the marble.", "Carlo Dossi"],
  ["All know that the drop merges into the ocean but few know that the ocean merges into the drop.", "Kabir"],
  ["All labor that uplifts humanity has dignity and importance and should be undertaken with painstaking excellence.", "Martin Luther King, Jr"],
  ["All men - whether they go by the name of Americans or Russians or Chinese or British or Malayans or Indians or Africans - have obligations to one another that transcend their obligations to their sovereign societies.", "Norman Cousins"],
  ["All men are brothers, we like to say, half-wishing sometimes in secret it were not true. But perhaps it is true. And is the evolutionary line from protozoan to Spinoza any less certain? That also may be true. We are obliged, therefore, to spread the news, painful and bitter though it may be for some to hear, that all living things on earth are kindred.", "Edward Abbey"],
  ["All men whilst they are awake are in one common world: but each of them, when he is asleep, is in a world of his own.", "Plutarch"],
  ["All my life I've looked at words as though I were seeing them for the first time.", "Ernest Hemingway"],
  ["All of life is a foreign country.", "Jack Kerouac"],
  ["All one's life as a young woman one is on show, a focus of attention, people notice you. You set yourself up to be noticed and admired. And then, not expecting it, you become middle-aged and anonymous. No one notices you. You achieve a wonderful freedom. It's a positive thing. You can move about unnoticed and invisible.", "Doris Lessing"],
  ["All our words are but crumbs that fall down from the feast of the mind.", "Khalil Gibran"],
  ["All political parties die at last of swallowing their own lies.", "John Arbuthnot"],
  ["All progress has resulted from people who took unpopular positions.", "Adlai Stevenson"],
  ["All religions are the same: religion is basically guilt, with different holidays.", "Cathy Ladman"],
  ["All religions united with government are more or less inimical to liberty. All, separated from government, are compatible with liberty.", "Henry Clay"],
  ["All restraints upon man's natural liberty, not necessary for the simple maintenance of justice, are of the nature of slavery, and differ from each other only in degree.", "Lysander Spooner"],
  ["All slang is metaphor, and all metaphor is poetry.", "G.K. Chesterton"],
  ["All sunshine makes a desert.", "Arabic proverb"],
  ["All that glisters is not gold; / Often have you heard that told: / Many a man his life hath sold / But my outside to behold: / Gilded tombs do worms enfold.", "William Shakespeare"],
  ["All the arguments to prove man's superiority cannot shatter this hard fact: in suffering the animals are our equals.", "Peter Singer"],
  ["All the time a person is a child he is both a child and learning to be a parent. After he becomes a parent he becomes predominantly a parent reliving childhood.", "Benjamin Spock"],
  ["All the world's a stage, / And the men and women merely players: / They have their exits and their entrances; / And one man in his time plays many parts.", "William Shakespeare"],
  ["All visible objects, man, are but as pasteboard masks. But in each event - in the living act, the undoubted deed - there, some unknown but still reasoning thing puts forth the mouldings of its features from behind the unreasoning mask.", "Herman Melville"],
  ["All wholesome food is caught without a net or trap.", "William Blake"],
  ["All words are pegs to hang ideas on.", "Henry Ward Beecher"],
  ["Almost all our faults are more pardonable than the methods we resort to to hide them.", "François de La Rochefoucauld"],
  ["Although a democracy must often fight with one hand tied behind its back, it nonetheless has the upper hand.", "Aharon Barak"],
  ["Although men flatter themselves with their great actions, they are not so often the result of a great design as of chance.", "François de La Rochefoucauld"],
  ["Although the connections are not always obvious, personal change is inseparable from social and political change.", "Harriet Lerner"],
  ["Always, Sir, set a high value on spontaneous kindness. He whose inclination prompts him to cultivate your friendship of his own accord, will love you more than one whom you have been at pains to attach to you.", "Samuel Johnson"],
  ["Am I not destroying my enemies when I make friends of them?", "Abraham Lincoln"],
  ["Ambition is like hunger; it obeys no law but its appetite.", "Josh Billings"],
  ["America has changed over the years. But these values my grandparents taught me - they haven't gone anywhere. They're as strong as ever; still cherished by people of every party, every race, every faith. They live on in each of us. What makes us American, what makes us patriots, is what's in here. That's what matters. And that's why we can take the food and music and holidays and styles of other countries, and blend it into something uniquely our own. That's why we can attract strivers and entrepreneurs from around the globe to build new factories and create new industries here. That's why our military can look the way it does - every shade of humanity, forged into common service. That's why anyone who threatens our values, whether fascists or communists or jihadists or homegrown demagogues, will always fail in the end.", "Barack Obama"],
  ["America will never be destroyed from the outside. If we falter, and lose our freedoms, it will be because we destroyed ourselves.", "Abraham Lincoln"],
  ["Among individuals, as among nations, respect for the rights of others is peace.", "Benito Juárez"],
  ["Among men, it seems, historically at any rate, that processes of co-ordination and disintegration follow each other with great regularity, and the index of the co-ordination is the measure of the disintegration which follows. There is no mob like a group of well-drilled soldiers when they have thrown off their discipline. And there is no lostness like that which comes to a man when a perfect and certain pattern has dissolved about him. There is no hater like one who has greatly loved.", "John Steinbeck"],
  ["An act of goodness is of itself an act of happiness. No reward coming after the event can compare with the sweet reward that went with it.", "Maurice Maeterlinck"],
  ["An age is called Dark not because the light fails to shine, but because people refuse to see it.", "James Albert Michener"],
  ["An artist is not paid for his labor but for his vision.", "James McNeill Whistler"],
  ["An artist should never be a prisoner of himself, prisoner of style, prisoner of reputation, prisoner of success, etc.", "Henri Matisse"],
  ["An education isn't how much you have committed to memory, or even how much you know. It's being able to differentiate between what you know and what you don't.", "Anatole France"],
  ["An epoch will come when people will disclaim kinship with us as we disclaim kinship with the monkeys.", "Khalil Gibran"],
  ["An expert is a man who has made all the mistakes which can be made in a very narrow field.", "Niels Bohr"],
  ["An honest man speaks the truth, though it may give offence; a vain man, in order that it may.", "William Hazlitt"],
  ["An idea is not responsible for the people who believe in it.", "Don Marquis"],
  ["An individual human existence should be like a river: small at first, narrowly contained within its banks, and rushing passionately past rocks and over waterfalls. Gradually the river grows wider, the banks recede, the waters flow more quietly, and in the end, without any visible break, they become merged in the sea, and painlessly lose their individual being.", "Bertrand Russell"],
  ["An institution is the lengthened shadow of one man.", "Ralph Waldo Emerson"],
  ["An old miser kept a tame jackdaw, that used to steal pieces of money, and hide them in a hole, which a cat observing, asked, \"Why he would hoard up those round shining things that he could make no use of?\" \"Why,\" said the jackdaw, \"my master has a whole chestful, and makes no more use of them than I do.\"", "Jonathan Swift"],
  ["An open mind is a prerequisite to an open heart.", "Robert M. Sapolsky"],
  ["An optimist is a person who sees a green light everywhere, while a pessimist sees only the red stoplight... The truly wise person is color-blind.", "Albert Schweitzer"],
  ["An unhurried sense of time is in itself a form of wealth.", "Bonnie Friedman"],
  ["And if there were a God, I think it very unlikely that He would have such an uneasy vanity as to be offended by those who doubt His existence.", "Bertrand Russell"],
  ["And none will hear the postman's knock / Without a quickening of the heart. / For who can bear to feel himself forgotten?", "W.H. Auden"],
  ["And Silence, like a poultice, comes / To heal the blows of sound.", "Oliver Wendell Holmes, Sr"],
  ["And the evil is done in hopes that evil surrenders / But the deeds of the devil are burned too deep in the embers / And a world of hunger in vengeance will always remember.", "Phil Ochs"],
  ["And the fox said to the little prince: men have forgotten this truth, but you must not forget it. You become responsible, forever, for what you have tamed.", "Antoine de Saint-Exupery"],
  ["And this our life, exempt from public haunt, / Finds tongues in trees, books in the running brooks, / Sermons in stones, and good in everything.", "William Shakespeare"],
  ["And those who were seen dancing were thought to be insane by those who could not hear the music.", "Friedrich Nietzsche"],
  ["Anger as soon as fed is dead- / 'Tis starving makes it fat.", "Emily Dickinson"],
  ["Anger is a great force. If you control it, it can be transmuted into a power which can move the whole world.", "William Shenstone"],
  ["Anger, if not restrained, is frequently more hurtful to us than the injury that provokes it.", "Lucius Annaeus Seneca"],
  ["Animal factories are one more sign of the extent to which our technological capacities have advanced faster than our ethics.", "Peter Singer"],
  ["Animals cannot speak, but can you and I not speak for them and represent them? Let us all feel their silent cry of agony and let us all help that cry to be heard in the world.", "Rukmini Devi Arundale"],
  ["Animals give me more pleasure through the viewfinder of a camera than they ever did in the crosshairs of a gunsight. And after I've finished \"shooting\", my unharmed victims are still around for others to enjoy.", "Jimmy Stewart"],
  ["Animals have these advantages over man: they never hear the clock strike, they die without any idea of death, they have no theologians to instruct them, their last moments are not disturbed by unwelcome and unpleasant ceremonies, their funerals cost them nothing, and no one starts lawsuits over their wills.", "Voltaire"],
  ["Any advertisement in public space that gives you no choice whether you see it or not is yours. It belongs to you. It's yours to take, re-arrange, and re-use. Asking for permission is like asking to keep a rock someone just threw at your head.", "Banksy"],
  ["Any fine morning, a power saw can fell a tree that took a thousand years to grow.", "Edwin Way Teale"],
  ["Any fool can destroy trees. They cannot run away; and if they could, they would still be destroyed, chased and hunted down as long as fun or a dollar could be got out of their bark hides, branching horns, or magnificent bole backbones. Few that fell trees plant them; nor would planting avail much towards getting back anything like the noble primeval forests.", "John Muir"],
  ["Any life, no matter how long and complex it may be, is made up of a single moment - the moment in which a man finds out, once and for all, who he is.", "Jorge Luis Borges"],
  ["Any sufficiently advanced technology is indistinguishable from magic.", "Arthur C. Clarke"],
  ["Anyone can be passionate, but it takes real lovers to be silly.", "Rose Franken"],
  ["Anyone can do any amount of work provided it isn't the work he is supposed to be doing at the moment.", "Robert Benchley"],
  ["Anyone who has accustomed himself to regard the life of any living creature as worthless is in danger of arriving also at the idea of worthless human lives.", "Albert Schweitzer"],
  ["Anyone who has ever looked into the glazed eyes of a soldier dying on the battlefield will think hard before starting a war.", "Otto von Bismarck"],
  ["Anyone who wishes to become a good writer should endeavour, before he allows himself to be tempted by the more showy qualities, to be direct, simple, brief, vigorous, and lucid.", "H.W. Fowler"],
  ["apologize, v.i.: To lay the foundation for a future offence.", "Ambrose Bierce"],
  ["Appeal, v.t. In law, to put the dice into the box for another throw.", "Ambrose Bierce"],
  ["Applause is the spur of noble minds, the end and aim of weak ones.", "Charles Caleb Colton"],
  ["Architecture is inhabited sculpture.", "Constantin Brancusi"],
  ["Arguments over grammar and style are often as fierce as those over Windows versus Mac, and as fruitless as Coke versus Pepsi or boxers versus briefs.", "Jack Lynch"],
  ["Art is like baby shoes. When you coat them with gold, they can no longer be worn.", "John Updike"],
  ["Art is partly communication, but only partly. The rest is discovery.", "William Golding"],
  ["Art is the elimination of the unnecessary.", "Pablo Picasso"],
  ["Art should be like a holiday: something to give a man the opportunity to see things differently and to change his point of view.", "Paul Klee"],
  ["As a general truth, communities prosper and flourish, or droop and decline, in just the degree that they practise or neglect to practise the primary duties of justice and humanity.", "William Henry Seward"],
  ["As a well spent day brings happy sleep, so life well used brings happy death.", "Leonardo da Vinci"],
  ["As against having beautiful workshops, studios, etc., one writes best in a cellar on a rainy day.", "Van Wyck Brooks"],
  ["As an adolescent I aspired to lasting fame, I craved factual certainty, and I thirsted for a meaningful vision of human life - so I became a scientist. This is like becoming an archbishop so you can meet girls.", "Matt Cartmill"],
  ["As far as I'm concerned, 'whom' is a word that was invented to make everyone sound like a butler.", "Calvin Trillin"],
  ["As freely as the firmament embraces the world, / or the sun pours forth impartially his beams, / so mercy must encircle both friend and foe.", "Johann Christoph Friedrich von Schiller"],
  ["As I grow to understand life less and less, I learn to live it more and more.", "Jules Renard"],
  ["As I have not worried to be born, I do not worry to die.", "Federico García Lorca"],
  ["As I would not be a slave, so I would not be a master. This expresses my idea of democracy.", "Abraham Lincoln"],
  ["As long as a man stands in his own way, everything seems to be in his way.", "Ralph Waldo Emerson"],
  ["As long as there are slaughterhouses there will be battlefields.", "Leo Tolstoy"],
  ["As nightfall does not come at once, neither does oppression. In both instances there is a twilight when everything remains seemingly unchanged. And it is in such twilight that we all must be most aware of change in the air - however slight - lest we become unwitting victims of the darkness.", "William Orville Douglas"],
  ["As no roads are so rough as those that have just been mended, so no sinners are so intolerant as those that have just turned saints.", "Charles Caleb Colton"],
  ["As often as Herman had witnessed the slaughter of animals and fish, he always had the same thought: in their behavior toward creatures, all men were Nazis.", "Isaac Bashevis Singer"],
  ["As regards intellectual work, it remains a fact, indeed, that great decisions in the realms of thought and momentous discoveries and solutions of problems are only possible to an individual working in solitude.", "Sigmund Freud"],
  ["As scarce as truth is, the supply has always been in excess of the demand.", "Josh Billings"],
  ["As societies grow decadent, the language grows decadent, too. Words are used to disguise, not to illuminate, action: you liberate a city by destroying it. Words are to confuse, so that at election time people will solemnly vote against their own interests.", "Gore Vidal"],
  ["As soon as man does not take his existence for granted, but beholds it as something unfathomably mysterious, thought begins.", "Albert Schweitzer"],
  ["As the pain that can be told is but half a pain, so the pity that questions has little healing in its touch.", "Edith Wharton"],
  ["As the State is a soulless machine, it can never be weaned from violence to which it owes its very existence.", "Mahatma Gandhi"],
  ["As the traveler who has once been from home is wiser than he who has never left his own doorstep, so a knowledge of one other culture should sharpen our ability to scrutinize more steadily, to appreciate more lovingly, our own.", "Margaret Mead"],
  ["As to conforming outwardly and living your own life inwardly, I do not think much of that.", "Henry David Thoreau"],
  ["Assassination: The extreme form of censorship.", "George Bernard Shaw"],
  ["Assumptions are the termites of relationships.", "Henry Winkler"],
  ["At a dinner party one should eat wisely but not too well, and talk well but not too wisely.", "W. Somerset Maugham"],
  ["At bottom, every man knows perfectly well that he is a unique being, only once on this earth; and by no extraordinary chance will such a marvelously picturesque piece of diversity in unity as he is, ever be put together a second time.", "Friedrich Nietzsche"],
  ["At least half the mystery novels published violate the law that the solution, once revealed, must seem to be inevitable.", "Raymond Chandler"],
  ["At least one way of measuring the freedom of any society is the amount of comedy that is permitted, and clearly a healthy society permits more satirical comment than a repressive, so that if comedy is to function in some way as a safety release then it must obviously deal with these taboo areas. This is part of the responsibility we accord our licensed jesters, that nothing be excused the searching light of comedy. If anything can survive the probe of humour it is clearly of value, and conversely all groups who claim immunity from laughter are claiming special privileges which should not be granted.", "Eric Idle"],
  ["At the beginning and at the end of love, the two lovers are embarrassed to find themselves alone.", "Jean de la Bruyere"],
  ["At the bottom of a good deal of the bravery that appears in the world there lurks a miserable cowardice. Men will face powder and steel because they cannot face public opinion.", "Edwin Hubbel Chapin"],
  ["At times it may be necessary to temporarily accept a lesser evil, but one must never label a necessary evil as good.", "Margaret Mead"],
  ["Authority without wisdom is like a heavy axe without an edge: fitter to bruise than polish.", "Anne Bradstreet"],
  ["Autumn is a second spring when every leaf is a flower.", "Albert Camus"],
  ["Avoid the necessity of those overgrown military establishments, which, under any form of government, are inauspicious to liberty, and which are to be regarded as particularly hostile to republican liberty.", "George Washington"],
  ["Bad officials are elected by good citizens who do not vote.", "George Jean Nathan"],
  ["Bad weather always looks worse through a window.", "Tom Lehrer"],
  ["Bare lists of words are found suggestive to an imaginative and excited mind.", "Ralph Waldo Emerson"],
  ["Barricades of ideas are worth more than barricades of stones.", "José Martí"],
  ["Be a Columbus to whole new continents and worlds within you, opening new channels, not of trade, but of thought.", "Henry David Thoreau"],
  ["Be a lamp, or a lifeboat, or a ladder. Help someone's soul heal. Walk out of your house like a shepherd.", "Jalaluddin Rumi"],
  ["Be ashamed to die until you have won some victory for humanity.", "Horace Mann"],
  ["Be careful how you interpret the world: it *is* like that.", "Erich Heller"],
  ["Be civil to all, sociable to many, familiar with few, friend to one, enemy to none.", "Benjamin Franklin"],
  ["Be good and you will be lonesome.", "Mark Twain"],
  ["Be humble, if thou would'st attain to wisdom. Be humbler still, when wisdom thou hast mastered.", "Helena Petrovna Hahn Blavatsky"],
  ["Be kind to thy father, for when thou wert young, / Who loved thee so fondly as he? / He caught the first accents that fell from thy tongue, / And joined in thy innocent glee.", "Margaret Courtney"],
  ["Be like the bird, who halting in his flight / On limb too slight, / Feels it give way beneath him, yet sings / Knowing he has wings.", "Victor Hugo"],
  ["Be master of your petty annoyances and conserve your energies for the big, worthwhile things. It isn't the mountain ahead that wears you out - it's the grain of sand in your shoe.", "Robert Service"],
  ["Be not too hasty to trust or admire the teachers of morality; they discourse like angels but they live like men.", "Samuel Johnson"],
  ["Be regular and orderly in your life so that you may be violent and original in your work.", "Gustave Flaubert"],
  ["Be thou the first true merit to befriend, his praise is lost who stays till all commend.", "Alexander Pope"],
  ["Be very circumspect in the choice of thy company. In the society of thine equals thou shalt enjoy more pleasure; in the society of thine superiors thou shalt find more profit. To be the best in the company is the way to grow worse; the best means to grow better is to be the worst there.", "Francis Quarles"],
  ["Be yourself and do not feign affection. Neither be cynical about love; in the face of all aridity and disenchantment it is as perennial as the grass.", "Max Ehrmann"],
  ["Beautiful young people are accidents of nature, but beautiful old people are works of art.", "Eleanor Roosevelt"],
  ["Beauty is the purgation of superfluities.", "Michelangelo Buonarroti"],
  ["Because no battle is ever won he said. They are not even fought. The field only reveals to man his own folly and despair, and victory is an illusion of philosophers and fools.", "William Faulkner"],
  ["Because the heart beats under a covering of hair, of fur, feathers, or wings, it is, for that reason, to be of no account?", "Jean Paul Richter"],
  ["Because we don't understand the brain very well we're constantly tempted to use the latest technology as a model for trying to understand it. In my childhood we were always assured that the brain was a telephone switchboard. (What else could it be?) And I was amused to see that Sherrington, the great British neuroscientist, thought that the brain worked like a telegraph system. Freud often compared the brain to hydraulic and electromagnetic systems. Leibniz compared it to a mill, and now, obviously, the metaphor is the digital computer.", "John R. Searle"],
  ["Before we set our hearts too much on anything, let us examine how happy are those who already possess it.", "François de La Rochefoucauld"],
  ["Being rich is having money; being wealthy is having time.", "Stephen Swid"],
  ["Belief in the supernatural reflects a failure of the imagination.", "Edward Abbey"],
  ["Belief like any other moving body follows the path of least resistance.", "Samuel Butler"],
  ["Besides the noble art of getting things done, there is the noble art of leaving things undone. The wisdom of life consists in the elimination of nonessentials.", "Lin Yutang"],
  ["Best they honor thee Who honor in thee only what is best.", "William Watson"],
  ["Better keep yourself clean and bright; you are the window through which you must see the world.", "George Bernard Shaw"],
  ["Better to write for yourself and have no public, than to write for the public and have no self.", "Cyril Connolly"],
  ["Between men and women there is no friendship possible. There is passion, enmity, worship, love, but no friendship.", "Oscar Wilde"],
  ["Between truth and the search for truth, I opt for the second.", "Bernard Berenson"],
  ["Beware of bugs in the above code; I have only proved it correct, not tried it.", "Donald Knuth"],
  ["Beware of the stories you read or tell; subtly, at night, beneath the waters of consciousness, they are altering your world.", "Ben Okri"],
  ["Beware the fury of the patient man.", "John Dryden"],
  ["Beware the irrational, however seductive. Shun the 'transcendent' and all who invite you to subordinate or annihilate yourself. Distrust compassion; prefer dignity for yourself and others. Don't be afraid to be thought arrogant or selfish. Picture all experts as if they were mammals. Never be a spectator of unfairness or stupidity. Seek out argument and disputation for their own sake; the grave will supply plenty of time for silence.", "Christopher Hitchens"],
  ["Big jobs usually go to the men who prove their ability to outgrow small ones.", "Ralph Waldo Emerson"],
  ["Bigotry tries to keep truth safe in its hand with a grip that kills it.", "Rabindranath Tagore"],
  ["Biographical history, as taught in our public schools, is still largely a history of boneheads: ridiculous kings and queens, paranoid political leaders, compulsive voyagers, ignorant generals, the flotsam and jetsam of historical currents. The men who radically altered history, the great creative scientists and mathematicians, are seldom mentioned if at all.", "Martin Gardner"],
  ["Black holes are where God divided by zero.", "Steven Wright"],
  ["Blessed is he who has found his work; let him ask no other blessedness.", "Thomas Carlyle"],
  ["Blessed is he who has learned to laugh at himself, for he shall never cease to be entertained.", "John Powell"],
  ["Books are like imprisoned souls till someone takes them down from a shelf and frees them.", "Samuel Butler"],
  ["Books are the bees which carry the quickening pollen from one to another mind.", "James Russell Lowell"],
  ["Books are the quietest and most constant of friends; they are the most accessible and wisest of counsellors, and the most patient of teachers.", "Charles W. Eliot"],
  ["Books written out of fire give me a great deal of pleasure. You get the sense that the world for these writers could not have continued if the book hadn't been written. When you come across a book like that it is a privilege.", "Hisham Matar"],
  ["Boredom is a vital problem for the moralist, since at least half of the sins of mankind are caused by the fear of it.", "Bertrand Russell"],
  ["Boredom is the feeling that everything is a waste of time; serenity, that nothing is.", "Thomas Szasz"],
  ["Bullets cannot be recalled. They cannot be uninvented. But they can be taken out of the gun.", "Martin Amis"],
  ["But man, proud man, / Drest in a little brief authority, / Most ignorant of what he's most assured, / His glassy essence, like an angry ape, / Plays such fantastic tricks before high heaven / As make the angels weep.", "William Shakespeare"],
  ["But they are useless. They can only give you answers.", "Pablo Picasso", "on computers"],
  ["But what then is capital punishment but the most premeditated of murders, to which no criminal's deed, however calculated it may be, can be compared? For there to be equivalence, the death penalty would have to punish a criminal who had warned his victim of the date at which he would inflict a horrible death on him and who, from that moment onward, had confined him at his mercy for months. Such a monster is not encountered in private life.", "Albert Camus"],
  ["Buying books would be a good thing if one could also buy the time to read them in: but as a rule the purchase of books is mistaken for the appropriation of their contents.", "Arthur Schopenhauer"],
  ["By a free country, I mean a country where people are allowed, so long as they do not hurt their neighbours, to do as they like. I do not mean a country where six men may make five men do exactly as they like.", "Robert Cecil"],
  ["By all means marry. If you get a good wife, you'll be happy. If you get a bad one, you'll become a philosopher.", "Socrates"],
  ["By plucking her petals, you do not gather the beauty of the flower.", "Rabindranath Tagore"],
  ["By the age of six the average child will have completed the basic American education. ... From television, the child will have learned how to pick a lock, commit a fairly elaborate bank holdup, prevent wetness all day long, get the laundry twice as white, and kill people with a variety of sophisticated armaments.", "Russell Baker"],
  ["By three methods we may learn wisdom: First, by reflection, which is noblest; second, by imitation, which is easiest; and third, by experience, which is the bitterest.", "Confucius"],
  ["By trying to make things easier for their children parents can make things much harder for them.", "Mardy Grothe"],
  ["By words the mind is winged.", "Aristophanes"],
  ["Calamity, n. A more than commonly plain and unmistakable reminder that the affairs of this life are not of our own ordering. Calamities are of two kinds: misfortune to ourselves, and good fortune to others.", "Ambrose Bierce"],
  ["Can anything be sadder than work left unfinished? Yes, work never begun.", "Christina Rossetti"],
  ["Catch-and-release, that's like running down pedestrians in your car and then, when they get up and limp away, saying - Off you go! That's fine. I just wanted to see if I could hit you.", "Ellen DeGeneres"],
  ["Certainly virtue is like precious odours, most fragrant when they are incensed, or crushed: for prosperity doth best discover vice, but adversity doth best discover virtue.", "Francis Bacon"],
  ["Cheerfulness and contentment are great beautifiers and are famous preservers of youthful looks.", "Charles Dickens"],
  ["Children aren't happy without something to ignore, And that's what parents were created for.", "Ogden Nash"],
  ["Children enter school as question marks and leave as periods.", "Neil Postman"],
  ["Children have never been very good at listening to their elders, but they have never failed to imitate them.", "James Baldwin"],
  ["Choose only one master - Nature.", "Rembrandt"],
  ["Choose the life that is most useful, and habit will make it the most agreeable.", "Francis Bacon"],
  ["Civilizations in decline are consistently characterised by a tendency towards standardization and uniformity.", "Arnold J. Toynbee"],
  ["Clear thinking requires courage rather than intelligence.", "Thomas Szasz"],
  ["Co-existence / or no existence.", "Piet Hein"],
  ["Come, live in my heart and pay no rent.", "Samuel Lover"],
  ["Comedy has to be based on truth. You take the truth and you put a little curlicue at the end.", "Sid Caesar"],
  ["Compared to the drama of words, Hamlet is a light farce.", "Anatoly Liberman"],
  ["Compassion is not weakness and concern for the unfortunate is not socialism.", "Hubert Humphrey"],
  ["Compassion is the basis of morality.", "Arthur Schopenhauer"],
  ["Compassion will cure more sins than condemnation.", "Henry Ward Beecher"],
  ["Compassion, in which all ethics must take root, can only attain its full breadth and depth if it embraces all living creatures and does not limit itself to mankind.", "Albert Schweitzer"],
  ["Confusion is a word we have invented for an order which is not understood.", "Henry Miller"],
  ["Congratulation: The civility of envy.", "Ambrose Bierce"],
  ["Conscience is a dog that does not stop us from passing but that we cannot prevent from barking.", "Sebastien-Roch Nicolas de Chamfort"],
  ["Conscience is a man's compass, and though the needle sometimes deviates, though one often perceives irregularities when directing one's course by it, one must still try to follow its direction.", "Vincent van Gogh"],
  ["Conscience is thoroughly well-bred and soon leaves off talking to those who do not wish to hear it.", "Samuel Butler"],
  ["Constant kindness can accomplish much. As the sun makes ice melt, kindness causes misunderstanding, mistrust, and hostility to evaporate.", "Albert Schweitzer"],
  ["Contempt is the weapon of the weak and a defense against one's own despised and unwanted feelings.", "Alice Miller"],
  ["Content thyself to be obscurely good. When vice prevails, and impious men bear sway, the post of honor is a private station.", "Joseph Addison"],
  ["Contentment is, after all, simply refined indolence.", "Thomas Chandler Haliburton"],
  ["Contradiction is not a sign of falsity, nor the lack of contradiction a sign of truth.", "Blaise Pascal"],
  ["Conversation, n. A fair to the display of the minor mental commodities, each exhibitor being too intent upon the arrangement of his own wares to observe those of his neighbor.", "Ambrose Bierce"],
  ["Convictions are more dangerous enemies of truth than lies.", "Friedrich Nietzsche"],
  ["Convulsions in nature, disorders, prodigies, miracles, though the most opposite to the plan of a wise superintendent, impress mankind with the strongest sentiments of religion.", "David Hume"],
  ["Corporation: n. An ingenious device for obtaining individual profit without individual responsibility.", "Ambrose Bierce"],
  ["Count no day lost in which you waited your turn, took only your share and sought advantage over no one.", "Robert Brault"],
  ["Courage is not the towering oak that sees storms come and go; it is the fragile blossom that opens in the snow.", "Alice M. Swaim"],
  ["Courage is resistance to fear, mastery of fear - not absence of fear.", "Mark Twain"],
  ["Courage is the price that life exacts for granting peace. The soul that knows it not, knows no release from little things.", "Amelia Earhart"],
  ["Courage without conscience is a wild beast.", "Robert Green Ingersoll"],
  ["Courtship to marriage, as a very witty prologue to a very dull play.", "William Congreve"],
  ["Cowardice asks the question, 'Is it safe?' Expediency asks the question, 'Is it politic?' Vanity asks the question, 'Is it popular?' But, conscience asks the question, 'Is it right?' And there comes a time when one must take a position that is neither safe, nor politic, nor popular but one must take it because one's conscience tells one that it is right.", "Martin Luther King, Jr"],
  ["Creative activity could be described as a type of learning process where teacher and pupil are located in the same individual.", "Arthur Koestler"],
  ["Creative minds are uneven, and the best of fabrics have their dull spots.", "HP Lovecraft"],
  ["Creativity is allowing yourself to make mistakes. Art is knowing which ones to keep.", "Scott Adams"],
  ["Crime is contagious. If the government becomes a lawbreaker, it breeds contempt for law.", "Louis Brandeis"],
  ["critic, n. A person who boasts himself hard to please because nobody tries to please him.", "Ambrose Bierce"],
  ["Crown: A headgear that makes the head superfluous.", "Gabriel Laub"],
  ["Curiosity is the lust of the mind.", "Thomas Hobbes"],
  ["Cut these words and they would bleed; they are vascular and alive; they walk and run.", "Ralph Waldo Emerson"],
  ["D'you call life a bad job? Never! We've had our ups and downs, we've had our struggles, we've always been poor, but it's been worth it, ay, worth it a hundred times I say when I look round at my children.", "W. Somerset Maugham"],
  ["Dalton's records, carefully preserved for a century, were destroyed during the World War II bombing of Manchester. It is not only the living who are killed in war.", "Isaac Asimov"],
  ["Daring ideas are like chessmen moved forward. They may be beaten, but they may start a winning game.", "Johann Wolfgang von Goethe"],
  ["Death destroys the body, as the scaffolding is destroyed after the building is up and finished. And he whose building is up rejoices at the destruction of the scaffolding and of the body.", "Leo Tolstoy"],
  ["Death is a friend of ours; and he that is not ready to entertain him is not at home.", "Francis Bacon"],
  ["Death is not extinguishing the light; it is putting out the lamp because the dawn has come.", "Rabindranath Tagore"],
  ["Death must be so beautiful. To lie in the soft brown earth, with the grasses waving above one's head, and listen to silence. To have no yesterday, and no tomorrow. To forget time, to forgive life, to be at peace.", "Oscar Wilde"],
  ["Death tugs at my ear and says, \"Live, I am coming.\"", "Oliver Wendell Holmes, Sr"],
  ["Deep within the soul of the lonely caged bird / Beats the rhythm of a distant forest / Etched upon its broken heart / The faded memory of flight.", "Ginni Bly"],
  ["Democracy, to me, is liberty plus economic security.", "Maury Maverick"],
  ["Destroying species is like tearing pages out of an unread book, written in a language humans hardly know how to read, about the place where they live.", "Holmes Rolston III"],
  ["Dictionaries are like watches: the worst is better than none, and the best cannot be expected to go quite true.", "Samuel Johnson"],
  ["Dictionary: Opinion presented as truth in alphabetical order.", "John Ralston Saul"],
  ["Dictionary: The universe in alphabetical order.", "Anatole France"],
  ["Did you know that the worldwide food shortage that threatens up to five hundred million children could be alleviated at the cost of only one day, only ONE day, of modern warfare.", "Peter Ustinov"],
  ["Discontent is the first step in the progress of a man or a nation.", "Oscar Wilde"],
  ["Distrust all in whom the impulse to punish is powerful.", "Friedrich Nietzsche"],
  ["Do I believe God is going to take away my illness when he turned an entirely deaf ear to the six million Jews who went into the gas chambers?", "Karen Armstrong"],
  ["Do I love you because you're beautiful, or are you beautiful because I love you?", "Oscar Hammerstein II"],
  ["Do not be too moral. You may cheat yourself out of much of life. So aim above morality. Be not simply good; be good for something.", "Henry David Thoreau"],
  ["Do not be too quick to assume your enemy is a savage just because he is your enemy. Perhaps he is your enemy because he thinks you are a savage. Or perhaps he is afraid of you because he feels that you are afraid of him. And perhaps if he believed you are capable of loving him he would no longer be your enemy.", "Thomas Merton"],
  ["Do not believe that it is very much of an advance to do the unnecessary three times as fast.", "Peter Drucker"],
  ["Do not commit the error, common among the young, of assuming that if you cannot save the whole of mankind, you have failed.", "Jan de Hartog"],
  ["Do not condemn the judgment of another because it differs from your own. You may both be wrong.", "Dandamis"],
  ["Do not fear to be eccentric in opinion, for every opinion now accepted was once eccentric.", "Bertrand Russell"],
  ["Do not keep the slanderer away, / treat him with affection and honor: / Body and soul, he scours all clean, / babbling about this and that.", "Kabir"],
  ["Do not pray for tasks equal to your powers; pray for powers equal to your tasks.", "Phillips Brooks"],
  ["Do not think of knocking out another person's brains because he differs in opinion from you. It would be as rational to knock yourself on the head because you differ from yourself ten years ago.", "Horace Mann"],
  ["Do something for somebody every day for which you do not get paid.", "Albert Schweitzer"],
  ["Do unto those downstream as you would have those upstream do unto you.", "Wendell Berry"],
  ["Do we need weapons to fight wars? Or do we need wars to create markets for weapons?", "Arundhati Roy"],
  ["Do you love me because I'm beautiful, or am I beautiful because you love me?", "Oscar Hammerstein II"],
  ["Do you wish the world were happy? / Then remember day by day, / Just to scatter seeds of kindness / As you pass along the way.", "Ella Wheeler Wilcox"],
  ["Does feminist mean large unpleasant person who'll shout at you or someone who believes women are human beings? To me it's the latter, so I sign up.", "Margaret Atwood"],
  ["Don't ask me who's influenced me. A lion is made up of the lambs he's digested, and I've been reading all my life.", "Giorgos Seferis"],
  ["Don't be afraid to take a big step. You can't cross a chasm in two small jumps.", "David Lloyd George"],
  ["Don't be seduced into thinking that that which does not make a profit is without value.", "Arthur Miller"],
  ["Don't be yourself. Be someone a little nicer.", "Mignon McLaughlin"],
  ["Don't discuss yourself, for you are bound to lose; if you belittle yourself, you are believed; if you praise yourself, you are disbelieved.", "Michel de Montaigne"],
  ["Don't judge each day by the harvest you reap, but by the seeds you plant.", "Robert Louis Stevenson"],
  ["Don't judge men's wealth or godliness by their Sunday appearance.", "Benjamin Franklin"],
  ["Don't mistake pleasure for happiness. They're a different breed of dog.", "Josh Billings"],
  ["Don't say you don't have enough time. You have exactly the same number of hours per day that were given to Helen Keller, Pasteur, Michaelangelo, Mother Teresa, Leonardo da Vinci, Thomas Jefferson, and Albert Einstein.", "H. Jackson Brown, Jr"],
  ["Don't surrender your loneliness / So quickly. / Let it cut more deeply. / Let it ferment and season you / As few human / Or even divine ingredients can.", "Hafez"],
  ["Don't tell me the moon is shining; show me the glint of light on broken glass.", "Anton Chekhov"],
  ["Don't wait for the Last Judgment. It takes place every day.", "Albert Camus"],
  ["Doubt everything at least once, even the proposition that two times two equals four.", "Georg Christoph Lichtenberg"],
  ["Drama is life with the dull bits cut out.", "Alfred Hitchcock"],
  ["Dreaming permits each and every one of us to be quietly and safely insane every night of our lives.", "William C. Dement"],
  ["Dreams have only one owner at a time. That's why dreamers are lonely.", "Erma Bombeck"],
  ["Dreams heed no borders, the eyes need no visas. With eyes shut I walk across the line in time. All the time.", "Gulzar"],
  ["Dreams pass into the reality of action. From the actions stems the dream again; and this interdependence produces the highest form of living.", "Anais Nin"],
  ["During the first period of a man's life the greatest danger is not to take the risk. When once the risk has really been taken, then the greatest danger is to risk too much.", "Søren Kierkegaard"],
  ["During times of universal deceit, telling the truth becomes a revolutionary act.", "George Orwell"],
  ["Dying is not a crime.", "Jack Kevorkian"],
  ["Each friend represents a world in us, a world possibly not born until they arrive, and it is only by this meeting that a new world is born.", "Anais Nin"],
  ["Each has his past shut in him like the leaves of a book known to him by heart and his friends can only read the title.", "Virginia Woolf"],
  ["Each man carries within him the soul of a poet who died young.", "Charles Augustin Sainte-Beuve"],
  ["Each man takes care that his neighbor shall not cheat him. But a day comes when he begins to care that he does not cheat his neighbor. Then all goes well - he has changed his market-cart into a chariot of the sun.", "Ralph Waldo Emerson"],
  ["Earth is here so kind, that just tickle her with a hoe and she laughs with a harvest.", "Douglas William Jerrold"],
  ["Earth laughs in flowers.", "Ralph Waldo Emerson"],
  ["Easy reading is damned hard writing.", "Nathaniel Hawthorne"],
  ["Eat food. Not too much. Mostly plants.", "Michael Pollan"],
  ["Edible, adj.: Good to eat, and wholesome to digest, as a worm to a toad, a toad to a snake, a snake to a pig, a pig to a man, and a man to a worm.", "Ambrose Bierce"],
  ["Education is what survives when what has been learned has been forgotten.", "B.F. Skinner"],
  ["Education makes people easy to lead, but difficult to drive; easy to govern, but impossible to enslave.", "Henry Peter Brougham"],
  ["Education's purpose is to replace an empty mind with an open one.", "Malcolm S. Forbes"],
  ["Egotism is the anesthetic that dulls the pain of stupidity.", "Frank William Leahy"],
  ["Elitism is the slur directed at merit by mediocrity.", "Sydney J. Harris"],
  ["Eloquence is a painting of the thoughts.", "Blaise Pascal"],
  ["Elvira always lied first to herself before she lied to anybody else, since this gave her a conviction of moral honesty.", "Phyllis Bottome"],
  ["Emancipation from the bondage of the soil is no freedom for the tree.", "Rabindranath Tagore"],
  ["Eminent posts make great men greater, and little men less.", "Jean de la Bruyere"],
  ["Endless money forms the sinews of war.", "Marcus Tullius Cicero"],
  ["English usage is sometimes more than mere taste, judgment, and education - sometimes it's sheer luck, like getting across the street.", "E.B. White"],
  ["Errors like straws upon the surface flow: / Who would search for pearls must dive below.", "John Dryden"],
  ["Eternal suffering awaits anyone who questions God's infinite love.", "Bill Hicks"],
  ["Ethics, decency, and morality are the real soldiers.", "Kiran Bedi"],
  ["Ethiopians imagine their gods as black and snub-nosed; Thracians blue-eyed and red-haired. But if horses or lions had hands, or could draw and fashion works as men do, horses would draw the gods shaped like horses and lions like lions, making the gods resemble themselves.", "Xenophanes"],
  ["Euphemism is a euphemism for lying.", "Bobbie Gentry"],
  ["Even a lie is a psychic fact.", "Carl Jung"],
  ["Even in the worm that crawls in the earth there glows a divine spark. When you slaughter a creature, you slaughter God.", "Isaac Bashevis Singer"],
  ["Every act of conscious learning requires the willingness to suffer an injury to one's self-esteem. That is why young children, before they are aware of their own self-importance, learn so easily; and why older persons, especially if vain or important, cannot learn at all.", "Thomas Szasz"],
  ["Every advance in civilization has been denounced as unnatural while it was recent.", "Bertrand Russell"],
  ["Every age is fed on illusions, lest men should renounce life early and the human race come to an end.", "Joseph Conrad"],
  ["Every burned book or house enlightens the world; every suppressed or expunged word reverberates through the earth from side to side.", "Ralph Waldo Emerson"],
  ["Every child comes with the message that God is not yet tired of the man.", "Rabindranath Tagore"],
  ["Every child is an artist. The problem is how to remain an artist once he grows up.", "Pablo Picasso"],
  ["Every creator painfully experiences the chasm between his inner vision and its ultimate expression.", "Isaac Bashevis Singer"],
  ["Every creature is better alive than dead, men and moose and pine trees, and he who understands it aright will rather preserve its life than destroy it.", "Henry David Thoreau"],
  ["Every decent man is ashamed of the government he lives under.", "H.L. Mencken"],
  ["Every dewdrop and raindrop had a whole heaven within it.", "Henry Wadsworth Longfellow"],
  ["Every eighteen months, the minimum IQ necessary to destroy the world drops by one point.", "Eliezer S. Yudkowsky"],
  ["Every government has as much of a duty to avoid war as a ship's captain has to avoid a shipwreck.", "Guy de Maupassant"],
  ["Every great advance in natural knowledge has involved the absolute rejection of authority.", "Thomas Henry Huxley"],
  ["Every gun that is made, every warship launched, every rocket fired signifies, in the final sense, a theft from those who hunger and are not fed, those who are cold and are not clothed.", "Dwight D. Eisenhower"],
  ["Every human being's essential nature is perfect and faultless, but after years of immersion in the world we easily forget our roots and take on a counterfeit nature.", "Lao Tzu"],
  ["Every increased possession loads us with new weariness.", "John Ruskin"],
  ["Every man feels instinctively that all the beautiful sentiments in the world weigh less than a single lovely action.", "James Russell Lowell"],
  ["Every man in the world is better than someone else. And not as good as someone else.", "William Saroyan"],
  ["Every man is a creature of the age in which he lives, and few are able to raise themselves above the ideas of the time.", "Voltaire"],
  ["Every man is a damned fool for at least five minutes every day. Wisdom consists in not exceeding the limit.", "Elbert Hubbard"],
  ["Every man is a volume if you know how to read him.", "William Ellery Channing"],
  ["Every man is guilty of all the good he didn't do.", "Voltaire"],
  ["Every man is the son of his own works.", "Miguel de Cervantes"],
  ["Every man possesses three characters: that which he exhibits, that which he really has, and that which he believes he has.", "Jean-Baptiste Alphonse Karr"],
  ["Every man supposes himself not to be fully understood or appreciated.", "Ralph Waldo Emerson"],
  ["Every man thinks God is on his side. The rich and powerful know he is.", "Jean Anouilh"],
  ["Every man's memory is his private literature.", "Aldous Huxley"],
  ["Every man's work, whether it be literature, or music or pictures or architecture or anything else, is always a portrait of himself.", "Samuel Butler"],
  ["Every natural form - palm leaves and acorns, oak leaves and sumach and dodder - are untranslatable aphorisms.", "Henry David Thoreau"],
  ["Every new opinion, at its starting, is precisely in a minority of one.", "Thomas Carlyle"],
  ["Every noon as the clock hands arrive at twelve, / I want to tie the two arms together, / And walk out of the bank carrying time in bags.", "Robert Bly"],
  ["Every one is a moon, and has a dark side which he never shows to anybody.", "Mark Twain"],
  ["Every one of us is precious in the cosmic perspective. If a human disagrees with you, let him live. In a hundred billion galaxies, you will not find another.", "Carl Sagan"],
  ["Every other author may aspire to praise; the lexicographer can only hope to escape reproach.", "Samuel Johnson"],
  ["Every reader finds himself. The writer's work is merely a kind of optical instrument that makes it possible for the reader to discern what, without this book, he would perhaps never have seen in himself.", "Marcel Proust"],
  ["Every saint has a past and every sinner a future.", "Oscar Wilde"],
  ["Every sin is an attempt to fly from emptiness.", "Simone Weil"],
  ["Every society honors its live conformists and its dead troublemakers.", "Mignon McLaughlin"],
  ["Every student needs someone who says, simply, \"You mean something. You count.\"", "Tony Kushner"],
  ["Every time I see an adult on a bicycle, I no longer despair for the future of the human race.", "H.G. Wells"],
  ["Every word was once a poem.", "Ralph Waldo Emerson"],
  ["Everybody knows if you are too careful you are so occupied in being careful that you are sure to stumble over something.", "Gertrude Stein"],
  ["Everybody needs his memories. They keep the wolf of insignificance from the door.", "Saul Bellow"],
  ["Everybody's talking about people breaking into houses but there are more people in the world who want to break out of houses.", "Thornton Wilder"],
  ["Everybody, soon or late, sits down to a banquet of consequences.", "Robert Louis Stevenson"],
  ["Everyone confesses that exertion which brings out all the powers of body and mind is the best thing for us; but most people do all they can to get rid of it, and as a general rule nobody does much more than circumstances drive them to do.", "Harriet Beecher Stowe"],
  ["Everyone discusses my art and pretends to understand, as if it were necessary to understand, when it is simply necessary to love.", "Claude Monet"],
  ["Everyone is born with genius, but most people only keep it a few minutes.", "Edgard Varese"],
  ["Everyone is kneaded out of the same dough but not baked in the same oven.", "Yiddish proverb"],
  ["Everyone wishes to have truth on his side, but not everyone wishes to be on the side of truth.", "Richard Whately"],
  ["Everyone, in some small sacred sanctuary of the self, is nuts.", "Leo Rosten"],
  ["Everything secret degenerates, even the administration of justice; nothing is safe that does not show how it can bear discussion and publicity.", "Lord Acton"],
  ["Everything that irritates us about others can lead us to an understanding of ourselves.", "Carl Jung"],
  ["Everything you add to the truth subtracts from the truth.", "Aleksandr Solzhenitsyn"],
  ["Everywhere is walking distance if you have the time.", "Steven Wright"],
  ["Evil is like a shadow - it has no real substance of its own, it is simply a lack of light. You cannot cause a shadow to disappear by trying to fight it, stamp on it, by railing against it, or any other form of emotional or physical resistance. In order to cause a shadow to disappear, you must shine light on it.", "Shakti Gawain"],
  ["Examinations are formidable even to the best prepared, for the greatest fool may ask more than the wisest man can answer.", "Charles Caleb Colton"],
  ["Excellence in any department can be attained only by the labor of a lifetime; it is not to be purchased at a lesser price.", "Samuel Johnson"],
  ["Experience is not what happens to a man; it is what a man does with what happens to him.", "Aldous Huxley"],
  ["Experience makes us see an enormous difference between piety and goodness.", "Blaise Pascal"],
  ["Experience teaches us to be most on our guard to protect liberty when the government's purpose is beneficent. Men born to freedom are naturally alert to repel invasion of their liberty by evil-minded rulers. The greatest dangers to liberty lurk in insidious encroachment by men of zeal, well-meaning but without understanding.", "Louis Dembitz Brandeis"],
  ["Experts have / their expert fun / ex cathedra / telling one / just how nothing / can be done.", "Piet Hein"],
  ["Extended empires are like expanded gold, exchanging solid strength for feeble splendor.", "Samuel Johnson"],
  ["Extraordinary claims require extraordinary evidence.", "Carl Sagan"],
  ["Extreme justice is extreme injustice.", "Marcus Tullius Cicero"],
  ["Eyes are vocal, tears have tongues, / And there are words not made with lungs.", "Richard Crashaw"],
  ["Faced with the choice between changing one's mind and proving that there is no need to do so, almost everyone gets busy on the proof.", "John Kenneth Galbraith"],
  ["Facts do not cease to exist because they are ignored.", "Aldous Huxley"],
  ["Failure is the condiment that gives success its flavor.", "Truman Capote"],
  ["Faith is a cop-out. If the only way you can accept an assertion is by faith, then you are conceding that it can't be taken on its own merits.", "Dan Barker"],
  ["Faith is believing what you know ain't so.", "Mark Twain"],
  ["Faith which does not doubt is dead faith.", "Miguel de Unamuno"],
  ["Fame is a bee. / It has a song / It has a sting / Ah, too, it has a wing.", "Emily Dickinson"],
  ["Fame is a vapor; popularity an accident; the only earthly certainty is oblivion.", "Mark Twain"],
  ["Fame is very agreeable, but the bad thing is that it goes on 24 hours a day.", "Gabriel García Márquez"],
  ["Familiarity is a magician that is cruel to beauty but kind to ugliness.", "Ouida [pen name of Marie Louise de la Ramee]"],
  ["Fashion is something barbarous, for it produces innovation without reason and imitation without benefit.", "George Santayana"],
  ["Fatigue is the best pillow.", "Benjamin Franklin"],
  ["Fear is the tax that conscience pays to guilt.", "George Sewell"],
  ["Fear prophets and those prepared to die for the truth, for as a rule they make many others die with them, often before them, at times instead of them.", "Umberto Eco"],
  ["Fearing no insult, asking for no crown, receive with indifference both flattery and slander, and do not argue with a fool.", "Aleksandr Pushkin"],
  ["Feeling gratitude and not expressing it is like wrapping a present and not giving it.", "William Arthur Ward"],
  ["Few are altogether deaf to the preaching of pine trees. Their sermons on the mountains go to our hearts; and if people in general could be got into the woods, even for once, to hear the trees speak for themselves, all difficulties in the way of forest preservation would vanish.", "John Muir"],
  ["Few are the giants of the soul who actually feel that the human race is their family circle.", "Freya Stark"],
  ["Few people are capable of expressing with equanimity opinions which differ from that of their social environment.", "Albert Einstein"],
  ["Few people think more than two or three times a year. I've made an international reputation for myself by thinking once or twice a week.", "George Bernard Shaw"],
  ["Few things are more satisfying than seeing your children have teenagers of their own.", "Doug Larson"],
  ["Fiction gives us a second chance that life denies us.", "Paul Theroux"],
  ["Fine minds are seldom fine souls.", "Jean Paul Richter"],
  ["Finish every day and be done with it. You have done what you could; Some blunders and absurdities crept in; Forget them as soon as you can. Tomorrow is a new day; You shall begin it serenely and with too high a spirit to be encumbered with your old nonsense.", "Ralph Waldo Emerson"],
  ["First, there is nothing unique about English's \"openness\" to words from other languages. Second, there is no logical conception of \"proper\" grammar as distinct from \"bad\" grammar that people lapse into out of ignorance or laziness.", "John McWhorter"],
  ["Flags are bits of colored cloth that governments use first to shrink-wrap people's brains and then as ceremonial shrouds to bury the dead.", "Arundhati Roy"],
  ["Flatter me, and I may not believe you. Criticize me, and I may not like you. Ignore me, and I may not forgive you. Encourage me, and I will not forget you.", "William Arthur Ward"],
  ["Flattery is like chewing gum. Enjoy it but don't swallow it.", "Hank Ketcham"],
  ["Flattery won't hurt you if you don't swallow it.", "Kin Hubbard"],
  ["Follow your inner moonlight; don't hide the madness.", "Allen Ginsberg"],
  ["For a long time we have gone along with some well-tested principles of conduct: that it was better to tell the truth than falsehoods; that a half-truth was no truth at all; that duties were older than and as fundamental as rights; that, as Justice Holmes put it, the mode by which the inevitable came to pass was effort; that to perpetuate a harm was always wrong, no matter how many joined in it, but to perpetuate it on a weaker person was particularly detestable ... Our institutions are founded on the assumption that most people will follow these principles most of the time because they want to, and the institutions work pretty well when this assumption is true.", "Dean Acheson"],
  ["For a man to achieve all that is demanded of him he must regard himself as greater than he is.", "Johann Wolfgang von Goethe"],
  ["For all our conceits about being the center of the universe, we live in a routine planet of a humdrum star stuck away in an obscure corner ... on an unexceptional galaxy which is one of about 100 billion galaxies. ... That is the fundamental fact of the universe we inhabit, and it is very good for us to understand that.", "Carl Sagan"],
  ["For all sad words of tongue and pen, the saddest are these, \"It might have been.\"", "John Greenleaf Whittier"],
  ["For blocks are better cleft with wedges, / Than tools of sharp or subtle edges, / And dullest nonsense has been found / By some to be the most profound.", "Samuel Butler"],
  ["For every language that becomes extinct, an image of man disappears.", "Octavio Paz"],
  ["For every prohibition you create you also create an underground.", "Jello Biafra"],
  ["For God's sake don't say yes until I've finished talking.", "Darryl F. Zanuck"],
  ["For in reason, all government without the consent of the governed is the very definition of slavery.", "Jonathan Swift"],
  ["For many years, I thought a poem was a whisper overheard, not an aria heard.", "Rita Dove"],
  ["For me, words are a form of action, capable of influencing change.", "Ingrid Bengis"],
  ["For money you can have everything it is said. No, that is not true. You can buy food, but not appetite; medicine, but not health; soft beds, but not sleep; knowledge but not intelligence; glitter, but not comfort; fun, but not pleasure; acquaintances, but not friendship; servants, but not faithfulness; grey hair, but not honor; quiet days, but not peace. The shell of all things you can get for money. But not the kernel. That cannot be had for money.", "Arne Garborg"],
  ["For sleep, riches, and health to be truly enjoyed, they must be interrupted.", "Jean Paul Richter"],
  ["For there is no friend like a sister / In calm or stormy weather; / To cheer one on the tedious way, / To fetch one if one goes astray, / To lift one if one totters down, / To strengthen whilst one stands.", "Christina Rossetti"],
  ["For truly it is to be noted, that children's plays are not sports, and should be deemed as their most serious actions.", "Michel de Montaigne"],
  ["For what are stars but asterisks. To point a human life?", "Emily Dickinson"],
  ["Force without wisdom falls of its own weight.", "Horace"],
  ["Forget not that the earth delights to feel your bare feet and the winds long to play with your hair.", "Khalil Gibran"],
  ["Forgive him, for he believes that the customs of his tribe are the laws of nature.", "George Bernard Shaw"],
  ["Form follows function.", "Louis Sullivan"],
  ["Fortune does not change men, it unmasks them.", "Suzanne Necker"],
  ["Friendship, like credit, is highest where it is not used.", "Elbert Hubbard"],
  ["From my close observation of writers... they fall into two groups: those who bleed copiously and visibly at any bad review, and those who bleed copiously and secretly at any bad review.", "Isaac Asimov"],
  ["Genius is eternal patience.", "Michelangelo Buonarroti"],
  ["Give me your tired, your poor, / Your huddled masses yearning to breathe free, / The wretched refuse of your teeming shore. / Send these, the homeless, tempest-tost to me, / I lift my lamp beside the golden door!", "Emma Lazarus", "from a poem written to raise funds for building the pedestal of the Statue of Liberty"],
  ["Given enough eyeballs, all bugs are shallow", "Eric S. Raymond"],
  ["Globalization, as defined by rich people like us, is a very nice thing... you are talking about the Internet, you are talking about cell phones, you are talking about computers. This doesn't affect two-thirds of the people of the world.", "Jimmy Carter"],
  ["Go to where the silence is and say something.", "Amy Goodman"],
  ["God builds his temple in the heart on the ruins of churches and religions.", "Ralph Waldo Emerson"],
  ["God is a comedian playing to an audience too afraid to laugh.", "Voltaire"],
  ["God is usually on the side of the big squadrons against the small.", "Comte Roger de Bussy-Rabutin"],
  ["God loved the birds and invented trees. Man loved the birds and invented cages.", "Jacques Deval"],
  ["God made everything out of nothing, but the nothingness shows through.", "Paul Valery"],
  ["God must have loved the people in power, for he made them so much like their own image of him.", "Kenneth Patchen"],
  ["God, to me, it seems, is a verb, not a noun, proper or improper.", "R. Buckminster Fuller"],
  ["Gold in its native state is but dull, unornamental stuff, and only lowborn metals excite the admiration of the ignorant with an ostentatious glitter. However, like the rest of the world, I still go on underrating men of gold and glorifying men of mica.", "Mark Twain"],
  ["Good and evil grow up together and are bound in an equilibrium that cannot be sundered. The most we can do is try to tilt the equilibrium toward the good.", "Eric Hoffer"],
  ["Good books don't give up all their secrets at once.", "Stephen King"],
  ["Good fiction creates empathy. A novel takes you somewhere and asks you to look through the eyes of another person, to live another life.", "Barbara Kingsolver"],
  ["Good men must not obey the laws too well.", "Ralph Waldo Emerson"],
  ["Good music is very close to primitive language.", "Denis Diderot"],
  ["Good prose is like a windowpane.", "George Orwell"],
  ["Goodness is the only investment which never fails.", "Henry David Thoreau"],
  ["Government is not reason, it is not eloquence, it is force; like fire, a troublesome servant and a fearful master. Never for a moment should it be left to irresponsible action.", "George Washington"],
  ["Grasp the subject, the words will follow.", "Cato the Elder"],
  ["Gratitude is not only the greatest of virtues, but the parent of all others.", "Marcus Tullius Cicero"],
  ["Great geniuses have the shortest biographies.", "Ralph Waldo Emerson"],
  ["Great literature is simply language charged with meaning to the utmost possible degree.", "Ezra Pound"],
  ["Great thoughts reduced to practice become great acts.", "William Hazlitt"],
  ["Growth for the sake of growth is the ideology of the cancer cell.", "Edward Abbey"],
  ["Growth in wisdom can be measured precisely by decline in bile.", "Friedrich Nietzsche"],
  ["Gullibility and credulity are considered undesirable qualities in every department of human life - except religion.", "Christopher Hitchens"],
  ["Habit with him was all the test of truth, / It must be right: I've done it from my youth.", "George Crabbe"],
  ["Half of the harm that is done in this world is due to people who want to feel important. They don't mean to do harm but the harm does not interest them.", "T.S. Eliot"],
  ["Half the truth is often a great lie.", "Benjamin Franklin"],
  ["Happiness in intelligent people is the rarest thing I know.", "Ernest Hemingway"],
  ["Happiness is not a goal; it is a by-product.", "Eleanor Roosevelt"],
  ["Happy the people whose annals are blank in the history books!", "Charles de Montesquieu"],
  ["Hate is a dead thing. Who of you would be a tomb?", "Khalil Gibran"],
  ["Hatred - the anger of the weak.", "Alphonse Daudet"],
  ["Hatred is the most accessible and comprehensive of all the unifying agents. Mass movements can rise and spread without belief in a God, but never without belief in a devil.", "Eric Hoffer"],
  ["Have a heart that never hardens, and a temper that never tires, and a touch that never hurts.", "Charles Dickens"],
  ["Have patience! In time, even grass becomes milk.", "Charan Singh"],
  ["Having been unable to strengthen justice, we have justified strength.", "Blaise Pascal"],
  ["He acts before he speaks, and afterwards speaks according to his actions.", "Confucius"],
  ["He alone may chastise who loves.", "Rabindranath Tagore"],
  ["He had discovered a great law of human action, without knowing it - namely, that in order to make a man or a boy covet a thing, it is only necessary to make the thing difficult to obtain.", "Mark Twain"],
  ["He had the rare quality of not only loving but respecting childhood - its innocence, its keen sense of justice, its passionate and yet sensitive affections.", "Dinah Maria Mulock Craik"],
  ["He is a hard man who is only just, and a sad one who is only wise.", "Voltaire"],
  ["He is the best physician who is the most ingenious inspirer of hope.", "Samuel Taylor Coleridge"],
  ["He serves his party best who serves the country best.", "Rutherford B. Hayes"],
  ["He shall mark our goings, question whence we came, / Set his guards about us, as in Freedom's name. / He shall peep and mutter, and night shall bring / Watchers 'neath our window, lest we mock the King.", "Rudyard Kipling"],
  ["He that is the author of a war lets loose the whole contagion of hell and opens a vein that bleeds a nation to death.", "Thomas Paine"],
  ["He that respects himself is safe from others; he wears a coat of mail that none can pierce.", "Henry Wadsworth Longfellow"],
  ["He that uses many words for explaining any subject, doth, like the cuttlefish, hide himself for the most part in his own ink.", "John Ray"],
  ["He that wrestles with us strengthens our nerves, and sharpens our skill. Our antagonist is our helper.", "Edmund Burke"],
  ["He who allows oppression, shares the crime.", "Erasmus Darwin"],
  ["He who binds to himself a joy, / Does the winged life destroy; / He who kisses the joy as it flies, / Lives in Eternity's sun rise.", "William Blake"],
  ["He who dares not offend cannot be honest.", "Thomas Paine"],
  ["He who does not bellow the truth when he knows the truth makes himself the accomplice of liars and forgers.", "Charles Peguy"],
  ["He who establishes his argument by noise and command, shows that his reason is weak.", "Michel de Montaigne"],
  ["He who exercises government by means of his virtue may be compared to the north polar star, which keeps its place and all the stars turn towards it.", "Confucius"],
  ["He who fights with monsters might take care lest he thereby become a monster. And if you gaze for long into an abyss, the abyss gazes also into you.", "Friedrich Nietzsche"],
  ["He who has a why can endure any how.", "Friedrich Nietzsche"],
  ["He who has imagination without learning has wings and no feet.", "Joseph Joubert"],
  ["He who has provoked the lash of wit, cannot complain that he smarts from it.", "James Boswell"],
  ["He who is cruel to animals becomes hard also in his dealings with men. We can judge the heart of a man by his treatment of animals.", "Immanuel Kant"],
  ["He who is only just is cruel. Who on earth could live were all judged justly?", "Lord Byron"],
  ["He who knows only his own side of the case knows little of that.", "John Stuart Mill"],
  ["He who listens to truth is not less than he who utters truth.", "Khalil Gibran"],
  ["He who opens a school door, closes a prison.", "Victor Hugo"],
  ["He who postpones the hour of living is like the rustic who waits for the river to run out before he crosses.", "Horace"],
  ["He who praises everybody, praises nobody.", "Samuel Johnson"],
  ["He who sees a need and waits to be asked for help is as unkind as if he had refused it.", "Dante Alighieri"],
  ["He who sings scares away his woes.", "Miguel de Cervantes"],
  ["He who wants to persuade should put his trust not in the right argument, but in the right word. The power of sound has always been greater than the power of sense.", "Joseph Conrad"],
  ["He who would make his own liberty secure must guard even his enemy from oppression; for if he violates this duty he establishes a precedent that will reach to himself.", "Thomas Paine"],
  ["He who would travel happily must travel light.", "Antoine de Saint-Exupery"],
  ["He whom the gods love, dies young.", "Titus Maccius Plautus"],
  ["He will always be a slave who does not know how to live upon a little.", "Horace"],
  ["He's the best physician who knows the worthlessness of the most medicines.", "Benjamin Franklin"],
  ["Heard melodies are sweet, but those unheard / Are sweeter.", "John Keats"],
  ["Heaven gives its glimpses only to those / Not in position to look too close.", "Robert Frost"],
  ["Heaven is under our feet as well as over our heads.", "Henry David Thoreau"],
  ["Heavy hearts, like heavy clouds in the sky, are best relieved by the letting of a little water.", "Christopher Morley"],
  ["Here is the test to find whether your mission on earth is finished: If you're alive, it isn't.", "Richard Bach"],
  ["Here is where people, / One frequently finds, / Lower their voices / And raise their minds.", "Richard Armour", "on libraries"],
  ["Here lies, extinguished in his prime, / a victim of modernity: / but yesterday he hadn't time-- / and now he has eternity.", "Piet Hein"],
  ["Here lives a free man. Nobody serves him.", "Albert Camus"],
  ["Heresy is only another word for freedom of thought.", "Graham Greene"],
  ["High is our calling, Friend! - Creative Art / (Whether the instrument of words she use, Or pencil pregnant with ethereal hues,) / Demands the service of a mind and heart.", "William Wordsworth"],
  ["Him that I love, I wish to be free - even from me.", "Anne Morrow Lindbergh"],
  ["His mother had often said, When you choose an action, you choose the consequences of that action. She had emphasized the corollary of this axiom even more vehemently: when you desired a consequence you had damned well better take the action that would create it.", "Lois McMaster Bujold"],
  ["His words, like so many nimble and airy servitors, trip about him at command.", "John Milton"],
  ["Historians tell the story of the past, novelists the story of the present.", "Edmond de Goncourt"],
  ["History is a novel whose author is the people.", "Alfred de Vigny"],
  ["History is a vast early warning system.", "Norman Cousins"],
  ["History is all explained by geography.", "Robert Penn Warren"],
  ["History may be read as the story of the magnificent rearguard action fought during several thousand years by dogma against curiosity.", "Robert Lynd"],
  ["History teaches that grave threats to liberty often come in times of urgency, when constitutional rights seem too extravagant to endure.", "Thurgood Marshall"],
  ["Hofstadter's Law: It always takes longer than you expect, even when you take into account Hofstadter's Law.", "Douglas Hofstadter"],
  ["Home is not where you live but where they understand you.", "Christion Morgenstern"],
  ["Homicide and verbicide - that is, violent treatment of a word with fatal results to its legitimate meaning, which is its life - are alike forbidden.", "Oliver Wendell Holmes, Sr"],
  ["Homo sapiens is the species that invents symbols in which to invest passion and authority, then forgets that symbols are inventions.", "Joyce Carol Oates"],
  ["Honest criticism is hard to take, particularly from a relative, a friend, an acquaintance, or a stranger.", "Franklin P. Jones"],
  ["Hope: Desire and expectation rolled into one.", "Ambrose Bierce"],
  ["Hot lead can be almost as effective coming from a linotype as from a firearm.", "John O'Hara"],
  ["How anyone can profess to find animal life interesting and yet take delight in reducing the wonder of any animal to a bloody mass of fur or feathers?", "Joseph Wood Krutch"],
  ["How beautiful it is to do nothing, and then rest afterward.", "Spanish proverb"],
  ["How can one better magnify the Almighty than by sniggering with him at his little jokes, particularly the poorer ones.", "Samuel Beckett"],
  ["How can they say my life is not a success? Have I not for more than sixty years got enough to eat and escaped being eaten?", "Logan Pearsall Smith"],
  ["How can we expect another to keep our secret if we have been unable to keep it ourselves.", "François de La Rochefoucauld"],
  ["How can you have a war on terrorism when war itself is terrorism?", "Howard Zinn"],
  ["How can you sing if your mouth be filled with food? How shall your hand be raised in blessing if it is filled with gold?", "Khalil Gibran"],
  ["How do I love thee? Let me count the ways. I love thee to the depth and breadth and height my soul can reach.", "Elizabeth Barrett Browning"],
  ["How dreadful knowledge of the truth can be when there's no help in the truth.", "Sophocles"],
  ["How easy to be amiable in the midst of happiness and success.", "Madame Anne Sophie Swetchine"],
  ["How far should one accept the rules of the society in which one lives? To put it another way: at what point does conformity become corruption? Only by answering such questions does the conscience truly define itself.", "Kenneth Tynan"],
  ["How far you go in life depends on your being tender with the young, compassionate with the aged, sympathetic with the striving and tolerant of the weak and strong. Because someday in life you will have been all of these.", "George Washington Carver"],
  ["How hard to realize that every camp of men or beast has this glorious starry firmament for a roof! In such places standing alone on the mountaintop it is easy to realize that whatever special nests we make - leaves and moss like the marmots and birds, or tents or piled stone - we all dwell in a house of one room - the world with the firmament for its roof - and are sailing the celestial spaces without leaving any track.", "John Muir"],
  ["How simple life becomes when things like mirrors are forgotten.", "Daphne du Maurier"],
  ["How strange that nature does not knock, and yet does not intrude!", "Emily Dickinson"],
  ["How would you describe the difference between modern war and modern industry - between, say, bombing and strip mining, or between chemical warfare and chemical manufacturing? The difference seems to be only that in war the victimization of humans is directly intentional and in industry it is \"accepted\" as a \"trade-off\".", "Wendell Berry"],
  ["Human beings are perhaps never more frightening than when they are convinced beyond doubt that they are right.", "Laurens van der Post"],
  ["Human kindness has never weakened the stamina or softened the fiber of a free people. A nation does not have to be cruel to be tough.", "Franklin D. Roosevelt"],
  ["Human nature will not flourish, any more than a potato, if it be planted and replanted, for too long a series of generations, in the same worn-out soil. My children have had other birthplaces, and, so far as their fortunes may be within my control, shall strike their roots into unaccustomed earth.", "Nathaniel Hawthorne"],
  ["Human subtlety will never devise an invention more beautiful, more simple or more direct than does Nature, because in her inventions, nothing is lacking and nothing is superfluous.", "Leonardo da Vinci"],
  ["human wandering through the zoo / what do your cousins think of you.", "Don Marquis"],
  ["Humanity also needs dreamers, for whom the disinterested development of an enterprise is so captivating that it becomes impossible for them to devote their care to their own material profit. Without doubt, these dreamers do not deserve wealth, because they do not desire it. Even so, a well-organized society should assure to such workers the efficient means of accomplishing their task, in a life freed from material care and freely consecrated to research.", "Marie Curie"],
  ["Humankind is made up of two sexes, women and men. Is it possible for humankind to grow by the improvement of only one part while the other part is ignored?", "Mustafa Kemal Ataturk"],
  ["Humans think they are smarter than dolphins because we build cars and buildings and start wars, etc., and all that dolphins do is swim in the water, eat fish, and play around. Dolphins believe that they are smarter for exactly the same reasons.", "Douglas Adams"],
  ["Humility like darkness reveals the heavenly lights.", "Henry David Thoreau"],
  ["Humor is emotional chaos remembered in tranquility.", "James Thurber"],
  ["Humor may be defined as the kindly contemplation of the incongruities of life, and the artistic expression thereof.", "Stephen Leacock"],
  ["Hundreds of hysterical persons must confuse these phenomena with messages from the beyond and take their glory to the bishop rather than the eye doctor.", "James Thurber"],
  ["Hypocrisy is the homage which vice pays to virtue.", "François de La Rochefoucauld"],
  ["I abhor vivisection. It should at least be curbed. Better, it should be abolished. I know of no achievement through vivisection, no scientific discovery, that could not have been obtained without such barbarism and cruelty. The whole thing is evil.", "Charles Mayo"],
  ["I am a Conservative to preserve all that is good in our constitution, a Radical to remove all that is bad. I seek to preserve property and to respect order, and I equally decry the appeal to the passions of the many or the prejudices of the few.", "Benjamin Disraeli"],
  ["I am a part of all that I have met.", "Alfred, Lord Tennyson"],
  ["I am about to - or I am going to - die; either expression is used.", "Dominique Bouhours"],
  ["I am always sorry when any language is lost, because languages are the pedigrees of nations.", "Samuel Johnson"],
  ["I am aware that no man is a villain in his own eyes.", "James Baldwin"],
  ["I am I plus my surroundings and if I do not preserve the latter, I do not preserve myself.", "Jose Ortega Y Gasset"],
  ["I am in the habit of looking not so much to the nature of a gift as to the spirit in which it is offered.", "Robert Louis Stevenson"],
  ["I am malicious because I am miserable. ... If any being felt emotions of benevolence towards me, I should return them a hundred and a hundred fold (words of Frankenstein monster).", "Mary Wollstonecraft Shelley"],
  ["I am no more humble than my talents require.", "Oscar Levant"],
  ["I am no more lonely than a single mullein or dandelion in a pasture, or a bean leaf, or sorrel, or a horse-fly, or a bumblebee. I am no more lonely than the Mill Brook, or a weathercock, or the north star, or the south wind, or an April shower, or a January thaw, or the first spider in a new house.", "Henry David Thoreau"],
  ["I am not a lover of lawns. Rather would I see daisies in their thousands, ground ivy, hawkweed, and even the hated plantain with tall stems, and dandelions with splendid flowers and fairy down, than the too-well-tended lawn.", "William Henry Hudson"],
  ["I am not interested to know whether vivisection produces results that are profitable to the human race or doesn't. The pain which it inflicts upon unconsenting animals is the basis of my enmity toward it, and it is to me sufficient justification of the enmity without looking further.", "Mark Twain"],
  ["I am not one of those who believe that a great army is the means of maintaining peace, because if you build up a great profession those who form parts of it want to exercise their profession.", "Woodrow Wilson"],
  ["I am not only a pacifist but a militant pacifist. I am willing to fight for peace. Nothing will end war unless the people themselves refuse to go to war.", "Albert Einstein"],
  ["I am now quite cured of seeking pleasure in society, be it country or town. A sensible man ought to find sufficient company in himself.", "Emily Bronte"],
  ["I am only one, / But still I am one. / I cannot do everything, / But still I can do something; / And because I cannot do everything, / I will not refuse to do the something that I can do.", "Edward Everett Hale"],
  ["I am patient with stupidity but not with those who are proud of it.", "Edith Sitwell"],
  ["I am so convinced of the advantages of looking at mankind instead of reading about them, and of the bitter effects of staying at home with all the narrow prejudices of an Islander, that I think there should be a law amongst us to set our young men abroad for a term among the few allies our wars have left us.", "Lord Byron"],
  ["I am, indeed, a king, because I know how to rule myself.", "Pietro Aretino"],
  ["I and the public know. / What all schoolchildren learn. / Those to whom evil is done. / Do evil in return", "W.H. Auden"],
  ["I ask you to judge me by the enemies I have made.", "Franklin D. Roosevelt"],
  ["I became a vegetarian after realizing that animals feel afraid, cold, hungry and unhappy like we do.", "Cesar Chavez"],
  ["I begin to see what marriage is for. It's to keep people away from each other. Sometimes I think that two people who love each other can be saved from madness only by the things that come between them: children, duties, visits, bores, relations, the things that protect married people from each other.", "Edith Wharton"],
  ["I believe a leaf of grass is no less than the journey-work of the stars.", "Walt Whitman"],
  ["I believe I found the missing link between animal and civilized man. It is us.", "Konrad Lorenz"],
  ["I believe I have no prejudices whatsoever. All I need to know is that a man is a member of the human race. That's bad enough for me.", "Mark Twain"],
  ["I believe in evidence. I believe in observation, measurement, and reasoning, confirmed by independent observers. I'll believe anything, no matter how wild and ridiculous, if there is evidence for it. The wilder and more ridiculous something is, however, the firmer and more solid the evidence will have to be.", "Isaac Asimov"],
  ["I believe in God, only I spell it Nature.", "Frank Lloyd Wright"],
  ["I believe that in the course of the next century the notion that it's a woman's duty to have children will change and make way for the respect and admiration of all women, who bear their burdens without complaint or a lot of pompous words!", "Anne Frank"],
  ["I believe that life can go on forever. It takes a million years to evolve a new species, ten million for a new genus, one hundred million for a class, a billion for a phylum - and that's usually as far as your imagination goes. In a billion years, it seems, intelligent life might be as different from humans as humans are from insects. But what would happen in another ten billion years? It's utterly impossible to conceive of ourselves changing as drastically as that, over and over again. All you can say is, on that kind of time scale the material form that life would take is completely open. To change from a human being to a cloud may seem a big order, but it's the kind of change you'd expect over billions of years.", "Freeman Dyson"],
  ["I believe the greatest gift I can conceive of having from anyone is to be seen, heard, understood, and touched by them. The greatest gift I can give is to see, hear, understand, and touch another person.", "Virginia Satir"],
  ["I believe there are more instances of the abridgment of the rights of the people by the gradual and silent encroachments of those in power than by violent and sudden usurpations.", "James Madison"],
  ["I can't understand why people are frightened of new ideas. I'm frightened of the old ones.", "John Cage"],
  ["I cannot believe in a God who wants to be praised all the time.", "Friedrich Nietzsche"],
  ["I cannot conceive otherwise than that He, the Infinite Father, expects or requires no worship or praise from us, but that He is even infinitely above it.", "Benjamin Franklin"],
  ["I cannot imagine a God who rewards and punishes the objects of his creation, whose purposes are modeled after our own - a God, in short, who is but a reflection of human frailty.", "Albert Einstein"],
  ["I cannot judge my work while I am doing it. I have to do as painters do, stand back and view it from a distance, but not too great a distance.", "Blaise Pascal"],
  ["I cannot stress often enough that what science is all about is not proving things to be true but proving them to be false.", "Lawrence M. Krauss"],
  ["I cannot walk through the suburbs in the solitude of the night without thinking that the night pleases us because it suppresses idle details, just as our memory does.", "Jorge Luis Borges"],
  ["I come from a people who gave the Ten Commandments to the world. Time has come to strengthen them by three additional ones, which we ought to adopt and commit ourselves to: thou shall not be a perpetrator; thou shall not be a victim; and thou shall never, but never, be a bystander.", "Yehuda Bauer"],
  ["I contend that we are both atheists. I just believe in one fewer god than you do. When you understand why you dismiss all the other possible gods, you will understand why I dismiss yours.", "Stephen Roberts"],
  ["I count him braver who overcomes his desires than him who conquers his enemies, for the hardest victory is over self.", "Aristotle"],
  ["I did try very hard to tell the whole truth without violating my literary instincts. One can write nothing readable unless one constantly struggles to efface one's own personality. Good prose is like a window pane.", "George Orwell"],
  ["I died as a mineral and became a plant, I died as plant and rose to animal, I died as animal and I was Man. Why should I fear? When was I less by dying?", "Jalaluddin Rumi"],
  ["I distrust those people who know so well what God wants them to do because I notice it always coincides with their own desires.", "Susan B. Anthony"],
  ["I do not feel obliged to believe that the same God who has endowed us with sense, reason, and intellect has intended us to forgo their use.", "Galileo Galilei"],
  ["I do not think there is any thrill that can go through the human heart like that felt by the inventor as he sees some creation of the brain unfolding to success... Such emotions make a man forget food, sleep, friends, love, everything.", "Nikola Tesla"],
  ["I do not torture animals, and I do not support the torture of animals, such as that which goes on at rodeos: cowardly men in big hats abusing simple beasts in a fruitless search for manhood.", "George Carlin"],
  ["I don't believe that the big men, the politicians and the capitalists alone are guilty of the war. Oh, no, the little man is just as keen, otherwise the people of the world would have risen in revolt long ago! There is an urge and rage in people to destroy, to kill, to murder, and until all mankind, without exception, undergoes a great change, wars will be waged, everything that has been built up, cultivated, and grown, will be destroyed and disfigured, after which mankind will have to begin all over again.", "Anne Frank"],
  ["I don't hate my enemies. After all, I made 'em.", "Red Skelton"],
  ["I don't know if God exists, but it would be better for his reputation if he didn't.", "Jules Renard"],
  ["I don't know what your destiny will be, but one thing I do know: the only ones among you who will be really happy are those who have sought and found how to serve.", "Albert Schweitzer"],
  ["I don't know who my grandfather was; I'm much more concerned to know what his grandson will be.", "Abraham Lincoln"],
  ["I don't know why we are here, but I'm pretty sure that it is not in order to enjoy ourselves.", "Ludwig Wittgenstein"],
  ["I don't mind that you think slowly but I do mind that you are publishing faster than you think.", "Wolfgang Pauli"],
  ["I don't necessarily agree with everything I say.", "Marshall McLuhan"],
  ["I don't need time. What I need is a deadline.", "Duke Ellington"],
  ["I don't think that combat has ever been written about truthfully; it has always been described in terms of bravery and cowardice. I won't even accept these words as terms of human reference any more. And anyway, hell, they don't even apply to what, in actual fact, modern warfare has become.", "James Jones"],
  ["I don't trust a man who uses the word evil eighteen times in ten minutes. If you're half evil, nothing soothes you more than to think the person you are opposed to is totally evil.", "Norman Mailer"],
  ["I dream my painting, and then I paint my dream.", "Vincent van Gogh"],
  ["I dream of giving birth to a child who will ask, \"Mother, what was war?\"", "Eve Merriam"],
  ["I dreamt that my hair was kempt. Then I dreamt that my true love unkempt it.", "Ogden Nash"],
  ["I envy people who drink - at least they know what to blame everything on.", "Oscar Levant"],
  ["I fear nothing, I hope for nothing, I am free.", "Nikos Kazantzakis"],
  ["I feel fairly certain that my hatred harms me more than the people whom I hate.", "Max Frisch"],
  ["I feel we are all islands - in a common sea.", "Anne Morrow Lindbergh"],
  ["I find bowing to people who occasionally wear crowns rather odd. I'll reserve my deference for achievement rather than bloodline.", "Robin Ince"],
  ["I find it difficult to feel responsible for the suffering of others. That's why I find war so hard to bear. It's the same with animals: I feel the less harm I do, the lighter my heart. I love a light heart. And when I know I'm causing suffering, I feel the heaviness of it. It's a physical pain. So it's self-interest that I don't want to cause harm.", "Alice Walker"],
  ["I find that principles have no real force except when one is well fed.", "Mark Twain"],
  ["I found one day in school a boy of medium size ill-treating a smaller boy. I expostulated, but he replied: \"The bigs hit me, so I hit the babies; that's fair.\" In these words he epitomized the history of the human race.", "Bertrand Russell"],
  ["I frequently tramped eight or ten miles through the deepest snow to keep an appointment with a beech-tree, or a yellow birch, or an old acquaintance among the pines.", "Henry David Thoreau"],
  ["I had a lover's quarrel with the world.", "Robert Frost"],
  ["I had rather take my chance that some traitors will escape detection than spread abroad a spirit of general suspicion and distrust, which accepts rumor and gossip in place of undismayed and unintimidated inquiry.", "Learned Hand"],
  ["I happen temporarily to occupy this big White House. I am living witness that any one of your children may look to come here as my father's child has.", "Abraham Lincoln"],
  ["I hate war as only a soldier who has lived it can, only as one who has seen its brutality, its futility, its stupidity.", "Dwight D. Eisenhower"],
  ["I hate with a murderous hatred those men who, having lived their youth, would send into war other youth, not lived, unfulfilled, to fight and die for them; the pride and cowardice of those old men, making their wars that boys must die.", "Mary Roberts Rinehart"],
  ["I have a dream that my four little children will one day live in a nation where they will not be judged by the color of their skin but by the content of their character.", "Martin Luther King, Jr"],
  ["I have a great deal of company in my house; especially in the morning, when nobody calls.", "Henry David Thoreau"],
  ["I have a trunk containing continents.", "Beryl Markham"],
  ["I have always found that mercy bears richer fruits than strict justice.", "Abraham Lincoln"],
  ["I have always imagined that paradise will be a kind of library.", "Jorge Luis Borges"],
  ["I have ever hated all nations, professions, and communities, and all my love is toward individuals.", "Jonathan Swift"],
  ["I have forced myself to contradict myself in order to avoid conforming to my own taste.", "Marcel Duchamp"],
  ["I have gained this by philosophy: that I do without being commanded what others do only from fear of the law.", "Aristotle"],
  ["I have known a vast quantity of nonsense talked about bad men not looking you in the face. Don't trust that conventional idea. Dishonesty will stare honesty out of countenance, any day in the week, if there is anything to be got by it.", "Charles Dickens"],
  ["I have learnt silence from the talkative, toleration from the intolerant, and kindness from the unkind; yet strange, I am ungrateful to these teachers.", "Khalil Gibran"],
  ["I have lived in this world just long enough to look carefully the second time into things that I am most certain of the first time.", "Josh Billings"],
  ["I have lost all sense of home, having moved about so much. It means to me now - only that place where the books are kept.", "John Steinbeck"],
  ["I have made a ceaseless effort not to ridicule, not to bewail, not to scorn human actions, but to understand.", "Baruch Spinoza"],
  ["I have never been contained except I made the prison.", "Mary Evans"],
  ["I have never gone to sleep with a grievance against anyone. And, as far as I could, I have never let anyone go to sleep with a grievance against me.", "Abba Agathon"],
  ["I have never met a man so ignorant that I couldn't learn something from him.", "Galileo Galilei"],
  ["I have never started a poem yet whose end I knew. Writing a poem is discovering.", "Robert Frost"],
  ["I have never thought much of the courage of a lion tamer. Inside the cage he is at least safe from other men. There is not much harm in a lion. He has no ideals, no religion, no politics, no chivalry, no gentility; in short, no reason for destroying anything that he does not want to eat.", "George Bernard Shaw"],
  ["I have no riches but my thoughts, yet these are wealth enough for me.", "Sara Teasdale"],
  ["I have noticed that when chickens quit quarreling over their food they often find that there is enough for all of them I wonder if it might not be the same with the human race.", "Don Marquis"],
  ["I have often wondered how it is that every man loves himself more than all the rest of men, but yet sets less value on his own opinion of himself than on the opinion of others.", "Marcus Aurelius"],
  ["I have one share in corporate Earth, and I am nervous about the management.", "E.B. White"],
  ["I have studied it often, but I never could discover the plot.", "Mark Twain"],
  ["I have suffered from being misunderstood, but I would have suffered a hell of a lot more if I had been understood.", "Clarence Darrow"],
  ["I have three ex-wives. I can't remember any of their names, so I just call 'em Plaintiff.", "Lewis Grizzard"],
  ["I have yet to hear a man ask for advice on how to combine marriage and a career.", "Gloria Steinem"],
  ["I hold that gentleman to be the best-dressed whose dress no one observes.", "Anthony Trollope"],
  ["I hope our wisdom will grow with our power, and teach us that the less we use our power the greater it will be.", "Thomas Jefferson"],
  ["I keep my ideals, because in spite of everything I still believe that people are really good at heart.", "Anne Frank"],
  ["I knew I'd hate COBOL the moment I saw they'd used 'perform' instead of 'do'.", "Larry Wall"],
  ["I know only that what is moral is what you feel good after and what is immoral is what you feel bad after.", "Ernest Hemingway"],
  ["I know what I have given you. I do not know what you have received.", "Antonio Porchia"],
  ["I learn that ten percent of all the world's species are parasitic insects. It is hard to believe. What if you were an inventor, and you made ten percent of your inventions in such a way that they could only work by harnessing, disfiguring, or totally destroying the other ninety percent?", "Annie Dillard"],
  ["I learned compassion from being discriminated against. Everything bad that's ever happened to me has taught me compassion.", "Ellen DeGeneres"],
  ["I learned long ago that being Lewis Carroll was infinitely more exciting than being Alice.", "Joyce Carol Oates"],
  ["I like not only to be loved, but to be told that I am loved; the realm of silence is large enough beyond the grave.", "George Eliot (Mary Ann Evans)"],
  ["I like the pluralism of modernity; it doesn't threaten me or my faith. And if one's faith is dependent on being reinforced in every aspect of other people's lives, then it is a rather insecure faith, don't you think?", "Andrew Sullivan"],
  ["I like to believe that people in the long run are going to do more to promote peace than our governments. Indeed, I think that people want peace so much that one of these days governments had better get out of the way and let them have it.", "Dwight D. Eisenhower"],
  ["I live in the world rather as a spectator of mankind than as one of the species.", "Joseph Addison"],
  ["I live my life in widening circles that reach out across the world. I may not complete this last one but I give myself to it.", "Rainer Maria Rilke"],
  ["I long to accomplish a great and noble task, but it is my chief duty to accomplish humble tasks as though they were great and noble. The world is moved along, not only by the mighty shoves of its heroes, but also by the aggregate of the tiny pushes of each honest worker.", "Helen Keller"],
  ["I look for what needs to be done. ... After all, that's how the universe designs itself.", "R. Buckminster Fuller"],
  ["I love America more than any other country in this world, and, exactly for this reason, I insist on the right to criticize her perpetually.", "James Baldwin"],
  ["I love being a writer. What I can't stand is the paperwork.", "Peter De Vries"],
  ["I love my country too much to be a nationalist.", "Albert Camus"],
  ["I love you, and because I love you, I would sooner have you hate me for telling you the truth than adore me for telling you lies.", "Pietro Aretino"],
  ["I may be a despicable person, but when Truth speaks through me I am invincible.", "Mahatma Gandhi"],
  ["I met, not long ago, a young man who aspired to become a novelist. Knowing that I was in the profession, he asked me to tell him how he should set to work to realize his ambition. I did my best to explain. 'The first thing,' I said, 'is to buy quite a lot of paper, a bottle of ink, and a pen. After that you merely have to write.'", "Aldous Huxley"],
  ["I myself have never been able to find out precisely what feminism is: I only know that people call me a feminist whenever I express sentiments that differentiate me from a doormat.", "Rebecca West"],
  ["I need someone to protect me from all the measures they take in order to protect me.", "Banksy"],
  ["I never desire to converse with a man who has written more than he has read.", "Samuel Johnson"],
  ["I never found the companion that was so companionable as solitude.", "Henry David Thoreau"],
  ["I never lose sight of the fact that just being is fun.", "Katharine Hepburn"],
  ["I never saw a discontented tree. They grip the ground as though they liked it, and though fast rooted they travel about as far as we do.", "John Muir"],
  ["I never saw an ugly thing in my life: for let the form of an object be what it may, - light, shade, and perspective will always make it beautiful.", "John Constable"],
  ["I never would believe that Providence had sent a few men into the world, ready booted and spurred to ride, and millions ready saddled and bridled to be ridden.", "Walt Whitman"],
  ["I often pass a farm with cows grazing in the field and I think to myself how terrible it is that human beings grow other animals just to kill them and eat them. Most of us think of vegetarians as nuts and I'm not a vegetarian but I wouldn't be surprised if we came to a time in 50 or 100 years when civilized people everywhere refused to eat animals.", "Andy Rooney"],
  ["I once met a man who had forgiven an injury. I hope some day to meet the man who has forgiven an insult.", "Charles Buxton"],
  ["I once wanted to become an atheist, but I gave up - they have no holidays.", "Henny Youngman"],
  ["I own that I cannot see as plainly as others do, and as I should wish to do, evidence of design and beneficence on all sides of us. There seems to me too much misery in the world. I cannot persuade myself that a beneficent and omnipotent God would have designedly created the Ichneumonidae with the express intention of their feeding within the living bodies of caterpillars, or that a cat should play with mice.", "Charles Darwin"],
  ["I place economy among the first and most important republican virtues, and public debt as the greatest of the dangers to be feared. To preserve our independence, we must not let our rulers load us with perpetual debt.", "Thomas Jefferson"],
  ["I prayed for freedom for twenty years, but received no answer until I prayed with my legs.", "Frederick Douglass"],
  ["I prefer the sign NO ENTRANCE to the sign which says NO EXIT.", "Stanisław J. Lec"],
  ["I protect my right to be a Catholic by preserving your right to believe as a Jew, a Protestant, or non-believer, or as anything else you choose. We know that the price of seeking to force our beliefs on others is that they might some day force theirs on us.", "Mario Cuomo"],
  ["I react pragmatically. Where the market works, I'm for that. Where the government is necessary, I'm for that. I'm deeply suspicious of somebody who says, \"I'm in favor of privatization,\" or, \"I'm deeply in favor of public ownership.\" I'm in favor of whatever works in the particular case.", "John Kenneth Galbraith"],
  ["I realize that patriotism is not enough. I must have no hatred or bitterness towards anyone.", "Edith Cavell"],
  ["I refuse to answer that question on the grounds that I don't know the answer.", "Douglas Adams"],
  ["I saw the angel in the marble and carved until I set him free.", "Michelangelo Buonarroti"],
  ["I see that sensible men and conscientious men all over the world were of one religion, - the religion of well-doing and daring, men of sturdy truth, men of integrity and feeling for others.", "Ralph Waldo Emerson"],
  ["I see too plainly custom forms us all. Our thoughts, our morals, our most fixed belief, are consequences of our place of birth.", "Aaron Hill"],
  ["I shall allow no man to belittle my soul by making me hate him.", "Booker T. Washington"],
  ["I shall be telling this with a sigh / Somewhere ages and ages hence: / Two roads diverged in a wood, and I - / I took the one less traveled by, / And that has made all the difference.", "Robert Frost"],
  ["I shall live badly if I do not write, and I shall write badly if I do not live.", "Francoise Sagan"],
  ["I should dearly love that the world should be ever so little better for my presence. Even on this small stage we have our two sides, and something might be done by throwing all one's weight on the scale of breadth, tolerance, charity, temperance, peace, and kindliness to man and beast. We can't all strike very big blows, and even the little ones count for something.", "Arthur Conan Doyle"],
  ["I shut my eyes and all the world drops dead; I lift my eyes and all is born again.", "Sylvia Plath"],
  ["I slept and dreamt that life was joy. I awoke and saw that life was service. I acted and behold, service was joy.", "Rabindranath Tagore"],
  ["I speak the truth not so much as I would, but as much as I dare, and I dare a little more as I grow older.", "Michel de Montaigne"],
  ["I speak to everyone in the same way, whether he is the garbage man or the president of the university.", "Albert Einstein"],
  ["I speak two languages, Body and English.", "Mae West"],
  ["I talk about going to his [George W. Bush's] Inauguration and crying when he took the oath, 'cause I was so afraid he was going to \"wreck the economy and muck up the drinking water\" ... the failure of my pessimistic imagination at that moment boggles my mind now.", "Sarah Vowell"],
  ["I thank Thee first because I was never robbed before; second, because although they took my purse they did not take my life; third, because although they took my all, it was not much; and fourth because it was I who was robbed, and not I who robbed.", "Matthew Henry"],
  ["I think of a hero as someone who understands the degree of responsibility that comes with his freedom.", "Bob Dylan"],
  ["I think that to get under the surface and really appreciate the beauty of a country, one has to go there poor.", "Grace Moore"],
  ["I think the environment should be put in the category of our national security. Defense of our resources is just as important as defense abroad. Otherwise what is there to defend?", "Robert Redford"],
  ["I think the next best thing to solving a problem is finding some humor in it.", "Frank A. Clark"],
  ["I think there is only one quality worse than hardness of heart, and that is softness of head.", "Theodore Roosevelt"],
  ["I tire so of hearing people say, / Let things take their course. / Tomorrow is another day. / I do not need my freedom when I'm dead. / I cannot live on tomorrow's bread.", "Langston Hughes"],
  ["I took a speed reading course and read 'War and Peace' in twenty minutes. It involves Russia.", "Woody Allen"],
  ["I tore myself away from the safe comfort of certainties through my love for truth - and truth rewarded me.", "Simone de Beauvoir"],
  ["I tried to find Him on the Christian cross, but He was not there; I went to the temple of the Hindus and to the old pagodas, but I could not find a trace of Him anywhere. I searched on the mountains and in the valleys, but neither in the heights nor in the depths was I able to find Him. I went to the Kaaba in Mecca, but He was not there either. I questioned the scholars and philosophers, but He was beyond their understanding. I then looked into my heart, and it was there where He dwelled that I saw Him; He was nowhere else to be found.", "Jalaluddin Rumi"],
  ["I used to think that people who regarded everyone benignly were a mite simple or oblivious or just plain lax - until I tried it myself. Then I realized that they made it only look easy. Even the Berditchever Rebbe, revered as a man who could strike a rock and bring forth a stream, was continually honing his intentions. \"Until I remove the thread of hatred from my heart,\" he said of his daily meditations, \"I am, in my own eyes, as if I did not exist.\"", "Marc Barasch"],
  ["I used to think that the brain was the most wonderful organ in my body. Then I realized who was telling me this.", "Emo Philips"],
  ["I value my garden more for being full of blackbirds than of cherries, and very frankly give them fruit for their songs.", "Joseph Addison"],
  ["I value the friend who for me finds time on his calendar, but I cherish the friend who for me does not consult the calendar.", "Robert Brault"],
  ["I want people to talk to one another no matter what their difference of opinion might be.", "Studs Terkel"],
  ["I want to stay as close to the edge as I can without going over. Out on the edge you see all kinds of things you can't see from the center.", "Kurt Vonnegut, Jr"],
  ["I want to walk through life instead of being dragged through it.", "Alanis Morissette"],
  ["I wanted to change the world. But I have found that the only thing one can be sure of changing is oneself.", "Aldous Huxley"],
  ["I was court-martialled in my absence, and sentenced to death in my absence, so I said they could shoot me in my absence.", "Brendan Francis Behan"],
  ["I was never less alone than when by myself.", "Edward Gibbon"],
  ["I was reading the dictionary. I thought it was a poem about everything.", "Steven Wright"],
  ["I was sixteen years old when the first World War broke out, and I lived at that time in Hungary. From reading the newspapers in Hungary, it would have appeared that, whatever Austria and Germany did was right and whatever England, France, Russia, or America did was wrong. A good case could be made out for this general thesis, in almost every single instance. It would have been difficult for me to prove, in any single instance, that the newspapers were wrong, but somehow, it seemed to me unlikely that the two nations located in the center of Europe should be invariably right, and that all the other nations should be invariably wrong. History, I reasoned, would hardly operate in such a peculiar fashion, and it didn't take long until I began to hold views which were diametrically opposed to those held by the majority of my schoolmates. ... Even in times of war, you can see current events in their historical perspective, provided that your passion for the truth prevails over your bias in favor of your own nation.", "Leo Szilard"],
  ["I was thrown out of college for cheating on the metaphysics exam; I looked into the soul of the boy sitting next to me.", "Woody Allen"],
  ["I wasn't disturbing the peace, I was disturbing the war.", "Ammon Hennacy"],
  ["I went to the woods because I wished to live deliberately, to front only the essential facts of life, and see if I could not learn what it had to teach, and not, when I came to die, discover that I had not lived.", "Henry David Thoreau"],
  ["I will not play at tug o' war. / I'd rather play at hug o' war, / Where everyone hugs instead of tugs.", "Shel Silverstein"],
  ["I wish I could have known earlier that you have all the time you'll need right up to the day you die.", "William Wiley"],
  ["I wish our clever young poets would remember my homely definitions of prose and poetry; that is, prose, - words in their best order; poetry, - the best words in their best order.", "Samuel Taylor Coleridge"],
  ["I wish you all the joy that you can wish.", "William Shakespeare"],
  ["I would rather be able to appreciate things I can not have than to have things I am not able to appreciate.", "Elbert Hubbard"],
  ["I would rather be exposed to the inconveniences attending too much liberty than to those attending too small a degree of it.", "Thomas Jefferson"],
  ["I would rather be the man who bought the Brooklyn Bridge than the one who sold it.", "Will Rogers"],
  ["I would rather try to persuade a man to go along, because once I have persuaded him he will stick. If I scare him, he will stay just as long as he is scared, and then he is gone.", "Dwight D. Eisenhower"],
  ["I'd like to widen people's awareness of the tremendous timespan lying ahead - for our planet, and for life itself. Most educated people are aware that we're the outcome of nearly 4bn years of Darwinian selection, but many tend to think that humans are somehow the culmination. Our sun, however, is less than halfway through its lifespan. Six billion years from now, it will not be humans who watch the sun's demise. Any creatures that then exist will be as different from us as we are from bacteria or amoebae.", "Martin Rees"],
  ["I'd rather be a climbing ape than a falling angel.", "Terry Pratchett"],
  ["I'd rather see a sermon than hear one any day; I'd rather one should walk with me than merely tell the way.", "Edgar Guest"],
  ["I'll tell you how the sun rose-- / A ribbon at a time.", "Emily Dickinson"],
  ["I'm always amazed that people will actually choose to sit in front of the television and just be savaged by stuff that belittles their intelligence.", "Alice Walker"],
  ["I'm fed up to the ears with old men dreaming up wars for young men to die in.", "George McGovern"],
  ["I'm not at all contemptuous of comforts, but they have their place and it is not first.", "E.F. Schumacher"],
  ["I'm proud of the fact that I never invented weapons to kill.", "Thomas Edison"],
  ["I'm proud to pay taxes in the United States; the only thing is, I could be just as proud for half the money.", "Arthur Godfrey"],
  ["I'm sometimes asked \"Why do you spend so much of your time and money talking about kindness to animals when there is so much cruelty to men?\" I answer: \"I am working at the roots.\"", "George T. Angell"],
  ["I'm sure that someday children in schools will study the history of the men who made war as you study an absurdity. They'll be shocked, just as today we're shocked with cannibalism.", "Golda Meir"],
  ["I've also seen that great men are often lonely. This is understandable, because they have built such high standards for themselves that they often feel alone. But that same loneliness is part of their ability to create.", "Yousuf Karsh"],
  ["I've learned that you shouldn't go through life with a catcher's mitt on both hands. You need to be able to throw something back.", "Maya Angelou"],
  ["I've made an odd discovery. Every time I talk to a savant I feel quite sure that happiness is no longer a possibility. Yet when I talk with my gardener, I'm convinced of the opposite.", "Bertrand Russell"],
  ["Ideas are like rabbits. You get a couple and learn how to handle them, and pretty soon you have a dozen.", "John Steinbeck"],
  ["Ideas that enter the mind under fire remain there securely and for ever.", "Leon Trotsky"],
  ["If a book be false in its facts, disprove them; if false in its reasoning, refute it. But for God s sake, let us freely hear both sides if we choose.", "Thomas Jefferson"],
  ["If a man does not keep pace with his companions, perhaps it is because he hears a different drummer. Let him step to the music which he hears, however measured or far away.", "Henry David Thoreau"],
  ["If a man does not make new acquaintance as he advances through life, he will soon find himself left alone. A man, Sir, should keep his friendship in constant repair.", "Samuel Johnson"],
  ["If a man have a genius for painting, poetry, music, architecture, or philosophy, he makes a bad husband, and an ill provider.", "Ralph Waldo Emerson"],
  ["If a man should importune me to give a reason why I loved him, I find it could no otherwise be expressed, than by making answer: because it was he, because it was I.", "Michel de Montaigne"],
  ["If a man walks in the woods for love of them half of each day, he is in danger of being regarded as a loafer. But if he spends his days as a speculator, shearing off those woods and making the earth bald before her time, he is deemed an industrious and enterprising citizen.", "Henry David Thoreau"],
  ["If a man will begin with certainties, he shall end in doubts; but if he will be content to begin with doubts, he shall end in certainties.", "Francis Bacon"],
  ["If a man would register all his opinions upon love, politics, religion, learning, etc., beginning from his youth and so go on to old age, what a bundle of inconsistencies and contradictions would appear at last!", "Jonathan Swift"],
  ["If a rabbit defined intelligence the way man does, then the most intelligent animal would be a rabbit, followed by the animal most willing to obey the commands of a rabbit.", "Robert Brault"],
  ["If a triangle could speak, it would say, that God is eminently triangular, while a circle would say that the divine nature is eminently circular.", "Baruch Spinoza"],
  ["If all men knew what others say of them, there would not be four friends in the world.", "Blaise Pascal"],
  ["If an animal does something, we call it instinct; if we do the same thing for the same reason, we call it intelligence.", "Will Cuppy"],
  ["If any kid ever realized what was involved in factory farming they would never touch meat again. I was so moved by the intelligence, sense of fun and personalities of the animals I worked with on 'Babe' that by the end of the film I was a vegetarian.", "James Cromwell"],
  ["If any man seeks for greatness, let him forget greatness and ask for truth, and he will find both.", "Horace Mann"],
  ["If any question why we died, Tell them, because our fathers lied.", "Rudyard Kipling"],
  ["If ever the time should come, when vain and aspiring men shall possess the highest seats in government, our country will stand in need of its experienced patriots to prevent its ruin.", "Samuel Adams"],
  ["If Galileo had said in verse that the world moved, the inquisition might have let him alone.", "Thomas Hardy"],
  ["If God created us in his own image, we have more than reciprocated.", "Voltaire"],
  ["If God exists, I hope he has a good excuse.", "Woody Allen"],
  ["If I am walking with two other men, each of them will serve as my teacher. I will pick out the good points of the one and imitate them, and the bad points of the other and correct them in myself.", "Confucius"],
  ["If I could be sure of doing with my books as much as my [doctor] father did for the sick!", "Marcel Proust"],
  ["If I had eight hours to chop down a tree, I'd spend six sharpening my axe.", "Abraham Lincoln"],
  ["If I have any beliefs about immortality, it is that certain dogs I have known will go to heaven, and very, very few persons.", "James Thurber"],
  ["If I have ever made any valuable discoveries, it has been owing more to patient observation than to any other reason.", "Isaac Newton"],
  ["If I knew of something that could serve my nation but would ruin another, I would not propose it to my prince, for I am first a man and only then a Frenchman ... because I am necessarily a man, and only accidentally am I French.", "Montesquieu"],
  ["If I seem to take part in politics, it is only because politics encircles us today like the coil of a snake from which one cannot get out, no matter how much one tries. I wish therefore to wrestle with the snake.", "Mahatma Gandhi"],
  ["If I were not an atheist, I would believe in a God who would choose to save people on the basis of the totality of their lives and not the pattern of their words. I think he would prefer an honest and righteous atheist to a TV preacher whose every word is God, God, God, and whose every deed is foul, foul, foul.", "Isaac Asimov"],
  ["If it is committed in the name of God or country, there is no crime so heinous that the public will not forgive it.", "Tom Robbins"],
  ["If it wasn't for bad luck, I wouldn't have no luck at all.", "William Bell"],
  ["If it weren't for the fact that the TV set and the refrigerator are so far apart, some of us wouldn't get any exercise at all.", "Joey Adams"],
  ["If liberty means anything at all, it means the right to tell people what they do not want to hear.", "George Orwell"],
  ["If life had a second edition, how I would correct the proofs.", "John Clare"],
  ["If men could regard the events of their own lives with more open minds, they would frequently discover that they did not really desire the things they failed to obtain.", "Emile Herzog"],
  ["If money be not thy servant, it will be thy master. The covetous man cannot so properly be said to possess wealth, as that may be said to possess him.", "Francis Bacon"],
  ["If more politicians in this country were thinking about the next generation instead of the next election, it might be better for the United States and the world.", "Claude Pepper"],
  ["If my decomposing carcass helps nourish the roots of a juniper tree or the wings of a vulture - that is immortality enough for me.", "Edward Abbey"],
  ["If one sins against the laws of proportion and gives something too big to something too small to carry it - too big sails to too small a ship, too big meals to too small a body, too big powers to too small a soul - the result is bound to be a complete upset. In an outburst of hubris the overfed body will rush into sickness, while the jack-in-office will rush into the unrighteousness that hubris always breeds.", "Plato"],
  ["If only I could so live and so serve the world that after me there should never again be birds in cages.", "Isak Dinesen (pen name of Karen Blixen)"],
  ["If only I may grow: firmer, simpler, - quieter, warmer.", "Dag Hammarskjold"],
  ["If only the sun-drenched celebrities are being noticed and worshiped, then our children are going to have a tough time seeing the value in the shadows, where the thinkers, probers and scientists are keeping society together.", "Rita Dove"],
  ["If only there were evil people somewhere insidiously committing evil deeds and it were necessary only to separate them from the rest of us and destroy them. But the line dividing good and evil cuts through the heart of every human being. And who is willing to destroy a piece of his own heart?", "Aleksandr Solzhenitsyn"],
  ["If people are good only because they fear punishment, and hope for reward, then we are a sorry lot indeed.", "Albert Einstein"],
  ["If people knew how hard I worked to get my mastery, it wouldn't seem so wonderful after all.", "Michelangelo Buonarroti"],
  ["If pleasures are greatest in anticipation, just remember that this is also true of trouble.", "Elbert Hubbard"],
  ["If privacy is outlawed, only outlaws will have privacy.", "Phil Zimmermann"],
  ["If slaughterhouses had glass walls, everyone would be a vegetarian.", "Paul McCartney"],
  ["If someone does something we disapprove of, we regard him as bad if we believe we can deter him from persisting in his conduct, but we regard him as mad if we believe we cannot.", "Thomas Szasz"],
  ["If stock market experts were so expert, they would be buying stock, not selling advice.", "Norman R. Augustine"],
  ["If the aborigine drafted an IQ test, all of Western civilization would presumably flunk it.", "Stanley Marion Garn"],
  ["If the doors of perception were cleansed everything would appear to man as it is, infinite.", "William Blake"],
  ["If the gods listened to the prayers of men, all humankind would quickly perish since they constantly pray for many evils to befall one another.", "Epicurus"],
  ["If the stars should appear one night in a thousand years, how would men believe and adore.", "Ralph Waldo Emerson"],
  ["If the truth doesn't save us, what does that say about us?", "Lois McMaster Bujold"],
  ["If the wind will not serve, take to the oars.", "Latin proverb"],
  ["If the world ever advances beyond what it is today, it must be led by men who express their real opinions.", "Robert G. Ingersoll"],
  ["If there be such a thing as truth, it must infallibly be struck out by the collision of mind with mind.", "William Godwin"],
  ["If there be time to expose through discussion the falsehood and the fallacies, to avert the evil by the processes of education, the remedy to be applied is more speech, not enforced silence.", "Louis Dembitz Brandeis"],
  ["If there existed no external means for dimming their consciences, one-half of the men would at once shoot themselves, because to live contrary to one's reason is a most intolerable state, and all men of our time are in such a state.", "Leo Tolstoy"],
  ["If there is a God, I don't think He would demand that anyone bow down or stand up to Him.", "Rebecca West"],
  ["If there is a sin against life, it consists perhaps not so much in despairing of life as in hoping for another life and in eluding the implacable grandeur of this life.", "Albert Camus"],
  ["If there's a book you really want to read but it hasn't been written yet, then you must write it.", "Toni Morrison"],
  ["If they can get you asking the wrong questions, they don't have to worry about the answers.", "Thomas Pynchon"],
  ["If they give you ruled paper, write the other way.", "Juan Ramon Jimenez"],
  ["If thought corrupts language, language can also corrupt thought.", "George Orwell"],
  ["If triangles had a God, he would have three sides.", "Charles de Montesquieu"],
  ["If Tyranny and Oppression come to this land, it will be in the guise of fighting a foreign enemy.", "James Madison"],
  ["If we could read the secret history of our enemies, we should find in each man's life sorrow and suffering enough to disarm all hostility.", "Henry Wadsworth Longfellow"],
  ["If we don't believe in freedom of expression for people we despise, we don't believe in it at all.", "Noam Chomsky"],
  ["If we escape punishment for our vices, why should we complain if we are not rewarded for our virtues?", "John Churton Collins"],
  ["If we had paid no more attention to our plants than we have to our children, we would now be living in a jungle of weed.", "Luther Burbank"],
  ["If we have not quiet in our minds, outward comfort will do no more for us than a golden slipper on a gouty foot.", "John Bunyan"],
  ["If we make peaceful revolution impossible, we make violent revolution inevitable.", "John F. Kennedy"],
  ["If we were to wake up some morning and find that everyone was the same race, creed and color, we would find some other cause for prejudice by noon.", "George D. Aiken"],
  ["If we would have new knowledge, we must get us a whole world of new questions.", "Susanne Langer"],
  ["If what you are getting online is for free, you are not the customer, you are the product.", "Jonathan Zittrain"],
  ["If words are to enter men's minds and bear fruit, they must be the right words shaped cunningly to pass men's defenses and explode silently and effectually within their minds.", "J.B. Phillips"],
  ["If writers were good businessmen, they'd have too much sense to be writers.", "Irvin S. Cobb"],
  ["If you are afraid of being lonely, don't try to be right.", "Jules Renard"],
  ["If you are neutral in situations of injustice, you have chosen the side of the oppressor. If an elephant has its foot on the tail of a mouse and you say that you are neutral, the mouse will not appreciate your neutrality.", "Desmond Tutu"],
  ["If you are out to describe the truth, leave elegance to the tailor.", "Albert Einstein"],
  ["If you break your neck, if you have nothing to eat, if your house is on fire, then you've got a problem. Everything else is an inconvenience. Life is inconvenient. Life is lumpy. A lump in the oatmeal, a lump in the throat, and a lump in the breast are not the same kind of lump. One needs to learn the difference.", "Robert Fulghum"],
  ["If you bungle raising your children, I don't think whatever else you do well matters very much.", "Jacqueline Kennedy Onassis"],
  ["If you came and you found a strange man... teaching your kids to punch each other, or trying to sell them all kinds of products, you'd kick him right out of the house, but here you are; you come in and the TV is on, and you don't think twice about it.", "Jerome E. Singer"],
  ["If you could say it in words there would be no reason to paint.", "Edward Hopper"],
  ["If you devote your life to seeking revenge, first dig two graves.", "Confucius"],
  ["If you do not tell the truth about yourself you cannot tell it about other people.", "Virginia Woolf"],
  ["If you don't love me, it does not matter, anyway I can love for both of us.", "Stendhal (Marie-Henri Beyle)"],
  ["If you don't turn your life into a story, you just become a part of someone else's story.", "Terry Pratchett"],
  ["If you have a weak candidate and a weak platform, wrap yourself up in the American flag and talk about the Constitution.", "Matthew Stanley Quay"],
  ["If you have knowledge, let others light their candles in it.", "Margaret Fuller"],
  ["If you have the same ideas as everybody else but have them one week earlier than everyone else then you will be hailed as a visionary. But if you have them five years earlier you will be named a lunatic.", "Barry Jones"],
  ["If you hire only those people you understand, the company will never get people better than you are. Always remember that you often find outstanding people among those you don't particularly like.", "Soichiro Honda"],
  ["If you know only one language, you're a prisoner, stuck in the tyranny of that one language.", "Andrew Cohen"],
  ["If you pray for rain long enough, it eventually does fall. If you pray for floodwaters to abate, they eventually do. The same happens in the absence of prayers.", "Steve Allen"],
  ["If you put a chain around the neck of a slave, the other end fastens itself around your own.", "Ralph Waldo Emerson"],
  ["If you shut your door to all errors truth will be shut out.", "Rabindranath Tagore"],
  ["If you talk to a man in a language he understands, that goes to his head. If you talk to him in his language, that goes to his heart.", "Nelson Mandela"],
  ["If you talk to God, you are praying. If God talks to you, you have schizophrenia.", "Thomas Szasz"],
  ["If you torture data sufficiently, it will confess to almost anything.", "Fred Menger"],
  ["If you want a symbolic gesture, don't burn the flag, wash it.", "Norman Thomas"],
  ["If you want creative workers, give them enough time to play.", "John Cleese"],
  ["If you want to make peace with your enemy, you have to work with your enemy. Then he becomes your partner.", "Nelson Mandela"],
  ["If you want to make peace, you don't talk to your friends. You talk to your enemies.", "Moshe Dayan"],
  ["If you want to understand your government, don't begin by reading the Constitution. It conveys precious little of the flavor of today's statecraft. Instead, read selected portions of the Washington Telephone Directory, such as pages 354-58, which contain listings for all the organizations with titles beginning with the word \"National\". There are, of course, the big ones, like the National Association of Manufacturers, and the National Association of Broadcasters. But the pages teem with others, National Cigar Leaf Tobacco Association, National Association of Mirror Manufacturers, National Association of Miscellaneous Ornamental and Architectural Products Contractors, National Association of Margarine Manufacturers.", "George Will"],
  ["If you want to work on your art, work on your life.", "Anton Chekhov"],
  ["If you want total security, go to prison. There you're fed, clothed, given medical care and so on. The only thing lacking... is freedom.", "Dwight D. Eisenhower"],
  ["If you want your children to turn out well, spend twice as much time with them, and half as much money.", "Abigail Van Buren"],
  ["If you wish to be loved, show more of your faults than your virtues.", "Edward Bulwer-Lytton"],
  ["If you wish to make an apple pie truly from scratch, you must first invent the universe.", "Carl Sagan"],
  ["If you would be a real seeker after truth, it is necessary that at least once in your life you doubt, as far as possible, all things.", "Rene Descartes"],
  ["If you would be pungent, be brief; for it is with words as with sunbeams - the more they are condensed, the deeper they burn.", "Robert Southey"],
  ["If you would stand well with a great mind, leave him with a favorable impression of yourself; if with a little mind, leave him with a favorable impression of himself.", "Samuel Taylor Coleridge"],
  ["If you wouldn't write it and sign it, don't say it.", "Earl Wilson"],
  ["If you write to impress it will always be bad, but if you write to express it will be good.", "Thornton Wilder"],
  ["If you've got a religious belief that withers in the face of observations of the natural world, you ought to rethink your beliefs - rethinking the world isn't an option.", "PZ Myers"],
  ["If your morals make you dreary, depend on it they are wrong.", "Robert Louis Stevenson"],
  ["If, after I depart this vale, you ever remember me and have thought to please my ghost, forgive some sinner and wink your eye at some homely girl.", "H.L. Mencken"],
  ["If, every day, I dare to remember that I am here on loan, that this house, this hillside, these minutes are all leased to me, not given, I will never despair. Despair is for those who expect to live forever. I no longer do.", "Erica Jong"],
  ["Ignorance is an evil weed, which dictators may cultivate among their dupes, but which no democracy can afford among its citizens.", "William Beveridge"],
  ["Ignorance more frequently begets confidence than does knowledge.", "Charles Darwin"],
  ["Ignorance, allied with power, is the most ferocious enemy justice can have.", "James Baldwin"],
  ["Illegal aliens have always been a problem in the United States. Ask any Indian.", "Robert Orben"],
  ["Illness is in part what the world has done to a victim, but in a larger part it is what the victim has done with his world.", "Karl Menninger"],
  ["Imagine a world in which generations of human beings come to believe that certain films were made by God or that specific software was coded by him. Imagine a future in which millions of our descendants murder each other over rival interpretations of Star Wars or Windows 98. Could anything - anything - be more ridiculous? And yet, this would be no more ridiculous than the world we are living in.", "Sam Harris"],
  ["Imagine there's no countries, / It isn't hard to do. / Nothing to kill or die for, / And no religion, too. / Imagine all the people / Living life in peace.", "John Lennon"],
  ["Impiety, n. Your irreverence toward my deity.", "Ambrose Bierce"],
  ["In a completely rational society, the best of us would be teachers and the rest of us would have to settle for something less, because passing civilization along from one generation to the next ought to be the highest honor and the highest responsibility anyone could have.", "Lee Iacocca"],
  ["In a consumer society there are inevitably two kinds of slaves: the prisoners of addiction and the prisoners of envy.", "Ivan Illich"],
  ["In a democracy dissent is an act of faith. Like medicine, the test of its value is not in its taste, but in its effects.", "J. William Fulbright"],
  ["In a free country there is much clamor, with little suffering: in a despotic state there is little complaint but much suffering.", "Lazare Nicolas Marguerite Carnot"],
  ["In a library we are surrounded by many hundreds of dear friends imprisoned by an enchanter in paper and leathern boxes.", "Ralph Waldo Emerson"],
  ["In a perfect union the man and woman are like a strung bow. Who is to say whether the string bends the bow, or the bow tightens the string?", "Cyril Connolly"],
  ["In a pond koi can reach lengths of eighteen inches. Amazingly, when placed in a lake, koi can grow to three feet long. The metaphor is obvious. You are limited by how you see the world.", "Vince Poscente"],
  ["In a time of drastic change it is the learners who inherit the future. The learned usually find themselves equipped to live in a world that no longer exists.", "Eric Hoffer"],
  ["In a world of fugitives, the person taking the opposite direction will appear to run away.", "T.S. Eliot"],
  ["In all affairs it's a healthy thing now and then to hang a question mark on the things you have long taken for granted.", "Bertrand Russell"],
  ["In all history there is no war which was not hatched by the governments, the governments alone, independent of the interests of the people, to whom war is always pernicious even when successful.", "Leo Tolstoy"],
  ["In all life one should comfort the afflicted, but verily, also, one should afflict the comfortable, and especially when they are comfortably, contentedly, even happily wrong.", "John Kenneth Galbraith"],
  ["In America, anybody can be president. That's one of the risks you take.", "Adlai Stevenson"],
  ["In an earlier stage of our development most human groups held to a tribal ethic. Members of the tribe were protected, but people of other tribes could be robbed or killed as one pleased. Gradually the circle of protection expanded, but as recently as 150 years ago we did not include blacks. So African human beings could be captured, shipped to America, and sold. In Australia white settlers regarded Aborigines as a pest and hunted them down, much as kangaroos are hunted down today. Just as we have progressed beyond the blatantly racist ethic of the era of slavery and colonialism, so we must now progress beyond the speciesist ethic of the era of factory farming, of the use of animals as mere research tools, of whaling, seal hunting, kangaroo slaughter, and the destruction of wilderness. We must take the final step in expanding the circle of ethics.", "Peter Singer"],
  ["In any free society, the conflict between social conformity and individual liberty is permanent, unresolvable, and necessary.", "Kathleen Norris"],
  ["In death the many become one; in life the one becomes many.", "Rabindranath Tagore"],
  ["In every work of genius we recognize our own rejected thoughts: they come back to us with a certain alienated majesty.", "Ralph Waldo Emerson"],
  ["In everyone's life, at some time, our inner fire goes out. It is then burst into flame by an encounter with another human being. We should all be thankful for those people who rekindle the inner spirit.", "Albert Schweitzer"],
  ["In International / Consequences / the players must reckon / to reap what they've sown. / We have a defence / against other defences, / but what's to defend us / against our own?", "Piet Hein"],
  ["In my youth I thought of writing a satire on mankind; but now in my age I think I should write an apology for them.", "Horace Walpole"],
  ["In nature there are neither rewards nor punishments; there are consequences.", "Robert Green Ingersoll"],
  ["In nothing does man, with his grand notions of heaven and charity, show forth his innate, low-bred, wild animalism more clearly than in his treatment of his brother beasts. From the shepherd with his lambs to the red-handed hunter, it is the same; no recognition of rights - only murder in one form or another.", "John Muir"],
  ["In one and the same fire, clay grows hard and wax melts.", "Francis Bacon"],
  ["In order that people may be happy in their work, these three things are needed: they must be fit for it; they must not do too much of it; and they must have a sense of success in it.", "John Ruskin"],
  ["In order to improve the mind, we ought less to learn than to contemplate.", "Rene Descartes"],
  ["In other countries poverty is a misfortune - with us it is a crime.", "Edward Bulwer-Lytton"],
  ["In our world of big names, curiously, our true heroes tend to be anonymous. In this life of illusion and quasi-illusion, the person of solid virtues who can be admired for something more substantial than his well-knownness often proves to be the unsung hero: the teacher, the nurse, the mother, the honest cop, the hard worker at lonely, underpaid, unglamorous, unpublicized jobs.", "Daniel J. Boorstin"],
  ["In politics, absurdity is not a handicap.", "Napoleon Bonaparte"],
  ["In questions of science, the authority of a thousand is not worth the humble reasoning of a single individual.", "Galileo Galilei"],
  ["In reality, every reader is, while he is reading, the reader of his own self.", "Marcel Proust"],
  ["In recognizing the humanity of our fellow beings, we pay ourselves the highest tribute.", "Thurgood Marshall"],
  ["In religion, faith is a virtue. In science, faith is a vice.", "Jerry Coyne"],
  ["In science it often happens that scientists say, \"You know that's a really good argument; my position is mistaken,\" and then they would actually change their minds and you never hear that old view from them again. They really do it. It doesn't happen as often as it should, because scientists are human and change is sometimes painful. But it happens every day. I cannot recall the last time something like that happened in politics or religion.", "Carl Sagan"],
  ["In seeking wisdom, the first step is silence, the second listening, the third remembering, the fourth practicing, the fifth - teaching others.", "Ibn Gabirol"],
  ["In solitude, when we are least alone.", "Lord Byron"],
  ["In some circumstances, the refusal to be defeated is a refusal to be educated.", "Margaret Halsey"],
  ["In the absence of touching and being touched, people of all ages can sicken and grow touch-starved.", "Diane Ackerman"],
  ["In the case of good books, the point is not how many of them you can get through, but rather how many can get through to you.", "Mortimer J. Adler"],
  ["In the cellars of the night, when the mind starts moving around old trunks of bad times, the pain of this and the shame of that, the memory of a small boldness is a hand to hold.", "John Leonard"],
  ["In the common words we use every day, souls of past races, the thoughts and feelings of individual men stand around us, not dead, but frozen into their attitudes like the courtiers in the garden of the Sleeping Beauty.", "Owen Barfield"],
  ["In the face of suffering, one has no right to turn away, not to see.", "Elie Wiesel"],
  ["In the faces of men and women I see God.", "Walt Whitman"],
  ["In the new version of the law of supply and demand, jobs are so cheap - as measured by the pay - that a worker is encouraged to take on as many of them as she possibly can.", "Barbara Ehrenreich"],
  ["In the ordinary business of life, industry can do anything which genius can do, and very many things which it cannot.", "Henry Ward Beecher"],
  ["In the presence of eternity, the mountains are as transient as the clouds.", "Robert Green Ingersoll"],
  ["In the republic of mediocrity genius is dangerous.", "Robert G. Ingersoll"],
  ["In the silence of night I have often wished for just a few words of love from one man, rather than the applause of thousands of people.", "Judy Garland"],
  ["In the small matters trust the mind, in the large ones the heart.", "Sigmund Freud"],
  ["In the Soviet Union, capitalism triumphed over communism. In this country, capitalism triumphed over democracy.", "Fran Lebowitz"],
  ["In the struggle between yourself and the world, second the world.", "Franz Kafka"],
  ["In the youth of a state arms do flourish; in the middle age of a state, learning; and then both of them together for a time; in the declining age of a state, mechanical arts and merchandise.", "Francis Bacon"],
  ["In their early passions women are in love with the lover, later they are in love with love.", "François de La Rochefoucauld"],
  ["In this age, the mere example of nonconformity, the mere refusal to bend the knee to custom, is itself a service.", "John Stuart Mill"],
  ["In this world there are only two tragedies. One is not getting what one wants, and the other is getting it. The last is much the worst.", "Oscar Wilde"],
  ["In this world, you must be a bit too kind to be kind enough.", "Pierre Carlet de Chamblain de Marivaux"],
  ["In those parts of the world where learning and science have prevailed, miracles have ceased; but in those parts of it as are barbarous and ignorant, miracles are still in vogue.", "Ethan Allen"],
  ["In three words I can sum up everything I've learned about life: it goes on.", "Robert Frost"],
  ["In truth everything and everyone / Is a shadow of the Beloved, / And our seeking is His seeking / And our words are His words... / We search for Him here and there, / while looking right at Him. / Sitting by His side, we ask: / \"O Beloved, where is the Beloved?\"", "Rumi"],
  ["In words are seen the state of mind and character and disposition of the speaker.", "Plutarch"],
  ["In words as fashions the same rule will hold, / Alike fantastic if too new or old; / Be not the first by whom the new are tried, / Nor yet the last to lay the old aside.", "Alexander Pope"],
  ["In youth we feel richer for every new illusion; in maturer years, for every one we lose.", "Madame Anne Sophie Swetchine"],
  ["Ink is handicapped, in a way, because you can blow up a man with gunpowder in half a second, while it may take twenty years to blow him up with a book. But the gunpowder destroys itself along with its victim, while a book can keep on exploding for centuries.", "Christopher Morley"],
  ["Inquiry is fatal to certainty.", "Will Durant"],
  ["Insane people are always sure that they are fine. It is only the sane people who are willing to admit that they are crazy.", "Nora Ephron"],
  ["Insanity - a perfectly rational adjustment to an insane world.", "RD Laing"],
  ["Insanity in individuals is something rare - but in groups, parties, nations, and epochs, it is the rule.", "Friedrich Nietzsche"],
  ["Insanity is relative. It depends on who has who locked in what cage.", "Ray Bradbury"],
  ["Inside my empty bottle I was constructing a lighthouse while all the others were making ships.", "Charles Simic"],
  ["Integrity has no need of rules.", "Albert Camus"],
  ["Intellectuals solve problems: geniuses prevent them.", "Albert Einstein"],
  ["Interpretation is the revenge of the intellect upon art.", "Susan Sontag"],
  ["Intolerance of ambiguity is the mark of an authoritarian personality.", "Theodor Adorno"],
  ["Invention requires an excited mind; execution, a calm one.", "Johann Peter Eckermann"],
  ["Iron rusts from disuse, stagnant water loses its purity, and in cold weather becomes frozen, even so does inaction sap the vigor of the mind.", "Leonardo da Vinci"],
  ["Irreverence is the champion of liberty and its only sure defense.", "Mark Twain"],
  ["Is God willing to prevent evil, but not able? Then he is not omnipotent. Is he able, but not willing? Then he is malevolent. Is he both able and willing? Then whence cometh evil? Is he neither able nor willing? Then why call him God?", "Epicurus"],
  ["Is it [hunting] really a sport if you have all the equipment and your opponent doesn't know a game is going on?", "Bill Maher"],
  ["Is man one of God's blunders or is God one of man's?", "Friedrich Nietzsche"],
  ["Is not dread of thirst when your well is full, thirst that is unquenchable?", "Khalil Gibran"],
  ["Isn't it enough to see that a garden is beautiful without having to believe that there are fairies at the bottom of it too?", "Douglas Adams"],
  ["It (death) comes equally to us all, and makes us all equal when it comes.", "John Donne"],
  ["It (marriage) may be compared to a cage, the birds without try desperately to get in, and those within try desperately to get out.", "Michel de Montaigne"],
  ["It came to me that reform should begin at home, and since that day I have not had time to remake the world.", "Will Durant"],
  ["It does not do to leave a live dragon out of your calculations, if you live near him.", "J.R.R. Tolkien"],
  ["It does not require many words to speak the truth.", "Chief Joseph"],
  ["It doesn't matter how beautiful your theory is, it doesn't matter how smart you are. If it doesn't agree with experiment, it's wrong.", "Richard Feynman"],
  ["It has always seemed absurd to suppose that a god would choose for his companions, during all eternity, the dear souls whose highest and only ambition is to obey.", "Robert Green Ingersoll"],
  ["It has always seemed strange to me that in our endless discussions about education so little stress is laid on the pleasure of becoming an educated person, the enormous interest it adds to life. To be able to be caught up into the world of thought - that is to be educated.", "Edith Hamilton"],
  ["It has been said that a pretty face is a passport. But it's not, it's a visa, and it runs out fast.", "Julie Burchill"],
  ["It is a bit embarrassing to have been concerned with the human problem all one's life and find at the end that one has no more to offer by way of advice than \"try to be a little kinder.\"", "Aldous Huxley"],
  ["It is a capital mistake to theorize before one has data. Insensibly one begins to twist facts to suit theories, instead of theories to suit facts.", "Arthur Conan Doyle"],
  ["It is a curious thing that every creed promises a paradise which will be absolutely uninhabitable for anyone of civilized taste.", "Evelyn Waugh"],
  ["It is a curious thought, but it is only when you see people looking ridiculous that you realize just how much you love them.", "Agatha Christie"],
  ["It is a difficult matter to argue with the belly since it has no ears.", "Cato the Elder"],
  ["It is a glorious thing to be indifferent to suffering, but only to one's own suffering.", "Robert Lynd"],
  ["It is a profound and necessary truth that the deep things in science are not found because they are useful; they are found because it was possible to find them.", "J. Robert Oppenheimer"],
  ["It is a trick among the dishonest to offer sacrifices that are not needed, or not possible, to avoid making those that are required.", "Ivan Goncharov"],
  ["It is a truism that almost any sect, cult, or religion will legislate its creed into law if it acquires the political power to do so.", "Robert A. Heinlein"],
  ["It is a very lonely life that a man leads, who becomes aware of truths before their times.", "Thomas Brackett Reed"],
  ["It is almost impossible to carry the torch of truth through a crowd without singeing somebody's beard.", "Georg Christoph Lichtenberg"],
  ["It is also a victory to know when to retreat.", "Erno Paasilinna"],
  ["It is always the secure who are humble.", "G.K. Chesterton"],
  ["It is an ironic habit of human beings to run faster when we have lost our way.", "Rollo May"],
  ["It is an outrage to discriminate against any such man because of creed, or birthplace, or origin.", "Theodore Roosevelt"],
  ["It is as easy to dream a book as it is hard to write one.", "Honore de Balzac"],
  ["It is as hard for the good to suspect evil, as it is for the bad to suspect good.", "Marcus Tullius Cicero"],
  ["It is as impossible to translate poetry as it is to translate music.", "Voltaire"],
  ["It is better to have loafed and lost than never to have loafed at all.", "James Thurber"],
  ["It is better to prevent crimes than to punish them.", "Cesare Beccaria"],
  ["It is better to sleep on things beforehand than lie awake about them afterwards.", "Baltasar Gracian"],
  ["It is by the goodness of God that in our country we have those three unspeakably precious things: freedom of speech, freedom of conscience, and the prudence never to practice either of them.", "Mark Twain"],
  ["It is chiefly through books that we enjoy intercourse with superior minds.", "William Ellery Channing"],
  ["It is criminal to steal a purse, daring to steal a fortune, a mark of greatness to steal a crown. The blame diminishes as the guilt increases.", "Johann Christoph Friedrich von Schiller"],
  ["It is curious that physical courage should be so common in the world and moral courage so rare.", "Mark Twain"],
  ["It is dangerous to be right when the government is wrong.", "Voltaire"],
  ["It is difficult to begin without borrowing, but perhaps it is the most generous course thus to permit your fellow-men to have an interest in your enterprise.", "Henry David Thoreau"],
  ["It is difficult to free fools from the chains they revere.", "Voltaire"],
  ["It is difficult to get a man to understand something when his salary depends upon his not understanding it.", "Upton Sinclair"],
  ["It is difficult to get the news from poems yet men die miserably every day for lack of what is found there.", "William Carlos Williams"],
  ["It is easier to fight for one’s principles than to live up to them.", "Felix Adler"],
  ["It is easier to lead men to combat, stirring up their passion, than to restrain them and direct them toward the patient labors of peace.", "Andre Gide"],
  ["It is easier to make a saint out of a libertine than out of a prig.", "George Santayana"],
  ["It is easy in the world to live after the world's opinion, it is easy in solitude to live after your own; but the great man is he who, in the midst of the world, keeps with perfect sweetness the independence of solitude.", "Ralph Waldo Emerson"],
  ["It is error only, and not truth, that shrinks from inquiry.", "Thomas Paine"],
  ["It is forbidden to kill; therefore all murderers are punished unless they kill in large numbers and to the sound of trumpets.", "Voltaire"],
  ["It is fortunate to be of high birth, but it is no less so to be of such character that people do not care to know whether you are or are not.", "Jean de la Bruyere"],
  ["It is good to rub and polish your mind against that of others.", "Michel de Montaigne"],
  ["It is hard enough to remember my opinions, without also remembering my reasons for them.", "Friedrich Nietzsche"],
  ["It is horrifying that we have to fight our own government to save the environment.", "Ansel Adams"],
  ["It is human nature to hate the man whom you have hurt.", "Publius Cornelius Tacitus"],
  ["It is impossible to enjoy idling thoroughly unless one has plenty of work to do. There is no fun in doing nothing when you have nothing to do. Wasting time is merely an occupation then, and a most exhausting one. Idleness, like kisses, to be sweet must be stolen.", "Jerome K. Jerome"],
  ["It is impossible to imagine Goethe or Beethoven being good at billiards or golf.", "H.L. Mencken"],
  ["It is impossible to live pleasurably without living prudently, honorably, and justly; or to live prudently, honorably, and justly, without living pleasurably.", "Epicurus"],
  ["It is inaccurate to say I hate everything. I am strongly in favor of common sense, common honesty, and common decency. This makes me forever ineligible for public office.", "H.L. Mencken"],
  ["It is kindness immediately to refuse what you intend to deny.", "Publilius Syrus"],
  ["It is lamentable, that to be a good patriot one must become the enemy of the rest of mankind.", "Voltaire"],
  ["It is more often from pride than from ignorance that we are so obstinately opposed to current opinions; we find the first places taken, and we do not want to be the last.", "François de La Rochefoucauld"],
  ["It is more shameful to distrust one's friends than to be deceived by them.", "De la Rochefoucauld"],
  ["It is my belief that the writer, the free-lance author, should be and must be a critic of the society in which he lives. It is easy enough, and always profitable, to rail away at national enemies beyond the sea, at foreign powers beyond our borders who question the prevailing order. But the moral duty of the free writer is to begin his work at home; to be a critic of his own community, his own country, his own culture. If the writer is unwilling to fill this part, then the writer should abandon pretense and find another line of work: become a shoe repairman, a brain surgeon, a janitor, a cowboy, a nuclear physicist, a bus driver.", "Edward Abbey"],
  ["It is necessary to the happiness of man that he be mentally faithful to himself. Infidelity does not consist in believing, or in disbelieving; it consists in professing to believe what he does not believe.", "Thomas Paine"],
  ["It is never my custom to use words lightly. If twenty-seven years in prison have done anything to us, it was to use the silence of solitude to make us understand how precious words are and how real speech is in its impact on the way people live and die.", "Nelson Mandela"],
  ["It is never the shallower for the calmnesse. The Sea is a deepe, there is as much water in the Sea, in a calme, as in a storme.", "John Donne"],
  ["It is no measure of health to be well adjusted to a profoundly sick society.", "J. Krishnamurti"],
  ["It is not always the same thing to be a good man and a good citizen.", "Aristotle"],
  ["It is not bigotry to be certain we are right; but it is bigotry to be unable to imagine how we might possibly have gone wrong.", "G.K. Chesterton"],
  ["It is not giving children more that spoils them; it is giving them more to avoid confrontation.", "John Gray"],
  ["It is not how old you are, but how you are old.", "Jules Renard"],
  ["It is not necessarily true that averaging the averages of different populations gives the average of the combined population. (Simpson's Paradox)", "Edward H. Simpson"],
  ["It is not only the prisoners who grow coarse and hardened from corporal punishment, but those as well who perpetrate the act or are present to witness it.", "Anton Chekhov"],
  ["It is not so much our friends' help that helps us as the confident knowledge that they will help us.", "Epicurus"],
  ["It is not the mountain we conquer but ourselves.", "Edmund Hillary"],
  ["It is not the strongest of the species that survive, nor the most intelligent, but the one most responsive to change.", "Charles Darwin"],
  ["It is not what we do, but also what we do not do, for which we are accountable.", "Moliere"],
  ["It is of interest to note that while some dolphins are reported to have learned English - up to fifty words used in correct context - no human being has been reported to have learned dolphinese.", "Carl Sagan"],
  ["It is often forgotten that (dictionaries) are artificial repositories, put together well after the languages they define. The roots of language are irrational and of a magical nature.", "Jorge Luis Borges"],
  ["It is one of the blessings of old friends that you can afford to be stupid with them.", "Ralph Waldo Emerson"],
  ["It is one of the maladies of our age to profess a frenzied allegiance to truth in unimportant matters, to refuse consistently to face her where graver issues are at stake.", "Janos Arany"],
  ["It is one of the most beautiful compensations of this life that no man can sincerely try to help another without helping himself.", "Ralph Waldo Emerson"],
  ["It is one thing to show a man that he is in error, and another to put him in possession of the truth.", "John Locke"],
  ["It is only the wisest and the stupidest that cannot change.", "Confucius"],
  ["It is only those who have neither fired a shot nor heard the shrieks and groans of the wounded who cry aloud for blood, more vengeance, more desolation. War is hell.", "William Tecumseh Sherman"],
  ["It is only when the rich are sick that they fully feel the impotence of wealth.", "Charles Caleb Colton"],
  ["It is paradoxical that many educators and parents still differentiate between a time for learning and a time for play without seeing the vital connection between them.", "Leo Buscaglia"],
  ["It is preoccupation with possessions, more than anything else, that prevents us from living freely and nobly.", "Bertrand Russell"],
  ["It is probably no mere historical accident that the word person, in its first meaning, is a mask. It is rather a recognition of the fact that everyone is always and everywhere, more or less consciously, playing a role.", "Robert Ezra Park"],
  ["It is said that a rogue does not look you in the face, neither does an honest man look at you as if he had his reputation to establish. I have seen some who did not know when to turn aside their eyes in meeting yours. A truly confident and magnanimous spirit is wiser than to contend for the mastery in such encounters.", "Henry David Thoreau"],
  ["It is surprising what a man can do when he has to, and how little most men will do when they don't have to.", "Walter Linn"],
  ["It is the ability to take a joke, not make one, that proves you have a sense of humor.", "Max Eastman"],
  ["It is the certainty that they possess the truth that makes men cruel.", "Anatole France"],
  ["It is the characteristic of the most stringent censorships that they give credibility to the opinions they attack.", "Voltaire"],
  ["It is the final proof of God's omnipotence that he need not exist in order to save us.", "Peter De Vries"],
  ["It is the greatest of all advantages to enjoy no advantage at all.", "Henry David Thoreau"],
  ["It is the greatest of all mistakes to do nothing because you can only do a little.", "Sydney Smith"],
  ["It is the mark of an educated mind to be able to entertain a thought without accepting it.", "Aristotle"],
  ["It is useless to attempt to reason a man out of a thing he was never reasoned into.", "Jonathan Swift"],
  ["It is well to know something of the manners of various peoples, in order more sanely to judge our own, and that we do not think that everything against our modes is ridiculous, and against reason, as those who have seen nothing are accustomed to think.", "Rene Descartes"],
  ["It is with words as with sunbeams - the more they are condensed, the deeper they burn.", "Robert Southey"],
  ["It is wrong always, everywhere, and for anyone, to believe anything upon insufficient evidence.", "William Kingdon Clifford"],
  ["It may sound trite, but using the weapons of the enemy, no matter how good one's intentions, makes one the enemy.", "Charles de Lint"],
  ["It might be a good idea if the various countries of the world would occasionally swap history books, just to see what other people are doing with the same set of facts.", "Bill Vaughan"],
  ["It often happens that those of whom we speak least on earth are best known in heaven.", "Nicolas Caussin"],
  ["It ought to be plain / how little you gain / by getting excited / and vexed. / You'll always be late / for the previous train, / and always in time / for the next.", "Piet Hein"],
  ["It seems like the less a statesman amounts to, the more he adores the flag.", "Kin Hubbard"],
  ["It seldom happens that any felicity comes so pure as not to be tempered and allayed by some mixture of sorrow.", "Miguel de Cervantes"],
  ["It takes a certain maturity of mind to accept that nature works as steadily in rust as in rose petals.", "Esther Warner Dendel"],
  ["It takes a great deal of bravery to stand up to our enemies, but just as much to stand up to our friends.", "J.K. Rowling"],
  ["It takes a lot of time to be a genius, you have to sit around so much doing nothing, really doing nothing.", "Gertrude Stein"],
  ["It takes two to speak the truth: one to speak, and another to hear.", "Henry David Thoreau"],
  ["It took less than an hour to make the atoms, a few hundred million years to make the stars and planets, but five billion years to make man!", "George Gamow"],
  ["It was my shame, and now it is my boast, That I have loved you rather more than most.", "Hilaire Belloc"],
  ["It was our own moral failure and not any accident of chance, that while preserving the appearance of the Republic we lost its reality.", "Marcus Tullius Cicero"],
  ["It will not do to investigate the subject of religion too closely, as it is apt to lead to infidelity.", "Abraham Lincoln"],
  ["It would indeed be ironic if, in the name of national defense, we would sanction the subversion of one of those liberties which make the defense of our nation worthwhile.", "Earl Warren"],
  ["It's a poor sort of memory that only works backwards.", "Lewis Carroll"],
  ["It's a shallow life that doesn't give a person a few scars.", "Garrison Keillor"],
  ["It's all right to tell a man to lift himself by his own bootstraps, but it is a cruel jest to say to a bootless man that he ought to lift himself by his own bootstraps.", "Martin Luther King, Jr"],
  ["It's best to give while your hand is still warm.", "Philip Roth"],
  ["It's good to have money and the things that money can buy, but it's good, too, to check up once in a while and make sure that you haven't lost the things that money can't buy.", "George H. Lorimer"],
  ["It's hard to be religious when certain people are never incinerated by bolts of lightning.", "Bill Watterson"],
  ["It's impossible to be loyal to your family, your friends, your country, and your principles, all at the same time.", "Mignon McLaughlin"],
  ["It's like, at the end, there's this surprise quiz: Am I proud of me? I gave my life to become the person I am right now. Was it worth what I paid?", "Richard Bach"],
  ["It's not a business with me. ... I'm not a professional of poetry; I'm a farmer of poetry.", "Jack Gilbert"],
  ["It's not the genius who is 100 years ahead of his time but average man who is 100 years behind it.", "Robert Musil"],
  ["It's splendid to be a great writer, to put men into the frying pan of your imagination and make them pop like chestnuts.", "Gustave Flaubert"],
  ["It's surprising how much memory is built around things unnoticed at the time.", "Barbara Kingsolver"],
  ["Jealousy in romance is like salt in food. A little can enhance the savor, but too much can spoil the pleasure and, under certain circumstances, can be life-threatening.", "Maya Angelou"],
  ["Jobs are like going to church: it's nice once or twice a year to sing along and eat something and all that, but unless you really believe there's something holy going on, it gets to be a drag going in every single week.", "Thomas Michael Disch"],
  ["Jokes of the proper kind, properly told, can do more to enlighten questions of politics, philosophy, and literature than any number of dull arguments.", "Isaac Asimov"],
  ["Journalism is publishing what someone doesn't want us to know, the rest is propaganda.", "Horacio Verbitsky"],
  ["Journalist Ed Murrow: \"Who owns the patent on this vaccine?\" Jonas Salk: \"Well, the people, I would say. There is no patent. Could you patent the sun?\"", "Jonas Salk"],
  ["Journalists do not believe the lies of politicians, but they do repeat them - which is even worse!", "Michel Colucci"],
  ["Joy is the best makeup.", "Anne Lamott"],
  ["Just as a cautious businessman avoids tying up all his capital in one concern, so, perhaps, worldly wisdom will advise us not to look for the whole of our satisfaction from a single aspiration.", "Sigmund Freud"],
  ["Just as appetite comes by eating, so work brings inspiration, if inspiration is not discernible at the beginning.", "Igor Stravinsky"],
  ["Just because you do not take an interest in politics doesn't mean politics won't take an interest in you.", "Pericles"],
  ["Just praise is only a debt, but flattery is a present.", "Samuel Johnson"],
  ["Just think of the tragedy of teaching children not to doubt.", "Clarence Darrow"],
  ["Justice will not be served until those who are unaffected are as outraged as those who are.", "Benjamin Franklin"],
  ["Keep away from people who try to belittle your ambitions. Small people always do that, but the really great make you feel that you, too, can become great.", "Mark Twain"],
  ["Keep me away from the wisdom which does not cry, the philosophy which does not laugh and the greatness which does not bow before children.", "Khalil Gibran"],
  ["Kill a man, and you are an assassin. Kill millions of men, and you are a conqueror. Kill everyone, and you are a god.", "Jean Rostand"],
  ["Kindness in words creates confidence. Kindness in thinking creates profoundness. Kindness in giving creates love.", "Lao Tzu"],
  ["Kindness is always fashionable.", "Amelia Barr"],
  ["Kindness is in our power, even when fondness is not.", "Samuel Johnson"],
  ["Kindness is loving people more than they deserve.", "Joseph Joubert"],
  ["Kindness is more important than wisdom, and the recognition of this is the beginning of wisdom.", "Theodore Rubin"],
  ["Kindness is not without its rocks ahead. People are apt to put it down to an easy temper and seldom recognize it as the secret striving of a generous nature; whilst, on the other hand, the ill-natured get credit for all the evil they refrain from.", "Honore de Balzac"],
  ["Kindness is the golden chain by which society is bound together.", "Johann Wolfgang von Goethe"],
  ["Kings stand more in need of the company of the intelligent than the intelligent do of the society of kings.", "Saadi"],
  ["Knowing all truth is less than doing a little bit of good.", "Albert Schweitzer"],
  ["Knowing ignorance is strength; ignoring knowledge is sickness.", "Lao Tzu"],
  ["Knowing is not enough; we must apply. Willing is not enough; we must do.", "Johann Wolfgang von Goethe"],
  ["Knowing others is intelligence; knowing yourself is true wisdom. Mastering others is strength, mastering yourself is true power.", "Lao Tzu"],
  ["Knowing trees, I understand the meaning of patience. Knowing grass, I can appreciate persistence.", "Hal Borland"],
  ["Knowing what / Thou knowest not / Is in a sense / Omniscience.", "Piet Hein"],
  ["Knowledge comes, but wisdom lingers.", "Alfred, Lord Tennyson"],
  ["Knowledge is of two kinds. We know a subject ourselves, or we know where we can find information upon it.", "Samuel Johnson"],
  ["Labor to keep alive in your breast that little spark of celestial fire called conscience.", "George Washington"],
  ["Language exerts hidden power, like a moon on the tides.", "Rita Mae Brown"],
  ["Language furnishes the best proof that a law accepted by a community is a thing that is tolerated and not a rule to which all freely consent.", "Ferdinand de Saussure"],
  ["Language is a city to the building of which every human being brought a stone.", "Ralph Waldo Emerson"],
  ["Language is a form of human reason, which has its internal logic of which man knows nothing.", "Claude Levi-Strauss"],
  ["Language is a skin: I rub my language against the other. It is as if I had words instead of fingers, or fingers at the tip of my words. My language trembles with desire.", "Roland Barthes"],
  ["Language is an anonymous, collective, and unconscious art; the result of the creativity of thousands of generations.", "Edward Sapir"],
  ["Language is as real, as tangible, in our lives as streets, pipelines, telephone switchboards, microwaves, radioactivity, cloning laboratories, nuclear power stations.", "Adrienne Rich"],
  ["Language is fossil poetry.", "Ralph Waldo Emerson"],
  ["Language is like money, without which specific relative values may well exist and be felt, but cannot be reduced to a common denominator.", "George Santayana"],
  ["Language is like soil. However rich, it is subject to erosion, and its fertility is constantly threatened by uses that exhaust its vitality. It needs constant re-invigoration if it is not to become arid and sterile", "Elizabeth Drew"],
  ["Language is more fashion than science, and matters of usage, spelling, and pronunciation tend to wander around like hemlines.", "Bill Bryson"],
  ["Language is not an abstract construction of the learned, or of dictionary makers, but is something arising out of the work, needs, ties, joys, affections, tastes, of long generations of humanity, and has its bases broad and low, close to the ground.", "Noah Webster"],
  ["Language is not neutral. It is not merely a vehicle which carries ideas. It is itself a shaper of ideas.", "Dale Spender"],
  ["Language is the amber in which a thousand precious and subtle thoughts have been safely embedded and preserved.", "Richard C. Trench"],
  ["Language is the archives of history.", "Ralph Waldo Emerson"],
  ["Language is the armoury of the human mind; and at once contains the trophies of its past, and the weapons of its future conquests.", "Samuel Taylor Coleridge"],
  ["Language is the only homeland.", "Czeslaw Milosz"],
  ["Language, n. The music with which we charm the serpents guarding another's treasure.", "Ambrose Bierce"],
  ["Languages differ essentially in what they must convey and not in what they may convey.", "Roman Jakobson"],
  ["Languages, like our bodies, are in a perpetual flux, and stand in need of recruits to supply those words that are continually falling through disuse.", "Cornelius Conway Felton"],
  ["Laugh, and the world laughs with you; / Weep, and you weep alone. / For this brave old earth must borrow its mirth, / But has trouble enough of its own.", "Ella Wheeler Wilcox"],
  ["Laughter and tears are meant to turn the wheels of the same machinery of sensibility; one is wind-power, and the other water-power.", "Oliver Wendell Holmes, Sr"],
  ["Laughter is inner jogging.", "Norman Cousins"],
  ["Laws too gentle are seldom obeyed; too severe, seldom executed.", "Benjamin Franklin"],
  ["Learning is acquired by reading books; but the much more necessary learning, the knowledge of the world, is only to be acquired by reading man, and studying all the various editions of them.", "Philip Dormer Stanhope"],
  ["Learning is like rowing upstream: not to advance is to drop back.", "Chinese proverb"],
  ["Learning without thought is labor lost; thought without learning is perilous.", "Confucius"],
  ["Leaving home in a sense involves a kind of second birth in which we give birth to ourselves.", "Robert Neelly Bellah"],
  ["Let early education be a sort of amusement, you will then better be able to find out the natural bent of the child.", "Plato"],
  ["Let him that would move the world, first move himself.", "Socrates"],
  ["Let me never fall into the vulgar mistake of dreaming that I am persecuted whenever I am contradicted.", "Ralph Waldo Emerson"],
  ["Let me tell you the secret that has led me to my goal. My strength lies solely in my tenacity.", "Louis Pasteur"],
  ["Let no man pull you low enough to hate him.", "Martin Luther King, Jr"],
  ["Let proportion be found not only in numbers and measures, but also in sounds, weights, times, and positions, and what ever force there is.", "Leonardo da Vinci"],
  ["Let us enrich ourselves with our mutual differences.", "Paul Valery"],
  ["Let us face a pluralistic world in which there are no universal churches, no single remedy for all diseases, no one way to teach or write or sing, no magic diet, no world poets, and no chosen races, but only the wretched and wonderfully diversified human race.", "Jacques Barzun"],
  ["Let your capital be simplicity and contentment.", "Henry David Thoreau"],
  ["Letter writing is the only device for combining solitude with good company.", "George Gordon Byron"],
  ["Lexicographer's business is solely to collect, arrange, and define the words that usage presents to his hands. He has no right to proscribe words; he is to present them as they are.", "Noah Webster"],
  ["Liberty is given by nature even to mute animals.", "Publius Cornelius Tacitus"],
  ["Library: A place where the dead lie.", "Elbert Hubbard"],
  ["Life cannot be classified in terms of a simple neurological ladder, with human beings at the top; it is more accurate to talk of different forms of intelligence, each with its strengths and weaknesses. This point was well demonstrated in the minutes before last December's tsunami, when tourists grabbed their digital cameras and ran after the ebbing surf, and all the 'dumb' animals made for the hills.", "B.R. Myers"],
  ["Life consists in what a man is thinking of all day.", "Ralph Waldo Emerson"],
  ["Life consists not in holding good cards but in playing those you hold well.", "Josh Billings"],
  ["Life has no meaning a priori. ... It is up to you to give it a meaning, and value is nothing but the meaning that you choose.", "Jean-Paul Sartre"],
  ["Life is a bridge. Cross over it, but build no house on it.", "Indian proverb"],
  ["Life is a foreign language; all men mispronounce it.", "Christopher Morley"],
  ["Life is a four-letter word.", "Lenny Bruce"],
  ["Life is a jest, and all things show it, / I thought so once, and now I know it.", "John Gay"],
  ["Life is a long lesson in humility.", "James M. Barrie"],
  ["Life is a tragedy for those who feel, and a comedy for those who think.", "Jean de la Bruyere"],
  ["Life is a tragedy when seen in close-up, but a comedy in long-shot.", "Charlie Chaplin"],
  ["Life is an adventure in forgiveness.", "Norman Cousins"],
  ["Life is an exciting business, and most exciting when it is lived for others.", "Helen Keller"],
  ["Life is just a short walk from the cradle to the grave and it sure behooves us to be kind to one another along the way.", "Alice Childress"],
  ["Life is like a landscape. You live in the midst of it, but can describe it only from the vantage point of distance.", "Charles A. Lindbergh"],
  ["Life is like a library owned by an author. In it are a few books which he wrote himself, but most of them were written for him.", "Harry Emerson Fosdick"],
  ["Life is like a ten-speed bike. Most of us have gears we never use.", "Charles Schulz"],
  ["Life is mostly froth and bubble, / Two things stand like stone, / Kindness in another's trouble, / Courage in your own.", "Adam Lindsay Gordon"],
  ["Life is short, short, brother! / Ain't it the truth? / And there is no other / Ain't it the truth? / You gotta rock that rainbow while you still got your youth!", "Yip Harburg"],
  ["Life is short. Be swift to love! Make haste to be kind!", "Henri Frederic Amiel"],
  ["Life is something that happens when you can't get to sleep.", "Fran Lebowitz"],
  ["Life is the childhood of our immortality.", "Johann Wolfgang von Goethe"],
  ["Life isn't about finding yourself. Life is about creating yourself.", "George Bernard Shaw"],
  ["Life shrinks or expands in proportion to one's courage.", "Anais Nin"],
  ["Life without industry is guilt, industry without art is brutality.", "John Ruskin"],
  ["Life's greatest happiness is to be convinced we are loved.", "Victor Hugo"],
  ["Life's most urgent question is: what are you doing for others?", "Martin Luther King, Jr"],
  ["Like a lawyer, the human brain wants victory, not truth; and, like a lawyer, it is sometimes more admirable for skill than virtue.", "Robert Wright"],
  ["Like all weak men he laid an exaggerated stress on not changing one's mind.", "William Somerset Maugham"],
  ["Like cars in amusement parks, our direction is often determined through collisions.", "Yahia Lababidi"],
  ["Literature becomes the living memory of a nation.", "Aleksandr Solzhenitsyn"],
  ["Literature encourages tolerance - bigots and fanatics seldom have any use for the arts, because they're so preoccupied with their beliefs and actions that they can't see them also as possibilities.", "Northrop Frye"],
  ["Literature is my Utopia. Here I am not disenfranchised. No barrier of the sense shuts me out from the sweet, gracious discourse of my book friends. They talk to me without embarrassment or awkwardness.", "Helen Keller"],
  ["Literature is the art of writing something that will be read twice; journalism what will be grasped at once.", "Cyril Connolly"],
  ["Literature is the language of society, as speech is the language of man.", "Louis de Bonald"],
  ["Little girls are cute and small only to adults. To one another they are not cute. They are life-sized.", "Margaret Atwood"],
  ["Little Strokes, Fell great Oaks.", "Benjamin Franklin"],
  ["Live a balanced life. Learn some and think some, and draw and paint and sing and dance and play and work every day some.", "Robert Fulghum"],
  ["Live a good life. If there are gods and they are just, they will not care how devout you have been, but will welcome you based on the virtues you have lived by. If there are gods, but unjust, then you should not want to worship them. If there are no gods, then you will be gone, but will have lived a noble life that will live on in the memories of your loved ones.", "Marcus Aurelius"],
  ["Live and let live, be and let be, / Hear and let hear, see and let see, / Sing and let sing, dance and let dance. ... Live and let live and remember this line: / \"Your bus'ness is your bus'ness and my bus'ness is mine.\"", "Cole Porter"],
  ["Live as if you were living a second time, and as though you had acted wrongly the first time.", "Viktor Frankl"],
  ["Loneliness... is and always has been the central and inevitable experience of every man.", "Thomas Wolfe"],
  ["Look into any man's heart you please, and you will always find, in every one, at least one black spot which he has to keep concealed.", "Henrik Ibsen"],
  ["Losing one glove / is certainly painful, / but nothing / compared to the pain, / of losing one, / throwing away the other, / and finding / the first one again.", "Piet Hein"],
  ["Lots of people think they're charitable if they give away their old clothes and things they don't want. It isn't charity to give away things you want to get rid of and it isn't a sacrifice to do things you don't mind doing.", "Myrtle Reed"],
  ["Lots of people want to ride with you in the limo, but what you want is someone who will take the bus with you when the limo breaks down.", "Oprah Winfrey"],
  ["Lots of times you have to pretend to join a parade in which you're not really interested in order to get where you're going.", "Christopher Morley"],
  ["Love all, trust a few, do wrong to none.", "William Shakespeare"],
  ["Love involves a peculiar, unfathomable combination of understanding and misunderstanding.", "Diane Arbus"],
  ["Love is like quicksilver in the hand. Leave the fingers open and it stays. Clutch it, and it darts away.", "Dorothy Parker"],
  ["Love is like war; easy to begin but very hard to stop.", "H.L. Mencken"],
  ["Love truth, but pardon error.", "Voltaire"],
  ["Love, friendship, respect, do not unite people as much as a common hatred for something.", "Anton Chekhov"],
  ["Love: a temporary insanity, curable by marriage.", "Ambrose Bierce"],
  ["Lovemaking is radical, while marriage is conservative.", "Eric Hoffer"],
  ["Lower your voice and strengthen your argument.", "Lebanese proverb"],
  ["Loyalty to a petrified opinion never yet broke a chain or freed a human soul.", "Mark Twain"],
  ["Lying is done with words and also with silence.", "Adrienne Rich"],
  ["Lying to ourselves is more deeply ingrained than lying to others.", "Fyodor Dostoyevsky"],
  ["Make no judgments where you have no compassion.", "Anne McCaffrey"],
  ["Man can be the most affectionate and altruistic of creatures, yet he's potentially more vicious than any other. He is the only one who can be persuaded to hate millions of his own kind whom he has never seen and to kill as many as he can lay his hands on in the name of his tribe or his God.", "Benjamin Spock"],
  ["Man is condemned to be free; because once thrown into the world, he is responsible for everything he does.", "Jean-Paul Sartre"],
  ["Man is least himself when he talks in his own person. Give him a mask, and he will tell you the truth.", "Oscar Wilde"],
  ["Man is most nearly himself when he achieves the seriousness of a child at play.", "Heraclitus"],
  ["Man is the best computer we can put aboard a spacecraft and the only one that can be mass produced with unskilled labor.", "Wernher von Braun"],
  ["Man is the religious animal. He is the only religious animal that has the true religion - several of them. He is the only animal that loves his neighbor as himself and cuts his throat if his theology isn't straight.", "Mark Twain"],
  ["Man needs to go outside himself in order to find repose and reveal himself.", "José Martí"],
  ["Man's capacity for justice makes democracy possible, but man's inclination to injustice makes democracy necessary.", "Reinhold Niebuhr"],
  ["Man's life does not commence in the womb and never ends in the grave.", "Charan Singh"],
  ["Mankind's true moral test, its fundamental test (which lies deeply buried from view), consists of its attitude towards those who are at its mercy: animals. And in this respect mankind has suffered a fundamental debacle, a debacle so fundamental that all others stem from it.", "Milan Kundera"],
  ["Manners are a sensitive awareness of the feelings of others. If you have that awareness, you have good manners, no matter what fork you use.", "Emily Post"],
  ["Many a long dispute among divines may be thus abridged: It is so. It is not so. It is so. It is not so.", "Benjamin Franklin"],
  ["Many a man thinks he is buying pleasure, when he is really selling himself to it.", "Benjamin Franklin"],
  ["Many a secret that cannot be pried out by curiosity can be drawn out by indifference.", "Sydney J. Harris"],
  ["Many are concerned about the monuments of the West and the East- to know who built them. For my part, I should like to know who in those days did not build them- who were above such trifling.", "Henry David Thoreau"],
  ["Many demolitions are actually renovations.", "Jalaluddin Rumi"],
  ["Many highly intelligent people are poor thinkers. Many people of average intelligence are skilled thinkers. The power of the car is separate from the way the car is driven.", "Edward de Bono"],
  ["Many ideas grow better when transplanted into another mind than in the one where they sprang up.", "Oliver Wendell Holmes, Jr"],
  ["Many individuals have, like uncut diamonds, shining qualities beneath a rough exterior.", "Juvenal"],
  ["Many live in the ivory tower called reality; they never venture on the open sea of thought.", "Francois Gautier"],
  ["Many people consider the things government does for them to be social progress but they regard the things government does for others as socialism.", "Earl Warren"],
  ["Many people hear voices when no-one is there. Some of them are called mad and are shut up in rooms where they stare at the walls all day. Others are called writers and they do pretty much the same thing.", "Margaret Chittenden"],
  ["Many wealthy people are little more than janitors of their possessions.", "Frank Lloyd Wright"],
  ["Many who have spent a lifetime in it can tell us less of love than the child that lost a dog yesterday.", "Thornton Wilder"],
  ["Marriage is the only adventure open to the cowardly.", "Voltaire"],
  ["Martyrdom has always been a proof of the intensity, never of the correctness of a belief.", "Arthur Schnitzler"],
  ["Matters of religion should never be matters of controversy. We neither argue with a lover about his taste, nor condemn him, if we are just, for knowing so human a passion.", "George Santayana"],
  ["Mausoleum, noun. The final and funniest folly of the rich.", "Ambrose Bierce"],
  ["May my silences become more accurate.", "Theodore Roethke"],
  ["May none but honest and wise men ever rule under this roof (White House).", "John Adams"],
  ["May your trails be crooked, winding, lonesome, dangerous, leading to the most amazing view. May your mountains rise into and above the clouds.", "Edward Abbey"],
  ["Maybe every other American movie shouldn't be based on a comic book. Other countries will think Americans live in an infantile fantasy land where reality is whatever we say it is and every problem can be solved with violence.", "Bill Maher"],
  ["Memories are interpreted like dreams.", "Leo Longanesi"],
  ["Men always want to be a woman's first love - women like to be a man's last romance.", "Oscar Wilde"],
  ["Men are idolaters, and want something to look at and kiss and hug, or throw themselves down before; they always did, they always will; and if you don't make it of wood, you must make it of words.", "Oliver Wendell Holmes, Sr"],
  ["Men are men before they are lawyers, or physicians, or merchants, or manufacturers; and if you make them capable and sensible men, they will make themselves capable and sensible lawyers or physicians.", "John Stuart Mill"],
  ["Men are not against you; they are merely for themselves.", "Gene Fowler"],
  ["Men are often capable of greater things than they perform. They are sent into the world with bills of credit, and seldom draw to their full extent.", "Horace Walpole"],
  ["Men are the devils of the earth and the animals are its tormented souls.", "Arthur Schopenhauer"],
  ["Men are wise in proportion, not to their experience, but to their capacity for experience.", "George Bernard Shaw"],
  ["Men cannot see their reflection in running water, but only in still water.", "Chuang Tzu"],
  ["Men ever had, and ever will have leave, / To coin new words well suited to the age, / Words are like leaves, some wither every year, / And every year a younger race succeeds.", "Horace"],
  ["Men hate those to whom they have to lie.", "Victor Hugo"],
  ["Men have become the tools of their tools.", "Henry David Thoreau"],
  ["Men have slow reflexes. In general it takes several generations later for them to understand.", "Stanisław J. Lec"],
  ["Men make counterfeit money; in many more cases, money makes counterfeit men.", "Sydney J. Harris"],
  ["Men never do evil so completely and cheerfully as when they do it from religious conviction.", "Blaise Pascal"],
  ["Men of genius are often dull and inert in society, as a blazing meteor when it descends to earth, is only a stone.", "Henry Wadsworth Longfellow"],
  ["Men of lofty genius when they are doing the least work are most active.", "Leonardo da Vinci"],
  ["Men rarely (if ever) managed to dream up a god superior to themselves. Most gods have the manners and morals of a spoiled child.", "Robert A. Heinlein"],
  ["Men shout to avoid listening to one another.", "Miguel de Unamuno"],
  ["Men, said the Devil, / are good to their brothers: / they don't want to mend / their own ways, but each other's.", "Piet Hein"],
  ["Mere parsimony is not economy. Expense, and great expense, may be an essential part in true economy.", "Edmund Burke"],
  ["Military justice is to justice what military music is to music.", "Georges Clemenceau"],
  ["Millions long for immortality who do not know what to do with themselves on a rainy Sunday afternoon.", "Susan Ertz"],
  ["Minds, like bodies, will often fall into a pimpled, ill-conditioned state from mere excess of comfort.", "Charles Dickens"],
  ["Mirrors, those revealers of the truth, are hated; that does not prevent them from being of use.", "Victor Hugo"],
  ["Mistakes are part of the dues that one pays for a full life.", "Sophia Loren"],
  ["Mistakes live in the neighbourhood of truth and therefore delude us.", "Rabindranath Tagore"],
  ["Misunderstandings and neglect occasion more mischief in the world than even malice and wickedness.", "Johann Wolfgang von Goethe"],
  ["Moderate giftedness has been made worthless by the printing press and radio and television and satellites and all that. A moderately gifted person who would have been a community treasure a thousand years ago has to give up, has to go into some other line of work, since modern communications put him or her into daily competition with nothing but world's champions.", "Kurt Vonnegut, Jr"],
  ["Modern English is the Wal-Mart of languages: convenient, huge, hard to avoid, superficially friendly, and devouring all rivals in its eagerness to expand.", "Mark Abley"],
  ["Modern prose has become, like modern manners and modern dress, a good deal less formal than it was in the nineteenth century.", "James Runcieman Sutherland"],
  ["Money is human happiness in the abstract: he, then, who is no longer capable of enjoying human happiness in the concrete devotes his heart entirely to money.", "Arthur Schopenhauer"],
  ["Money may be the husk of many things but not the kernel. It brings you food, but not appetite; medicine, but not health; acquaintance, but not friends; servants, but not loyalty; days of joy, but not peace or happiness.", "Henrik Ibsen"],
  ["Money often costs too much.", "Ralph Waldo Emerson"],
  ["Money, n. A blessing that is of no advantage to us excepting when we part with it. An evidence of culture and a passport to polite society.", "Ambrose Bierce"],
  ["Money. You don't know where it's been, / but you put it where your mouth is. / And it talks.", "Dana Gioia"],
  ["Monsters remain human beings. In fact, to reduce them to a subhuman level is to exonerate them of their acts of terrorism and mass murder - just as animals are not deemed morally responsible for killing. Insisting on the humanity of terrorists is, in fact, critical to maintaining their profound responsibility for the evil they commit. And, if they are human, then they must necessarily not be treated in an inhuman fashion. You cannot lower the moral baseline of a terrorist to the subhuman without betraying a fundamental value.", "Andrew Sullivan"],
  ["Moral certainty is always a sign of cultural inferiority. The more uncivilized the man, the surer he is that he knows precisely what is right and what is wrong. All human progress, even in morals, has been the work of men who have doubted the current moral values, not of men who have whooped them up and tried to enforce them. The truly civilized man is always skeptical and tolerant, in this field as in all others. His culture is based on \"I am not too sure.\"", "H.L. Mencken"],
  ["Morality is contraband in war.", "Mahatma Gandhi"],
  ["Morality is the custom of one's country and the current feeling of one's peers. Cannibalism is moral in a cannibal country.", "Samuel Butler"],
  ["More die in the United States of too much food than of too little.", "John Kenneth Galbraith"],
  ["More than any other time in history, mankind faces a crossroads. One path leads to despair and utter hopelessness. The other, to total extinction. Let us pray we have the wisdom to choose correctly.", "Woody Allen"],
  ["Most institutions demand unqualified faith; but the institution of science makes skepticism a virtue.", "Robert King Merton"],
  ["Most men pursue pleasure with such breathless haste they hurry past it.", "Søren Kierkegaard"],
  ["Most people are mirrors, reflecting the moods and emotions of the times; few are windows, bringing light to bear on the dark corners where troubles fester. The whole purpose of education is to turn mirrors into windows.", "Sydney J. Harris"],
  ["Most people think that shadows follow, precede, or surround beings or objects. The truth is that they also surround words, ideas, desires, deeds, impulses and memories.", "Elie Wiesel"],
  ["Much of writing might be described as mental pregnancy with successive difficult deliveries.", "J.B. Priestley"],
  ["Music hath charms to soothe a savage breast, To soften rocks, or bend a knotted oak.", "William Congreve"],
  ["Music was invented to confirm human loneliness.", "Lawrence Durrell"],
  ["Music was my refuge. I could crawl into the spaces between the notes and curl my back to loneliness.", "Maya Angelou"],
  ["My aim is to agitate and disturb people. I'm not selling bread, I'm selling yeast.", "Miguel de Unamuno"],
  ["My country is the world, and my religion is to do good.", "Thomas Paine"],
  ["My country, right or wrong; if right, to be kept right; and if wrong, to be set right.", "Carl Schurz"],
  ["My faith is that the only soul a man must save is his own.", "William Orville Douglas"],
  ["My feeling is that there is nothing in life but refraining from hurting others, and comforting those who are sad.", "Olive Schreiner"],
  ["My friends are my estate.", "Emily Dickinson"],
  ["My greatest skill has been to want but little.", "Henry David Thoreau"],
  ["My kind of loyalty was loyalty to one's country, not to its institutions or its officeholders.", "Mark Twain"],
  ["My library / Was dukedom large enough.", "William Shakespeare"],
  ["My life has been full of terrible misfortunes most of which never happened.", "Michel de Montaigne"],
  ["My own experience and development deepen every day my conviction that our moral progress may be measured by the degree in which we sympathize with individual suffering and individual joy.", "George Eliot (Mary Ann Evans)"],
  ["My play was a complete success. The audience was a failure.", "Oscar Wilde"],
  ["My poverty is not complete: it lacks me.", "Antonio Porchia"],
  ["My sole inspiration is a telephone call from a director.", "Cole Porter"],
  ["My soul is a broken field, plowed by pain.", "Sara Teasdale"],
  ["My stories run up and bite me in the leg - I respond by writing them down - everything that goes on during the bite. When I finish, the idea lets go and runs off.", "Ray Bradbury"],
  ["My theology, briefly, is that the universe was dictated but not signed.", "Christopher Morley"],
  ["My two favorite things in life are libraries and bicycles. They both move people forward without wasting anything. The perfect day: riding a bike to the library.", "Peter Golkin"],
  ["My wife and I were happy for 20 years. Then we met.", "Rodney Dangerfield"],
  ["My words fly up, my thoughts remain below: Words without thoughts never to heaven go.", "William Shakespeare"],
  ["Naive you are / if you believe / life favours those / who aren't naive.", "Piet Hein"],
  ["Nationalist pride, like other variants of pride, can be a substitute for self-respect.", "Eric Hoffer"],
  ["Nations have recently been led to borrow billions for war; no nation has ever borrowed largely for education. Probably, no nation is rich enough to pay for both war and civilization. We must make our choice; we cannot have both.", "Abraham Flexner"],
  ["Natural ability without education has more often attained to glory and virtue than education without natural ability.", "Marcus Tullius Cicero"],
  ["Nature is ever at work building and pulling down, creating and destroying, keeping everything whirling and flowing, allowing no rest but in rhythmical motion, chasing everything in endless song out of one beautiful form into another.", "John Muir"],
  ["Nature is slow, but sure; she works no faster than need be; she is the tortoise that wins the race by her perseverance.", "Henry David Thoreau"],
  ["Nature is trying very hard to make us succeed,  but nature does not depend on us. We are not the only experiment.", "R. Buckminster Fuller"],
  ["Nature magically suits a man to his fortunes, by making them the fruit of his character.", "Ralph Waldo Emerson"],
  ["Nature never did betray the heart that loved her.", "William Wordsworth"],
  ["Nature never said to me: Do not be poor. Still less did she say: Be rich. Her cry to me was always: Be independent.", "Sebastien-Roch Nicolas de Chamfort"],
  ["Nature teaches more than she preaches. There are no sermons in stones. It is easier to get a spark out of a stone than a moral.", "John Burroughs"],
  ["Nature uses as little as possible of anything.", "Johannes Kepler"],
  ["Nature will bear the closest inspection. She invites us to lay our eye level with her smallest leaf and take an insect view of its plain.", "Henry David Thoreau"],
  ["Nature's laws affirm instead of prohibit. If you violate her laws, you are your own prosecuting attorney, judge, jury, and hangman.", "Luther Burbank"],
  ["Nearly all men can stand adversity, but if you want to test a man's character, give him power.", "Abraham Lincoln"],
  ["Necessity is the plea for every infringement of human freedom. It is the argument of tyrants; it is the creed of slaves.", "William Pitt"],
  ["Neither a man nor a crowd nor a nation can be trusted to act humanely or to think sanely under the influence of a great fear.", "Bertrand Russell"],
  ["Neither genius, fame, nor love show the greatness of the soul. Only kindness can do that.", "Jean Baptiste Henri Lacordaire"],
  ["Neither great poverty nor great riches will hear reason.", "Henry Fielding"],
  ["Neutrality helps the oppressor, never the victim. Silence encourages the tormentor, never the tormented.", "Elie Wiesel"],
  ["Never be haughty to the humble; never be humble to the haughty.", "Jefferson Davis"],
  ["Never bear more than one trouble at a time. Some people bear three kinds - all they have had, all they have now, and all they expect to have.", "Edward Everett Hale"],
  ["Never confuse motion with action.", "Benjamin Franklin"],
  ["Never cut what you can untie.", "Joseph Joubert"],
  ["Never doubt that a small group of thoughtful citizens can change the world. Indeed, it is the only thing that ever has.", "Margaret Mead"],
  ["Never idealize others. They will never live up to your expectations.", "Leo Buscaglia"],
  ["Never lend books - nobody ever returns them; the only books I have in my library are those which people have lent me.", "Anatole France"],
  ["Never let your sense of morals get in the way of doing what's right.", "Isaac Asimov"],
  ["Never let your zeal outrun your charity. The former is but human, the latter is divine.", "Hosea Ballou"],
  ["Never rise to speak till you have something to say; and when you have said it, cease.", "John Witherspoon"],
  ["Never spend your money before you have it.", "Thomas Jefferson"],
  ["Never think that war, no matter how necessary, nor how justified, is not a crime. Ask the infantry and ask the dead.", "Ernest Hemingway"],
  ["Never underestimate the determination of a kid who is time rich and cash poor.", "Cory Doctorow"],
  ["Never, never be afraid to do what's right, especially if the well-being of a person or animal is at stake. Society's punishments are small compared to the wounds we inflict on our soul when we look the other way.", "Martin Luther King, Jr"],
  ["New opinions are always suspected, and usually opposed, without any other reason but because they are not already common.", "John Locke"],
  ["News is what people want to keep hidden; everything else is publicity.", "Bill Moyers"],
  ["No amount of belief makes something a fact.", "James Randi"],
  ["No battle is ever won, he said. They are not even fought. The field only reveals to man his own folly and despair, and victory is an illusion of philosophers and fools.", "William Faulkner"],
  ["No bird soars too high if he soars with his own wings.", "William Blake"],
  ["No cow's like a horse, and no horse like a cow. That's one similarity, anyhow.", "Piet Hein"],
  ["No drug, not even alcohol, causes the fundamental ills of society. If we're looking for the source of our troubles, we shouldn't test people for drugs, we should test them for stupidity, ignorance, greed, and love of power.", "P.J. O'Rourke"],
  ["No fathers or mothers think their own children ugly; and this self-deceit is yet stronger with respect to the offspring of the mind.", "Miguel de Cervantes"],
  ["No greater mistake can be made than to think that our institutions are fixed or may not be changed for the worse. ... Increasing prosperity tends to breed indifference and to corrupt moral soundness. Glaring inequalities in condition create discontent and strain the democratic relation. The vicious are the willing, and the ignorant are unconscious instruments of political artifice. Selfishness and demagoguery take advantage of liberty. The selfish hand constantly seeks to control government, and every increase of governmental power, even to meet just needs, furnishes opportunity for abuse and stimulates the effort to bend it to improper uses. ... The peril of this nation is not in any foreign foe! We, the people, are its power, its peril, and its hope!", "Charles Evans Hughes"],
  ["No human being is illegal.", "Elie Wiesel"],
  ["No humorist laughs at his own wheeze: A snuff-box has no right to sneeze.", "Keith Preston"],
  ["No iron spike can pierce a human heart as icily as a period in the right place.", "Isaac Babel"],
  ["No man but feels more of a man in the world if he have a bit of ground that he can call his own. However small it is on the surface, it is four thousand miles deep; and that is a very handsome property.", "Charles Dudley Warner"],
  ["No man can be called friendless when he has God and the companionship of good books.", "Elizabeth Barrett Browning"],
  ["No man ever steps in the same river twice, for it's not the same river and he's not the same man.", "Heraclitus"],
  ["No man has a prosperity so high or firm, but that two or three words can dishearten it; and there is no calamity which right words will not begin to redress.", "Ralph Waldo Emerson"],
  ["No man is a hero to his valet.", "Anne-Marie Bigot de Cornuel"],
  ["No man is an Island, entire of itself; every man is a piece of the Continent, a part of the main; if a clod be washed away by the sea, Europe is the less, as well as if a promontory were, as well as if a manor of thy friends or of thine own were; any man's death diminishes me, because I am involved in Mankind; And therefore never send to know for whom the bell tolls; It tolls for thee.", "John Donne"],
  ["No man is justified in doing evil on the ground of expediency.", "Theodore Roosevelt"],
  ["No man is useless who has a friend, and if we are loved we are indispensable.", "Robert Louis Stevenson"],
  ["No man should judge unless he asks himself in absolute honesty whether in a similar situation he might not have done the same.", "Viktor Frankl"],
  ["No man was ever more than about nine meals away from crime or suicide.", "Eric Sevareid"],
  ["No man's credit is as good as his money.", "E.W. Howe"],
  ["No man, for any considerable period, can wear one face to himself, and another to the multitude, without finally getting bewildered as to which may be true.", "Nathaniel Hawthorne"],
  ["No man, or body of men, can dam the stream of language.", "James Russell Lowell"],
  ["No matter that patriotism is too often the refuge of scoundrels. Dissent, rebellion, and all-around hell-raising remain the true duty of patriots.", "Barbara Ehrenreich"],
  ["No matter that we may mount on stilts, we still must walk on our own legs. And on the highest throne in the world, we still sit only on our own bottom.", "Michel de Montaigne"],
  ["No matter what side of the argument you are on, you always find people on your side that you wish were on the other.", "Jascha Heifetz"],
  ["No metaphysician ever felt the deficiency of language so much as the grateful.", "Charles Caleb Colton"],
  ["No one can make you feel inferior without your consent.", "Eleanor Roosevelt"],
  ["No one can terrorize a whole nation, unless we are all his accomplices.", "Edward R. Murrow"],
  ["No one else sees the world the way you do, so no one else can tell the stories that you have to tell.", "Charles de Lint"],
  ["No one ever ever won a chess game by betting on each move. Sometimes you have to move backward to get a step forward.", "Amar Bose"],
  ["No one has ever become poor by giving.", "Anne Frank"],
  ["No one imagines that symphony is supposed to improve as it goes along, or that the whole object of playing is to reach the finale. The point of music is discovered in every moment of playing and listening to it. It is the same, I feel, with the greater part of our lives, and if we are unduly absorbed in improving them we may forget altogether to live them.", "Alan Watts"],
  ["No one is useless in this world who lightens the burden of it for anyone else.", "Charles Dickens"],
  ["No one means all he says, and yet very few say all they mean, for words are slippery and thought is viscous.", "Henry Brooks Adams"],
  ["No one should drive a hard bargain with an artist.", "Ludwig van Beethoven"],
  ["No one worth possessing / Can be quite possessed.", "Sara Teasdale"],
  ["No poet has ever let go of his homeland.", "Joseph Freiherr von Eichendorff"],
  ["No protracted war can fail to endanger the freedom of a democratic country.", "Alexis de Tocqueville"],
  ["No sensible decision can be made any longer without taking into account not only the world as it is, but the world as it will be.", "Isaac Asimov"],
  ["No society that feeds its children on tales of successful violence can expect them not to believe that violence in the end is rewarded.", "Margaret Mead"],
  ["No sooner is the rage of hunger appeased than it becomes difficult to comprehend the meaning of starvation. It is only when you suffer that you really understand.", "Jules Verne"],
  ["No two persons ever read the same book.", "Edmund Wilson"],
  ["No, no, you're not thinking, you're just being logical.", "Niels Bohr"],
  ["Nobody can be exactly like me. Sometimes even I have trouble doing it.", "Tallulah Bankhead"],
  ["Nobody can be lucky all the time; / so when your luck deserts you in some fashion / don't think you've been abandoned in your prime, / but rather that you're saving up your ration.", "Piet Hein"],
  ["Nobody has ever measured, not even poets, how much the human heart can hold.", "Zelda Fitzgerald"],
  ["Nobody made a greater mistake than he who did nothing because he could do only a little.", "Edmund Burke"],
  ["Noise proves nothing--often a hen who has merely laid an egg cackles as if she had laid an asteroid.", "Mark Twain"],
  ["Non-violence leads to the highest ethics, which is the goal of all evolution. Until we stop harming all other living beings, we are still savages.", "Thomas Edison"],
  ["None are more hopelessly enslaved than those who falsely believe they are free.", "Johann Wolfgang von Goethe"],
  ["Nonviolence means avoiding not only external physical violence but also internal violence of spirit. You not only refuse to shoot a man, but you refuse to hate him.", "Martin Luther King, Jr"],
  ["Not being able to govern events, I govern myself.", "Michel de Montaigne"],
  ["Not far from the invention of fire must rank the invention of doubt.", "Thomas Henry Huxley"],
  ["Not ignorance, but ignorance of ignorance, is the death of knowledge.", "Alfred North Whitehead"],
  ["Not that I want to be a god or a hero. Just to change into a tree, grow for ages, not hurt anyone.", "Czeslaw Milosz"],
  ["Not thinking critically, I assumed that the \"successful\" prayers were proof that God answers prayer while the failures were proof that there was something wrong with me.", "Dan Barker"],
  ["Not what I have, but what I do, is my kingdom.", "Thomas Carlyle"],
  ["Nothing contributes more to peace of soul than having no opinion at all.", "Georg Christoph Lichtenberg"],
  ["Nothing contributes so much to tranquilizing the mind as a steady purpose - a point on which the soul may fix its intellectual eye.", "Mary Wollstonecraft Shelley"],
  ["Nothing fixes a thing so intensely in the memory as the wish to forget it.", "Michel de Montaigne"],
  ["Nothing great in the world has been accomplished without passion.", "Georg Wilhelm Friedrich Hegel"],
  ["Nothing is at last sacred but the integrity of your own mind. Absolve you to yourself, and you shall have the suffrage of the world.", "Ralph Waldo Emerson"],
  ["Nothing is illegal if one hundred businessmen decide to do it.", "Andrew Young"],
  ["Nothing is more dangerous than an idea when it's the only one you have.", "Emile Chartier"],
  ["Nothing is more humbling than to look with a strong magnifying glass at an insect so tiny that the naked eye sees only the barest speck and to discover that nevertheless it is sculpted and articulated and striped with the same care and imagination as a zebra. Apparently it does not occur to nature whether or not a creature is within our range of vision, and the suspicion arises that even the zebra was not designed for our benefit.", "Rudolf Arnheim"],
  ["Nothing is so firmly believed as what is least known.", "Michel de Montaigne"],
  ["Nothing is so impenetrable as laughter in a language you don't understand.", "William Golding"],
  ["Nothing produces such odd results as trying to get even.", "Franklin P. Jones"],
  ["Nothing so completely baffles one who is full of trick and duplicity himself, than straightforward and simple integrity in another.", "Charles Caleb Colton"],
  ["Nothing so soon the drooping spirits can raise / As praises from the men, whom all men praise.", "Abraham Cowley"],
  ["Nothing so soothes our vanity as a display of greater vanity in others; it makes us vain, in fact, of our modesty.", "Louis Kronenberger"],
  ["Nothing that grieves us can be called little: by the eternal laws of proportion a child's loss of a doll and a king's loss of a crown are events of the same size.", "Mark Twain"],
  ["Nothing which does not transport is poetry. The lyre is a winged instrument.", "Joseph Joubert"],
  ["Nothing, to my way of thinking, is a better proof of a well-ordered mind than a man's ability to stop just where he is and pass some time in his own company.", "Lucius Annaeus Seneca"],
  ["Now I can look at you in peace; I don't eat you any more.", "Franz Kafka"],
  ["Now I see the secret of the making of the best persons. It is to grow in the open air and to eat and sleep with the earth.", "Walt Whitman"],
  ["Now is the time to understand / That all your ideas of right and wrong / Were just a child's training wheels / To be laid aside / When you finally live / With veracity / And love.", "Hafez"],
  ["Now, here, you see, it takes all the running you can do, to keep in the same place. If you want to get somewhere else, you must run at least twice as fast as that!", "Lewis Carroll"],
  ["Nowadays, people can be divided into three classes - the Haves, the Have-Nots, and the Have-Not-Paid-for-What-They-Haves.", "Earl Wilson"],
  ["O innocent victims of Cupid, / Remember this terse little verse: / To let a fool kiss you is stupid, / To let a kiss fool you is worse.", "Yip Harburg"],
  ["O Liberty! how many crimes are committed in thy name!", "Jeanne-Marie Roland"],
  ["O wad some Power the giftie gie us / To see oursels as ithers see us! (Oh would some power the gift give us, to see ourselves as others see us.)", "Robert Burns"],
  ["O, what a world of vile ill-favoured faults, / Looks handsome in three hundred pounds a year!", "William Shakespeare"],
  ["Obesity is a mental state, a disease brought on by boredom and disappointment.", "Cyril Connolly"],
  ["Obstinacy is the result of the will forcing itself into the place of the intellect.", "Arthur Schopenhauer"],
  ["Of all nature's gifts to the human race, what is sweeter to a man than his children?", "Marcus Tullius Cicero"],
  ["Of all plagues with which mankind is cursed, ecclesiastic tyranny's the worst.", "Daniel Defoe"],
  ["Of all the preposterous assumptions of humanity over humanity, nothing exceeds most of the criticisms made on the habits of the poor by the well-housed, well-warmed, and well-fed.", "Herman Melville"],
  ["Of course you will insist on modesty in the children, and respect to their teachers, but if the boy stops you in your speech, cries out that you are wrong and sets you right, hug him!", "Ralph Waldo Emerson"],
  ["Of course, it's possible to love a human being - if you don't know them too well.", "Charles Bukowski"],
  ["Of life's two chief prizes, beauty and truth, I found the first in a loving heart and the second in a laborer's hand.", "Khalil Gibran"],
  ["Often the accurate answer to a usage question begins, \"It depends.\" And what it depends on most often is where you are, who you are, who your listeners or readers are, and what your purpose in speaking or writing is.", "Kenneth G. Wilson"],
  ["Often war is waged only in order to show valor; thus an inner dignity is ascribed to war itself, and even some philosophers have praised it as an ennoblement of humanity, forgetting the pronouncement of the Greek who said, \"War is an evil in as much as it produces more wicked men than it takes away.\"", "Immanuel Kant"],
  ["Oftentimes excusing of a fault / Doth make the fault the worse by th' excuse.", "William Shakespeare"],
  ["Oh to have a lodge in some vast wilderness. Where rumors of oppression and deceit, of unsuccessful and successful wars may never reach me anymore.", "William Cowper"],
  ["Oh, how small a portion of earth will hold us when we are dead, who ambitiously seek after the whole world while we are living!", "Philip of Macedon"],
  ["Oh, it is excellent to have a giant's strength; but it is tyrannous to use it like a giant.", "William Shakespeare"],
  ["Oh, the comfort - the inexpressible comfort of feeling safe with a person - having neither to weigh thoughts nor measure words, but pouring them all right out, just as they are, chaff and grain together, certain that a faithful hand will take and sift them, keep what is worth keeping, and with the breath of kindness blow the rest away.", "Dinah Maria Mulock Craik"],
  ["Oh, threats of hell and hopes of paradise! / One thing at least is certain - this life flies; / One thing is certain, and the rest is lies; / The flower that once has blown forever dies.", "Omar Khayyam"],
  ["Oh, would that my mind could let fall its dead ideas, as the tree does its withered leaves!", "Andre Gide"],
  ["Old age deprives the intelligent man only of qualities useless to wisdom.", "Joseph Joubert"],
  ["Older men declare war. But it is youth that must fight and die.", "Herbert Hoover"],
  ["On stage, I make love to 25,000 different people, then I go home alone.", "Janis Joplin"],
  ["On the mountains of truth you can never climb in vain: either you will reach a point higher up today, or you will be training your powers so that you will be able to climb higher tomorrow.", "Friedrich Nietzsche"],
  ["On two occasions I have been asked, \"Pray, Mr. Babbage, if you put into the machine wrong figures, will the right answers come out?\" I am not able rightly to apprehend the kind of confusion of ideas that could provoke such a question.", "Charles Babbage"],
  ["Once or twice in every generation a line is crossed so egregiously that where you stood on the issue will forever define you.", "Kara Vallow"],
  ["Once plants and animals were raised together on the same farm - which therefore neither produced unmanageable surpluses of manure, to be wasted and to pollute the water supply, nor depended on such quantities of commercial fertilizer. The genius of American farm experts is very well demonstrated here: they can take a solution and divide it neatly into two problems.", "Wendell Berry"],
  ["Once upon a time a man whose ax was missing suspected his neighbor's son. The boy walked like a thief, looked like a thief, and spoke like a thief. But the man found his ax while digging in the valley, and the next time he saw his neighbor's son, the boy walked, looked and spoke like any other child.", "Lao Tzu"],
  ["Once you have decided to keep a certain pile, it is no longer yours; for you can't spend it.", "Michel de Montaigne"],
  ["Once you hear the details of victory, it is hard to distinguish it from a defeat.", "Jean-Paul Sartre"],
  ["Once you label me you negate me.", "Søren Kierkegaard"],
  ["One can be instructed in society, one is inspired only in solitude.", "Johann Wolfgang von Goethe"],
  ["One can never pay in gratitude; one can only pay \"in kind\" somewhere else in life.", "Anne Morrow Lindbergh"],
  ["One can promise actions, but not feelings, for the latter are involuntary. He who promises to love forever or hate forever or be forever faithful to someone is promising something that is not in his power.", "Friedrich Nietzsche"],
  ["One cannot be deeply responsive to the world without being saddened very often.", "Erich Fromm"],
  ["One cannot hire a hand; the whole man always comes with it.", "Peter Drucker"],
  ["One day I was speeding along at the typewriter, and my daughter - who was a child at the time - asked me, \"Daddy, why are you writing so fast?\" And I replied, \"Because I want to see how the story turns out!\"", "Louis L'Amour"],
  ["One day work is hard, and another day it is easy; but if I had waited for inspiration I am afraid I should have done nothing. The miner does not sit at the top of the shaft waiting for the coal to come bubbling up to the surface. One must go deep down, and work out every vein carefully.", "Arthur Sullivan"],
  ["One day's exposure to mountains is better than cartloads of books. See how willingly Nature poses herself upon photographers' plates. No earthly chemicals are so sensitive as those of the human soul.", "John Muir"],
  ["One does not advance the swimming abilities of ducks by throwing the eggs in the water.", "Multatuli (pen name of Eduard Douwes Dekker)"],
  ["One does not love a place the less for having suffered in it, unless it has been all suffering, nothing but suffering.", "Jane Austen"],
  ["One forges one's style on the terrible anvil of daily deadlines.", "Emile Zola"],
  ["One glance at a book and you hear the voice of another person, perhaps someone dead for 1,000 years. To read is to voyage through time.", "Carl Sagan"],
  ["One half of the world cannot understand the pleasures of the other.", "Jane Austen"],
  ["One has to be a lowbrow, a bit of a murderer, to be a politician, ready and willing to see people sacrificed, slaughtered, for the sake of an idea, whether a good one or a bad one.", "Henry Miller"],
  ["One is happy as a result of one's own efforts once one knows the necessary ingredients of happiness: simple tastes, a certain degree of courage, self denial to a point, love of work, and above all, a clear conscience.", "George Sand [pen name of Amantine-Aurore-Lucile Dupin]"],
  ["One law for the lion and ox is oppression.", "William Blake"],
  ["One machine can do the work of fifty ordinary men. No machine can do the work of one extraordinary man.", "Elbert Hubbard"],
  ["One man meets an infamous punishment for that crime which confers a diadem upon another.", "Juvenal"],
  ["One may have a blazing hearth in one's soul, and yet no one ever comes to sit by it.", "Vincent van Gogh"],
  ["One must be drenched in words, literally soaked in them, to have the right ones form themselves into the proper patterns at the right moment.", "Hart Crane"],
  ["One must pay dearly for immortality; one has to die several times while still alive.", "Friedrich Nietzsche"],
  ["One must think like a hero to behave like a merely decent human being.", "May Sarton"],
  ["One of my greatest pleasures in writing has come from the thought that perhaps my work might annoy someone of comfortably pretentious position. Then comes the saddening realization that such people rarely read.", "John Kenneth Galbraith"],
  ["One of the best ways to get yourself a reputation as a dangerous citizen these days is to go about repeating the very phrases which our founding fathers used in the great struggle for independence.", "Charles A. Beard"],
  ["One of the funny things about the stock market is that every time one person buys, another sells, and both think they are astute.", "William Feather"],
  ["One of the great mistakes is to judge policies and programs by their intentions rather than their results.", "Milton Friedman"],
  ["One of the great tragedies of mankind is that morality has been hijacked by religion.", "Arthur C. Clarke"],
  ["One of the hardest things in life is having words in your heart that you can't utter.", "James Earl Jones"],
  ["One of the indictments of civilizations is that happiness and intelligence are so rarely found in the same person.", "William Feather"],
  ["One of the most time-consuming things is to have an enemy.", "E.B. White"],
  ["One of the oldest human needs is having someone to wonder where you are when you don't come home at night.", "Margaret Mead"],
  ["One of the primary tests of the mood of a society at any given time is whether its comfortable people tend to identify, psychologically, with the power and achievements of the very successful or with the needs and sufferings of the underprivileged.", "Richard Hofstadter"],
  ["One of the saddest lessons of history is this: If we've been bamboozled long enough, we tend to reject any evidence of the bamboozle. We're no longer interested in finding out the truth. The bamboozle has captured us. It's simply too painful to acknowledge, even to ourselves, that we've been taken. Once you give a charlatan power over you, you almost never get it back.", "Carl Sagan"],
  ["One of the strongest characteristics of genius is the power of lighting its own fire.", "John W. Foster"],
  ["One of the symptoms of an approaching nervous breakdown is the belief that one's work is terribly important.", "Bertrand Russell"],
  ["One of the truest tests of integrity is its blunt refusal to be compromised.", "Chinua Achebe"],
  ["One ought, every day at least, to hear a little song, read a good poem, see a fine picture and, if it were possible, speak a few reasonable words.", "Johann Wolfgang von Goethe"],
  ["One owes respect to the living. To the dead, one owes only the truth.", "Voltaire"],
  ["One should count each day a separate life.", "Lucius Annaeus Seneca"],
  ["One unerring mark of the love of truth is not entertaining any proposition with greater assurance than the proofs it is built upon will warrant.", "John Locke"],
  ["One who condones evils is just as guilty as the one who perpetrates it.", "Martin Luther King, Jr"],
  ["One will rarely err if extreme actions be ascribed to vanity, ordinary actions to habit, and mean actions to fear.", "Friedrich Nietzsche"],
  ["Only a mediocre writer is always at his best.", "William Somerset Maugham"],
  ["Only by going alone in silence, without baggage, can one truly get into the heart of the wilderness. All other travel is mere dust and hotels and baggage and chatter.", "John Muir"],
  ["Only enemies speak the truth; friends and lovers lie endlessly, caught in the web of duty.", "Stephen King"],
  ["Only mediocrity can be trusted to be always at its best. Genius must always have lapses proportionate to its triumphs.", "Max Beerbohm"],
  ["Only the educated are free.", "Epictetus"],
  ["Only the good doubt their own goodness, which is what makes them good in the first place. The bad know they are good, but the good know nothing. They spend their lives forgiving others, but they can't forgive themselves.", "Paul Auster"],
  ["Only the hand that erases can write the true thing.", "Meister Eckhart"],
  ["Only the madman is absolutely sure.", "Robert Anton Wilson"],
  ["Only those things are beautiful which are inspired by madness and written by reason.", "Andre Gide"],
  ["Ordering a man to write a poem is like commanding a pregnant woman to give birth to a red-headed child.", "Carl Sandburg"],
  ["Other men are lenses through which we read our own minds.", "Ralph Waldo Emerson"],
  ["Our admiration of fine writing will always be in proportion to its real difficulty and its apparent ease.", "Charles Caleb Colton"],
  ["Our cemeteries are full of people who prayed to live.", "Annie Laurie Gaylor"],
  ["Our chief want in life is somebody who shall make us do what we can.", "Ralph Waldo Emerson"],
  ["Our choicest plans / have fallen through, / our airiest castles / tumbled over, / because of lines / we neatly drew / and later neatly / stumbled over.", "Piet Hein"],
  ["Our conscience is not the vessel of eternal verities. It grows with our social life, and a new social condition means a radical change in conscience.", "Walter Lippmann"],
  ["Our country, right or wrong. When right, to be kept right; when wrong, to be put right.", "Carl Schurz"],
  ["Our deeds determine us, as much as we determine our deeds.", "George Eliot (Mary Ann Evans)"],
  ["Our elections are free, it's in the results where eventually we pay.", "Bill Stern"],
  ["Our expression and our words never coincide, which is why the animals don't understand us.", "Malcolm De Chazal"],
  ["Our greatest pretenses are built up not to hide the evil and the ugly in us, but our emptiness. The hardest thing to hide is something that is not there.", "Eric Hoffer"],
  ["Our heads are round so that thoughts can change direction.", "Francis Picabia"],
  ["Our homeland is the whole world. Our law is liberty. We have but one thought, revolution in our hearts.", "Dario Fo"],
  ["Our houses are such unwieldy property that we are often imprisoned rather than housed in them.", "Henry David Thoreau"],
  ["Our incomes are like our shoes; if too small, they gall and pinch us; but if too large, they cause us to stumble and to trip.", "John Locke"],
  ["Our lives are like islands in the sea, or like trees in the forest. The maple and the pine may whisper to each other with their leaves ... But the trees also commingle their roots in the darkness underground, and the islands also hang together through the ocean's bottom.", "William James"],
  ["Our memories are card indexes consulted and then returned in disorder by authorities whom we do not control.", "Cyril Connolly"],
  ["Our perception that we have \"no time\" is one of the distinctive marks of modern Western culture.", "Margaret Visser"],
  ["Our shouting is louder than our actions, / Our swords are taller than us, / This is our tragedy. / In short / We wear the cape of civilization / But our souls live in the stone age.", "Nizar Qabbani"],
  ["Our sincerest laughter With some pain is fraught; Our sweetest songs are those that tell of saddest thought.", "Percy Bysshe Shelley"],
  ["Our society must make it right and possible for old people not to fear the young or be deserted by them, for the test of a civilization is the way that it cares for its helpless members.", "Pearl S. Buck"],
  ["Out of the quarrel with others we make rhetoric; out of the quarrel with ourselves we make poetry.", "William Butler Yeats"],
  ["Pain and suffering are always inevitable for a large intelligence and a deep heart. The really great men must, I think, have great sadness on earth.", "Fyodor Dostoyevsky"],
  ["Patience is also a form of action.", "Auguste Rodin"],
  ["Patriotism is a kind of religion; it is the egg from which wars are hatched.", "Guy de Maupassant"],
  ["Patriotism is often an arbitrary veneration of real estate above principles.", "George Jean Nathan"],
  ["Patriotism is proud of a country's virtues and eager to correct its deficiencies; it also acknowledges the legitimate patriotism of other countries, with their own specific virtues. The pride of nationalism, however, trumpets its country's virtues and denies its deficiencies, while it is contemptuous toward the virtues of other countries. It wants to be, and proclaims itself to be, \"the greatest\", but greatness is not required of a country; only goodness is.", "Sydney J. Harris"],
  ["Patriotism is supporting your country all the time and the government when it deserves it.", "Mark Twain"],
  ["Patriotism is your conviction that this country is superior to all others because you were born in it.", "George Bernard Shaw"],
  ["Patriotism means to stand by the country. It does not mean to stand by the President or any other public official save exactly to the degree in which he himself stands by the country. It is patriotic to support him insofar as he efficiently serves the country. It is unpatriotic not to oppose him to the exact extent that by inefficiency or otherwise he fails in his duty to stand by the country. In either event it is unpatriotic not to tell the truth - whether about the President or about anyone else.", "Theodore Roosevelt"],
  ["Patriotism, n. Combustible rubbish ready to the torch of any one ambitious to illuminate his name. In Dr. Johnson's famous dictionary patriotism is defined as the last resort of a scoundrel. With all due respect to an enlightened but inferior lexicographer I beg to submit it is the first.", "Ambrose Bierce"],
  ["Pedantry and mastery are opposite attitudes toward rules. To apply a rule to the letter, rigidly, unquestioningly, in cases where it fits and in cases where it does not fit, is pedantry. ... To apply a rule with natural ease, with judgment, noticing the cases where it fits, and without ever letting the words of the rule obscure the purpose of the action or the opportunities of the situation, is mastery.", "George Polya"],
  ["People are like stained glass windows: they sparkle and shine when the sun is out, but when the darkness sets in their true beauty is revealed only if there is a light within.", "Elisabeth Kübler-Ross"],
  ["People change and forget to tell each other.", "Lillian Hellman"],
  ["People do not wish to appear foolish; to avoid the appearance of foolishness, they are willing to remain actually fools.", "Alice Walker"],
  ["People forget years and remember moments.", "Ann Beattie"],
  ["People hate as they love, unreasonably.", "William Makepeace Thackeray"],
  ["People like to imagine that because all our mechanical equipment moves so much faster, that we are thinking faster, too.", "Christopher Morley"],
  ["People never lie so much as before an election, during a war, or after a hunt.", "Otto von Bismarck"],
  ["People of small caliber like to sit on high horses.", "Magdalena Samozwaniec"],
  ["People rarely win wars; governments rarely lose them.", "Arundhati Roy"],
  ["People share a common nature but are trained in gender roles.", "Lillie Devereux Blake"],
  ["People who demand neutrality in any situation are usually not neutral but in favor of the status quo.", "Max Eastman"],
  ["People who lean on logic and philosophy and rational exposition end by starving the best part of the mind.", "William Butler Yeats"],
  ["People who want to share their religious views with you almost never want you to share yours with them.", "Dave Barry"],
  ["People's memories are maybe the fuel they burn to stay alive.", "Haruki Murakami"],
  ["Perfect love is rare indeed - for to be a lover will require that you continually have the subtlety of the very wise, the flexibility of the child, the sensitivity of the artist, the understanding of the philosopher, the acceptance of the saint, the tolerance of the scholar and the fortitude of the certain.", "Leo Buscaglia"],
  ["Perfect order is the forerunner of perfect horror.", "Carlos Fuentes"],
  ["Perfect valor is to behave, without witnesses, as one would act were all the world watching.", "François de La Rochefoucauld"],
  ["Perhaps the best cure for the fear of death is to reflect that life has a beginning as well as an end. There was a time when you were not: that gives us no concern. Why then should it trouble us that a time will come when we shall cease to be? To die is only to be as we were before we were born.", "William Hazlitt"],
  ["Perhaps the greatest social service that can be rendered by anybody to the country and to mankind is to bring up a family.", "George Bernard Shaw"],
  ["Perhaps the most valuable result of all education is the ability to make yourself do the thing you have to do, when it ought to be done, whether you like it or not.", "Thomas Henry Huxley"],
  ["Perhaps travel cannot prevent bigotry, but by demonstrating that all peoples cry, laugh, eat, worry, and die, it can introduce the idea that if we try and understand each other, we may even become friends.", "Maya Angelou"],
  ["Permanent good can never be the outcome of untruth and violence.", "Mahatma Gandhi"],
  ["Permit me to issue and control the money of a nation, and I care not who makes its laws.", "Amschel Mayer Rothschild"],
  ["Persons appear to us according to the light we throw upon them from our own minds.", "Laura Ingalls Wilder"],
  ["Peter Principle: In a hierarchy, every employee tends to rise to his level of incompetence.", "Laurence J Peter"],
  ["Philanthropy is commendable, but it must not cause the philanthropist to overlook the circumstances of economic injustice which make philanthropy necessary.", "Martin Luther King, Jr"],
  ["Philosophers / must ultimately find / their true perfection / in knowing all / the follies of mankind / - by introspection.", "Piet Hein"],
  ["Pick a flower on earth and you move the farthest star.", "Paul Dirac"],
  ["Please subdue the anguish of your soul. Nobody is destined only to happiness or to pain. The wheel of life takes one up and down by turn.", "Kalidasa"],
  ["Pleasure is very seldom found where it is sought; our brightest blazes of gladness are commonly kindled by unexpected sparks.", "Samuel Johnson"],
  ["Pleasure may come from illusion, but happiness can come only of reality.", "Sebastien-Roch Nicolas de Chamfort"],
  ["Plenty of kind, decent, caring people have no religious beliefs, and they act out of the goodness of their hearts. Conversely, plenty of people who profess to be religious, even those who worship regularly, show no particular interest in the world beyond themselves.", "John Danforth"],
  ["Poetry is a deal of joy and pain and wonder, with a dash of the dictionary.", "Khalil Gibran"],
  ["Poetry is a sort of homecoming.", "Paul Celan"],
  ["Poetry is a subject as precise as geometry.", "Gustave Flaubert"],
  ["Poetry is the art of creating imaginary gardens with real toads.", "Marianne Moore"],
  ["Poetry is the art of saying what you mean but disguising it.", "Diane Wakoski"],
  ["Poetry is the art of uniting pleasure with truth.", "Samuel Johnson"],
  ["Poetry is the clear expression of mixed feelings.", "W.H. Auden"],
  ["Poetry is the overflowing of the Soul.", "Henry Theodore Tuckerman"],
  ["Poetry is the shadow cast by our streetlight imaginations.", "Lawrence Ferlinghetti"],
  ["Poetry is to prose as dancing is to walking.", "John Barrington Wain"],
  ["Poetry is truth in its Sunday clothes.", "Joseph Roux"],
  ["Poetry is what gets lost in translation.", "Robert Frost"],
  ["Poetry is when an emotion has found its thought and the thought has found words.", "Robert Frost"],
  ["Poetry should please by a fine excess and not by singularity. It should strike the reader as a wording of his own highest thoughts, and appear almost as a remembrance.", "John Keats"],
  ["Poetry, indeed, cannot be translated; and, therefore, it is the poets that preserve the languages; for we would not be at the trouble to learn a language if we could have all that is written in it just as well in a translation. But as the beauties of poetry cannot be preserved in any language except that in which it was originally written, we learn the language.", "Samuel Johnson"],
  ["Poets are soldiers that liberate words from the steadfast possession of definition.", "Eli Khamarov"],
  ["Poets are the unacknowledged legislators of the world.", "Percy Bysshe Shelley"],
  ["Poisons and medicine are oftentimes the same substance given with different intents.", "Peter Mere Latham"],
  ["Politeness is the art of choosing among your thoughts.", "Madame de Stael"],
  ["Politeness is to human nature what warmth is to wax.", "Arthur Schopenhauer"],
  ["Political history is largely an account of mass violence and of the expenditure of vast resources to cope with mythical fears and hopes.", "Murray Edelman"],
  ["Politics, as a practice, whatever its professions, has always been the systematic organization of hatreds.", "Henry Adams"],
  ["Politics, n. Strife of interests masquerading as a contest of principles.", "Ambrose Bierce"],
  ["Poor is the power of the lead that becomes bullets compared to the power of the hot metal that becomes types.", "Georg Brandes"],
  ["Poverty is the worst form of violence.", "Mahatma Gandhi"],
  ["Power always has to be kept in check; power exercised in secret, especially under the cloak of national security, is doubly dangerous.", "William Proxmire"],
  ["Power always thinks it has a great soul and vast views beyond the comprehension of the weak; and that it is doing God's service when it is violating all his laws.", "John Adams"],
  ["Power does not corrupt. Fear corrupts... perhaps the fear of a loss of power.", "John Steinbeck"],
  ["Power is not revealed by striking hard or often, but by striking true.", "Honore de Balzac"],
  ["Power is of two kinds. One is obtained by the fear of punishment and the other by acts of love. Power based on love is a thousand times more effective and permanent than the one derived from fear of punishment.", "Mahatma Gandhi"],
  ["Power is only important as an instrument for service to the powerless.", "Lech Walesa"],
  ["Power tends to corrupt, and absolute power corrupts absolutely.", "Lord Acton (John Emerich Edward Dalberg-Acton)"],
  ["Prayers are to men as dolls are to children. They are not without use and comfort, but it is not easy to take them very seriously.", "Samuel Butler"],
  ["Presents, I often say, endear absents.", "Charles Lamb"],
  ["President, n. The leading figure in a small group of men of whom - and of whom only - it is positively known that immense numbers of their countrymen did not want any of them for president.", "Ambrose Bierce"],
  ["Pride, like laudanum and other poisonous medicines, is beneficial in small, though injurious in large, quantities. No man who is not pleased with himself, even in a personal sense, can please others.", "Frederick Saunders"],
  ["Prison: Young Crime's finishing school.", "Clara Lucas Balfour"],
  ["Problems worthy of attack prove their worth by hitting back.", "Piet Hein"],
  ["Profits, like sausages and laws, are esteemed most by those who know least about what goes into them.", "Gary Hector"],
  ["Progressive societies outgrow institutions as children outgrow clothes.", "Henry George"],
  ["Prolonged study of the English language leaves me with a conviction that nearly all the linguistic tendencies of the present day have been displayed in earlier centuries, and it is self-evident that the language has not bled to death through change. Vulgarity finds its antidote; old crudities become softened with time. Distinctions, both those that are useful and those that are burdensome, flourish and die, reflourish and die again.", "Robert W. Burchfield"],
  ["Propaganda is a soft weapon; hold it in your hands too long, and it will move about like a snake, and strike the other way.", "Jean Anouilh"],
  ["Prosperity is not without many fears and distastes; and adversity is not without comforts and hopes.", "Francis Bacon"],
  ["Punishment is the last and least effective instrument in the hands of the legislator for the prevention of crime.", "John Ruskin"],
  ["Puritanism: The haunting fear that someone, somewhere may be happy.", "H.L. Mencken"],
  ["Question with boldness even the existence of a God; because, if there be one, he must more approve of the homage of reason, than that of blindfolded fear.", "Thomas Jefferson"],
  ["Questions show the mind's range, and answers its subtlety.", "Joseph Joubert"],
  ["Quite frankly I talk about the fact that I'm a feminist as often as I can, and every time I do it gets a huge reaction and the media reacts and the Twitterverse explodes and things like that, because here I am saying I'm a feminist. I will keep saying that until there is no more reaction to that when I say it, because that's where we want to get to.", "Justin Trudeau"],
  ["Rain! whose soft architectural hands have power to cut stones, and chisel to shapes of grandeur the very mountains.", "Henry Ward Beecher"],
  ["Rather than love, than money, than fame, give me truth.", "Henry David Thoreau"],
  ["Read, every day, something no one else is reading. Think, every day, something no one else is thinking. Do, every day, something no one else would be silly enough to do. It is bad for the mind to continually be part of unanimity.", "Christopher Morley"],
  ["Readers may be divided into four classes: 1. Sponges, who absorb all that they read and return it in nearly the same state, only a little dirtied. 2. Sand-glasses, who retain nothing and are content to get through a book for the sake of getting through the time. 3. Strain-bags, who retain merely the dregs of what they read. 4. Mogul diamonds, equally rare and valuable, who profit by what they read, and enable others to profit by it also.", "Samuel Taylor Coleridge"],
  ["Reading a book is like rewriting it for yourself. You bring to a novel, anything you read, all your experience of the world. You bring your history and you read it in your own terms.", "Angela Carter"],
  ["Reading is not just an escape. It is access to a better way of life.", "Karin Slaughter"],
  ["Reading is sometimes an ingenious device for avoiding thought.", "Arthur Helps"],
  ["Reading is to the mind what exercise is to the body.", "Joseph Addison"],
  ["Ready money is Aladdin's lamp.", "Lord Byron"],
  ["Real knowledge is to know the extent of one's ignorance.", "Confucius"],
  ["Real misanthropes are not found in solitude, but in the world; since it is experience of life, and not philosophy, which produces real hatred of mankind.", "Giacomo Leopardi"],
  ["Reason often makes mistakes, but conscience never does.", "Josh Billings"],
  ["Recently, I was asked if I was going to fire an employee who made a mistake that cost the company $600,000. No, I replied, I just spent $600,000 training him. Why would I want somebody to hire his experience?", "Thomas J. Watson"],
  ["Red roses for young lovers. French beans for longstanding relationships.", "Ruskin Bond"],
  ["Religion is something left over from the infancy of our intelligence, it will fade away as we adopt reason and science as our guidelines.", "Bertrand Russell"],
  ["Religion--freedom--vengeance--what you will, A word's enough to raise mankind to kill.", "Lord Byron"],
  ["Religions are not revealed: they are evolved. If a religion were revealed by God, that religion would be perfect in whole and in part, and would be as perfect at the first moment of its revelation as after ten thousand years of practice. There has never been a religion that fulfills those conditions.", "Robert Blatchford"],
  ["Religious freedom should work two ways: we should be free to practice the religion of our choice, but we must also be free from having someone else's religion practiced on us.", "John Irving"],
  ["Remarriage: A triumph of hope over experience.", "Samuel Johnson"],
  ["Remember that there is nothing stable in human affairs; therefore avoid undue elation in prosperity, or undue depression in adversity.", "Socrates"],
  ["Remember when life's path is steep to keep your mind even.", "Horace"],
  ["Remember, tomorrow we are not crowning a king, or bowing down to a dictator. Tomorrow, our new employee starts his temp job. We're the boss.", "Audra McDonald", "on Jan 19, 2017"],
  ["Remember, we all stumble, every one of us. That's why it's a comfort to go hand in hand.", "Emily Kimbrough"],
  ["Remorse is a violent dyspepsia of the mind.", "Ogden Nash"],
  ["Rewards and punishments are the lowest form of education.", "Chuang Tzu"],
  ["Right now I'm having amnesia and deja vu at the same time. I think I've forgotten this before.", "Steven Wright"],
  ["Rightful liberty is unobstructed action according to our will within limits drawn around us by the equal rights of others. I do not add 'within the limits of the law' because law is often but the tyrant's will, and always so when it violates the rights of the individual.", "Thomas Jefferson"],
  ["Roads endure longer than pyramids.", "Karol Bunsch"],
  ["Rudeness is the weak man's imitation of strength.", "Eric Hoffer"],
  ["Sad is his lot, who, once at least in his life, has not been a poet.", "Alphonse Marie Louis de Prat de Lamartine"],
  ["Said a hunted fox followed by twenty horsemen and a pack of twenty hounds, \"Of course they will kill me. But how poor and how stupid they must be. Surely it would not be worth while for twenty foxes riding on twenty asses and accompanied by twenty wolves to chase and kill one man.\"", "Khalil Gibran"],
  ["Satire is a sort of glass, wherein beholders do generally discover everybody's face but their own, which is the chief reason for that kind of reception it meets in the world, and that so very few are offended with it.", "Jonathan Swift"],
  ["Say not, 'I have found the truth,' but rather, 'I have found a truth.'", "Khalil Gibran"],
  ["Say oh wise man how you have come to such knowledge? Because I was never ashamed to confess my ignorance and ask others.", "Johann Gottfried Von Herder"],
  ["Science is built with facts as a house is with stones, but a collection of facts is no more a science than a heap of stones is a house.", "Jules Henri Poincaré"],
  ["Science is more than a body of knowledge; it is a way of thinking. I have a foreboding of an America in my children's or grandchildren's time - when the United States is a service and information economy; when nearly all the key manufacturing industries have slipped away to other countries; when awesome technological powers are in the hands of a very few, and no one representing the public interest can even grasp the issues; when the people have lost the ability to set their own agendas or knowledgeably question those in authority; when, clutching our crystals and nervously consulting our horoscopes, our critical faculties in decline, unable to distinguish between what feels good and what's true, we slide, almost without noticing, back into superstition and darkness.", "Carl Sagan"],
  ["Science is organized knowledge. Wisdom is organized life.", "Immanuel Kant"],
  ["Scratch a pessimist and you find often a defender of privilege.", "William Beveridge"],
  ["Scriptures, n. The sacred books of our holy religion, as distinguished from the false and profane writings on which all other faiths are based.", "Ambrose Bierce"],
  ["Secrecy, being an instrument of conspiracy, ought never to be the system of a regular government.", "Jeremy Bentham"],
  ["Seek not to follow in the footsteps of men of old; seek what they sought.", "Matsuo Basho"],
  ["Self-esteem is the reputation we acquire with ourselves.", "Nathaniel Branden"],
  ["Serious sport has nothing to do with fair play. It is bound up with hatred, jealousy, boastfulness, disregard of all rules and sadistic pleasure in witnessing violence. In other words, it is war minus the shooting.", "George Orwell"],
  ["Several excuses are always less convincing than one.", "Aldous Huxley"],
  ["Shadow owes its birth to light.", "John Gay"],
  ["Shake off all the fears of servile prejudices, under which weak minds are servilely crouched. Fix reason firmly in her seat, and call on her tribunal for every fact, every opinion. Question with boldness even the existence of a God; because, if there be one, he must more approve of the homage of reason than that of blindfolded fear.", "Thomas Jefferson"],
  ["Shall I tell you the secret of the true scholar? It is this: every man I meet is my master in some point, and in that I learn of him.", "Ralph Waldo Emerson"],
  ["Shelved rows of books warm and brighten the starkest room, and scattered single volumes reveal mental processes in progress - books in the act of consumption, abandoned but readily resumable, tomorrow or next year.", "John Updike"],
  ["Ships that pass in the night and speak each other in passing; / Only a signal shown and a distant voice in the darkness; / So on the ocean of life we pass and speak one another, / Only a look and a voice; then darkness again and a silence.", "Henry Wadsworth Longfellow"],
  ["Should you shield the canyons from the windstorms you would never see the true beauty of their carvings.", "Elisabeth Kübler-Ross"],
  ["Show me a hero and I will write you a tragedy.", "F. Scott Fitzgerald"],
  ["Show me a sane man and I will cure him for you.", "Carl Jung"],
  ["Silence is the severest criticism.", "Charles Buxton"],
  ["Silence will save me from being wrong (and foolish), but it will also deprive me of the possibility of being right.", "Igor Stravinsky"],
  ["Silent gratitude isn't much use to anyone.", "Gladys Bronwyn Stern"],
  ["Simplicity doesn't mean to live in misery and poverty. You have what you need, and you don't want to have what you don't need.", "Charan Singh"],
  ["Simplicity is making the journey of this life with just baggage enough.", "Charles Dudley Warner"],
  ["Simplicity is the ultimate sophistication.", "Leonardo da Vinci"],
  ["Sin is geographical.", "Bertrand Russell"],
  ["Sin lies only in hurting others unnecessarily. All other \"sins\" are invented nonsense.", "Robert A. Heinlein"],
  ["Since Auschwitz we know what man is capable of. And since Hiroshima we know what is at stake.", "Viktor Frankl"],
  ["Since my house burned down / I now own a better view / of the rising moon.", "Mizuta Masahide"],
  ["Since we are destined to live out our lives in the prison of our minds, our one duty is to furnish it well.", "Peter Ustinov"],
  ["Since when do we have to agree with people to defend them from injustice?", "Lillian Hellman"],
  ["Sit down and put down everything that comes into your head and then you're a writer. But an author is one who can judge his own stuff's worth, without pity, and destroy most of it.", "Colette"],
  ["Slang is a language that rolls up its sleeves, spits on its hands, and goes to work.", "Carl Sandburg"],
  ["Sleep after toil, port after stormy seas, ease after war, death after life does greatly please.", "Edmund Spenser"],
  ["Smaller than a breadbox, bigger than a TV remote, the average book fits into the human hand with a seductive nestling, a kiss of texture, whether of cover cloth, glazed jacket, or flexible paperback.", "John Updike"],
  ["Smoking cures weight problems... eventually.", "Steven Wright"],
  ["Snakes and ladders: the game of organized religions.", "Yahia Lababidi"],
  ["So act that your principle of action might safely be made a law for the whole world.", "Immanuel Kant"],
  ["So difficult it is to show the various meanings and imperfections of words when we have nothing else but words to do it with.", "John Locke"],
  ["So long as men worship the Caesars and Napoleons, Caesars and Napoleons will duly rise and make them miserable.", "Aldous Huxley"],
  ["So long as we have enough people in this country willing to fight for their rights, we'll be called a democracy.", "Roger Baldwin"],
  ["So long as you have food in your mouth, you have solved all questions for the time being.", "Franz Kafka"],
  ["So many gods, so many creeds, So many paths that wind and wind, While just the art of being kind is all the sad world needs.", "Ella Wheeler Wilcox"],
  ["Society cannot exist without inequality of fortunes and the inequality of fortunes could not subsist without religion. Whenever a half-starved person is near another who is glutted, it is impossible to reconcile the difference if there is not an authority who tells him to.", "Napoleon Bonaparte"],
  ["Society is composed of two great classes: those who have more dinners than appetite, and those who have more appetite than dinners.", "Sebastien-Roch Nicolas de Chamfort"],
  ["Society is like a lawn, where every roughness is smoothed, every bramble eradicated, and where the eye is delighted by the smiling verdure of a velvet surface; he, however, who would study nature in its wildness and variety, must plunge into the forest, must explore the glen, must stem the torrent, and dare the precipice.", "Washington Irving"],
  ["Society is like a stew. If you don't keep it stirred up you get a lot of scum on the top.", "Edward Abbey"],
  ["Society prepares the crime; the criminal commits it.", "Henry Thomas Buckle"],
  ["Solitude has but one disadvantage; it is apt to give one too high an opinion of one's self. In the world we are sure to be often reminded of every known or supposed defect we may have.", "Lord Byron"],
  ["Solitude, though it may be silent as light, is like light, the mightiest of agencies; for solitude is essential to man. All men come into this world alone; all leave it alone.", "Thomas De Quincey"],
  ["Some books are to be tasted, others to be swallowed, and some few to be chewed and digested.", "Francis Bacon"],
  ["Some fellows pay a compliment like they expected a receipt.", "Kin Hubbard"],
  ["Some humans would do anything to see if it was possible to do it. If you put a large switch in some cave somewhere, with a sign on it saying 'End-of-the-World Switch. PLEASE DO NOT TOUCH', the paint wouldn't even have time to dry.", "Terry Pratchett"],
  ["Some men look at constitutions with sanctimonious reverence, and deem them like the ark of the covenant, too sacred to be touched. They ascribe to the men of the preceding age a wisdom more than human, and suppose what they did to be beyond amendment. I knew that age well; I belonged to it, and labored with it. It deserved well of its country. It was very like the present, but without the experience of the present; and forty years of experience in government is worth a century of book-reading; and this they would say themselves, were they to rise from the dead. I am certainly not an advocate for frequent and untried changes in laws and constitutions. I think moderate imperfections had better be borne with; because, when once known, we accommodate ourselves to them, and find practical means of correcting their ill effects. But I know also, that laws and institutions must go hand in hand with the progress of the human mind. As that becomes more developed, more enlightened, as new discoveries are made, new truths disclosed, and manners and opinions change with the change of circumstances, institutions must advance also, and keep pace with the times. We might as well require a man to wear still the coat which fitted him when a boy, as civilized society to remain ever under the regimen of their barbarous ancestors.", "Thomas Jefferson"],
  ["Some men of a secluded and studious life have sent forth from their closet or their cloister, rays of intellectual light that have agitated courts and revolutionized kingdoms; like the moon which, though far removed from the ocean, and shining upon it with a serene and sober light, is the chief cause of all those ebbings and flowings which incessantly disturb that restless world of waters.", "Charles Caleb Colton"],
  ["Some painters transform the sun into a yellow spot; others transform a yellow spot into the sun.", "Pablo Picasso"],
  ["Some people approach every problem with an open mouth.", "Adlai Stevenson"],
  ["Some people become so expert at reading between the lines they don't read the lines.", "Margaret Millar"],
  ["Some people think they are worth a lot of money just because they have it.", "Fannie Hurst"],
  ["Some people walk in the rain, others just get wet.", "Roger Miller"],
  ["Some people with great virtues are disagreeable, while others with great vices are delightful.", "François de La Rochefoucauld"],
  ["Some problems in the world are not bullet-izable.", "H.R. McMaster", "talking about PowerPoint"],
  ["Some stories are true that never happened.", "Elie Wiesel"],
  ["Some tortures are physical / And some are mental, / But the one that is both / Is dental.", "Ogden Nash"],
  ["Sometime they'll give a war and nobody will come.", "Carl Sandburg"],
  ["Sometimes a man wants to be stupid if it lets him do a thing his cleverness forbids.", "John Steinbeck"],
  ["Sometimes even to live is an act of courage.", "Lucius Annaeus Seneca"],
  ["Sometimes I think we're alone in the universe, and sometimes I think we're not. In either case, the idea is quite staggering.", "Arthur C. Clarke"],
  ["Sometimes I wonder whether the world is being run by smart people who are putting us on or by imbeciles who really mean it.", "Mark Twain"],
  ["Sometimes laughter hurts, but humor and mockery are our only weapons.", "Cabu (pen name of Jean Cabut)"],
  ["Sometimes you can't see yourself clearly until you see yourself through the eyes of others.", "Ellen DeGeneres"],
  ["Sometimes, exhausted / with toil and endeavour, / I wish I could sleep / for ever and ever; / but then this reflection / my longing allays: / I shall be doing it / one of these days.", "Piet Hein"],
  ["Soon silence will have passed into legend. Man has turned his back on silence. Day after day he invents machines and devices that increase noise and distract humanity from the essence of life, contemplation, meditation. Tooting, howling, screeching, booming, crashing, whistling, grinding, and trilling bolster his ego.", "Jean Arp"],
  ["Speculation is perfectly all right, but if you stay there you've only founded a superstition. If you test it, you've started a science.", "Hal Clement"],
  ["Speech is for the convenience of those who are hard of hearing.", "Henry David Thoreau"],
  ["Spend the afternoon. You can't take it with you.", "Annie Dillard"],
  ["Spring is a natural resurrection, an experience in immortality.", "Henry David Thoreau"],
  ["Stability in language is synonymous with rigor mortis.", "Ernest Weekley"],
  ["Standard English is a convenient abstraction, like the average man.", "George Leslie Brook"],
  ["Story, finally, is humanity's autobiography.", "Lloyd Alexander"],
  ["Strike an average between what a woman thinks of her husband a month before she marries him and what she thinks of him a year afterward, and you will have the truth about him.", "H.L. Mencken"],
  ["Students who acquire large debts putting themselves through school are unlikely to think about changing society. When you trap people in a system of debt, they can't afford the time to think.", "Noam Chomsky"],
  ["Style is time's fool. Form is time's student.", "Stewart Brand"],
  ["Substitute damn every time you're inclined to write very; your editor will delete it and the writing will be just as it should be.", "Mark Twain"],
  ["Success is not the key to happiness. Happiness is the key to success.", "Albert Schweitzer"],
  ["Success usually comes to those who are too busy to be looking for it.", "Henry David Thoreau"],
  ["Sunshine is delicious, rain is refreshing, wind braces us up, snow is exhilarating; there is really no such thing as bad weather, only different kinds of good weather.", "John Ruskin"],
  ["Superfluous wealth can buy superfluities only.", "Henry David Thoreau"],
  ["Surplus wealth is a sacred trust which its possessor is bound to administer in his lifetime for the good of the community.", "Andrew Carnegie"],
  ["Syllables govern the world.", "John Selden"],
  ["Tact is the ability to describe others as they see themselves.", "Abraham Lincoln"],
  ["Take a commonplace, clean it and polish it, light it so that it produces the same effect of youth and freshness and originality and spontaneity as it did originally, and you have done a poet's job. The rest is literature.", "Jean Cocteau"],
  ["Take a music bath once or twice a week for a few seasons, and you will find that it is to the soul what the water bath is to the body.", "Oliver Wendell Holmes, Jr"],
  ["Take long walks in stormy weather or through deep snows in the fields and woods, if you would keep your spirits up. Deal with brute nature. Be cold and hungry and weary.", "Henry David Thoreau"],
  ["Talent develops in tranquillity, character in the full current of human life.", "Johann Wolfgang von Goethe"],
  ["Talk doesn't cook rice.", "Chinese proverb"],
  ["Talking of successful rackets / modesty deserves a mention. / Exclamation marks in brackets / never fail to draw attention.", "Piet Hein"],
  ["Taught from infancy that beauty is woman's sceptre, the mind shapes itself to the body, and roaming round its gilt cage, only seeks to adorn its prison.", "Mary Wollstonecraft"],
  ["Taught from their infancy that beauty is woman's sceptre, the mind shapes itself to the body, and roaming round its gilt cage, only seeks to adorn its prison.", "Mary Wollstonecraft"],
  ["Taxes are the price we pay for a civilized society.", "Oliver Wendell Holmes, Jr"],
  ["Teaching a child not to step on a caterpillar is as valuable to the child as it is to the caterpillar.", "Bradley Miller"],
  ["Tear man out of his outward circumstances; and what he then is; that only is he.", "Johann Gottfried Seume"],
  ["Tears are not arguments.", "Machado de Assis"],
  ["Tears are the safety valve of the heart when too much pressure is laid on it.", "Albert Richard Smith"],
  ["Television knows no night. It is perpetual day. TV embodies our fear of the dark, of night, of the other side of things.", "Jean Baudrillard"],
  ["Television's perfect. You turn a few knobs, a few of those mechanical adjustments at which the higher apes are so proficient, and lean back and drain your mind of all thought. And there you are watching the bubbles in the primeval ooze. You don't have to concentrate. You don't have to react. You don't have to remember. You don't miss your brain because you don't need it. Your heart and liver and lungs continue to function normally. Apart from that, all is peace and quiet. You are in the man's nirvana. And if some poor nasty minded person comes along and says you look like a fly on a can of garbage, pay him no mind. He probably hasn't got the price of a television set.", "Raymond Thornton Chandler"],
  ["Tell all the Truth but tell it slant-- / The Truth must dazzle gradually / Or every man be blind.", "Emily Dickinson"],
  ["Testing can show the presence of errors, but not their absence.", "Edsger Dijkstra"],
  ["Thank everyone who calls out your faults, your anger, your impatience, your egotism; do this consciously, voluntarily.", "Jean Toomer"],
  ["That action is best which accomplishes the greatest happiness for the greatest numbers.", "Francis Hutcheson"],
  ["That is a good book which is opened with expectation, and closed with delight and profit.", "Amos Bronson Alcott"],
  ["That some good can be derived from every event is a better proposition than that everything happens for the best, which it assuredly does not.", "James Kern Feibleman"],
  ["That sorrow which is the harbinger of joy is preferable to the joy which is followed by sorrow.", "Saadi"],
  ["That which can be destroyed by the truth, should be.", "P.C. Hodgell"],
  ["That wretched alchemist called money can turn a man's heart into a stone!", "Mehmet Murat Ildan"],
  ["The [Nobel] prize is such an extraordinary honor. It might seem unfair, however, to reward a person for having so much pleasure over the years, asking the maize plant to solve specific problems and then watching its responses.", "Barbara McClintock"],
  ["The ability to simplify means to eliminate the unnecessary so that the necessary may speak.", "Hans Hofmann"],
  ["The absence of flaw in beauty is itself a flaw.", "Havelock Ellis"],
  ["The actions of men are the best interpreters of their thoughts.", "John Locke"],
  ["The aim of an argument or discussion should not be victory, but progress.", "Joseph Joubert"],
  ["The American who first discovered Columbus made a bad discovery.", "Georg Christoph Lichtenberg"],
  ["The animal shall not be measured by man. In a world older and more complete than ours, they move finished and complete, gifted with extension of the senses we have lost or never attained, living by voices we shall never hear. They are not brethren; they are not underlings; they are other nations, caught with ourselves in the net of life and time, fellow prisoners of the splendor and travail of the earth.", "Henry Beston"],
  ["The animals of the world exist for their own reasons. They were not made for humans any more than black people were made for whites or women for men.", "Alice Walker"],
  ["The appropriately beautiful or ugly sound of any word is an illusion wrought on us by what the word connotes.", "Max Beerbohm"],
  ["The art of being wise is the art of knowing what to overlook.", "William James"],
  ["The art of life is to know how to enjoy a little and to endure much.", "William Hazlitt"],
  ["The art of medicine consists in amusing the patient while nature cures the disease.", "Voltaire"],
  ["The art of progress is to preserve order amid change, and to preserve change amid order.", "Alfred North Whitehead"],
  ["The artist brings something into the world that didn't exist before, and he does it without destroying something else.", "John Updike"],
  ["The automobile has not merely taken over the street, it has dissolved the living tissue of the city. Its appetite for space is absolutely insatiable; moving and parked, it devours urban land, leaving the buildings as mere islands of habitable space in a sea of dangerous and ugly traffic.", "James Marston Fitch"],
  ["The average man, who does not know what to do with his life, wants another one which will last forever.", "Anatole France"],
  ["The barriers are not erected which can say to aspiring talents and industry, \"Thus far and no farther.\"", "Ludwig van Beethoven"],
  ["The basis of all animal rights should be the Golden Rule: we should treat them as we would wish them to treat us, were any other species in our dominant position.", "Christine Stevens"],
  ["The beginnings and endings of all human undertakings are untidy.", "John Galsworthy"],
  ["The being cannot be termed rational or virtuous, who obeys any authority, but that of reason.", "Mary Wollstonecraft"],
  ["The belief in the possibility of a short decisive war appears to be one of the most ancient and dangerous of human illusions.", "Robert Lynd"],
  ["The beloved of the Almighty are the rich who have the humility of the poor, and the poor who have the magnanimity of the rich.", "Saadi"],
  ["The best armor is to keep out of gunshot.", "Francis Bacon"],
  ["The best cure for worry, depression, melancholy, brooding, is to go deliberately forth and try to lift with one's sympathy the gloom of somebody else.", "Arnold Bennett"],
  ["The best educated human being is the one who understands most about the life in which he is placed.", "Helen Keller"],
  ["The best effect of fine persons is felt after we have left their presence.", "Ralph Waldo Emerson"],
  ["The best lack all conviction, while the worst / Are full of passionate intensity.", "William Butler Yeats"],
  ["The best music is essentially there to provide you something to face the world with.", "Bruce Springsteen"],
  ["The best people possess a feeling for beauty, the courage to take risks, the discipline to tell the truth, the capacity for sacrifice. Ironically, their virtues make them vulnerable; they are often wounded, sometimes destroyed.", "Ernest Hemingway"],
  ["The best portion of a good man's life is his little, nameless, unremembered acts of kindness and of love.", "William Wordsworth"],
  ["The best theology is probably no theology; just love one another.", "Charles Schulz"],
  ["The best things in life are nearest: Breath in your nostrils, light in your eyes, flowers at your feet, duties at your hand, the path of right just before you.", "Robert Louis Stevenson"],
  ["The best way to be more free is to grant more freedom to others.", "Carlo Dossi"],
  ["The best way to have a good idea is to have lots of ideas.", "Linus Pauling"],
  ["The best way to predict the future is to invent it.", "Alan Kay"],
  ["The best work is done with the heart breaking, or overflowing.", "Mignon McLaughlin"],
  ["The best writing is rewriting.", "E.B. White"],
  ["The bicycle is the most civilized conveyance known to man. Other forms of transport grow daily more nightmarish. Only the bicycle remains pure in heart.", "Iris Murdoch"],
  ["The bitterest tears shed over graves are for words left unsaid and deeds left undone.", "Harriet Beecher Stowe"],
  ["The bluebird carries the sky on his back.", "Henry David Thoreau"],
  ["The body is a house of many windows: there we all sit, showing ourselves and crying on the passers-by to come and love us.", "Robert Louis Stevenson"],
  ["The books that the world calls immoral are books that show the world its own shame.", "Oscar Wilde"],
  ["The buck stops here.", "Harry Truman"],
  ["The business of the poet and the novelist is to show the sorriness underlying the grandest things and the grandeur underlying the sorriest things.", "Thomas Hardy"],
  ["The butterfly counts not years but moments and has time enough.", "Rabindranath Tagore"],
  ["The butterfly flitting from flower to flower ever remains mine, I lose the one that is netted by me.", "Rabindranath Tagore"],
  ["The capacity to produce social chaos is the last resort of desperate people.", "Cornel West"],
  ["The cardinal doctrine of a fanatic's creed is that his enemies are the enemies of God.", "Andrew Dickson White"],
  ["The central function of imaginative literature is to make you realize that other people act on moral convictions different from your own.", "William Empson"],
  ["The characteristic of a well-bred man is, to converse with his inferiors without insolence, and with his superiors with respect and with ease.", "Lord Chesterfield"],
  ["The charm, one might say the genius of memory, is that it is choosy, chancy and temperamental; it rejects the edifying cathedral and indelibly photographs the small boy outside, chewing a hunk of melon in the dust.", "Elizabeth Bowen"],
  ["The church says: The body is a sin. Science says: The body is a machine. Advertising says: The body is a business. The body says: I am a fiesta.", "Eduardo Galeano"],
  ["The closing years of life are like the end of a masquerade party, when the masks are dropped.", "Arthur Schopenhauer"],
  ["The color of truth is grey.", "Andre Gide"],
  ["The conscience of the world is so guilty that it always assumes that people who investigate heresies must be heretics; just as if a doctor who studies leprosy must be a leper. Indeed, it is only recently that science has been allowed to study anything without reproach.", "Aleister Crowley"],
  ["The corporate grip on opinion in the United States is one of the wonders of the Western world. No First World country has ever managed to eliminate so entirely from its media all objectivity - much less dissent.", "Gore Vidal"],
  ["The cost of a thing is the amount of what I call life which is required to be exchanged for it, immediately or in the long run.", "Henry David Thoreau"],
  ["The courage of the poet is to keep ajar the door that leads into madness.", "Christopher Morley"],
  ["The court is like a palace built of marble; I mean that it is made up of very hard and very polished people.", "Jean de la Fontaine"],
  ["The creation of a thousand forests is in one acorn.", "Ralph Waldo Emerson"],
  ["The crucial disadvantage of aggression, competitiveness, and skepticism as national characteristics is that these qualities cannot be turned off at five o'clock.", "Margaret Halsey"],
  ["The cruelest lies are often told in silence.", "Robert Louis Stevenson"],
  ["The cure for anything is salt water - sweat, tears, or the sea.", "Isak Dinesen (pen name of Karen Blixen)"],
  ["The day the child realizes that all adults are imperfect, he becomes an adolescent; the day he forgives them, he becomes an adult; the day he forgives himself, he becomes wise.", "Alden Nowlan"],
  ["The days come and go like muffled and veiled figures sent from a distant friendly party, but they say nothing, and if we do not use the gifts they bring, they carry them as silently away.", "Ralph Waldo Emerson"],
  ["The death of democracy is not likely to be an assassination from ambush. It will be a slow extinction from apathy, indifference, and undernourishment.", "Robert Maynard Hutchins"],
  ["The death of dogma is the birth of morality.", "Immanuel Kant"],
  ["The decent moderation of today will be the least of human things tomorrow. At the time of the Spanish Inquisition, the opinion of good sense and of the good medium was certainly that people ought not to burn too large a number of heretics; extreme and unreasonable opinion obviously demanded that they should burn none at all.", "Maurice Maeterlinck"],
  ["The degree of civilization in a society can be judged by entering its prisons.", "Fyodor Dostoyevsky"],
  ["The desire of knowledge, like the thirst of riches, increases ever with the acquisition of it.", "Laurence Sterne"],
  ["The desire of the man is for the woman, but the desire of the woman is for the desire of the man.", "Madame de Stael"],
  ["The devil can cite Scripture for his purpose.", "William Shakespeare"],
  ["The dew of compassion is a tear.", "Lord Byron"],
  ["The difference between false memories and true ones is the same as for jewels: it is always the false ones that look the most real, the most brilliant.", "Salvador Dali"],
  ["The difference in mind between man and the higher animals, great as it is, certainly is one of degree and not of kind.", "Charles Darwin"],
  ["The dissenter is every human being at those moments of his life when he resigns momentarily from the herd and thinks for himself.", "Archibald MacLeish"],
  ["The doctrine of the material efficacy of prayer reduces the Creator to a cosmic bellhop of a not very bright or reliable kind.", "Herbert J. Muller"],
  ["The door of a bigoted mind opens outwards so that the only result of the pressure of facts upon it is to close it more snugly.", "Ogden Nash"],
  ["The doubts of an honest man contain more moral truth than the profession of faith of people under a worldly yoke.", "Ximenes Doudan"],
  ["The drying up a single tear has more of honest fame than shedding seas of gore.", "Lord Byron"],
  ["The dust of exploded beliefs may make a fine sunset.", "Geoffrey Madan"],
  ["The Earth is a very small stage in a vast cosmic arena. Think of the rivers of blood spilled by all those generals and emperors so that, in glory and triumph, they could become the momentary masters of a fraction of a dot. Think of the endless cruelties visited by the inhabitants of one corner of this pixel on the scarcely distinguishable inhabitants of some other corner, how frequent their misunderstandings, how eager they are to kill one another, how fervent their hatreds.", "Carl Sagan"],
  ["The easiest kind of relationship for me is with ten thousand people. The hardest is with one.", "Joan Baez"],
  ["The easy confidence with which I know another man's religion is folly teaches me to suspect that my own is also.", "Mark Twain"],
  ["The end of the human race will be that it will eventually die of civilization.", "Ralph Waldo Emerson"],
  ["The enemy is anybody who's going to get you killed, no matter which side he's on.", "Joseph Heller"],
  ["The English language is rather like a monster accordion, stretchable at the whim of the editor, compressible ad lib.", "Robert Burchfield"],
  ["The epitaph that I would write for history would say: I conceal nothing. It is not enough not to lie. One should strive not to lie in a negative sense by remaining silent.", "Leo Tolstoy"],
  ["The excesses of our youth are drafts upon our old age, payable with interest, about thirty years after date.", "Charles Caleb Colton"],
  ["The eye of the understanding is like the eye of the sense; for as you may see great objects through small crannies or holes, so you may see great axioms of nature through small and contemptible instances.", "Francis Bacon"],
  ["The eyes of others our prisons; their thoughts our cages.", "Virginia Woolf"],
  ["The fact that a believer is happier than a skeptic is no more to the point than the fact than a drunken man is happier than a sober one.", "George Bernard Shaw"],
  ["The fact that astronomies change while the stars abide is a true analogy of every realm of human life and thought, religion not least of all. No existent theology can be a final formulation of spiritual truth.", "Harry Emerson Fosdick"],
  ["The fact that we live at the bottom of a deep gravity well, on the surface of a gas-covered planet going around a nuclear fireball 90 million miles away and think this to be normal is obviously some indication of how skewed our perspective tends to be.", "Douglas Adams"],
  ["The fate of animals is of greater importance to me than the fear of appearing ridiculous; it is indissolubly connected with the fate of men.", "Emile Zola"],
  ["The fear of capitalism has compelled socialism to widen freedom, and the fear of socialism has compelled capitalism to increase equality.", "Ariel Durant"],
  ["The feeling of being hurried is not usually the result of living a full life and having no time. It is on the contrary born of a vague fear that we are wasting our life. When we do not do the one thing we ought to do, we have no time for anything else - we are the busiest people in the world.", "Eric Hoffer"],
  ["The fetters imposed on liberty at home have ever been forged out of the weapons provided for defence against real, pretended, or imaginary dangers from abroad.", "James Madison"],
  ["The finest words in the world are only vain sounds if you cannot understand them.", "Anatole France"],
  ["The fingers of your thoughts are molding your face ceaselessly.", "Charles Reznikoff"],
  ["The fire which enlightens is the same fire which consumes.", "Henri Frederic Amiel"],
  ["The firmest fayth is found in fewest woordes.", "Edward Dyer"],
  ["The first forty years of life give us the text; the next thirty supply the commentary on it.", "Arthur Schopenhauer"],
  ["The first human who hurled an insult instead of a stone was the founder of civilization.", "Sigmund Freud"],
  ["The first man to see an illusion by which men have flourished for centuries surely stands in a lonely place.", "Gary Zukav"],
  ["The first method for estimating the intelligence of a ruler is to look at the men he has around him.", "Niccolo Machiavelli"],
  ["The first problem for all of us, men and women, is not to learn, but to unlearn.", "Gloria Steinem"],
  ["The first symptom of love in a young man is timidity; in a girl boldness.", "Victor Hugo"],
  ["The folly of mistaking a paradox for a discovery, a metaphor for a proof, a torrent of verbiage for a spring of capital truths, and oneself for an oracle, is inborn in us.", "Paul Valery"],
  ["The foolish and the dead alone never change their opinions.", "James Russell Lowell"],
  ["The force which makes for war does not derive its strength from the interested motives of evil men; it derives its strength from the disinterested motives of good men.", "Norman Angell"],
  ["The function of the imagination is not to make strange things settled, so much as to make settled things strange.", "G.K. Chesterton"],
  ["The fundamental delusion of humanity is to suppose that I am here and you are out there.", "Yasutani Roshi"],
  ["The further one grows spiritually, the more and more people one loves and the fewer and fewer people one likes.", "Gale D. Webbe"],
  ["The game of life is a game of boomerangs. Our thoughts, deeds and words return to us sooner or later with astounding accuracy.", "Florence Scovel Shinn"],
  ["The gods of the valley are not the gods of the hills.", "Ethan Allen"],
  ["The government ought not to be invested with power to control the affections, any more than the consciences of citizens.", "Lydia Maria Child"],
  ["The great enemy of clear language is insincerity.", "George Orwell"],
  ["The great high of winning Wimbledon lasts for about a week. You go down in the record book, but you don't have anything tangible to hold on to. But having a baby - there isn't any comparison.", "Chris Evert"],
  ["The great men in literature have usually tried to bring the written word into harmony with the spoken, instead of encouraging an exclusive language to write in.", "John Erskine"],
  ["The great secret of success is to go through life as a man who never gets used up. That is possible for him who never argues and strives with men and facts, but in all experience retires upon himself, and looks for the ultimate cause of things in himself.", "Albert Schweitzer"],
  ["The great thing about getting older is that you don't lose all the other ages you've been.", "Madeleine L'Engle"],
  ["The great vice of the present day is bravura, an attempt to do something beyond the truth.", "John Constable"],
  ["The greatest analgesic, soporific, stimulant, tranquilizer, narcotic, and to some extent even antibiotic - in short, the closest thing to a genuine panacea - known to medical science is work.", "Thomas Szasz"],
  ["The greatest compliment that was ever paid me was when one asked me what I thought, and attended to my answer.", "Henry David Thoreau"],
  ["The greatest happiness of life is the conviction that we are loved - loved for ourselves, or rather, loved in spite of ourselves.", "Victor Hugo"],
  ["The greatest masterpiece in literature is only a dictionary out of order.", "Jean Cocteau"],
  ["The greatest minds are capable of the greatest vices as well as of the greatest virtues.", "Rene Descartes"],
  ["The greatest obstacle to discovering the shape of the earth, the continents, and the oceans was not ignorance but the illusion of knowledge.", "Daniel J. Boorstin"],
  ["The greatest of faults, I should say, is to be conscious of none.", "Thomas Carlyle"],
  ["The greatest part of a writer's time is spent in reading, in order to write; a man will turn over half a library to make one book.", "Samuel Johnson"],
  ["The greatest sign of success for a teacher is to be able to say, \"The children are now working as if I did not exist.\"", "Maria Montessori"],
  ["The greatest tragedy in mankind's entire history may be the hijacking of morality by religion.", "Arthur C. Clarke"],
  ["The habit of reading is the only enjoyment in which there is no alloy; it lasts when all other pleasures fade.", "Anthony Trollope"],
  ["The hands that help are better far / Than lips that pray. / Love is the ever gleaming star / That leads the way, / That shines, not on vague worlds of bliss, / But on a paradise in this.", "Robert Green Ingersoll"],
  ["The happiness of your life depends upon the quality of your thoughts: therefore, guard accordingly, and take care that you entertain no notions unsuitable to virtue and reasonable nature.", "Marcus Aurelius"],
  ["The hardest thing about any political campaign is how to win without proving that you are unworthy of winning.", "Adlai Stevenson"],
  ["The hardest-learned lesson: that people have only their kind of love to give, not our kind.", "Mignon McLaughlin"],
  ["The heart has its reasons that reason knows nothing of.", "Blaise Pascal"],
  ["The heart is wiser than the intellect.", "Josiah Gilbert Holland"],
  ["The heart that breaks open can contain the whole universe.", "Joanna Macy"],
  ["The heights by great men reached and kept / Were not attained by sudden flight, / But they, while their companions slept, / Were toiling upward in the night.", "Henry Wadsworth Longfellow"],
  ["The high-minded man must care more for the truth than for what people think.", "Aristotle"],
  ["The higher up you go, the more mistakes you are allowed. Right at the top, if you make enough of them, it's considered to be your style.", "Fred Astaire"],
  ["The higher we soar the smaller we appear to those who cannot fly.", "Friedrich Nietzsche"],
  ["The highest exercise of charity is charity towards the uncharitable.", "J.S. Buckminster"],
  ["The highest purpose is to have no purpose at all. This puts one in accord with nature in her manner of operation.", "John Cage"],
  ["The highest result of education is tolerance.", "Helen Keller"],
  ["The history of liberty is a history of the limitation of government power, not the increase of it.", "Woodrow Wilson"],
  ["The hope of a secure and livable world lies with disciplined nonconformists, who are dedicated to justice, peace, and brotherhood. The trailblazers in human, academic, scientific, and religious freedom have always been nonconformists. In any cause that concerns the progress of mankind, put your faith in the nonconformist!", "Martin Luther King, Jr"],
  ["The human mind is inspired enough when it comes to inventing horrors; it is when it tries to invent a heaven that it shows itself cloddish.", "Evelyn Waugh"],
  ["The ideal scientist thinks like a poet and only later works like a bookkeeper.", "E.O. Wilson"],
  ["The idealists and visionaries, foolish enough to throw caution to the winds and express their ardor and faith in some supreme deed, have advanced mankind and have enriched the world.", "Emma Goldman"],
  ["The ideals which have lighted my way, and time after time have given me new courage to face life cheerfully, have been Kindness, Beauty, and Truth.", "Albert Einstein"],
  ["The illusion that we are separate from one another is an optical delusion of our consciousness.", "Albert Einstein"],
  ["The illusion which exalts us is dearer to us than ten thousand truths.", "Aleksandr Pushkin"],
  ["The ingenuities we practice in order to appear admirable to ourselves would suffice to invent the telephone twice over on a rainy summer morning.", "Brendan Gill"],
  ["The innocent and the beautiful have no enemy but time.", "William Butler Yeats"],
  ["The institution of royalty in any form is an insult to the human race.", "Mark Twain"],
  ["The instruction we find in books is like fire. We fetch it from our neighbours, kindle it at home, communicate it to others, and it becomes the property of all.", "Voltaire"],
  ["The intellect of man is forced to choose / Perfection of the life, or of the work, / And if it take the second must refuse / A heavenly mansion, raging in the dark.", "William Butler Yeats"],
  ["The juvenile sea squirt wanders through the sea searching for a suitable rock or hunk of coral to cling to and make its home for life. For this task, it has a rudimentary nervous system. When it finds its spot and takes root, it doesn't need its brain anymore, so it eats it. It's rather like getting tenure.", "Daniel Dennett"],
  ["The knife disappears with sharpening.", "James Richardson"],
  ["The lame man who keeps the right road outstrips the runner who takes a wrong one. The more active and swift the latter is, the further he will go astray.", "Francis Bacon"],
  ["The late F.W.H. Myers used to tell how he asked a man at a dinner table what he thought would happen to him when he died. The man tried to ignore the question, but on being pressed, replied: \"Oh well, I suppose I shall inherit eternal bliss, but I wish you wouldn't talk about such unpleasant subjects.\"", "Bertrand Russell"],
  ["The law, in its majestic equality, forbids the rich as well as the poor to sleep under bridges, to beg in the streets, and to steal bread.", "Anatole France"],
  ["The least pain in our little finger gives us more concern and uneasiness than the destruction of millions of our fellow-beings.", "William Hazlitt"],
  ["The legitimate powers of government extend to such acts only as are injurious to others. It does me no injury for my neighbor to say there are twenty gods or no God.", "Thomas Jefferson"],
  ["The less justified a man is in claiming excellence for his own self, the more ready he is to claim all excellence for his nation, his religion, his race, or his holy cause. A man is likely to mind his own business when it is worth minding. When it is not, he takes his mind off his own meaningless affairs by minding other people's business.", "Eric Hoffer"],
  ["The liar's punishment is not in the least that he is not believed, but that he cannot believe anyone else.", "George Bernard Shaw"],
  ["The liberties of a people never were, nor ever will be, secure when the transactions of their rulers may be concealed from them.", "Patrick Henry"],
  ["The life of every man is a diary in which he means to write one story, and writes another, and his humblest hour is when he compares the volume as it is with what he vowed to make it.", "J.M. Barrie"],
  ["The light which experience gives is a lantern on the stern, which shines only on the waves behind us.", "Samuel Taylor Coleridge"],
  ["The lights of stars that were extinguished ages ago still reach us. So it is with great men who died centuries ago, but still reach us with the radiation of their personalities.", "Khalil Gibran"],
  ["The limits of my language mean the limits of my world.", "Ludwig Wittgenstein"],
  ["The limits of tyrants are prescribed by the endurance of those whom they oppress.", "Frederick Douglass"],
  ["The living are soft and yielding; the dead are rigid and stiff. Living plants are flexible and tender; the dead are brittle and dry.", "Lao Tzu"],
  ["The living language is like a cow-path: it is the creation of the cows themselves, who, having created it, follow it or depart from it according to their whims or their needs. From daily use, the path undergoes change. A cow is under no obligation to stay.", "E.B. White"],
  ["The longest day must have its close - the gloomiest night will wear on to a morning. An eternal, inexorable lapse of moments is ever hurrying the day of the evil to an eternal night, and the night of the just to an eternal day.", "Harriet Beecher Stowe"],
  ["The Lord prefers common-looking people. That is the reason He makes so many of them.", "Abraham Lincoln"],
  ["The louder he talks of honor, the faster we count our spoons.", "Ralph Waldo Emerson"],
  ["The love of learning, the sequestered nooks, / And all the sweet serenity of books.", "Henry Wadsworth Longfellow"],
  ["The love of one's country is a splendid thing. But why should love stop at the border.", "Pablo Casals"],
  ["The luck of having talent is not enough; one must also have a talent for luck.", "Hector Berlioz"],
  ["The lust for comfort murders the passions of the soul.", "Khalil Gibran"],
  ["The machine has got to be accepted, but it is probably better to accept it rather as one accepts a drug - that is, grudgingly and suspiciously. Like a drug, the machine is useful, dangerous, and habit-forming. The oftener one surrenders to it the tighter its grip becomes.", "George Orwell"],
  ["The man dies in all who keep silent in the face of tyranny.", "Wole Soyinka"],
  ["The man who can make others laugh secures more votes for a measure than the man who forces them to think.", "Malcolm De Chazal"],
  ["The man who dies rich dies disgraced.", "Andrew Carnegie"],
  ["The man who is always waving the flag usually waives what it stands for.", "Laurence J. Peter"],
  ["The man who is denied the opportunity of taking decisions of importance begins to regard as important the decisions he is allowed to take.", "C. Northcote Parkinson"],
  ["The man who prefers his country before any other duty shows the same spirit as the man who surrenders every right to the state. They both deny that right is superior to authority.", "Lord Acton"],
  ["The man who thinks he can do without the world is indeed mistaken; but the man who thinks the world cannot do without him is mistaken even more.", "François de La Rochefoucauld"],
  ["The man's desire is for the woman but the woman's desire is rarely other than for the desire of the man.", "Samuel Taylor Coleridge"],
  ["The mark of the immature man is that he wants to die nobly for a cause, while the mark of a mature man is that he wants to live humbly for one.", "Wilhelm Stekel"],
  ["The mass of men lead lives of quiet desperation and go to the grave with the song still in them.", "Henry David Thoreau"],
  ["The meaning of a poem is the outcome of a dialogue between the words on the page and the particular person who happens to be reading it. The interpretation can only be false if the reader does not know the contemporary meaning of the words.", "W.H. Auden"],
  ["The means by which we live have outdistanced the ends for which we live. Our scientific power has outrun our spiritual power. We have guided missiles and misguided men.", "Martin Luther King, Jr"],
  ["The means of defence against foreign danger have been always the instruments of tyranny at home.", "James Madison"],
  ["The measure of a country's greatness is its ability to retain compassion in times of crisis.", "Thurgood Marshall"],
  ["The measure of a man's real character is what he would do if he knew he would never be found out.", "Thomas Babington Macaulay"],
  ["The meeting of two personalities is like the contact of two chemical substances: if there is any reaction, both are transformed.", "Carl Jung"],
  ["The memory of most men is an abandoned cemetery where lie, unsung and unhonored, the dead whom they have ceased to cherish.", "Marguerite Yourcenar"],
  ["The mind has exactly the same power as the hands; not merely to grasp the world, but to change it.", "Colin Wilson"],
  ["The mind is but a barren soil; a soil which is soon exhausted, and will produce no crop, or only one, unless it be continually fertilized and enriched with foreign matter.", "Joshua Reynolds"],
  ["The mind is not a vessel that needs filling, but wood that needs igniting.", "Plutarch"],
  ["The modern conservative is engaged in one of man's oldest exercises in moral philosophy; that is, the search for a superior moral justification for selfishness.", "John Kenneth Galbraith"],
  ["The moment one gives close attention to anything, even a blade of grass, it becomes a mysterious, awesome, indescribably magnificent world in itself.", "Henry Miller"],
  ["The moral test of government is how that government treats those who are in the dawn of life, the children; those who are in the twilight of life, the elderly; and those who are in the shadows of life - the sick, the needy and the handicapped.", "Hubert Horatio Humphrey"],
  ["The more I study religions the more I am convinced that man never worshipped anything but himself.", "Richard Francis Burton"],
  ["The more intelligent and cultured a man is, the more subtly he can humbug himself.", "Carl Jung"],
  ["The more original a discovery, the more obvious it seems afterward.", "Arthur Koestler"],
  ["The more people are reached by mass communication, the less they communicate with each other.", "Marya Mannes"],
  ["The more powerful and original a mind, the more it will incline towards the religion of solitude.", "Aldous Huxley"],
  ["The more sand that has escaped from the hourglass of our life, the clearer we should see through it.", "Jean-Paul Sartre"],
  ["The more we live by our intellect, the less we understand the meaning of life.", "Leo Tolstoy"],
  ["The mosque is too far from home, so let's do this / Let's make a weeping child laugh.", "Nida Fazli"],
  ["The most certain test by which we can judge whether a country is really free is the amount of security enjoyed by minorities.", "Lord Acton (John Emerich Edward Dalberg-Acton)"],
  ["The most dangerous of all falsehoods is a slightly distorted truth.", "Georg Christoph Lichtenberg"],
  ["The most effective kind of education is that a child should play amongst lovely things.", "Plato"],
  ["The most erroneous stories are those we think we know best - and therefore never scrutinize or question.", "Stephen Jay Gould"],
  ["The most exhausting thing in life is being insincere.", "Anne Morrow Lindbergh"],
  ["The most futile thing in this world is any attempt, perhaps, at exact definition of character. All individuals are a bundle of contradictions - none more so than the most capable.", "Theodore Dreiser"],
  ["The most happy marriage I can picture or imagine to myself would be the union of a deaf man to a blind woman.", "Samuel Taylor Coleridge"],
  ["The most important discoveries will provide answers to questions that we do not yet know how to ask and will concern objects we have not yet imagined.", "John N. Bahcall"],
  ["The most important political office is that of private citizen.", "Louis Brandeis"],
  ["The most important scientific revolutions all include, as their only common feature, the dethronement of human arrogance from one pedestal after another of previous convictions about our centrality in the cosmos.", "Stephen Jay Gould"],
  ["The most important thing a father can do for his children is to love their mother.", "Theodore M. Hesburgh"],
  ["The most miserable pettifogging in the world is that of a man in the court of his own conscience.", "Henry Ward Beecher"],
  ["The most perfect technique is that which is not noticed at all.", "Pablo Casals"],
  ["The most potent weapon in the hands of the oppressor is the mind of the oppressed.", "Steve Biko"],
  ["The most radical revolutionary will become a conservative the day after the revolution.", "Hannah Arendt"],
  ["The most tyrannical of governments are those which make crimes of opinions, for everyone has an inalienable right to his thoughts.", "Baruch Spinoza"],
  ["The most valuable possession you can own is an open heart. The most powerful weapon you can be is an instrument of peace.", "Carlos Santana"],
  ["The mountain remains unmoved at seeming defeat by the mist.", "Rabindranath Tagore"],
  ["The music that can deepest reach, / And cure all ill, is cordial speech.", "Ralph Waldo Emerson"],
  ["The nationalist not only does not disapprove of atrocities committed by his own side, he has a remarkable capacity for not even hearing about them.", "George Orwell"],
  ["The negative is the equivalent of the composer's score, and the print the performance.", "Ansel Adams"],
  ["The Net interprets censorship as damage and routes around it.", "John Gilmore"],
  ["The nice thing about standards is that there are so many of them to choose from.", "Andrew S. Tanenbaum"],
  ["The noble and the nobility are usually at odds with one another.", "Johann Gottfried Seume"],
  ["The noble art of losing face / may one day save the human race / and turn into eternal merit / what weaker minds would call disgrace.", "Piet Hein"],
  ["The notes I handle no better than many pianists. But the pauses between the notes - ah, that is where the art resides.", "Artur Schnabel"],
  ["The notion of making money by popular work, and then retiring to do good work, is the most familiar of all the devil's traps for artists.", "Logan Pearsall Smith"],
  ["The nuclear arms race is like two sworn enemies standing waist deep in gasoline, one with three matches, the other with five.", "Carl Sagan"],
  ["The object of most prayers is to wangle an advance on good intentions.", "Robert Brault"],
  ["The objector and the rebel who raises his voice against what he believes to be the injustice of the present and the wrongs of the past is the one who hunches the world along.", "Clarence Darrow"],
  ["The one real object of education is to have a man in the condition of continually asking questions.", "Mandell Creighton"],
  ["The one thing that doesn't abide by majority rule is a person's conscience.", "Harper Lee"],
  ["The one thing we can never get enough of is love. And the one thing we never give enough is love.", "Henry Miller"],
  ["The only difference between me and a madman is that I'm not mad.", "Salvador Dali"],
  ["The only difference between saints and sinners is that every saint has a past while every sinner has a future.", "Oscar Wilde"],
  ["The only fence against the world is a thorough knowledge of it.", "John Locke"],
  ["The only gift is a portion of thyself.", "Ralph Waldo Emerson"],
  ["The only gift is giving to the poor; / All else is exchange.", "Thiruvalluvar"],
  ["The only man I know who behaves sensibly is my tailor; he takes my measurements anew each time he sees me. The rest go on with their old measurements and expect me to fit them.", "George Bernard Shaw"],
  ["The only maxim of a free government ought to be to trust no man living with power to endanger the public liberty.", "John Adams"],
  ["The only real voyage of discovery consists not in seeking new landscapes but in having new eyes.", "Marcel Proust"],
  ["The only sure bulwark of continuing liberty is a government strong enough to protect the interests of the people, and a people strong enough and well enough informed to maintain its sovereign control over its government.", "Franklin D. Roosevelt"],
  ["The only thing one can give an artist is leisure in which to work. To give an artist leisure is actually to take part in his creation.", "Ezra Pound"],
  ["The only way human beings can win a war is to prevent it.", "George Marshall"],
  ["The opinion of 10,000 men is of no value if none of them know anything about the subject.", "Marcus Aurelius"],
  ["The opposite of a correct statement is a false statement. But the opposite of a profound truth may well be another profound truth.", "Niels Bohr"],
  ["The optimist proclaims we live in the best of all possible worlds, and the pessimist fears this is true.", "James Branch Cabell"],
  ["The pages are still blank, but there is a miraculous feeling of the words being there, written in invisible ink and clamoring to become visible.", "Vladimir Nabokov"],
  ["The pain passes but the beauty remains.", "Pierre-Auguste Renoir", "responding to Matisse on why he painted in spite of his painful arthritis"],
  ["The palest ink is better than the best memory.", "Chinese proverb"],
  ["The Panama Canal was dug with a microscope.", "Ronald Ross", "alluding to the research done to get rid of the mosquito"],
  ["The past is a foreign country; they do things differently there.", "L.P. Hartley"],
  ["The past, - well, it's just like / our Great-Aunt Laura, / who cannot or will not perceive / that though she is welcome, / and though we adore her, / yet now it is time to leave.", "Piet Hein"],
  ["The path of least resistance makes all rivers, and some men, crooked.", "Napoleon Hill"],
  ["The peculiar evil of silencing the expression of an opinion is, that it is robbing the human race; posterity as well as the existing generation; those who dissent from the opinion, still more than those who hold it. If the opinion is right, they are deprived of the opportunity of exchanging error for truth: if wrong, they lose, what is almost as great a benefit, the clearer perception and livelier impression of truth, produced by its collision with error.", "John Stuart Mill"],
  ["The penalty that good men pay for not being interested in politics is to be governed by men worse than themselves.", "Plato"],
  ["The people can always be brought to the bidding of the leaders. That is easy. All you have to do is tell them they are being attacked and denounce the pacifists for lack of patriotism and exposing the country to danger. It works the same way in any country.", "Hermann Göring"],
  ["The people never give up their liberties, but under some delusion.", "Edmund Burke"],
  ["The people of these United States are the rightful masters of both Congresses and courts, not to overthrow the Constitution, but to overthrow the men who pervert the Constitution.", "Abraham Lincoln"],
  ["The people who burned witches at the stake never for one moment thought of their act as violence; rather they thought of it as an act of divinely mandated righteousness. The same can be said of most of the violence we humans have ever committed.", "Gil Bailie"],
  ["The perfection of a clock is not to go fast, but to be accurate.", "Luc de Clapiers, marquis de Vauvenargues"],
  ["The pessimist complains about the wind; the optimist expects it to change; the realist adjusts the sails.", "William Arthur Ward"],
  ["The poet judges not as a judge judges but as the sun falling around a helpless thing.", "Walt Whitman"],
  ["The politician is an acrobat. He keeps his balance by saying the opposite of what he does.", "Maurice Barres"],
  ["The Potter books in general are a prolonged argument for tolerance, a prolonged plea for an end to bigotry, and I think it's one of the reasons that some people don't like the books, but I think that it's a very healthy message to pass on to younger people that you should question authority and you should not assume that the establishment or the press tells you all of the truth.", "J.K. Rowling"],
  ["The power to command frequently causes failure to think.", "Barbara Tuchman"],
  ["The power to define the situation is the ultimate power.", "Jerry Rubin"],
  ["The President is merely the most important among a large number of public servants. He should be supported or opposed exactly to the degree which is warranted by his good conduct or bad conduct, his efficiency or inefficiency in rendering loyal, able, and disinterested service to the Nation as a whole. Therefore it is absolutely necessary that there should be full liberty to tell the truth about his acts, and this means that it is exactly necessary to blame him when he does wrong as to praise him when he does right. Any other attitude in an American citizen is both base and servile. To announce that there must be no criticism of the President, or that we are to stand by the President, right or wrong, is not only unpatriotic and servile, but is morally treasonable to the American public. Nothing but the truth should be spoken about him or any one else. But it is even more important to tell the truth, pleasant or unpleasant, about him than about any one else.", "Theodore Roosevelt"],
  ["The price of freedom of religion or of speech or of the press is that we must put up with, and even pay for, a good deal of rubbish.", "Robert H. Jackson"],
  ["The price we pay for money is paid in liberty.", "Robert Louis Stevenson"],
  ["The principal contributor to loneliness in this country is television. What happens is that the family 'gets together' alone.", "Ashley Montagu"],
  ["The principal goal of education in the schools should be creating men and women who are capable of doing new things, not simply repeating what other generations have done; men and women who are creative, inventive, and discoverers, who can be critical and verify, and not accept, everything they are offered.", "Jean Piaget"],
  ["The problem in our country isn't with books being banned, but with people no longer reading. ... You don't have to burn books to destroy a culture. Just get people to stop reading them.", "Ray Bradbury"],
  ["The problem with being sure that God is on your side is that you can't change your mind, because God sure isn't going to change His.", "Roger Ebert"],
  ["The propagandist's purpose is to make one set of people forget that certain other sets of people are human.", "Aldous Huxley"],
  ["The pursuit of truth and beauty is a sphere of activity in which we are permitted to remain children all our lives.", "Albert Einstein"],
  ["The quality of our thoughts is bordered on all sides by our facility with language.", "J. Michael Straczynski"],
  ["The quarrels of lovers are like summer storms. Everything is more beautiful when they have passed.", "Suzanne Necker"],
  ["The question is not \"Can they reason?\", nor \"Can they talk?\", but \"Can they suffer?\"", "Jeremy Bentham"],
  ["The question is whether or not you choose to disturb the world around you, or if you choose to let it go on as if you had never arrived.", "Ann Patchett"],
  ["The question why there is evil in existence is the same as why there is imperfection... But this is the real question we ought to ask: Is this imperfection the final truth, is evil absolute and ultimate?", "Rabindranath Tagore"],
  ["The race of men, while sheep in credulity, are wolves for conformity.", "Carl Van Doren"],
  ["The radical novelty of modern science lies precisely in the rejection of the belief ... that the forces which move the stars and atoms are contingent upon the preferences of the human heart.", "Walter Lippmann"],
  ["The rain begins with a single drop.", "Manal al-Sharif"],
  ["The raw material of possible poems and histories.", "Ralph Waldo Emerson"],
  ["The reading of all good books is like a conversation with the finest men of past centuries.", "Rene Descartes"],
  ["The real index of civilization is when people are kinder than they need to be.", "Louis de Bernieres"],
  ["The real measure of our wealth is how much we'd be worth if we lost all our money.", "John Henry Jowett"],
  ["The real secret is why love starts out with claws like a cat and then fades with time like a half-eaten mouse.", "Herta Müller"],
  ["The reasonable man adapts himself to the world; the unreasonable one persists to adapt the world to himself. Therefore all progress depends on the unreasonable man.", "George Bernard Shaw"],
  ["The religion of one age is the literary entertainment of the next.", "Ralph Waldo Emerson"],
  ["The reward for conformity was that everyone liked you except yourself.", "Rita Mae Brown"],
  ["The rightness of a thing isn't determined by the amount of courage it takes.", "Mary Renault"],
  ["The ring always believes that the finger lives for it.", "Malcolm De Chazal"],
  ["The river does not swell with clear water.", "Italian proverb"],
  ["The root of all superstition is that men observe when a thing hits, but not when it misses.", "Francis Bacon"],
  ["The rung of a ladder was never meant to rest upon, but only to hold a man's foot long enough to enable him to put the other somewhat higher.", "Thomas Henry Huxley"],
  ["The saddest aspect of life right now is that science gathers knowledge faster than society gathers wisdom.", "Isaac Asimov"],
  ["The satiated man and the hungry one do not see the same thing when they look upon a loaf of bread.", "Rumi"],
  ["The secret of good writing is to say an old thing in a new way or a new thing in an old way.", "Richard Harding Davis"],
  ["The secret of joy is the mastery of pain.", "Anais Nin"],
  ["The sense of wishing to be known only for what one really is is like putting on an old, easy, comfortable garment. You are no longer afraid of anybody or anything. You say to yourself, 'Here I am - just so ugly, dull, poor, beautiful, rich, interesting, amusing, ridiculous - take me or leave me.' And how absolutely beautiful it is to be doing only what lies within your own capabilities and is part of your own nature. It is like a great burden rolled off a man's back when he comes to want to appear nothing that he is not, to take out of life only what is truly his own.", "David Grayson"],
  ["The shepherd always tries to persuade the sheep that their interests and his own are the same.", "Stendhal (Marie-Henri Beyle)"],
  ["The sick do not ask if the hand that smooths their pillow is pure, nor the dying care if the lips that touch their brow have known the kiss of sin.", "Oscar Wilde"],
  ["The simplest questions are the most profound. Where were you born? Where is your home? Where are you going? What are you doing? Think about these once in a while and watch your answers change.", "Richard Bach"],
  ["The skylines lit up at dead of night, the air-conditioning systems cooling empty hotels in the desert and artificial light in the middle of the day all have something both demented and admirable about them. The mindless luxury of a rich civilization, and yet of a civilization perhaps as scared to see the lights go out as was the hunter in his primitive night.", "Jean Baudrillard"],
  ["The sleep of reason produces monsters.", "Francisco Goya"],
  ["The sorrow which has no vent in tears may make other organs weep.", "Henry Maudsley"],
  ["The soul is healed by being with children.", "Fyodor Dostoyevsky"],
  ["The soul is the same in all living creatures, although the body is different.", "Hippocrates"],
  ["The soul should always stand ajar. That if the heaven inquire, He will not be obliged to wait, Or shy of troubling her.", "Emily Dickinson"],
  ["The soul would have no rainbow had the eyes no tears.", "John Vance Cheney"],
  ["The souls of emperors and cobblers are cast in the same mold. The same reason that makes us wrangle with a neighbor creates a war betwixt princes.", "Michel de Montaigne"],
  ["The soundest argument will produce no more conviction in an empty head than the most superficial declamation; as a feather and a guinea fall with equal velocity in a vacuum.", "Charles Caleb Colton"],
  ["The sparrow is sorry for the peacock at the burden of his tail.", "Rabindranath Tagore"],
  ["The speaking in a perpetual hyperbole is comely in nothing but in love.", "Francis Bacon"],
  ["The spirit of democracy cannot be imposed from without. It has to come from within.", "Mahatma Gandhi"],
  ["The squirrel that you kill in jest, dies in earnest.", "Henry David Thoreau"],
  ["The stateliest building man can raise is the ivy's food at last.", "Charles Dickens"],
  ["The strength of a language does not lie in rejecting what is foreign but in assimilating it.", "Johann Wolfgang von Goethe"],
  ["The strongest natures, when they are influenced, submit the most unreservedly; it is perhaps a sign of their strength.", "Virginia Woolf"],
  ["The struggle with evil by means of violence is the same as an attempt to stop a cloud, in order that there may be no rain.", "Leo Tolstoy"],
  ["The study of error is not only in the highest degree prophylactic, but it serves as a stimulating introduction to the study of truth.", "Walter Lippmann"],
  ["The successful revolutionary is a statesman, the unsuccessful one a criminal.", "Erich Fromm"],
  ["The sum of human wisdom is not contained in any one language, and no single language is capable of expressing all forms and degrees of human comprehension.", "Ezra Pound"],
  ["The sun is pure communism everywhere except in cities, where it's private property.", "Malcolm De Chazal"],
  ["The sun, with all those planets revolving around it and dependent upon it, can still ripen a bunch of grapes as if it had nothing else in the universe to do.", "Galileo Galilei"],
  ["The supreme accomplishment is to blur the line between work and play.", "Arnold J. Toynbee"],
  ["The Supreme Ethical Rule: Act so as to elicit the best in others and thereby in thyself.", "Felix Adler"],
  ["The supreme happiness in life is the conviction that we are loved.", "Victor Hugo"],
  ["The surest way to corrupt a youth is to instruct him to hold in higher esteem those who think alike than those who think differently.", "Friedrich Nietzsche"],
  ["The tax which will be paid for the purpose of education is not more than the thousandth part of what will be paid to kings, priests, and nobles who will rise up among us if we leave the people in ignorance.", "Thomas Jefferson"],
  ["The television, that insidious beast, that Medusa which freezes a billion people to stone every night, staring fixedly, that Siren which called and sang and promised so much and gave, after all, so little.", "Ray Bradbury"],
  ["The term 'working mother' is ridiculously redundant.", "Donna Reed"],
  ["The test of a democracy is not the magnificence of buildings or the speed of automobiles or the efficiency of air transportation, but rather the care given to the welfare of all the people.", "Helen Keller"],
  ["The test of our progress is not whether we add more to the abundance of those who have much, it is whether we provide enough for those who have too little.", "Franklin D. Roosevelt"],
  ["The thing that makes you exceptional, if you are at all, is inevitably that which must also make you lonely.", "Lorraine Hansberry"],
  ["The things we admire in men, kindness and generosity, openness, honesty, understanding and feeling are the concomitants of failure in our system. And those traits we detest, sharpness, greed, acquisitiveness, meanness, egotism and self-interest are the traits of success. And while men admire the quality of the first they love the produce of the second.", "John Steinbeck"],
  ["The time will come when men such as I will look upon the murder of animals as they now look on the murder of men.", "Leonardo da Vinci"],
  ["The trade of governing has always been monopolized by the most ignorant and the most rascally individuals of mankind.", "Thomas Paine"],
  ["The tragedy in the lives of most of us is that we go through life walking down a high-walled lane with people of our own kind, the same economic situation, the same national background and education and religious outlook. And beyond those walls, all humanity lies, unknown and unseen, and untouched by our restricted and impoverished lives.", "Florence Luscomb"],
  ["The tragedy of life is what dies inside a man while he lives.", "Albert Schweitzer"],
  ["The tragedy of modern war is not so much that young men die but that they die fighting each other, instead of their real enemies back home in the capitals.", "Edward Abbey"],
  ["The tree which moves some to tears of joy is in the eyes of others only a green thing that stands in the way. Some see nature all ridicule and deformity... and some scarce see nature at all. But to the eyes of the man of imagination, nature is imagination itself.", "William Blake"],
  ["The trees that are slow to grow bear the best fruit.", "Moliere"],
  ["The trouble with the world is that the stupid are cocksure and the intelligent are full of doubt.", "Bertrand Russell"],
  ["The trouble with this country is that there are too many politicians who believe, with a conviction based on experience, that you can fool all of the people all of the time.", "Franklin P. Adams"],
  ["The trouble with words is that you never know whose mouths they've been in.", "Dennis Potter"],
  ["The true alchemists do not change lead into gold; they change the world into words.", "William H. Gass"],
  ["The true birthplace is that wherein for the first time one looks intelligently upon oneself; my first homelands have been books, and to a lesser degree schools.", "Marguerite Yourcenar"],
  ["The true civilization is where every man gives to every other every right that he claims for himself.", "Robert Green Ingersoll"],
  ["The true danger is when liberty is nibbled away, for expedients, and by parts.", "Edmund Burke"],
  ["The true measure of a man is how he treats someone who can do him absolutely no good.", "Samuel Johnson"],
  ["The true test of a civilization is, not the census, nor the size of the cities, nor the crops - no, but the kind of man the country turns out.", "Ralph Waldo Emerson"],
  ["The truly creative mind in any field is no more than this: A human creature born abnormally, inhumanly sensitive. To him... a touch is a blow, a sound is a noise, a misfortune is a tragedy, a joy is an ecstasy, a friend is a lover, a lover is a god, and failure is death. Add to this cruelly delicate organism the overpowering necessity to create, create, create - so that without the creating of music or poetry or books or buildings or something of meaning, his very breath is cut off from him. He must create, must pour out creation. By some strange, unknown, inward urgency he is not really alive unless he is creating.", "Pearl S. Buck"],
  ["The truth is rarely pure, and never simple.", "Oscar Wilde"],
  ["The truth is that every morning war is declared afresh. And the men who wish to continue it are as guilty as the men who began it, more guilty perhaps, for the latter perhaps did not foresee all its horrors.", "Marcel Proust"],
  ["The truth is that our finest moments are most likely to occur when we are feeling deeply uncomfortable, unhappy, or unfulfilled. For it is only in such moments, propelled by our discomfort, that we are likely to step out of our ruts and start searching for different ways or truer answers.", "M. Scott Peck"],
  ["The truth isn't always beauty, but the hunger for it is.", "Nadine Gordimer"],
  ["The two most engaging powers of an author are to make new things familiar, and familiar things new.", "Samuel Johnson"],
  ["The ultimate sense of security will be when we come to recognize that we are all part of one human race. Our primary allegiance is to the human race and not to one particular color or border. I think the sooner we renounce the sanctity of these many identities and try to identify ourselves with the human race the sooner we will get a better world and a safer world.", "Mohamed ElBaradei"],
  ["The ultimate test of a moral society is the kind of world that it leaves to its children.", "Dietrich Bonhoeffer"],
  ["The universe is like a safe to which there is a combination. But the combination is locked up in the safe.", "Peter De Vries"],
  ["The universe is made of stories, not of atoms.", "Muriel Rukeyser"],
  ["The unluckiest insolvent in the world is the man whose expenditure of speech is too great for his income of ideas.", "Christopher Morley"],
  ["The vanquished know war. They see through the empty jingoism of those who use the abstract words of glory, honor, and patriotism to mask the cries of the wounded, the senseless killing, war profiteering, and chest-pounding grief.", "Chris Hedges"],
  ["The vast majority of human beings dislike and even dread all notions with which they are not familiar. Hence it comes about that at their first appearance innovators have always been derided as fools and madmen.", "Aldous Huxley"],
  ["The vine that has grown old on an old tree falls with the ruin of that tree and through that bad companionship must perish with it.", "Leonardo da Vinci"],
  ["The violets in the mountains have broken the rocks.", "Tennessee Williams"],
  ["The voice of conscience is so delicate that it is easy to stifle it; but it is also so clear that it is impossible to mistake it.", "Madame de Stael"],
  ["The walls of books around me, dense with the past, formed a kind of insulation against the present world and its disasters.", "Ross Macdonald"],
  ["The wastebasket is a writer's best friend.", "Isaac Bashevis Singer"],
  ["The weakest living creature, by concentrating his powers on a single object, can accomplish something. The strongest, by dispensing his over many, may fail to accomplish anything. The drop, by continually falling, bores its passage through the hardest rock. The hasty torrent rushes over it with hideous uproar, and leaves no trace behind.", "Thomas Carlyle"],
  ["The well taught philosophic mind / To all compassion gives; / Casts round the world an equal eye, / And feels for all that lives.", "Anna Letitia Barbauld"],
  ["The whole art of teaching is only the art of awakening the natural curiosity of young minds for the purpose of satisfying it afterwards.", "Anatole France"],
  ["The whole problem with the world is that fools and fanatics are always so certain of themselves, and wiser people so full of doubts.", "Bertrand Russell"],
  ["The willow which bends to the tempest, often escapes better than the oak which resists it; and so in great calamities, it sometimes happens that light and frivolous spirits recover their elasticity and presence of mind sooner than those of a loftier character.", "Walter Scott"],
  ["The wise are instructed by reason, average minds by experience, the stupid by necessity and the brute by instinct.", "Marcus Tullius Cicero"],
  ["The wisest man is he who does not fancy that he is so at all.", "Nicolas Boileau-Despréaux"],
  ["The woods are lovely, dark and deep. / But I have promises to keep, / And miles to go before I sleep / And miles to go before I sleep.", "Robert Frost"],
  ["The words a father speaks to his children in the privacy of the home are not overheard at the time, but, as in whispering galleries, they will be clearly heard at the end and by posterity.", "Jean Paul Richter"],
  ["The words of some men are thrown forcibly against you and adhere like burrs.", "Henry David Thoreau"],
  ["The world in general doesn't know what to make of originality; it is startled out of its comfortable habits of thought, and its first reaction is one of anger.", "W. Somerset Maugham"],
  ["The world is a looking glass, and gives back to every man the reflection of his own face.", "William Makepeace Thackeray"],
  ["The world is a skirt I want to lift up.", "Hanif Kureishi"],
  ["The world is a story we tell ourselves about the world.", "Vikram Chandra"],
  ["The world is changed not by the self-regarding, but by men and women prepared to make fools of themselves.", "P.D. James"],
  ["The World is divided into armed camps ready to commit genocide just because we can't agree on whose fairy tales to believe.", "Ed Krebs"],
  ["The world is in greater peril from those who tolerate or encourage evil than from those who actually commit it.", "Albert Einstein"],
  ["The world is more malleable than you think and it's waiting for you to hammer it into shape.", "Bono"],
  ["The world is mud-luscious and puddle-wonderful.", "E.E. Cummings"],
  ["The world looks with some awe upon a man who appears unconcernedly indifferent to home, money, comfort, rank, or even power and fame. The world feels not without a certain apprehension, that here is someone outside its jurisdiction; someone before whom its allurements may be spread in vain; someone strangely enfranchised, untamed, untrammelled by convention, moving independent of the ordinary currents of human action.", "Winston Churchill"],
  ["The world of books is the most remarkable creation of man. Nothing else that he builds ever lasts. Monuments fall; nations perish; civilizations grow old and die out; and, after an era of darkness, new races build others.", "Clarence Day"],
  ["The world owes all its onward impulses to men ill at ease. The happy man inevitably confines himself within ancient limits.", "Nathaniel Hawthorne"],
  ["The world, we are told, was made especially for man - a presumption not supported by all the facts... Why should man value himself as more than a small part of the one great unit of creation?", "John Muir"],
  ["The worst kind of people are those who confuse kindness for weakness.", "Werner Makowski"],
  ["The writing of a poem is like a child throwing stones into a mineshaft. You compose first, then you listen for the reverberation.", "James Fenton"],
  ["The zoo is a prison for animals who have been sentenced without trial and I feel guilty because I do nothing about it. I wanted to see an oyster-catcher, so I was no better than the people who caged the oyster-catcher for me to see.", "Russell Hoban"],
  ["There are 10^11 stars in the galaxy. That used to be a huge number. But it's only a hundred billion. It's less than the national deficit! We used to call them astronomical numbers. Now we should call them economical numbers.", "Richard Feynman"],
  ["There are a thousand hacking at the branches of evil to one who is striking at the root.", "Henry David Thoreau"],
  ["There are a thousand thoughts lying within a man that he does not know till he takes up a pen to write.", "William Makepeace Thackeray"],
  ["There are books in which the footnotes or comments scrawled by some reader's hand in the margin are more interesting that the text. The world is one of these books.", "George Santayana"],
  ["There are few things more disturbing than to find, in somebody we detest, a moral quality which seems to us demonstrably superior to anything we ourselves possess. It augurs not merely an unfairness on the part of creation, but a lack of artistic judgment. Sainthood is acceptable only in saints.", "Pamela Hansford Johnson"],
  ["There are four ways, and only four ways, in which we have contact with the world. We are evaluated and classified by these four contacts: what we do, how we look, what we say, and how we say it.", "Dale Carnegie"],
  ["There are many people who reach their conclusions about life like schoolboys; they cheat their master by copying the answer out of a book without having worked out the sum for themselves.", "Søren Kierkegaard"],
  ["There are many ways to be free. One of them is to transcend reality by imagination, as I try to do.", "Anais Nin"],
  ["There are more truths in twenty-four hours of a man's life than in all the philosophies.", "Raoul Vaneigem"],
  ["There are no chaste minds. Minds copulate wherever they meet.", "Eric Hoffer"],
  ["There are no passengers on spaceship earth. We are all crew.", "Marshall McLuhan"],
  ["There are no persons capable of stooping so low as those who desire to rise in the world.", "Marguerite Guardiner"],
  ["There are none so sour as those who are sweet to order.", "Luc de Clapiers, marquis de Vauvenargues"],
  ["There are only two kinds of men: the righteous who believe themselves sinners; the sinners who believe themselves righteous.", "Blaise Pascal"],
  ["There are some things so serious you have to laugh at them.", "Niels Henrik David Bohr"],
  ["There are some who only employ words for the purpose of disguising their thoughts.", "Voltaire"],
  ["There are stars whose radiance is visible on Earth though they have long been extinct. There are people whose brilliance continues to light the world though they are no longer among the living. These lights are particularly bright when the night is dark. They light the way for humankind.", "Hannah Senesh"],
  ["There are those who say to you - we are rushing this issue of civil rights. I say we are 172 years late.", "Hubert Humphrey"],
  ["There are three ingredients to the good life; learning, earning, and yearning.", "Christopher Morley"],
  ["There are times when we must sink to the bottom of our misery to understand truth, just as we must descend to the bottom of a well to see the stars in broad daylight.", "Vaclav Havel"],
  ["There are two kinds of fool. One says, \"This is old, and therefore good.\" And one says, \"This is new, and therefore better.\"", "John Brunner"],
  ["There are two kinds of light - the glow that illuminates, and the glare that obscures.", "James Thurber"],
  ["There are two kinds of truth: the truth that lights the way and the truth that warms the heart. The first of these is science, and the second is art. Neither is independent of the other or more important than the other. Without art, science would be as useless as a pair of high forceps in the hands of a plumber. Without science, art would become a crude mess of folklore and emotional quackery. The truth of art keeps science from becoming inhuman, and the truth of science keeps art from becoming ridiculous.", "Raymond Thornton Chandler"],
  ["There are two possible outcomes: If the result confirms the hypothesis, then you've made a measurement. If the result is contrary to the hypothesis, then you've made a discovery.", "Enrico Fermi"],
  ["There are two things to aim at in life; first to get what you want, and after that to enjoy it. Only the wisest of mankind achieve the second.", "Logan Pearsall Smith"],
  ["There are two ways of being happy: We may either diminish our wants or augment our means - either will do - the result in the same; and it is for each man to decide for himself, and do that which happens to be the easiest. If you are idle or sick or poor, however hard it may be to diminish your wants, it will be harder to augment your means. If you are active and prosperous or young and in good health, it may be easier for you to augment your means than to diminish your wants. But if you are wise, you will do both at the same time, young or old, rich or poor, sick or well; and if you are very wise you will do both in such a way as to augment the general happiness of society.", "Benjamin Franklin"],
  ["There are two ways of spreading light: to be the candle or the mirror that reflects it.", "Edith Wharton"],
  ["There are years that ask questions and years that answer.", "Zora Neale Hurston"],
  ["There comes a point when a man must refuse to answer to his leader if he is also to answer to his own conscience.", "Hartley Shawcross"],
  ["There comes a time in a man's life when to get where he has to - if there are no doors or windows - he walks through a wall.", "Bernard Malamud"],
  ["There is a beauty in discovery. There is mathematics in music, a kinship of science and poetry in the description of nature, and exquisite form in a molecule. Attempts to place different disciplines in different camps are revealed as artificial in the face of the unity of knowledge. All literate men are sustained by the philosopher, the historian, the political analyst, the economist, the scientist, the poet, the artisan, and the musician.", "Glenn T. Seaborg"],
  ["There is a budding morrow in midnight.", "John Keats"],
  ["There is a crack in everything. That's how the light gets in.", "Leonard Cohen"],
  ["There is a cult of ignorance in the United States, and there always has been. The strain of anti-intellectualism has been a constant thread winding its way through our political and cultural life, nurtured by the false notion that democracy means that \"my ignorance is just as good as your knowledge.\"", "Isaac Asimov"],
  ["There is a field beyond all notions of right and wrong. Come, meet me there.", "Rumi"],
  ["There is a foolish corner in the brain of the wisest man.", "Aristotle"],
  ["There is a loftier ambition than merely to stand high in the world. It is to stoop down and lift mankind a little higher.", "Henry van Dyke"],
  ["There is a point beyond which even justice becomes unjust.", "Sophocles"],
  ["There is a principle which is a bar against all information, which is proof against all arguments and which cannot fail to keep a man in everlasting ignorance - that principle is contempt prior to investigation.", "Herbert Spencer"],
  ["There is a road from the eye to the heart that does not go through the intellect.", "G.K. Chesterton"],
  ["There is a rumor going around that I have found God. I think this is unlikely because I have enough difficulty finding my keys, and there is empirical evidence that they exist.", "Terry Pratchett"],
  ["There is a tragic clash between Truth and the world. Pure undistorted truth burns up the world.", "Nikolai Berdyaev"],
  ["There is a wonderful mythical law of nature that the three things we crave most in life - happiness, freedom, and peace of mind - are always attained by giving them to someone else.", "Peyton C. March"],
  ["There is also an artificial aristocracy founded on wealth and birth, without either virtue or talents... The artificial aristocracy is a mischievous ingredient in government, and provisions should be made to prevent its ascendancy.", "Thomas Jefferson"],
  ["There is always something to do. There are hungry people to feed, naked people to clothe, sick people to comfort and make well. And while I don't expect you to save the world, I do think it's not asking too much for you to love those with whom you sleep, share the happiness of those whom you call friend, engage those among you who are visionary, and remove from your life those who offer you depression, despair, and disrespect.", "Nikki Giovanni"],
  ["There is an eloquence in true enthusiasm that is not to be doubted.", "Washington Irving"],
  ["There is as much difference between us and ourselves as between us and others.", "Michel de Montaigne"],
  ["There is far too much law for those who can afford it and far too little for those who cannot.", "Derek Bok"],
  ["There is hopeful symbolism in the fact that flags do not wave in a vacuum.", "Arthur C. Clarke"],
  ["There is no absurdity so palpable but that it may be firmly planted in the human head if you only begin to inculcate it before the age of five, by constantly repeating it with an air of great solemnity.", "Arthur Schopenhauer"],
  ["There is no agony like bearing an untold story inside of you.", "Maya Angelou"],
  ["There is no beautifier of complexion, or form, or behavior, like the wish to scatter joy and not pain around us.", "Ralph Waldo Emerson"],
  ["There is no character, howsoever good and fine, but it can be destroyed by ridicule, howsoever poor and witless.", "Mark Twain"],
  ["There is no coming to consciousness without pain.", "Carl Jung"],
  ["There is no den in the wide world to hide a rogue. Commit a crime and the earth is made of glass. Commit a crime, and it seems as if a coat of snow fell on the ground, such as reveals in the woods the track of every partridge, and fox, and squirrel.", "Ralph Waldo Emerson"],
  ["There is no disguise that can for long conceal love where it exists or simulate it where it does not.", "François de La Rochefoucauld"],
  ["There is no doubt that I have lots of words inside me; but at moments, like rush-hour traffic at the mouth of a tunnel, they jam.", "John Updike"],
  ["There is no excellent beauty that hath not some strangeness in the proportion.", "Francis Bacon"],
  ["There is no exception to the rule that every rule has an exception.", "James Thurber"],
  ["There is no greater fallacy than the belief that aims and purposes are one thing, while methods and tactics are another.", "Emma Goldman"],
  ["There is no greater gift to an insecure leader that quite matches a vague enemy who can be used to whip up fear and hatred among the population.", "Paul Rusesabagina"],
  ["There is no greater mistake than the hasty conclusion that opinions are worthless because they are badly argued.", "Thomas Huxley"],
  ["There is no greatness where there is not simplicity, goodness, and truth.", "Leo Tolstoy"],
  ["There is no human being who, as a result of desiring to build a better life, should be named or declared illegal.", "Alejandro González Iñárritu"],
  ["There is no instance of a nation benefiting from prolonged warfare.", "Sun Tzu"],
  ["There is no kind of dishonesty into which otherwise good people more easily and frequently fall than that of defrauding the government.", "Benjamin Franklin"],
  ["There is no man so good, who, were he to submit all his thoughts and actions to the laws, would not deserve hanging ten times in his life.", "Michel de Montaigne"],
  ["There is no more irritating fellow than the man who tries to settle an argument about communism, or justice, or liberty, by quoting from Webster.", "Mortimer J. Adler"],
  ["There is no one, no matter how wise he is, who has not in his youth said things or done things that are so unpleasant to recall in later life that he would expunge them entirely from his memory if that were possible.", "Marcel Proust"],
  ["There is no religion without love, and people may talk as much as they like about their religion, but if it does not teach them to be good and kind to other animals as well as humans, it is all a sham.", "Anna Sewell"],
  ["There is no remedy so easy as books, which if they do not give cheerfulness, at least restore quiet to the most troubled mind.", "Lady Mary Wortley Montagu"],
  ["There is no rule more invariable than that we are paid for our suspicions by finding what we suspect.", "Henry David Thoreau"],
  ["There is no such thing as a 'self-made' man. We are made up of thousands of others. Everyone who has ever done a kind deed for us, or spoken one word of encouragement to us, has entered into the make-up of our character and of our thoughts.", "George Matthew Adams"],
  ["There is no such thing as not voting: you either vote by voting, or you vote by staying home and tacitly doubling the value of some diehard's vote.", "David Foster Wallace"],
  ["There is no surer way to misread any document than to read it literally.", "Learned Hand"],
  ["There is no terror in the bang, only in the anticipation of it.", "Alfred Hitchcock"],
  ["There is not less wit nor less invention in applying rightly a thought one finds in a book, than in being the first author of that thought.", "Pierre Bayle"],
  ["There is not such a cradle of democracy upon the earth as the Free Public Library, this republic of letters, where neither rank, office, nor wealth receives the slightest consideration.", "Andrew Carnegie"],
  ["There is nothing like desire for preventing the things one says from bearing any resemblance to what one has in one's mind.", "Marcel Proust"],
  ["There is nothing like returning to a place that remains unchanged to find the ways in which you yourself have altered.", "Nelson Mandela"],
  ["There is nothing more agreeable in life than to make peace with the Establishment - and nothing more corrupting.", "A.J.P. Taylor"],
  ["There is nothing more dangerous than a government of the many controlled by the few.", "Lawrence Lessig"],
  ["There is nothing so agonizing to the fine skin of vanity as the application of a rough truth.", "Edward Bulwer-Lytton"],
  ["There is nothing so despicable as a secret society that is based upon religious prejudice and that will attempt to defeat a man because of his religion.", "William Howard Taft"],
  ["There is nothing so useless as doing efficiently that which should not be done at all.", "Peter Drucker"],
  ["There is nothing worse than a sharp image of a fuzzy concept.", "Ansel Adams"],
  ["There is one art of which man should be master, the art of reflection.", "Samuel Taylor Coleridge"],
  ["There is one art, no more, no less: to do all things with artlessness.", "Piet Hein"],
  ["There is only one difference between a madman and me. The madman thinks he is sane. I know I am mad.", "Salvador Dali"],
  ["There is only one way to achieve happiness on this terrestrial ball, and that is to have either a clear conscience or none at all.", "Ogden Nash"],
  ["There is pleasure in the pathless woods, / There is rapture in the lonely shore, / There is society where none intrudes, / By the deep sea, and music in its roar: / I love not man the less, but nature more.", "Lord Byron"],
  ["There is something beautiful about all scars of whatever nature. A scar means the hurt is over, the wound is closed and healed, done with.", "Harry Crews"],
  ["There is then creative reading as well as creative writing. When the mind is braced by labor and invention, the page of whatever book we read becomes luminous with manifold allusion.", "Ralph Waldo Emerson"],
  ["There is wisdom in turning as often as possible from the familiar to the unfamiliar: it keeps the mind nimble, it kills prejudice, and it fosters humor.", "George Santayana"],
  ["There is, nevertheless, a certain respect and a general duty of humanity that ties us, not only to beasts that have life and sense, but even to trees and plants.", "Michel de Montaigne"],
  ["There lives more faith in honest doubt, / Believe me, than in half the creeds.", "Alfred, Lord Tennyson"],
  ["There may be times when we are powerless to prevent injustice, but there must never be a time when we fail to protest.", "Elie Wiesel"],
  ["There was never a genius without a tincture of madness.", "Aristotle"],
  ["There will be no Homeland Security until we realize that the entire planet is our homeland. Every sentient being in the world must feel secure.", "John Perkins"],
  ["There's a schizoid quality to our relationship with animals, in which sentiment and brutality exist side by side. Half the dogs in America will receive Christmas presents this year, yet few of us pause to consider the miserable life of the pig - an animal easily as intelligent as a dog - that becomes the Christmas ham.", "Michael Pollan"],
  ["There's never been a true war that wasn't fought between two sets of people who were certain they were in the right. The really dangerous people believe they are doing whatever they are doing solely and only because it is without question the right thing to do. And that is what makes them dangerous.", "Neil Gaiman"],
  ["There's no money in poetry, but then there's no poetry in money, either.", "Robert Graves"],
  ["There's no sauce in the world like hunger.", "Miguel de Cervantes"],
  ["There's nothing that keeps its youth, / So far as I know, but a tree and truth.", "Oliver Wendell Holmes, Sr"],
  ["There's nothing that makes you so aware of the improvisation of human existence as a song unfinished. Or an old address book.", "Carson McCullers"],
  ["There's really no such thing as the 'voiceless'. There are only the deliberately silenced or the preferably unheard.", "Arundhati Roy"],
  ["There's so much pollution in the air now that if it weren't for our lungs there'd be no place to put it all.", "Robert Orben"],
  ["These are not books, lumps of lifeless paper, but minds alive on the shelves.", "Gilbert Highet"],
  ["They are happy men whose natures sort with their vocations.", "Francis Bacon"],
  ["They are ill discoverers that think there is no land, when they can see nothing but sea.", "Francis Bacon"],
  ["They deem him their worst enemy who tells them the truth.", "Plato"],
  ["They defend their errors as if they were defending their inheritance.", "Edmund Burke"],
  ["They have a right to censure, that have a heart to help: The rest is cruelty, not justice.", "William Penn"],
  ["They know enough who know how to learn.", "Henry Adams"],
  ["They laughed when I said I was going to be a comedian. They're not laughing now.", "Bob Monkhouse"],
  ["They that can give up essential liberty to obtain a little temporary safety deserve neither liberty nor safety.", "Benjamin Franklin"],
  ["They were so strong in their beliefs that there came a time when it hardly mattered what exactly those beliefs were; they all fused into a single stubbornness.", "Louise Erdrich"],
  ["They're too close to the trees to see the forest. People in California or New York understand that Alaska is not so big. They live in places where the wilderness once seemed limitless, but they know it disappears.", "Edgar Wayburn"],
  ["Think for yourself and question authority.", "Timothy Leary"],
  ["Think like a wise man but communicate in the language of the people.", "William Butler Yeats"],
  ["Think not those faithful who praise all thy words and actions; but those who kindly reprove thy faults.", "Socrates"],
  ["Thinking is an experimental dealing with small quantities of energy, just as a general moves miniature figures over a map before setting his troops in action.", "Sigmund Freud"],
  ["This above all: to thine own self be true, / And it must follow, as the night the day, / Thou canst not then be false to any man.", "William Shakespeare"],
  ["This country has come to feel the same when Congress is in session as when the baby gets hold of a hammer.", "Will Rogers"],
  ["This country will not be a permanently good place for any of us to live in unless we make it a reasonably good place for all of us to live in.", "Theodore Roosevelt"],
  ["This grand show is eternal. It is always sunrise somewhere; the dew is never all dried at once; a shower is forever falling; vapor is ever rising. Eternal sunrise, eternal sunset, eternal dawn and gloaming, on sea and continents and islands, each in its turn, as the round earth rolls.", "John Muir"],
  ["This is his first punishment, that by the verdict of his own heart no guilty man is acquitted.", "Juvenal"],
  ["This is my living faith, an active faith, a faith of verbs: to question, explore, experiment, experience, walk, run, dance, play, eat, love, learn, dare, taste, touch, smell, listen, argue, speak, write, read, draw, provoke, emote, scream, sin, repent, cry, kneel, pray, bow, rise, stand, look, laugh, cajole, create, confront, confound, walk back, walk forward, circle, hide, and seek. To seek: to embrace the questions, be wary of answers.", "Terry Tempest Williams"],
  ["This is the devilish thing about foreign affairs: they are foreign and will not always conform to our whim.", "James Reston"],
  ["This world is divided roughly into three kinds of nations: those that spend lots of money to keep their weight down; those whose people eat to live; and those whose people don't know where their next meal is coming from.", "David S. Landes"],
  ["Those who are incapable of committing great crimes do not readily suspect them in others.", "François de La Rochefoucauld"],
  ["Those who bring sunshine to the lives of others cannot keep it from themselves.", "James Matthew Barrie"],
  ["Those who can make you believe absurdities can make you commit atrocities.", "Voltaire"],
  ["Those who cannot forgive others break the bridge over which they themselves must pass.", "Confucius"],
  ["Those who cannot remember the past are condemned to repeat it.", "George Santayana"],
  ["Those who dream by day are cognizant of many things which escape those who dream only by night.", "Edgar Allan Poe"],
  ["Those who insist on the dignity of their office show they have not deserved it.", "Baltasar Gracian"],
  ["Those who know how to win are much more numerous than those who know how to make proper use of their victories.", "Polybius"],
  ["Those who know nothing of foreign languages know nothing of their own.", "Johann Wolfgang von Goethe"],
  ["Those who never retract their opinions love themselves more than they love truth.", "Joseph Joubert"],
  ["Those who profess to favor freedom and yet deprecate agitation, are men who want crops without plowing the ground.", "Frederick Douglass"],
  ["Those who put out the people's eyes, reproach them for their blindness.", "John Milton"],
  ["Those who will not reason, are bigots, those who cannot, are fools, and those who dare not, are slaves.", "Lord Byron"],
  ["Those who write clearly have readers, those who write obscurely have commentators.", "Albert Camus"],
  ["Though boys throw stones at frogs in sport, the frogs die not in sport, but in earnest.", "Bion of Borysthenes"],
  ["Though familiarity may not breed contempt, it takes off the edge of admiration.", "William Hazlitt"],
  ["Though force can protect in emergency, only justice, fairness, consideration and cooperation can finally lead men to the dawn of eternal peace.", "Dwight D. Eisenhower"],
  ["Though I have been trained as a soldier, and participated in many battles, there never was a time when, in my opinion, some way could not be found to prevent the drawing of the sword. I look forward to an epoch when a court, recognized by all nations, will settle international differences.", "Ulysses S. Grant"],
  ["Though we travel the world over to find the beautiful, we must carry it with us or we find it not.", "Ralph Waldo Emerson"],
  ["Thought is the blossom; language the bud; action the fruit behind it.", "Ralph Waldo Emerson"],
  ["Thoughts are the shadows of our feelings - always darker, emptier, and simpler.", "Friedrich Nietzsche"],
  ["Thousands of tired, nerve-shaken, over-civilized people are beginning to find out that going to the mountains is going home; that wildness is a necessity; and that mountain parks and reservations are useful not only as fountains of timber and irrigating rivers, but as fountains of life.", "John Muir"],
  ["Three grand essentials to happiness in this life are something to do, something to love, and something to hope for.", "Joseph Addison"],
  ["Three passions, simple but overwhelmingly strong, have governed my life: the longing for love, the search for knowledge, and unbearable pity for the suffering of mankind.", "Bertrand Russell"],
  ["Throw your dream into space like a kite, and you do not know what it will bring back, a new life, a new friend, a new love, or a new country.", "Anais Nin"],
  ["Time changes all things: there is no reason why language should escape this universal law.", "Ferdinand de Saussure"],
  ["Time engraves our faces with all the tears we have not shed.", "Natalie Clifford Barney"],
  ["Time has a wonderful way of weeding out the trivial.", "Richard Ben Sapir"],
  ["Time has no divisions to mark its passage, there is never a thunderstorm or blare of trumpets to announce the beginning of a new month or year. Even when a new century begins it is only we mortals who ring bells and fire off pistols.", "Thomas Mann"],
  ["Time is a dressmaker specializing in alterations.", "Faith Baldwin"],
  ["Time is the coin of your life. It is the only coin you have, and only you can determine how it will be spent. Be careful lest you let other people spend it for you.", "Carl Sandburg"],
  ["Time is the fairest and toughest judge.", "Edgar Quinet"],
  ["Time wears away error and polishes truth.", "Pierre-Marc-Gaston de Lévis"],
  ["To a clear eye the smallest fact is a window through which the infinite may be seen.", "Thomas Henry Huxley"],
  ["To a father growing old, nothing is dearer than a daughter.", "Euripides"],
  ["To a poet, silence is an acceptable response, even a flattering one.", "Colette"],
  ["To announce that there must be no criticism of the President, or that we are to stand by the President, right or wrong, is not only unpatriotic and servile, but is morally treasonable to the American public.", "Theodore Roosevelt"],
  ["To array a man's will against his sickness is the supreme art of medicine.", "Henry Ward Beecher"],
  ["To be able under all circumstances to practice five things constitutes perfect virtue; these five things are gravity, generosity of soul, sincerity, earnestness and kindness.", "Confucius"],
  ["To be an American is about something more than what we look like, or what our last names are, or how we worship.", "Barack Obama"],
  ["To be capable of embarrassment is the beginning of moral consciousness. Honor grows from qualms.", "John Leonard"],
  ["To be nobody but yourself - in a world which is doing its best, night and day, to make you everybody else - means to fight the hardest battle which any human being can fight, and never stop fighting.", "E.E. Cummings"],
  ["To be patriotic, hate all nations but your own; to be religious, all sects but your own; to be moral, all pretences but your own.", "Lionel Strachey"],
  ["To be sure, the dog is loyal. But why, on that account, should we take him as an example? He is loyal to man, not to other dogs.", "Karl Kraus"],
  ["To be well informed, one must read quickly a great number of merely instructive books. To be cultivated, one must read slowly and with a lingering appreciation the comparatively few books that have been written by men who lived, thought, and felt with style.", "Aldous Huxley"],
  ["To be without some of the things you want is an indispensable part of happiness.", "Bertrand Russell"],
  ["To blame the poor for subsisting on welfare has no justice unless we are also willing to judge every rich member of society by how productive he or she is. Taken individual by individual, it is likely that there's more idleness and abuse of government favors among the economically privileged than among the ranks of the disadvantaged.", "Norman Mailer"],
  ["To confront a person with his own shadow is to show him his own light.", "Carl G. Jung"],
  ["To cultivate kindness is a valuable part of the business of life.", "Samuel Johnson"],
  ["To delight in war is a merit in the soldier, a dangerous quality in the captain, and a positive crime in the statesman.", "George Santayana"],
  ["To die for an idea; it is unquestionably noble. But how much nobler it would be if men died for ideas that were true.", "H.L. Mencken"],
  ["To do great work a man must be very idle as well as very industrious.", "Samuel Butler"],
  ["To do nothing is sometimes a good remedy.", "Hippocrates"],
  ["To do the opposite of something is also a form of imitation.", "Georg Christoph Lichtenberg"],
  ["To fall in love is to create a religion that has a fallible god.", "Jorge Luis Borges"],
  ["To feed men and not to love them is to treat them as if they were barnyard cattle. To love them and not respect them is to treat them as if they were household pets.", "Mencius"],
  ["To find a person who will love you for no reason, and to shower that person with reasons, that is the ultimate happiness.", "Robert Brault"],
  ["To find the universal elements enough; to find the air and the water exhilarating; to be refreshed by a morning walk or an evening saunter... to be thrilled by the stars at night; to be elated over a bird's nest or a wildflower in spring - these are some of the rewards of the simple life.", "John Burroughs"],
  ["To freely bloom - that is my definition of success.", "Gerry Spence"],
  ["To fully understand a grand and beautiful thought requires, perhaps, as much time as to conceive it.", "Joseph Joubert"],
  ["To give pleasure to a single heart by a single kind act is better than a thousand head-bowings in prayer.", "Saadi"],
  ["To have and not to give is often worse than to steal.", "Marie von Ebner-Eschenbach"],
  ["To have great poets, there must be great audiences.", "Walt Whitman"],
  ["To himself everyone is immortal; he may know that he is going to die, but he can never know that he is dead.", "Samuel Butler"],
  ["To hurry pain is to leave a classroom still in session. To prolong pain is to remain seated in a vacated classroom and miss the next lesson.", "Yahia Lababidi"],
  ["To keep your marriage brimming, With love in the loving cup, Whenever you're wrong, admit it; Whenever you're right, shut up.", "Ogden Nash"],
  ["To kill time is not murder, it's suicide.", "William James"],
  ["To know another language is to have a second soul.", "Charlemagne"],
  ["To know how to hide one's ability is great skill.", "François de La Rochefoucauld"],
  ["To know how to say what other people only think is what makes men poets and sages; and to dare to say what others only dare to think, makes men martyrs or reformers, or both", "Elizabeth Rundle Charles"],
  ["To know how to say what others only know how to think is what makes men poets or sages; and to dare to say what others only dare to think makes men martyrs or reformers - or both.", "Elizabeth Charles"],
  ["To laugh often and much; to win the respect of intelligent people and the affection of children; to earn the appreciation of honest critics and endure the betrayal of false friends; to appreciate beauty; to find the best in others; to leave the world a bit better, whether by a healthy child, a garden patch or a redeemed social condition; to know even one life has breathed easier because you have lived. This is to have succeeded.", "Ralph Waldo Emerson"],
  ["To learn who rules over you, simply find out whom you are not allowed to criticize.", "Voltaire"],
  ["To listen to some devout people, one would imagine that God never laughs.", "Aurobindo Ghose"],
  ["To live content with small means; to seek elegance rather than luxury, and refinement rather than fashion; to be worthy, not respectable, and wealthy, not rich; to listen to stars and birds, babes and sages, with open heart; to study hard; to think quietly, act frankly, talk gently, await occasions, hurry never; in a word, to let the spiritual, unbidden and unconscious, grow up through the common--this is my symphony.", "William Henry Channing"],
  ["To love is to admire with the heart; to admire is to love with the mind.", "Theophile Gautier"],
  ["To love oneself is the beginning of a lifelong romance.", "Oscar Wilde"],
  ["To love truth for truth's sake is the principal part of human perfection in this world, and the seed-plot of all other virtues.", "John Locke"],
  ["To move freely you must be deeply rooted.", "Bella Lewitzky"],
  ["To my mind to kill in war is not a whit better than to commit ordinary murder.", "Albert Einstein"],
  ["To own a bit of ground, to scratch it with a hoe, to plant seeds and watch their renewal of life - this is the commonest delight of the race, the most satisfactory thing a man can do.", "Charles Dudley Warner"],
  ["To profess to be doing God's will is a form of megalomania.", "Joseph Prescott"],
  ["To read fast is as bad as to eat in a hurry.", "Vilhelm Ekelund"],
  ["To read is to translate, for no two persons' experiences are the same. A bad reader is like a bad translator: he interprets literally when he ought to paraphrase and paraphrases when he ought to interpret literally.", "W.H. Auden"],
  ["To read without reflecting is like eating without digesting.", "Edmund Burke"],
  ["To reason with poorly chosen words is like using a pair of scales with inaccurate weights.", "André Maurois"],
  ["To resist the frigidity of old age one must combine the body, the mind and the heart - and to keep them in parallel vigor one must exercise, study and love.", "Karl Viktor von Bonstetten"],
  ["To see a world in a grain of sand, / And a heaven in a wild flower, / Hold infinity in the palm of your hand, / And eternity in an hour.", "William Blake"],
  ["To see ourselves as others see us is a most salutary gift. Hardly less important is the capacity to see others as they see themselves.", "Aldous Huxley"],
  ["To sin by silence when they should protest makes cowards of men.", "Ella Wheeler Wilcox"],
  ["To sit alone in the lamplight with a book spread out before you, and hold intimate converse with men of unseen generations--such is a pleasure beyond compare.", "Kenko Yoshida"],
  ["To suffering there is a limit; to fearing, none.", "Francis Bacon"],
  ["To the artist there is never anything ugly in nature.", "Auguste Rodin"],
  ["To the complaint, 'There are no people in these photographs,' I respond, 'There are always two people: the photographer and the viewer.'", "Ansel Adams"],
  ["To the mind that is still, the whole universe surrenders.", "Lao Tzu"],
  ["To the question whether I am a pessimist or an optimist, I answer that my knowledge is pessimistic, but my willing and hoping are optimistic.", "Albert Schweitzer"],
  ["To understand the heart and mind of a person, look not at what he has already achieved, but at what he aspires to.", "Khalil Gibran"],
  ["To use bitter words, when kind words are at hand is like picking unripe fruit when the ripe fruit is there.", "Thiruvalluvar"],
  ["To want to meet an author because you like his books is as ridiculous as wanting to meet the goose because you like pate de foie gras.", "Arthur Koestler"],
  ["To write that essential book, a great writer does not need to invent it but merely to translate it, since it already exists in each one of us. The duty and task of a writer are those of translator.", "Marcel Proust"],
  ["Today I bent the truth to be kind, and I have no regret, for I am far surer of what is kind than I am of what is true.", "Robert Brault"],
  ["Today you are You, that is truer than true. There is no one alive who is Youer than You.", "Dr. Seuss"],
  ["Tolerably early in life I discovered that one of the unpardonable sins, in the eyes of most people, is for a man to presume to go about unlabelled. The world regards such a person as the police do an unmuzzled dog, not under proper control.", "Thomas Henry Huxley"],
  ["Tomatoes and oregano make it Italian; wine and tarragon make it French. Sour cream makes it Russian; lemon and cinnamon make it Greek. Soy sauce makes it Chinese; garlic makes it good.", "Alice May Brock"],
  ["Too many have dispensed with generosity in order to practice charity.", "Albert Camus"],
  ["Too many parents make life hard for their children by trying, too zealously, to make it easy for them.", "Johann Wolfgang von Goethe"],
  ["Too many people spend money they haven't earned, to buy things they don't want, to impress people they don't like.", "Will Rogers"],
  ["Too much sanity may be madness. And maddest of all, to see life as it is and not as it should be!", "Miguel de Cervantes"],
  ["Too often I would hear men boast of the miles covered that day, rarely of what they had seen.", "Louis L'Amour"],
  ["Too often we enjoy the comfort of opinion without the discomfort of thought.", "John F. Kennedy"],
  ["Too often we underestimate the power of a touch, a smile, a kind word, a listening ear, an honest compliment, or the smallest act of caring, all of which have the potential to turn a life around.", "Leo Buscaglia"],
  ["Translation is the art of erasing oneself in order to speak in another's voice.", "David Cole"],
  ["Transport of the mails, transport of the human voice, transport of flickering pictures - in this century, as in others, our highest accomplishments still have the single aim of bringing men together.", "Antoine de Saint-Exupery"],
  ["Travel is fatal to prejudice, bigotry, and narrow-mindedness.", "Mark Twain"],
  ["Traveling is a fool's paradise... I pack my trunk, embrace my friends, embark on the sea and at last wake up in Naples, and there besides me is the stern fact, the sad self, unrelenting, identical, that I fled from.", "Ralph Waldo Emerson"],
  ["Trees are the earth's endless effort to speak to the listening heaven.", "Rabindranath Tagore"],
  ["True compassion is more than flinging a coin to a beggar; it is not haphazard and superficial. It comes to see that an edifice that produces beggars needs restructuring.", "Martin Luther King, Jr"],
  ["True happiness consists not in the multitude of friends, but in their worth and choice.", "Samuel Johnson"],
  ["True religion is the life we lead, not the creed we profess.", "Louis Nizer"],
  ["True remorse is never just a regret over consequences; it is a regret over motive.", "Mignon McLaughlin"],
  ["True teachers are those who use themselves as bridges over which they invite their students to cross; then, having facilitated their crossing, joyfully collapse, encouraging them to create their own.", "Nikos Kazantzakis"],
  ["Trust is the first step to love.", "Munshi Premchand"],
  ["Truth does not change according to our ability to stomach it.", "Flannery O'Connor"],
  ["Truth has no special time of its own. Its hour is now - always.", "Albert Schweitzer"],
  ["Truth is not only violated by falsehood; it may be equally outraged by silence.", "Henri Frederic Amiel"],
  ["Truth shines the brighter clad in verse.", "Jonathan Swift"],
  ["Truth, in matters of religion, is simply the opinion that has survived.", "Oscar Wilde"],
  ["Truth, like gold, is to be obtained not by its growth, but by washing away from it all that is not gold.", "Leo Tolstoy"],
  ["Truth-tellers are not always palatable. There is a preference for candy bars.", "Gwendolyn Brooks"],
  ["Try to learn something about everything and everything about something.", "Thomas Henry Huxley"],
  ["Trying to determine what is going on in the world by reading newspapers is like trying to tell the time by watching the second hand of a clock.", "Ben Hecht"],
  ["Twin Mystery. To many people artists seem / undisciplined and lawless. / Such laziness, with such great gifts, / seems little short of crime. / One mystery is how they make / the things they make so flawless; / another, what they're doing with / their energy and time.", "Piet Hein"],
  ["Ultimately, literature is nothing but carpentry. ... With both you are working with reality, a material just as hard as wood.", "Gabriel García Márquez"],
  ["Ultimately, the only power to which man should aspire is that which he exercises over himself.", "Elie Wiesel"],
  ["Under democracy one party always devotes its chief energies to trying to prove that the other party is unfit to rule - and both commonly succeed, and are right.", "H.L. Mencken"],
  ["Understand this, I mean to arrive at the truth. The truth, however ugly in itself, is always curious and beautiful to seekers after it.", "Agatha Christie"],
  ["Understanding a person does not mean condoning; it only means that one does not accuse him as if one were God or a judge placed above him.", "Erich Fromm"],
  ["Uneasy lies the head that wears a crown.", "William Shakespeare"],
  ["Union and co-operation in war obviously increase the power of the individual a thousand fold. Is there the shadow of a reason why they should not produce equal effects in peace; why the principle of co-operation should not give to men the same superior powers, and advantages, (and much greater) in the creation, preservation, distribution, and enjoyment of wealth?", "Robert Owen"],
  ["Unless a good deed is voluntary, it has no moral significance.", "Everett Dean Martin"],
  ["Unless someone like you cares a whole awful lot, nothing is going to get better. It's not.", "Dr. Seuss"],
  ["Unthinking respect for authority is the greatest enemy of truth.", "Albert Einstein"],
  ["Until he extends the circle of his compassion to all living things, man will not himself find peace.", "Albert Schweitzer"],
  ["Until it is kindled by a spirit as flamingly alive as the one which gave it birth a book is dead to us. Words divested of their magic are but dead hieroglyphs.", "Henry Miller"],
  ["Until you dig a hole, you plant a tree, you water it and make it survive, you haven't done a thing. You are just talking.", "Wangari Muta Maathai"],
  ["Until you've lost your reputation, you never realize what a burden it was or what freedom really is.", "Margaret Mitchell"],
  ["Use the talents you possess, for the woods would be a very silent place if no birds sang except the best.", "Henry van Dyke"],
  ["Useless laws weaken the necessary laws.", "Charles de Montesquieu"],
  ["Uttering a word is like striking a note on the keyboard of the imagination.", "Ludwig Wittgenstein"],
  ["Vague and insignificant forms of speech, and abuse of language have so long passed for mysteries of science; and hard and misapplied words, with little or no meaning, have, by prescription, such a right to be mistaken for deep learning and height of speculation, that it will not be easy to persuade either those who speak or those who hear them, that they are but the covers of ignorance, and hindrance of true knowledge.", "John Locke"],
  ["Vanity made the revolution; liberty was only a pretext.", "Napoleon Bonaparte"],
  ["Very few established institutions, governments and constitutions ... are ever destroyed by their enemies until they have been corrupted and weakened by their friends.", "Walter Lippmann"],
  ["Vocations which we wanted to pursue, but didn't, bleed, like colors, on the whole of our existence.", "Honore de Balzac"],
  ["Walking is also an ambulation of mind.", "Gretel Ehrlich"],
  ["Walking is man's best medicine.", "Hippocrates"],
  ["Wandering in a vast forest at night, I have only a faint light to guide me. A stranger appears and says to me: 'My friend, you should blow out your candle in order to find your way more clearly.' The stranger is a theologian.", "Denis Diderot"],
  ["Wanting to meet an author because you like his work is like wanting to meet a duck because you like paté.", "Margaret Atwood"],
  ["War is an instrument entirely inefficient toward redressing wrong; and multiplies, instead of indemnifying losses.", "Thomas Jefferson"],
  ["War is delightful to those who have had no experience of it.", "Desiderius Erasmus"],
  ["War is God's way of teaching Americans geography.", "Ambrose Bierce"],
  ["War is merely the continuation of policy by other means.", "Carl von Clausewitz"],
  ["War is so unjust and ugly that all who wage it must try to stifle the voice of conscience within themselves.", "Leo Tolstoy"],
  ["War is terrorism, magnified a hundred times.", "Howard Zinn"],
  ["War is the unfolding of miscalculations.", "Barbara Tuchman"],
  ["War loses a great deal of romance after a soldier has seen his first battle.", "John Singleton Mosby"],
  ["War may sometimes be a necessary evil. But no matter how necessary, it is always an evil, never a good. We will not learn how to live together in peace by killing each other's children.", "Jimmy Carter"],
  ["War will exist until that distant day when the conscientious objector enjoys the same reputation and prestige that the warrior does today.", "John F. Kennedy"],
  ["War will never cease until babies begin to come into the world with larger cerebrums and smaller adrenal glands.", "H.L. Mencken"],
  ["War would end if the dead could return.", "Stanley Baldwin"],
  ["War, at first, is the hope that one will be better off; next, the expectation that the other fellow will be worse off; then, the satisfaction that he isn't any better off; and, finally, the surprise at everyone's being worse off.", "Karl Kraus"],
  ["Wars are the side-effects of nationalism.", "Yahia Lababidi"],
  ["Wars damage the civilian society as much as they damage the enemy. Soldiers never get over it.", "Paul Fussell"],
  ["We aim above the mark to hit the mark.", "Ralph Waldo Emerson"],
  ["We all have handicaps. The difference is that some of us must reveal ours, while others must conceal theirs, to be treated with mercy.", "Yahia Lababidi"],
  ["We all have our time machines. Some take us back, they're called memories. Some take us forward, they're called dreams.", "Jeremy Irons"],
  ["We all have to rise in the end, not just one or two who were smart enough, had will enough for their own salvation, but all the halt, the maimed, and the blind of us which is most of us.", "Maureen Duffy"],
  ["We all live under the same sky, but we don't all have the same horizon.", "Konrad Adenauer"],
  ["We all love animals. Why do we call some \"pets\" and others \"dinner?\"", "k.d. lang"],
  ["We all should know that diversity makes for a rich tapestry, and we must understand that all the threads of the tapestry are equal in value no matter what their color.", "Maya Angelou"],
  ["We all too often have socialism for the rich and rugged free market capitalism for the poor.", "Martin Luther King, Jr"],
  ["We all travel the milky way together, trees and men; but it never occurred to me until this storm-day, while swinging in the wind, that trees are travelers in the ordinary sense. They make many journeys, not extensive ones, it is true; but our own little journeys, away and back again, are only little more than tree-wavings - many of them not so much.", "John Muir"],
  ["We all wear masks, and the time comes when we cannot remove them without removing some of our own skin.", "Andre Berthiaume"],
  ["We allow our ignorance to prevail upon us and make us think we can survive alone, alone in patches, alone in groups, alone in races, even alone in genders.", "Maya Angelou"],
  ["We are a landscape of all we have seen.", "Isamu Noguchi"],
  ["We are all atheists about most of the gods that societies have ever believed in. Some of us just go one god further.", "Richard Dawkins"],
  ["We are all of us more or less echoes, repeating involuntarily the virtues, the defects, the movements, and the characters of those among whom we live.", "Joseph Joubert"],
  ["We are all sculptors and painters, and our material is our own flesh and bones.", "Henry David Thoreau"],
  ["We are all tattooed in our cradles with the beliefs of our tribe; the record may seem superficial, but it is indelible. You cannot educate a man wholly out of the superstitious fears which were implanted in his imagination, no matter how utterly his reason may reject them.", "Oliver Wendell Holmes, Sr"],
  ["We are far more concerned about the desecration of the flag than we are about the desecration of our land.", "Wendell Berry"],
  ["We are for the most part more lonely when we go abroad among men than when we stay in our chambers. A man thinking or working is always alone, let him be where he will.", "Henry David Thoreau"],
  ["We are healed of a suffering only by expressing it to the full.", "Marcel Proust"],
  ["We are like tenant farmers chopping down the fence around our house for fuel when we should be using Nature's inexhaustible sources of energy - sun, wind and tide. ... I'd put my money on the sun and solar energy. What a source of power! I hope we don't have to wait until oil and coal run out before we tackle that.", "Thomas Edison"],
  ["We are never more discontented with others than when we are discontented with ourselves.", "Henri Frederic Amiel"],
  ["We are not afraid to entrust the American people with unpleasant facts, foreign ideas, alien philosophies, and competitive values. For a nation that is afraid to let its people judge the truth and falsehood in an open market is a nation that is afraid of its people.", "John F. Kennedy"],
  ["We are not retreating - we are advancing in another direction.", "Douglas MacArthur"],
  ["We are not the same persons this year as last; nor are those we love. It is a happy chance if we, changing, continue to love a changed person.", "William Somerset Maugham"],
  ["We are on the cusp of this time where I can say, \"I speak as a citizen of the world\" without others saying, \"God, what a nut.\"", "Lawrence Lessig"],
  ["We are reformers in spring and summer; in autumn and winter we stand by the old - reformers in the morning, conservatives at night. Reform is affirmative, conservatism is negative; conservatism goes for comfort, reform for truth.", "Ralph Waldo Emerson"],
  ["We are so accustomed to disguise ourselves to others that in the end we become disguised to ourselves.", "François de La Rochefoucauld"],
  ["We are so fond of being out among nature, because it has no opinions about us.", "Friedrich Nietzsche"],
  ["We are so vain that we even care for the opinion of those we don't care for.", "Marie von Ebner-Eschenbach"],
  ["We are social creatures to the inmost centre of our being. The notion that one can begin anything at all from scratch, free from the past, or unindebted to others, could not conceivably be more wrong.", "Karl Popper"],
  ["We are usually convinced more easily by reasons we have found ourselves than by those which have occurred to others.", "Blaise Pascal"],
  ["We boil at different degrees.", "Ralph Waldo Emerson"],
  ["We call them dumb animals, and so they are, for they cannot tell us how they feel, but they do not suffer less because they have no words.", "Anna Sewell"],
  ["We can be knowledgeable with other men's knowledge but we cannot be wise with other men's wisdom.", "Michel de Montaigne"],
  ["We can pay our debt to the past by putting the future in debt to ourselves.", "John Buchan"],
  ["We can put television in its proper light by supposing that Gutenberg's great invention had been directed at printing only comic books.", "Robert M. Hutchins"],
  ["We can't solve problems by using the same kind of thinking we used when we created them.", "Albert Einstein"],
  ["We cannot defend freedom abroad by deserting it at home.", "Edward R. Murrow"],
  ["We confess our little faults to persuade people that we have no large ones.", "François de La Rochefoucauld"],
  ["We could never learn to be brave and patient, if there were only joy in the world.", "Helen Keller"],
  ["We despise all reverences and all objects of reverence which are outside the pale of our list of sacred things. And yet, with strange inconsistency, we are shocked when other people despise and defile the things which are holy to us.", "Mark Twain"],
  ["We do not err because truth is difficult to see. It is visible at a glance. We err because this is more comfortable.", "Aleksandr Solzhenitsyn"],
  ["We don't need to eat anyone who would run, swim, or fly away if he could.", "James Cromwell"],
  ["We don't understand life any better at forty than at twenty, but we know it and admit it.", "Jules Renard"],
  ["We either make ourselves happy or miserable. The amount of work is the same.", "Carlos Castaneda"],
  ["We establish no religion in this country. We command no worship. We mandate no belief, nor will we ever. Church and state are and must remain separate.", "Ronald Reagan"],
  ["We find comfort among those who agree with us, growth among those who don't.", "Frank A. Clark"],
  ["We grow tyrannical fighting tyranny. The most alarming spectacle today is not the spectacle of the atomic bomb in an unfederated world, it is the spectacle of the Americans beginning to accept the device of loyalty oaths and witchhunts, beginning to call anybody they don't like a Communist.", "E.B. White"],
  ["We hand folks over to God's mercy, and show none ourselves.", "George Eliot (Mary Ann Evans)"],
  ["We have abundant reason to rejoice, that, in this land, the light of truth and reason has triumphed over the power of bigotry and superstition, and that every person may here worship God according to the dictates of his own heart. In this enlightened age, and in this land of equal liberty, it is our boast, that a man's religious tenets will not forfeit the protection of the laws, nor deprive him of the right of attaining and holding the highest offices that are known in the United States.", "George Washington"],
  ["We have come to a point where it is loyalty to resist, and treason to submit.", "Carl Schurz"],
  ["We have enslaved the rest of the animal creation, and have treated our distant cousins in fur and feathers so badly that beyond doubt, if they were able to formulate a religion, they would depict the Devil in human form.", "William R. Inge"],
  ["We have in fact, two kinds of morality, side by side: one which we preach, but do not practice, and another which we practice, but seldom preach.", "Bertrand Russell"],
  ["We have just enough religion to make us hate but not enough to make us love one another.", "Jonathan Swift"],
  ["We have met the enemy and he is us.", "Walt Kelly"],
  ["We have not passed that subtle line between childhood and adulthood until we move from the passive voice to the active voice - that is, until we have stopped saying \"It got lost,\" and say, \"I lost it.\"", "Sydney J. Harris"],
  ["We have probed the earth, excavated it, burned it, ripped things from it, buried things in it, chopped down its forests, leveled its hills, muddied its waters, and dirtied its air. That does not fit my definition of a good tenant. If we were here on a month-to-month basis, we would have been evicted long ago.", "Rose Bird"],
  ["We have, I fear, confused power with greatness.", "Stewart I. Udall"],
  ["We haven't yet learned how to stay human when assembled in masses.", "Lewis Thomas"],
  ["We in America do not have government by the majority. We have government by the majority who participate.", "Thomas Jefferson"],
  ["We judge ourselves by what we feel capable of doing, while others judge us by what we have already done.", "Henry Wadsworth Longfellow"],
  ["We know now that a man can read Goethe or Rilke in the evening, that he can play Bach and Schubert, and go to his day's work at Auschwitz in the morning.", "George Steiner"],
  ["We lie the loudest when we lie to ourselves.", "Eric Hoffer"],
  ["We live in a world in which we need to share responsibility. It's easy to say \"It's not my child, not my community, not my world, not my problem.\" Then there are those who see the need and respond. I consider those people my heroes.", "Fred Rogers"],
  ["We love flattery, even though we are not deceived by it, because it shows that we are of importance enough to be courted.", "Ralph Waldo Emerson"],
  ["We meet no Stranger, but Ourself.", "Emily Dickinson"],
  ["We must be willing to let go of the life we have planned, so as to have the life that is waiting for us.", "E.M. Forster"],
  ["We must believe in luck. For how else can we explain the success of those we don't like?", "Jean Cocteau"],
  ["We must dissent from the fear, the hatred, and the mistrust. We must dissent from a nation that buried its head in the sand waiting in vain for the needs of its poor, its elderly, and its sick to disappear and just blow away. We must dissent from a government that has left its young without jobs, education, or hope. We must dissent from the poverty of vision and timeless absence of moral leadership. We must dissent, because America can do better, because America has no choice but to do better.", "Thurgood Marshall"],
  ["We must embrace pain and burn it as fuel for our journey.", "Kenji Miyazawa"],
  ["We must learn to honor excellence in every socially accepted human activity, however humble the activity, and to scorn shoddiness, however exalted the activity. An excellent plumber is infinitely more admirable than an incompetent philosopher. The society that scorns excellence in plumbing because plumbing is a humble activity and tolerates shoddiness in philosophy because it is an exalted activity will have neither good plumbing nor good philosophy. Neither its pipes nor its theories will hold water.", "John W. Gardner"],
  ["We must learn to regard people less in the light of what they do or omit to do, and more in the light of what they suffer.", "Dietrich Bonhoeffer"],
  ["We must not be frightened nor cajoled into accepting evil as deliverance from evil. We must go on struggling to be human, though monsters of abstractions police and threaten us.", "Robert Hayden"],
  ["We must not confuse dissent with disloyalty.", "Edward R. Murrow"],
  ["We must respect the other fellow's religion, but only in the same sense and to the extent that we respect his theory that his wife is beautiful and his children smart.", "H.L. Mencken"],
  ["We must, however, acknowledge, as it seems to me, that man with all his noble qualities...still bears in his bodily frame the indelible stamp of his lowly origin.", "Charles Darwin"],
  ["We now know that memories are not fixed or frozen, like Proust's jars of preserves in a larder, but are transformed, disassembled, reassembled, and recategorized with every act of recollection.", "Oliver Sacks"],
  ["We often forgive those who bore us, but we cannot forgive those whom we bore.", "François de La Rochefoucauld"],
  ["We open our mouths and out flow words whose ancestries we do not even know. We are walking lexicons. In a single sentence of idle chatter we preserve Latin, Anglo-Saxon, Norse: we carry a museum inside our heads, each day we commemorate peoples of whom we have never heard.", "Penelope Lively"],
  ["We perceive when love begins and when it declines by our embarrassment when alone together.", "Jean de la Bruyere"],
  ["We require from buildings, as from men, two kinds of goodness: first, the doing their practical duty well: then that they be graceful and pleasing in doing it; which last is itself another form of duty.", "John Ruskin"],
  ["We saw men haying far off in the meadow, their heads waving like the grass which they cut. In the distance the wind seemed to bend all alike.", "Henry David Thoreau"],
  ["We shall never cease from exploration / And the end of all our exploring / Will be to arrive where we started / And know the place for the first time.", "T.S. Eliot"],
  ["We shall succeed only so far as we continue that most distasteful of all activity, the intolerable labor of thought.", "Learned Hand"],
  ["We should be careful to get out of an experience only the wisdom that is in it - and stop there - lest we be like the cat that sits down on a hot stove-lid. She will never sit down on a hot stove-lid again, and that is well; but also she will never sit down on a cold one any more.", "Mark Twain"],
  ["We should be slower to think that the man at his worst is the real man, and certain that the better we are ourselves the less likely is he to be at his worst in our company. Every time he talks away his own character before us he is signifying contempt for ours.", "James M. Barrie"],
  ["We should have a great many fewer disputes in the world if words were taken for what they are, the signs of our ideas, and not for things themselves.", "John Locke"],
  ["We should manage our fortunes as we do our health - enjoy it when good, be patient when it is bad, and never apply violent remedies except in an extreme necessity.", "François de La Rochefoucauld"],
  ["We should measure affection, not like youngsters by the ardour of its passion, but by its strength and constancy.", "Marcus Tullius Cicero"],
  ["We should not be simply fighting evil in the name of good, but struggling against the certainties of people who claim always to know where good and evil are to be found.", "Tzvetan Todorov"],
  ["We should not write so that it is possible for the reader to understand us, but so that it is impossible for him to misunderstand us.", "Quintilian (Marcus Fabius Quintilianus)"],
  ["We should tackle reality in a slightly jokey way, otherwise we miss its point.", "Lawrence Durrell"],
  ["We should try to be the parents of our future rather than the offspring of our past.", "Miguel de Unamuno"],
  ["We sometimes congratulate ourselves at the moment of waking from a troubled dream; it may be so the moment after death.", "Nathaniel Hawthorne"],
  ["We take our colors, chameleon-like, from each other.", "Sebastien-Roch Nicolas de Chamfort"],
  ["We tend to overestimate the effect of a technology in the short run and underestimate the effect in the long run.", "Roy Amara"],
  ["We think caged birds sing, when indeed they cry.", "John Webster"],
  ["We understand death for the first time when he puts his hand upon one whom we love.", "Madame de Stael"],
  ["We who lived in concentration camps can remember the men who walked through the huts comforting others, giving away their last piece of bread. They may have been few in number, but they offer sufficient proof that everything can be taken from a man but one thing: the last of the human freedoms - to choose one's attitude in any given set of circumstances, to choose one's own way.", "Viktor Frankl"],
  ["We will bankrupt ourselves in the vain search for absolute security.", "Dwight D. Eisenhower"],
  ["We're here to put a dent in the universe.", "Steve Jobs"],
  ["We've all got both light and dark inside us. What matters is the part we choose to act on. That's who we really are.", "J.K. Rowling"],
  ["Wealth has never yet sacrificed itself on the altar of patriotism.", "Bob LaFollette"],
  ["Wear your learning, like your watch, in a private pocket, and do not pull it out and strike it merely to show you have one. If you are asked what o'clock it is, tell it, but do not proclaim it hourly and unasked, like the watchman.", "Lord Chesterfield"],
  ["Were we to choose our leaders on the basis of their reading experience and not their political programs, there would be much less grief on earth. I believe ... that for someone who has read a lot of Dickens to shoot his like in the name of an idea is harder than for someone who has read no Dickens.", "Joseph Brodsky"],
  ["What a child doesn't receive he can seldom later give.", "P.D. James"],
  ["What a heavy oar the pen is, and what a strong current ideas are to row in!", "Gustave Flaubert"],
  ["What a pitiable thing it is that our civilization can do no better for us than to make us slaves to indoor life, so that we have to go and take artificial exercise in order to preserve our health.", "George Wharton James"],
  ["What a strange illusion it is to suppose that beauty is goodness.", "Leo Tolstoy"],
  ["What a strange machine man is! You fill him with bread, wine, fish, and radishes, and out come sighs, laughter, and dreams.", "Nikos Kazantzakis"],
  ["What can be added to the happiness of a man who is in health, out of debt, and has a clear conscience?", "Adam Smith"],
  ["What can be asserted without evidence can be dismissed without evidence.", "Christopher Hitchens"],
  ["What didn't you do to bury me / But you forgot that I was a seed.", "Dinos Christianopoulos"],
  ["What difference does it make to the dead, the orphans, and the homeless, whether the mad destruction is wrought under the name of totalitarianism or the holy name of liberty or democracy?", "Mahatma Gandhi"],
  ["What do we live for, if it is not to make life less difficult to each other?", "George Eliot (Mary Ann Evans)"],
  ["What for centuries raised man above the beast is not the cudgel but the irresistible power of unarmed truth.", "Boris Pasternak"],
  ["What humbugs we are, who pretend to live for Beauty, and never see the Dawn!", "Logan Pearsall Smith"],
  ["What I like in a good author isn't what he says, but what he whispers.", "Logan Pearsall Smith"],
  ["What I stand for is what I stand on.", "Wendell Berry"],
  ["What I want to happen to religion in the future is this: I want it to be like bowling. It's a hobby, something some people will enjoy, that has some virtues to it, that will have its own institutions and its traditions and its own television programming, and that families will enjoy together. It's not something I want to ban or that should affect hiring and firing decisions, or that interferes with public policy. It will be perfectly harmless as long as we don't elect our politicians on the basis of their bowling score, or go to war with people who play nine-pin instead of ten-pin, or use folklore about backspin to make decrees about how biology works.", "PZ Myers"],
  ["What information consumes is rather obvious: it consumes the attention of its recipients. Hence a wealth of information creates a poverty of attention, and a need to allocate that attention efficiently among the overabundance of information sources that might consume it.", "Herbert Alexander Simon"],
  ["What is art but a way of seeing?", "Thomas Berger"],
  ["What is art? Nature concentrated.", "Honore de Balzac"],
  ["What is called discretion in men is called cunning in animals.", "Jean de la Fontaine"],
  ["What is freedom of expression? Without the freedom to offend, it ceases to exist.", "Salman Rushdie"],
  ["What is laid down, ordered, factual is never enough to embrace the whole truth: life always spills over the rim of every cup.", "Boris Pasternak"],
  ["What is life? It is the flash of a firefly in the night. It is the breath of a buffalo in the wintertime. It is the little shadow which runs across the grass and loses itself in the sunset.", "Crowfoot"],
  ["What is morally wrong can never be advantageous, even when it enables you to make some gain that you believe to be to your advantage. The mere act of believing that some wrongful course of action constitutes an advantage is pernicious.", "Marcus Tullius Cicero"],
  ["What is philosophy but a continual battle against custom?", "Thomas Carlyle"],
  ["What is reading, but silent conversation.", "Walter Savage Landor"],
  ["What is the purpose of the giant sequoia tree? The purpose of the giant sequoia tree is to provide shade for the tiny titmouse.", "Edward Abbey"],
  ["What is the use of a house if you haven't got a tolerable planet to put it on?", "Henry David Thoreau"],
  ["What is to give light must endure burning.", "Viktor Frankl"],
  ["What is wanted is not the will to believe, but the will to find out, which is the exact opposite.", "Bertrand Russell"],
  ["What loneliness is more lonely than distrust?", "George Eliot (Mary Ann Evans)"],
  ["What matters is not the idea a man holds, but the depth at which he holds it.", "Ezra Pound"],
  ["What men call social virtues, good fellowship, is commonly but the virtue of pigs in a litter, which lie close together to keep each other warm.", "Henry David Thoreau"],
  ["What monstrosities would walk the streets were some people's faces as unfinished as their minds.", "Eric Hoffer"],
  ["What power has love but forgiveness?", "William Carlos Williams"],
  ["What progress we are making. In the Middle Ages they would have burned me. Now they are content with burning my books.", "Sigmund Freud"],
  ["What really flatters a man is that you think him worth flattering.", "George Bernard Shaw"],
  ["What really knocks me out is a book that, when you're all done reading it, you wish the author that wrote it was a terrific friend of yours and you could call him up on the phone whenever you felt like it.", "J.D. Salinger"],
  ["What religion a man shall have is a historical accident, quite as much as what language he shall speak.", "George Santayana"],
  ["What sane person could live in this world and not be crazy?", "Ursula K. Le Guin"],
  ["What the mind doesn't understand, it worships or fears.", "Alice Walker"],
  ["What the object of senile avarice may be I cannot conceive. For can there be anything more absurd than to seek more journey money, the less there remains of the journey?", "Marcus Tullius Cicero"],
  ["What we hope ever to do with ease, we must learn first to do with diligence.", "Samuel Johnson"],
  ["What we think, or what we know, or what we believe, is in the end, of little consequence. The only thing of consequence is what we do.", "John Ruskin"],
  ["What wisdom can you find that is greater than kindness?", "Jean Jacques Rousseau"],
  ["What you are thunders so that I cannot hear what you say to the contrary.", "Ralph Waldo Emerson"],
  ["What you cannot enforce, do not command.", "Sophocles"],
  ["What you do is of little significance; but it is very important that you do it.", "Mahatma Gandhi"],
  ["What you get out depends on what you put in; and as the grandest mill in the world will not extract wheat-flour from peascods, so pages of formulae will not get a definite result out of loose data.", "Thomas Henry Huxley"],
  ["What's done to children, they will do to society.", "Karl A. Menninger"],
  ["What's madness but nobility of soul at odds with circumstance?", "Theodore Roethke"],
  ["Whatever a man prays for, he prays for a miracle. Every prayer reduces itself to this: Great God, grant that twice two be not four.", "Ivan Turgenev"],
  ["When a man is wrapped up in himself he makes a pretty small package.", "John Ruskin"],
  ["When a nation gives birth to a man who is able to produce a great thought, another is born who is able to understand and admire it.", "Joseph Joubert"],
  ["When a thing is funny, search it carefully for a hidden truth.", "George Bernard Shaw"],
  ["When Alexander the Great visited Diogenes and asked whether he could do anything for the famed teacher, Diogenes replied: 'Only stand out of my light.' Perhaps some day we shall know how to heighten creativity. Until then, one of the best things we can do for creative men and women is to stand out of their light.", "John W. Gardner"],
  ["When an individual is protesting society's refusal to acknowledge his dignity as a human being, his very act of protest confers dignity on him.", "Bayard Rustin"],
  ["When governments fear the people there is liberty. When the people fear the government there is tyranny.", "Thomas Jefferson"],
  ["When I am working on a problem I never think about beauty. I only think about how to solve the problem. But when I have finished, if the solution is not beautiful, I know it is wrong.", "R. Buckminster Fuller"],
  ["When I approach a child, he inspires in me two sentiments; tenderness for what he is, and respect for what he may become.", "Louis Pasteur"],
  ["When I can look Life in the eyes, / Grown calm and very coldly wise, / Life will have given me the Truth, / And taken in exchange - my youth.", "Sara Teasdale"],
  ["When I do good, I feel good; when I do bad, I feel bad. That's my religion.", "Abraham Lincoln"],
  ["When I eventually met Mr. Right I had no idea that his first name was Always.", "Rita Rudner"],
  ["When I feed the hungry, they call me a saint. When I ask why people are hungry, they call me a communist.", "Helder Camara"],
  ["When I feel inclined to read poetry, I take down my dictionary. The poetry of words is quite as beautiful as the poetry of sentences. The author may arrange the gems effectively, but their shape and lustre have been given by the attrition of ages.", "Oliver Wendell Holmes, Sr"],
  ["When I get a little money I buy books; and if any is left I buy food and clothes.", "Desiderius Erasmus"],
  ["When I go into the garden with a spade, and dig a bed, I feel such an exhilaration and health that I discover that I have been defrauding myself all this time in letting others do for me what I should have done with my own hands.", "Ralph Waldo Emerson"],
  ["When I invented the web, I didn't have to ask anyone's permission. Now, hundreds of millions of people are using it freely. I am worried that that is going to end in the USA. ... Democracy depends on freedom of speech. Freedom of connection, with any application, to any party, is the fundamental social basis of the Internet, and, now, the society based on it. Let's see whether the United States is capable of acting according to its important values, or whether it is, as so many people are saying, run by the misguided short-term interest of large corporations. I hope that Congress can protect net neutrality, so I can continue to innovate in the internet space. I want to see the explosion of innovations happening out there on the Web, so diverse and so exciting, continue unabated.", "Tim Berners-Lee"],
  ["When I read some of the rules for speaking and writing the English language correctly, as that a sentence must never end with a particle, and perceive how implicitly even the learned obey it, I think - any fool can make a rule and every fool will mind it.", "Henry David Thoreau"],
  ["When I reflect upon the number of disagreeable people who I know have gone to a better world, I am moved to lead a different life.", "Mark Twain"],
  ["When I was a boy of fourteen, my father was so ignorant I could hardly stand to have the old man around. But when I got to be twenty-one, I was astonished at how much the old man had learned in seven years.", "Mark Twain"],
  ["When I was a kid I used to pray every night for a new bicycle. Then I realized that the Lord doesn't work that way, so I stole one and asked for forgiveness.", "Emo Philips"],
  ["When I was in the military, they gave me a medal for killing two men and a discharge for loving one.", "Leonard Matlovich"],
  ["When I was young, I admired clever people. Now that I am old, I admire kind people.", "Abraham Joshua Heschel"],
  ["When it comes to having a central nervous system, and the ability to feel pain, hunger, and thirst, a rat is a pig is a dog is a boy.", "Ingrid Newkirk"],
  ["When it is dark enough, you can see the stars.", "Ralph Waldo Emerson"],
  ["When Life does not find a singer to sing her heart she produces a philosopher to speak her mind.", "Khalil Gibran"],
  ["When love and skill work together, expect a masterpiece.", "John Ruskin"],
  ["When love is not madness, it is not love.", "Pedro Calderon de la Barca"],
  ["When money speaks, the truth keeps silent.", "Russian proverb"],
  ["When nations grow old, the arts grow cold and commerce settles on every tree.", "William Blake"],
  ["When nothing seems to help, I go and look at a stone-cutter hammering away at his rock perhaps a hundred times without as much as a crack showing in it. Yet at the hundred and first blow it would split in two, and I know it was not that blow that did it, but all that had gone before together.", "Jacob A. Riis"],
  ["When old words die out on the tongue, new melodies break forth from the heart; and where the old tracks are lost, new country is revealed with its wonders.", "Rabindranath Tagore"],
  ["When once the itch of literature comes over a man, nothing can cure it but the scratching of a pen.", "Samuel Lover"],
  ["When one door closes another door opens; but we so often look so long and so regretfully upon the closed door, that we do not see the ones which open for us.", "Alexander Graham Bell"],
  ["When one door of happiness closes, another opens; but often we look so long at the closed door that we do not see the one which has been opened for us.", "Helen Keller"],
  ["When one guy sees an invisible man, he's a nut case; ten people see him, it's a cult; ten million people see him, it's a respected religion.", "Richard Jeni"],
  ["When one has been threatened with a great injustice, one accepts a smaller as a favour.", "Jane Welsh Carlyle"],
  ["When one has too great a dread of what is impending, one feels some relief when the trouble has come.", "Joseph Joubert"],
  ["When others asked the truth of me, I was convinced it was not the truth they wanted, but an illusion they could bear to live with.", "Anais Nin"],
  ["When people are fanatically dedicated to political or religious faiths or any other kind of dogmas or goals, it's always because these dogmas or goals are in doubt.", "Robert M. Pirsig"],
  ["When people tell you how young you look, they are also telling you how old you are.", "Cary Grant"],
  ["When small men begin to cast big shadows, it means that the sun is about to set.", "Lin Yutang"],
  ["When the effective leader is finished with his work, the people say it happened naturally.", "Lao Tzu"],
  ["When the field is nationwide, and the fight must be waged chiefly at second and third hand, and the force of personality cannot so readily make itself felt, then all the odds are on the man who is, intrinsically, the most devious and mediocre - the man who can most easily adeptly disperse the notion that his mind is a virtual vacuum. The Presidency tends, year by year, to go to such men. As democracy is perfected, the office represents, more and more closely, the inner soul of the people. We move toward a lofty ideal. On some great and glorious day the plain folks of the land will reach their heart's desire at last, and the White House will be adorned by a downright moron.", "H.L. Mencken"],
  ["When the last tree is cut, the last fish is caught, and the last river is polluted; when to breathe the air is sickening, you will realize, too late, that wealth is not in bank accounts and that you can't eat money.", "Alanis Obomsawin"],
  ["When the oak is felled the whole forest echoes with its fall, but a hundred acorns are sown in silence by an unnoticed breeze.", "Thomas Carlyle"],
  ["When the power of love overcomes the love of power the world will know peace.", "Jimi Hendrix"],
  ["When two elephants fight it is the grass that gets trampled.", "African proverb"],
  ["When two opposite points of view are expressed with equal intensity, the truth does not necessarily lie exactly halfway between them. It is possible for one side to be simply wrong.", "Richard Dawkins"],
  ["When we enlarge upon the affection our friends have for us, this is very often not so much out of a sense of gratitude as from a desire to persuade people of our own great worth, that can deserve so much kindness.", "François de La Rochefoucauld"],
  ["When we have exposed the specious reasoning of the hunters' apologists and stripped their sport of its counterfeit legitimacy, the naked brutality of hunting defines itself: killing for the fun of it.", "Steve Ruggeri"],
  ["When we try to pick out anything by itself, we find it hitched to everything else in the universe.", "John Muir"],
  ["When will our consciences grow so tender that we will act to prevent human misery rather than avenge it?", "Eleanor Roosevelt"],
  ["When women love us, they forgive us everything, even our crimes; when they do not love us, they give us credit for nothing, not even our virtues.", "Honore de Balzac"],
  ["When work is a pleasure, life is a joy! When work is a duty, life is slavery.", "Maxim Gorky"],
  ["When you battle with your conscience and lose, you win.", "Henny Youngman"],
  ["When you enjoy loving your neighbor it ceases to be a virtue.", "Khalil Gibran"],
  ["When you have eliminated the impossible, whatever remains, however improbable, must be the truth.", "Arthur Conan Doyle"],
  ["When you re-read a classic, you do not see more in the book than you did before; you see more in yourself than there was before.", "Clifton Fadiman"],
  ["When you say that you agree to a thing in principle, you mean that you have not the slightest intention of carrying it out.", "Otto von Bismarck"],
  ["When you see a man led to prison say in your heart, \"Mayhap he is escaping from a narrower prison.\" And when you see a man drunken say in your heart, \"Mayhap he sought escape from something still more unbeautiful.\"", "Khalil Gibran"],
  ["When you sell a man a book you don't sell him just twelve ounces of paper and ink and glue--you sell him a whole new life.", "Christopher Morley"],
  ["When you think of the long and gloomy history of man, you will find more hideous crimes have been committed in the name of obedience than have ever been committed in the name of rebellion.", "C.P. Snow"],
  ["When you turn the corner / And you run into yourself / Then you know that you have turned / All the corners that are left.", "Langston Hughes"],
  ["When you want to fool the world, tell the truth.", "Otto von Bismarck"],
  ["When you're traveling, you are what you are right there and then. People don't have your past to hold against you. No yesterdays on the road.", "William Least Heat-Moon"],
  ["Whenever a man has cast a longing eye on offices, a rottenness begins in his conduct.", "Thomas Jefferson"],
  ["Whenever anyone has offended me, I try to raise my soul so high that the offense cannot reach it.", "Rene Descartes"],
  ["Whenever books are burned men also in the end are burned.", "Heinrich Heine"],
  ["Whenever morality is based on theology, whenever right is made dependent on divine authority, the most immoral, unjust, infamous things can be justified and established.", "Ludwig Feuerbach"],
  ["Whenever people say \"We mustn't be sentimental,\" you can take it they are about to do something cruel. And if they add \"We must be realistic,\" they mean they are going to make money out of it.", "Brigid Brophy"],
  ["Whenever two people meet, there are really six people present. There is each man as he sees himself, each man as the other person sees him, and each man as he really is.", "William James"],
  ["Whenever you commend, add your reasons for doing so; it is this which distinguishes the approbation of a man of sense from the flattery of sycophants and admiration of fools.", "Richard Steele"],
  ["Whenever you're called on to make up your mind, / and you're hampered by not having any, / the best way to solve the dilemma, you'll find, / is simply by spinning a penny. / No - not so that chance shall decide the affair / while you're passively standing there moping; / but the moment the penny is up in the air, / you suddenly know what you're hoping.", "Piet Hein"],
  ["Where is the Life we have lost in living? Where is the wisdom we have lost in knowledge? Where is the knowledge we have lost in information?", "T.S. Eliot"],
  ["Where it is a duty to worship the sun, it is pretty sure to be a crime to examine the laws of heat.", "John Morley"],
  ["Where the light is brightest, the shadows are deepest.", "Johann Wolfgang von Goethe"],
  ["Wherever you have an efficient government you have a dictatorship.", "Harry S Truman"],
  ["Which of us is not forever a stranger and alone?", "Thomas Wolfe"],
  ["While fame impedes and constricts, obscurity wraps about a man like a mist; obscurity is dark, ample, and free; obscurity lets the mind take its way unimpeded. Over the obscure man is poured the merciful suffusion of darkness. None knows where he goes or comes. He may seek the truth and speak it; he alone is free; he alone is truthful, he alone is at peace.", "Virginia Woolf"],
  ["While grief is fresh, every attempt to divert only irritates. You must wait till it be digested, and then amusement will dissipate the remains of it.", "Samuel Johnson"],
  ["While language is forming, writers are applauded for extending its limits; when established, for restricting themselves to them.", "Isaac Disraeli"],
  ["While we are asleep in this world, we are awake in another one.", "Salvador Dali"],
  ["Who breaks the thread, the one who pulls, the one who holds on?", "James Richardson"],
  ["Who has not for the sake of his reputation sacrificed himself?", "Friedrich Nietzsche"],
  ["Who is more to be pitied, a writer bound and gagged by policemen or one living in perfect freedom who has nothing more to say?", "Kurt Vonnegut, Jr"],
  ["Who knows what Columbus would have discovered if America hadn't got in the way.", "Stanisław J. Lec"],
  ["Who overcomes by force hath overcome but half his foe.", "John Milton"],
  ["Who will consider that no dictionary of a living tongue ever can be perfect, since, while it is hastening to publication, some words are budding, and some falling away; that a whole life cannot be spent upon syntax and etymology, and that even a whole life would not be sufficient; that he, whose design includes whatever language can express, must often speak of what he does not understand.", "Samuel Johnson"],
  ["Who, being loved, is poor?", "Oscar Wilde"],
  ["Whoe'er excels in what we prize / Appears a hero in our eyes.", "Jonathan Swift"],
  ["Whoever imagines himself a favorite with God holds others in contempt.", "Robert Green Ingersoll"],
  ["Whoever, in the pursuit of science, seeks after immediate practical utility, may generally rest assured that he will seek in vain.", "H.L.F. von Helmholtz"],
  ["Why are so many people shy, lonely, shut up within themselves, unequal to their tasks, unable to be happy? Because they are inhabited by fear, like the man in the Parable of the Talents, erecting walls around themselves instead of building bridges into the lives of others; shutting out life.", "Joseph Fort Newton"],
  ["Why does no one confess his sins? Because he is yet in them. It is for a man who has awoke from sleep to tell his dreams.", "Lucius Annaeus Seneca"],
  ["Why is it that one can look at a lion or a planet or an owl or at someone's finger as long as one pleases, but looking into the eyes of another person is, if prolonged past a second, a perilous affair?", "Walker Percy"],
  ["Why is it that we rejoice at a birth and grieve at a funeral? It is because we are not the person involved.", "Mark Twain"],
  ["Why should I fear death? If I am, death is not. If death is, I am not. Why should I fear that which cannot exist when I do?", "Epicurus"],
  ["Why should I give them my mind as well?", "Dalai Lama", "when asked why he wasn't angry at the Chinese for taking over his country"],
  ["Wild animals never kill for sport. Man is the only one to whom the torture and death of his fellow creatures is amusing in itself.", "James Anthony Froude"],
  ["Wise sayings often fall on barren ground; but a kind word is never thrown away.", "Arthur Helps"],
  ["Wit is educated insolence.", "Aristotle"],
  ["Wit makes its own welcome, and levels all distinctions. No dignity, no learning, no force of character, can make any stand against good wit.", "Ralph Waldo Emerson"],
  ["With respect to the number of deaths, arms manufacturers are charitable institutions compared to cigarette manufacturers.", "Silvio Garattini"],
  ["Within one linear centimeter of your lower colon there lives and works more bacteria (about 100 billion) than all humans who have ever been born. Yet many people continue to assert that it is we who are in charge of the world.", "Neil deGrasse Tyson"],
  ["Without books the development of civilization would have been impossible. They are the engines of change, windows on the world, \"Lighthouses\" as the poet said \"erected in the sea of time.\" They are companions, teachers, magicians, bankers of the treasures of the mind, Books are humanity in print.", "Arthur Schopenhauer"],
  ["Without deviation from the norm, progress is not possible.", "Frank Zappa"],
  ["Without the freedom to criticize, there is no true praise.", "Pierre Beaumarchais"],
  ["Won't you come into the garden? I would like my roses to see you.", "Richard Brinsley Sheridan"],
  ["Woodman, spare that tree! / Touch not a single bough! / In youth it sheltered me, / And I'll protect it now.", "George Pope Morris"],
  ["Words - so innocent and powerless as they are, as standing in a dictionary, how potent for good and evil they become in the hands of one who knows how to combine them.", "Nathaniel Hawthorne"],
  ["Words / as slippery as smooth grapes, / words exploding in the light / like dormant seeds waiting / in the vaults of vocabulary, / alive again, and giving life: / once again the heart distills them.", "Pablo Neruda"],
  ["Words are a commodity in which there is never any slump.", "Christopher Morley"],
  ["Words are a mirror of their times. By looking at the areas in which the vocabulary of a language is expanding fastest in a given period, we can form a fairly accurate impression of the chief preoccupations of society at that time and the points at which the boundaries of human endeavour are being advanced.", "John Ayto"],
  ["Words are also actions, and actions are a kind of words.", "Ralph Waldo Emerson"],
  ["Words are chameleons, which reflect the color of their environment.", "Learned Hand"],
  ["Words are like leaves; and where they most abound / Much fruit of sense beneath is rarely found.", "Alexander Pope"],
  ["Words are like money ... it is the stamp of custom alone that gives them circulation or value.", "William Hazlitt"],
  ["Words are like money; there is nothing so useless, unless when in actual use.", "Samuel Butler"],
  ["Words are loaded pistols.", "Jean-Paul Sartre"],
  ["Words are not created by academics in universities and suchlike, rather it is the man in the street who does so. Dictionary compilers almost always recognise them too late and embalm them in alphabetical order, in many cases when they have lost their original meaning.", "Gabriel García Márquez"],
  ["Words are the small change of thought.", "Jules Renard"],
  ["Words are the soul's ambassadors, who go / Abroad upon her errands to and fro.", "James Howell"],
  ["Words are things; and a small drop of ink / Falling like dew upon a thought, produces / That which makes thousands, perhaps millions, think.", "Lord Byron"],
  ["Words are timeless. You should utter them or write them with a knowledge of their timelessness.", "Khalil Gibran"],
  ["Words differently arranged have different meanings, and meanings differently arranged have a different effect.", "Blaise Pascal"],
  ["Words fascinate me. They always have. For me, browsing in a dictionary is like being turned loose in a bank.", "Eddie Cantor"],
  ["Words form the thread on which we string our experiences.", "Aldous Huxley"],
  ["Words ought to be a little wild, for they are the assault of thoughts on the unthinking.", "John Maynard Keynes"],
  ["Words strain, / Crack and sometimes break, under the burden, / Under the tension, slip, slide, perish, / Decay with imprecision, will not stay in place, / Will not stay still.", "T.S. Eliot"],
  ["Words, like eyeglasses, obscure everything they do not make clear.", "Joseph Joubert"],
  ["Words, like Nature, half reveal and half conceal the Soul within.", "Alfred, Lord Tennyson"],
  ["Words, when written, crystallize history; their very structure gives permanence to the unchangeable past.", "Francis Bacon"],
  ["Work is of two kinds: first, altering the position of matter at or near the earth's surface relative to other matter; second, telling other people to do so. The first is unpleasant and ill-paid; the second is pleasant and highly paid.", "Bertrand Russell"],
  ["Work on good prose has three steps: a musical stage when it is composed, an architectonic one when it is built, and a textile one when it is woven.", "Walter Benjamin"],
  ["Work saves us from three great evils: boredom, vice and need.", "Voltaire"],
  ["Worry is interest paid on trouble before it comes due.", "William R. Inge"],
  ["Worth begets in base minds, envy; in great souls, emulation.", "Henry Fielding"],
  ["Would the boy you were be proud of the man you are?", "Laurence J. Peter"],
  ["Writers, like teeth, are divided into incisors and grinders.", "Walter Bagehot"],
  ["Writing for a penny a word is ridiculous. If a man really wanted to make a million dollars, the best way to do it would be start his own religion.", "L. Ron Hubbard"],
  ["Writing is a way of talking without being interrupted.", "Jules Renard"],
  ["Writing is like driving a car at night. You can only see as far as the headlights, but you make the whole trip that way.", "E.L. Doctorow"],
  ["Writing is thinking on paper.", "William Zinsser"],
  ["Writing the last page of the first draft is the most enjoyable moment in writing. It's one of the most enjoyable moments in life, period.", "Nicholas Sparks"],
  ["Writing, when properly managed, (as you may be sure I think mine is) is but a different name for conversation.", "Laurence Sterne"],
  ["Written laws are like spider's webs; they will catch, it is true, the weak and the poor, but would be torn in pieces by the rich and powerful.", "Anacharsis"],
  ["Wrongs are often forgiven, but contempt never is. Our pride remembers it forever.", "Lord Chesterfield"],
  ["Yesterday we obeyed kings and bent our necks before emperors. But today we kneel only to truth, follow only beauty, and obey only love.", "Khalil Gibran"],
  ["You are never too old to be what you might have been.", "George Eliot (Mary Ann Evans)"],
  ["You become writer by writing. It is a yoga.", "R.K. Narayan"],
  ["You campaign in poetry. You govern in prose.", "Mario Cuomo"],
  ["You can discover more about a person in an hour of play than in a year of discussion.", "Plato"],
  ["You can discover what your enemy fears most by observing the means he uses to frighten you.", "Eric Hoffer"],
  ["You can never solve a problem on the level on which it was created.", "Albert Einstein"],
  ["You can never understand one language until you understand at least two.", "Ronald Searle"],
  ["You can only protect your liberties in this world by protecting the other man's freedom. You can only be free if I am free.", "Clarence Darrow"],
  ["You can out-distance that which is running after you, but not what is running inside you.", "Rwandan proverb"],
  ["You can preach a better sermon with your life than with your lips.", "Oliver Goldsmith"],
  ["You can safely assume that you've created God in your own image when it turns out that God hates all the same people you do.", "Anne Lamott"],
  ["You can sometimes count every orange on a tree but never all the trees in a single orange.", "A.K. Ramanujan"],
  ["You can tell the ideals of a nation by its advertisements.", "Norman Douglas"],
  ["You can tell whether a man is clever by his answers. You can tell whether a man is wise by his questions.", "Naguib Mahfouz"],
  ["You can't be suspicious of a tree, or accuse a bird or a squirrel of subversion or challenge the ideology of a violet.", "Hal Borland"],
  ["You can't do anything with anybody's body to make it dirty to me. Six people, eight people, one person - you can do only one thing to make it dirty: kill it. Hiroshima was dirty.", "Lenny Bruce"],
  ["You can't say civilization isn't advancing, in every war they kill you in a new way.", "Will Rogers"],
  ["You can't shame or humiliate modern celebrities. What used to be called shame and humiliation is now called publicity.", "P.J. O'Rourke"],
  ["You can't turn back the clock. But you can wind it up again.", "Bonnie Prudden"],
  ["You can't wake a person who is pretending to be asleep.", "Navajo proverb"],
  ["You cannot shake hands with a clenched fist.", "Indira Gandhi"],
  ["You come into the world alone and you go out of the world alone, yet it seems to me you are more alone while living than even going and coming.", "Emily Carr"],
  ["You desire to know the art of living, my friend? It is contained in one phrase: make use of suffering.", "Henri Frederic Amiel"],
  ["You do not examine legislation in the light of the benefits it will convey if properly administered, but in the light of the wrongs it would do and the harms it would cause if improperly administered.", "Lyndon B. Johnson"],
  ["You have just dined, and however scrupulously the slaughterhouse is concealed in the graceful distance of miles, there is complicity.", "Ralph Waldo Emerson"],
  ["You have not converted a man because you have silenced him.", "John Morley"],
  ["You have not lived today until you have done something for someone who can never repay you.", "John Bunyan"],
  ["You have reached the pinnacle of success as soon as you become uninterested in money, compliments, or publicity.", "Thomas Wolfe"],
  ["You have to fall in love with hanging around words.", "John Ciardi"],
  ["You have to hold your audience in writing to the very end - much more than in talking, when people have to be polite and listen to you.", "Brenda Ueland"],
  ["You have your brush, you have your colors, you paint paradise, then in you go.", "Nikos Kazantzakis"],
  ["You know what getting married is? It's agreeing to taking this person who right now is at the top of his form, full of hopes and ideas, feeling good, looking good, wildly interested in you because you're the same way, and sticking by him while he slowly disintegrates. And he does the same for you. You're his responsibility now and he's yours. If no one else will take care of him, you will. If everyone else rejects you, he won't. What do you think love is? Going to bed all the time?", "Jane Smiley"],
  ["You may call God love, you may call God goodness. But the best name for God is compassion.", "Meister Eckhart"],
  ["You may not be able to change the world, but at least you can embarrass the guilty.", "Jessica Mitford"],
  ["You may shoot me with your words, / You may cut me with your eyes, / You may kill me with your hatefulness, / But still, like air, I'll rise.", "Maya Angelou"],
  ["You must accept the truth from whatever source it comes.", "Moses ben Maimon"],
  ["You must have been warned against letting the golden hours slip by. Yes, but some of them are golden only because we let them slip by.", "James M. Barrie"],
  ["You must not lose faith in humanity. Humanity is an ocean; if a few drops of the ocean are dirty, the ocean does not become dirty.", "Mahatma Gandhi"],
  ["You must protest / It is your diamond duty / Ah but in such an ugly time the true protest is beauty.", "Phil Ochs"],
  ["You never know till you try to reach them how accessible men are; but you must approach each man by the right door.", "Henry Ward Beecher"],
  ["You never really understand a person until you consider things from his point of view.", "Harper Lee"],
  ["You only have power over people as long as you don't take everything away from them. But when you've robbed a man of everything, he's no longer in your power - he's free again.", "Aleksandr Solzhenitsyn"],
  ["You shall judge of a man by his foes as well as by his friends.", "Joseph Conrad"],
  ["You take your life in your own hands, and what happens? A terrible thing: no one to blame.", "Erica Jong"],
  ["You talk when you cease to be at peace with your thoughts.", "Khalil Gibran"],
  ["You will find relief from vain fancies if you do every act in life as though it were your last.", "Marcus Aurelius"],
  ["You will find that silence or very gentle words are the most exquisite revenge for insult.", "Peter W. Hall"],
  ["You write to communicate to the hearts and minds of others what's burning inside you. And we edit to let the fire show through the smoke.", "Arthur Plotnik"],
  ["You've got to do your own growing, no matter how tall your grandfather was.", "Irish proverb"],
  ["You've got to have something to eat and a little love in your life before you can hold still for any damn body's sermon on how to behave.", "Billie Holiday"],
  ["Your assumptions are your windows on the world. Scrub them off every once in a while, or the light won't come in.", "Alan Alda"],
  ["Your children need your presence more than your presents.", "Jesse Jackson"],
  ["Your neighbor's vision is as true for him as your own vision is true for you.", "Miguel de Unamuno"],
  ["Your only obligation in any lifetime is to be true to yourself. Being true to anyone else or anything else is ... impossible.", "Richard Bach"],
  ["Your pain is the breaking of the shell that encloses your understanding.", "Khalil Gibran"],
  ["Your voice dries up if you don't use it.", "Patti Page"],
  ["Youth is the first victim of war - the first fruit of peace. It takes 20 years or more of peace to make a man; it takes only 20 seconds of war to destroy him.", "Boudewijn I"],
  ["Zen is not a particular state but the normal state: silent, peaceful, unagitated. In Zazen neither intention, analysis, specific effort nor imagination take place. It's enough just to be without hypocrisy, dogmatism, arrogance - embracing all opposites.", "Taisen Deshimaru"],
  ["The real art of conversation is not only to say the right thing at the right place but to leave unsaid the wrong thing at the tempting moment.", "Lady Dorothy Nevill"],
  ["Be humble for you are made of Earth. Be noble for you are made of stars.", "Serbian proverb"],
  ["He who wishes to secure the good of others has already secured his own.", "Confucius"],
  ["Each snowflake in an avalanche pleads not guilty.", "Stanisław J. Lec"],
  ["You live a new life for every new language you speak.", "Czech proverb"],
  ["Language is the apparel in which your thoughts parade in public. Never clothe them in vulgar and shoddy attire.", "George W. Crane"],
  ["A closed mind is like a closed book: just a block of wood.", "Chinese proverb"],
  ["God never occurs to you in person but always in action.", "Mahatma Gandhi"],
  ["Doubt comes in at the window when inquiry is denied at the door.", "Benjamin Jowett"],
  ["What soap is for the body, tears are for the soul.", "Jewish proverb"],
  ["The reality of the other person is not in what he reveals to you, but in what he cannot reveal to you. Therefore, if you would understand him, listen not to what he says but rather what he does not say.", "Khalil Gibran"],
  ["Learning is weightless, a treasure you can always carry easily.", "Chinese proverb"],
  ["Selfish, adj. Devoid of consideration for the selfishness of others.", "Ambrose Bierce"],
  ["The miracle is not that we do this work, but that we are happy to do it.", "Mother Teresa"],
  ["Dissent is what rescues democracy from a quiet death behind closed doors.", "Lewis H. Lapham"],
  ["God gives every bird his worm, but he does not throw it into the nest.", "Swedish proverb"],
  ["The only tyrant I accept in this world is the \"still small voice\" within me.", "Mahatma Gandhi"],
  ["What we are doing to the forests of the world is but a mirror reflection of what we are doing to ourselves and to one another.", "Mahatma Gandhi"],
  ["I have only made this letter longer because I have not had the time to make it shorter.", "Blaise Pascal"],
  ["There is more to life than increasing its speed.", "Mahatma Gandhi"],
  ["First they came for the Socialists, and I did not speak out - Because I was not a Socialist. Then they came for the Trade Unionists, and I did not speak out - Because I was not a Trade Unionist. Then they came for the Jews, and I did not speak out - Because I was not a Jew. Then they came for me - and there was no one left to speak for me.", "Martin Niemoller"],
  ["The greatest discovery of my generation is that human beings can alter their lives by altering their attitudes of mind.", "William James"],
  ["We win justice quickest by rendering justice to the other party.", "Mahatma Gandhi"],
  ["A society grows great when old men plant trees whose shade they know they shall never sit in.", "Greek proverb"],
  ["History is fables agreed upon.", "Voltaire"],
  ["To love is to receive a glimpse of heaven.", "Karen Sunde"],
  ["Culture of the mind must be subservient to the heart.", "Mahatma Gandhi"],
  ["There is nothing more frightful than ignorance in action.", "Johann Wolfgang von Goethe"],
  ["There is nothing with which every man is so afraid as getting to know how enormously much he is capable of doing and becoming.", "Søren Kierkegaard"],
  ["It's difficult to wake one who is only pretending to be asleep.", "Tagalog proverb"],
  ["Pray, v. To ask the laws of the universe to be annulled on behalf of a single petitioner confessedly unworthy.", "Ambrose Bierce"],
  ["If I look confused it's because I'm thinking.", "Samuel Goldwyn"],
  ["Danger and delight grow on one stalk.", "English proverb"],
  ["Well-timed silence is the most commanding expression.", "Mark Helprin"],
  ["I am a kind of paranoiac in reverse. I suspect people of plotting to make me happy.", "J.D. Salinger"],
  ["Speak the truth, then leave quickly.", "Serbian proverb"],
  ["If you think you can do a thing or think you can't do a thing, you're right.", "Henry Ford"],
  ["In the midst of great joy, do not promise anyone anything. In the midst of great anger, do not answer anyone's letter.", "Chinese proverb"],
  ["If the rich could hire someone else to die for them, the poor would make a wonderful living.", "Jewish proverb"],
  ["Judge not the horse by his saddle.", "Chinese proverb"],
  ["To believe in something, and not to live it, is dishonest.", "Mahatma Gandhi"],
  ["One kind word can warm three winter months.", "Japanese proverb"],
  ["My loneliness was born when men praised my talkative faults and blamed my silent virtues.", "Khalil Gibran"],
  ["In matters of conscience, the law of majority has no place.", "Mahatma Gandhi"],
  ["The weak can never forgive. Forgiveness is the attribute of the strong.", "Mahatma Gandhi"],
  ["Until lions have their historians, tales of the hunt shall always glorify the hunter.", "African proverb"],
  ["The truth must dazzle gradually / Or every man be blind.", "Emily Dickinson"],
  ["Pick battles big enough to matter, small enough to win.", "Jonathan Kozol"],
  ["We are all born into the world with nothing. Everything we acquire after that is profit.", "Sam Ewing"],
  ["An invasion of armies can be resisted, but not an idea whose time has come.", "Victor Hugo"],
  ["The believer is happy; the doubter is wise.", "Hungarian proverb"],
  ["Better than a thousand days of diligent study is one day with a great teacher.", "Japanese proverb"],
  ["Television is democracy at its ugliest.", "Paddy Chayefsky"],
  ["When you want to test the depths of a stream, don't use both feet.", "Chinese proverb"],
  ["There is no king who has not had a slave among his ancestors, and no slave who has not had a king among his.", "Helen Keller"],
  ["If I can stop one Heart from breaking / I shall not live in vain / If I can ease one Life the Aching / Or cool one Pain / Or help one fainting Robin / Unto his Nest again / I shall not live in Vain.", "Emily Dickinson"],
  ["Too much consistency is as bad for the mind as for the body.", "Aldous Huxley"],
  ["Man is certainly stark mad: he cannot make a flea, yet he makes gods by the dozens.", "Michel de Montaigne"],
  ["To know the road ahead, ask those coming back.", "Chinese proverb"],
  ["The most common way people give up their power is by thinking they don't have any.", "Alice Walker"],
  ["All humanity is one undivided and indivisible family, and each one of us is responsible for the misdeeds of all the others. I cannot detach myself from the wickedest soul.", "Mahatma Gandhi"],
  ["I do not want the peace that passeth understanding. I want the understanding which bringeth peace.", "Helen Keller"],
  ["Weather is a great bluffer. I guess the same is true of our human society - things can look dark, then a break shows in the clouds, and all is changed.", "E.B. White"],
  ["If you don't find God in the next person you meet, it is a waste of time looking for him further.", "Mahatma Gandhi"],
  ["Beauty, unaccompanied by virtue, is like a flower without fragrance.", "French proverb"],
  ["Computer Science is no more about computers than astronomy is about telescopes.", "Edsger Dijkstra"],
  ["If you don't learn to laugh at troubles, you won't have anything to laugh at when you grow old.", "E.W. Howe"],
  ["Holding on to anger is like grasping a hot coal with the intent of throwing it at someone else; you are the one getting burned.", "Buddha"],
  ["The human mind treats a new idea the same way the body treats a strange protein; it rejects it.", "Peter Medawar"],
  ["Everything considered, work is less boring than amusing oneself.", "Charles Baudelaire"],
  ["Strange that creatures without backbones have the hardest shells.", "Khalil Gibran"],
  ["Associate yourself with men of good quality if you esteem your own reputation; for 'tis better to be alone than in bad company.", "George Washington"],
  ["Just remember - when you think all is lost, the future remains.", "Robert H. Goddard"],
  ["The refusal to choose is a form of choice; disbelief is a form of belief.", "Frank X. Barron"],
  ["A beautiful thing is never perfect.", "Egyptian proverb"],
  ["Today the real test of power is not capacity to make war but capacity to prevent it.", "Anne O'Hare McCormick"],
  ["The big thieves hang the little ones.", "Czech proverb"],
  ["I object to violence because when it appears to do good, the good is only temporary; the evil it does is permanent.", "Mahatma Gandhi"],
  ["All you need in this life is ignorance and confidence, and then success is sure.", "Mark Twain"],
  ["Seven blunders of the world that lead to violence: wealth without work, pleasure without conscience, knowledge without character, commerce without morality, science without humanity, worship without sacrifice, politics without principle.", "Mahatma Gandhi"],
  ["Civilization is the encouragement of differences.", "Mahatma Gandhi"],
  ["A lot of people mistake a short memory for a clear conscience.", "Doug Larson"],
  ["Memoir is not an act of history but an act of memory, which is innately corrupt.", "Mary Karr"],
  ["A man's conscience, like a warning line on the highway, tells him what he shouldn't do - but it does not keep him from doing it.", "Frank A. Clark"],
  ["A bit of fragrance always clings to the hand that gives the rose.", "Chinese proverb"],
  ["All a man can betray is his conscience.", "Joseph Conrad"],
  ["The great tragedy of science - the slaying of a beautiful hypothesis by an ugly fact.", "Thomas Huxley"],
  ["Today's public figures can no longer write their own speeches or books, and there is some evidence that they can't read them either.", "Gore Vidal"],
  ["I used to work in a fire hydrant factory. You couldn't park anywhere near the place.", "Steven Wright"],
  ["We cannot be more sensitive to pleasure without being more sensitive to pain.", "Alan Watts"],
  ["The wild, cruel beast is not behind the bars of the cage. He is in front of it.", "Axel Munthe"],
  ["If you want to build a ship, don't drum up people together to collect wood and don't assign them tasks and work, but rather teach them to long for the endless immensity of the sea.", "Antoine de Saint-Exupery"],
  ["If you want others to be happy, practice compassion. If you want to be happy, practice compassion.", "Dalai Lama"],
  ["If the world were merely seductive, that would be easy. It it were merely challenging, that would be no problem. But I arise in the morning torn between a desire to improve the world and a desire to enjoy the world. This makes it hard to plan the day.", "E.B. White"],
  ["If you want something really important to be done you must not merely satisfy the reason, you must move the heart also.", "Mahatma Gandhi"],
  ["Only put off until tomorrow what you are willing to die having left undone.", "Pablo Picasso"],
  ["We'd all like a reputation for generosity and we'd all like to buy it cheap.", "Mignon McLaughlin"],
  ["The sole meaning of life is to serve humanity.", "Leo Tolstoy"],
  ["The bamboo that bends is stronger than the oak that resists.", "Japanese proverb"],
  ["I will not let anyone walk through my mind with their dirty feet.", "Mahatma Gandhi"],
  ["Nothing in the world can take the place of persistence. Talent will not; nothing is more common than unsuccessful men with talent. Genius will not; unrewarded genius is almost a proverb. Education will not; the world is full of educated derelicts. Persistence and determination alone are omnipotent.", "Calvin Coolidge"],
  ["Observe your enemies, for they first find out your faults.", "Antisthenes"],
  ["Nothing in life is to be feared, it is only to be understood.", "Marie Curie"],
  ["Change your thoughts and you change your world.", "Norman Vincent Peale"],
  ["When I despair, I remember that all through history, the way of truth and love has always won. There have been murderers and tyrants, and for a time they can seem invincible. But in the end they always fall. Think of it, always.", "Mahatma Gandhi"],
  ["The man who strikes first admits that his ideas have given out.", "Chinese proverb"],
  ["An ounce of mother is worth a pound of clergy.", "Spanish proverb"],
  ["There is no pillow so soft as a clear conscience.", "French proverb"],
  ["One is always a long way from solving a problem until one actually has the answer.", "Stephen Hawking"],
  ["No race can prosper till it learns there is as much dignity in tilling a field as in writing a poem.", "Booker T. Washington"],
  ["The true teacher defends his pupils against his own personal influence.", "Amos Bronson Alcott"],
  ["If you want to know what God thinks of money, just look at the people he gave it to.", "Dorothy Parker"],
  ["Happiness is when what you think, what you say, and what you do are in harmony.", "Mahatma Gandhi"],
  ["Love your enemies because they bring out the best in you.", "Friedrich Nietzsche"],
  ["The urge to save humanity is almost always a false front for the urge to rule.", "H.L. Mencken"],
  ["There are two ways to slide easily through life: to believe everything or to doubt everything; both ways save us from thinking.", "Theodore Rubin"],
  ["I will love the light for it shows me the way, Yet I will endure the darkness for it shows me the stars.", "Og Mandino"],
  ["There are many causes that I am prepared to die for but no causes that I am prepared to kill for.", "Mahatma Gandhi"],
  ["A penny will hide the biggest star in the universe if you hold it close enough to your eye.", "Samuel Grafton"],
  ["Nature can provide for the needs of people; [she] can't provide for the greed of people.", "Mahatma Gandhi"],
  ["A committee is a cul-de-sac down which ideas are lured and then quietly strangled.", "Barnett Cocks"],
  ["Punctuality is the politeness of kings.", "Louis XVIII"],
  ["A torn jacket is soon mended; but hard words bruise the heart of a child.", "Henry Wadsworth Longfellow"],
  ["Whoever heard of a hundred thousand animals rushing together to butcher each other, as men do everywhere [during war]?", "Desiderius Erasmus"],
  ["It is well to remember that the entire universe, with one trifling exception, is composed of others.", "John Andrew Holmes"],
  ["One cannot do right in one department of life whilst he is occupied in doing wrong in any other department. Life is one indivisible whole.", "Mahatma Gandhi"],
  ["Music is the only language in which you cannot say a mean or sarcastic thing.", "John Erskine"],
];

// prettier-ignore
export const authors: { [name: string]: string[] } = {
  "A.J.P. Taylor": ["historian", "1906-1990"],
  "A.K. Ramanujan": ["poet", "1929-1993"],
  "Aaron Hill": ["dramatist and writer", "1685-1750"],
  "Abba Agathon": ["monk", "4th/5th century"],
  "Abigail Van Buren": ["advice columnist", "1918-"],
  "Abraham Cowley": ["poet", "1618-1667"],
  "Abraham Flexner": ["educator", "1866-1959"],
  "Abraham Joshua Heschel": ["rabbi and professor", "1907-1972"],
  "Abraham Lincoln": ["United States president", "1809-1865"],
  "Abraham Maslow": ["psychologist", "1908-1970"],
  "Adam Lindsay Gordon": ["poet", "1833-1870"],
  "Adam Smith": ["economist", "1723-1790"],
  "Adlai Stevenson": ["statesman", "1900-1965"],
  "Adrienne Rich": ["writer", "1929-2012"],
  "Agatha Christie": ["author", "1890-1976"],
  "Aharon Barak": ["law professor and former President of the Supreme Court of Israel", "1936-"],
  "Alan Alda": ["actor and director", "1936-"],
  "Alan Kay": ["computer scientist", "1940-"],
  "Alan Watts": ["philosopher, writer and speaker", "1915-1973"],
  "Alanis Morissette": ["musician", "1974-"],
  "Alanis Obomsawin": ["filmmaker", "1932-"],
  "Albert Camus": ["writer, philosopher and Nobel laureate", "1913-1960"],
  "Albert Einstein": ["physicist and Nobel laureate", "1879-1955"],
  "Albert Richard Smith": ["author and entertainer", "1816-1860"],
  "Albert Schweitzer": ["philosopher, physician, musician, Nobel laureate", "1875-1965"],
  "Alden Nowlan": ["poet, novelist and playwright", "1933-1983"],
  "Aldous Huxley": ["writer and critic", "1894-1963"],
  "Aleister Crowley": ["author", "1875-1947"],
  "Alejandro González Iñárritu": ["film director, producer, screenwriter, composer", "1963-"],
  "Aleksandr Pushkin": ["poet, novelist and playwright", "1799-1837"],
  "Aleksandr Solzhenitsyn": ["novelist and Nobel laureate", "1918-2008"],
  "Alexander Graham Bell": ["inventor", "1847-1922"],
  "Alexander Pope": ["poet", "1688-1744"],
  "Alexis de Tocqueville": ["statesman and historian", "1805-1859"],
  "Alfred de Vigny": ["poet, playwright and novelist", "1797-1863"],
  "Alfred Hitchcock": ["film-maker", "1899-1980"],
  "Alfred North Whitehead": ["mathematician and philosopher", "1861-1947"],
  "Alfred, Lord Tennyson": ["poet", "1809-1892"],
  "Alice Childress": ["playwright, author and actor", "1916-1994"],
  "Alice M. Swaim": ["poet", "1911-1996"],
  "Alice May Brock": ["author", "1941-"],
  "Alice Miller": ["psychologist and author", "1923-2010"],
  "Alice Walker": ["poet and novelist", "1944-"],
  "Allen Ginsberg": ["poet", "1926-1997"],
  "Alphonse Daudet": ["writer", "1840-1897"],
  "Alphonse Marie Louis de Prat de Lamartine": ["poet and statesman", "1790-1869"],
  "Amar Bose": ["entrepreneur", "1929-2013"],
  "Ambrose Bierce": ["writer", "1842-1914"],
  "Amelia Barr": ["novelist", "1831-1919"],
  "Amelia Earhart": ["aviator", "1897-1937"],
  "Ammon Hennacy": ["activist", "1893-1970"],
  "Amos Bronson Alcott": ["teacher and author", "1799-1888"],
  "Amschel Mayer Rothschild": ["banker", "1743-1812"],
  "Amy Goodman": ["investigative journalist, columnist and author", "1957-"],
  "Anacharsis": ["philosopher", "6th century BCE"],
  "Anais Nin": ["writer", "1903-1977"],
  "Anatole France": ["novelist, essayist and Nobel laureate", "1844-1924"],
  "Anatoly Liberman": ["professor", "1937-"],
  "Andre Berthiaume": ["novelist", "1938-"],
  "Andre Gide": ["author and Nobel laureate", "1869-1951"],
  "Andrew Carnegie": ["industrialist", "1835-1919"],
  "Andrew Cohen": ["professor of linguistics", "1944-"],
  "Andrew Dickson White": ["diplomat, historian and educator", "1832-1918"],
  "Andrew S. Tanenbaum": ["computer science professor", "1944-"],
  "Andrew Sullivan": ["author and editor", "1963-"],
  "Andrew Young": ["author, civil rights activist, US congressman, mayor, UN ambassador", "1932-"],
  "André Maurois": ["author", "1885-1967"],
  "Andy Rooney": ["journalist", "1919-"],
  "Angela Carter": ["novelist and journalist", "1940-1992"],
  "Ann Beattie": ["novelist", "1947-"],
  "Ann Patchett": ["writer", "1963-"],
  "Anna Letitia Barbauld": ["poet, essayist and editor", "1743-1825"],
  "Anna Sewell": ["writer", "1820-1878"],
  "Anne-Marie Bigot de Cornuel": ["society hostess", "1605-1694"],
  "Anne Bradstreet": ["poet", "1612-1672"],
  "Anne Frank": ["diarist", "1929-1945"],
  "Anne Lamott": ["writer", "1954-"],
  "Anne McCaffrey": ["writer", "1926-2011"],
  "Anne Morrow Lindbergh": ["author and aviator", "1906-2001"],
  "Anne O'Hare McCormick": ["correspondent", "1880-1954"],
  "Annie Dillard": ["author", "1945-"],
  "Annie Laurie Gaylor": ["freethinker and activist", "1955-"],
  "Ansel Adams": ["photographer", "1902-1984"],
  "Anthony Burgess": ["author", "1917-1993"],
  "Anthony Trollope": ["novelist", "1815-1882"],
  "Antisthenes": ["philosopher", "445-365 BCE"],
  "Antoine de Saint-Exupery": ["author and aviator", "1900-1944"],
  "Anton Chekhov": ["short-story writer and dramatist", "1860-1904"],
  "Antonio Porchia": ["poet", "1886-1968"],
  "Archibald MacLeish": ["poet and librarian", "1892-1982"],
  "Ariel Durant": ["writer", "1898-1981"],
  "Aristophanes": ["dramatist", "c. 448-385 BCE"],
  "Aristotle": ["philosopher", "384-322 BCE"],
  "Arne Garborg": ["writer", "1851-1924"],
  "Arnold Bennett": ["novelist", "1867-1931"],
  "Arnold J. Toynbee": ["historian", "1889-1975"],
  "Arthur C. Clarke": ["science fiction writer", "1917-2008"],
  "Arthur Conan Doyle": ["physician and writer", "1859-1930"],
  "Arthur Godfrey": ["television host and entertainer", "1903-1983"],
  "Arthur Helps": ["writer", "1813-1875"],
  "Arthur Koestler": ["novelist and journalist", "1905-1983"],
  "Arthur Lloyd James": ["phonetician", "1884-1943"],
  "Arthur Miller": ["playwright and essayist", "1915-2005"],
  "Arthur Plotnik": ["editor and author", "1937-"],
  "Arthur Schnitzler": ["writer and doctor", "1862-1931"],
  "Arthur Schopenhauer": ["philosopher", "1788-1860"],
  "Arthur Sullivan": ["composer", "1842-1900"],
  "Artur Schnabel": ["pianist", "1882-1951"],
  "Arundhati Roy": ["writer and activist", "1961-"],
  "Ashley Montagu": ["anthropologist and writer", "1905-1999"],
  "Audra McDonald": ["actress and singer", "1970-"],
  "Auguste Rodin": ["sculptor", "1840-1917"],
  "Aurobindo Ghose": ["Indian philosopher", "1872-1950"],
  "Axel Munthe": ["physician", "1857-1949"],
  "B.R. Myers": ["author", "1963-"],
  "B.F. Skinner": ["psychologist", "1904-1990"],
  "Baltasar Gracian": ["philosopher and writer", "1601-1658"],
  "Banksy": ["street artist", "1974-"],
  "Barack Obama": ["United States president", "1961-"],
  "Barbara Ehrenreich": ["journalist and author", "1941-"],
  "Barbara Kingsolver": ["novelist, essayist and poet", "1955-"],
  "Barbara McClintock": ["scientist and Nobel laureate", "1902-1992"],
  "Barbara Tuchman": ["author and historian", "1912-1989"],
  "Barnett Cocks": ["clerk at the House of Commons", "1907-1989"],
  "Barry Jones": ["politician and author", "1932-"],
  "Baruch Spinoza": ["philosopher", "1632-1677"],
  "Bayard Rustin": ["civil rights activist", "1912-1987"],
  "Bella Lewitzky": ["dancer", "1916-2004"],
  "Ben Hecht": ["journalist, screenwriter, playwright, novelist, director, producer", "1894-1964"],
  "Ben Okri": ["poet and novelist", "1959-"],
  "Benito Juárez": ["President of Mexico", "1806-1872"],
  "Benjamin Disraeli": ["British prime minister", "1804-1881"],
  "Benjamin Jowett": ["tutor", "1817-1893"],
  "Benjamin Franklin": ["statesman, author and inventor", "1706-1790"],
  "Benjamin Spock": ["pediatrician and author", "1903-1998"],
  "Bernard Berenson": ["art historian", "1865-1959"],
  "Bernard Malamud": ["novelist and short-story writer", "1914-1986"],
  "Bertrand Russell": ["philosopher, mathematician, author, Nobel laureate", "1872-1970"],
  "Beryl Markham": ["adventurer", "1902-1986"],
  "Bill Bryson": ["author", "1951-"],
  "Bill Hicks": ["comedian and social critic", "1961-1994"],
  "Bill Maher": ["comedian, actor and writer", "1956-"],
  "Bill Moyers": ["journalist", "1934-"],
  "Bill Stern": ["sports announcer", "1907-1971"],
  "Bill Vaughan": ["journalist", "1915-1977"],
  "Bill Watterson": ["comic strip artist", "1958-"],
  "Billie Holiday": ["jazz singer and songwriter", "1915-1959"],
  "Bion of Borysthenes": ["philosopher", "c. 325-250 BCE"],
  "Blaise Pascal": ["philosopher and mathematician", "1623-1662"],
  "Bob Dylan": ["singer-songwriter and Nobel laureate", "1941-"],
  "Bob LaFollette": ["congressman, senator and governor", "1855-1925"],
  "Bob Monkhouse": ["comedian", "1928-2003"],
  "Bobbie Gentry": ["singer and songwriter", "1944-"],
  "Bonnie Friedman": ["author", "1958-"],
  "Bonnie Prudden": ["fitness trainer and author", "1914-"],
  "Bono": ["musician and social activist", "1960-"],
  "Booker T. Washington": ["educator", "1856-1915"],
  "Boris Pasternak": ["poet and novelist", "1890-1960"],
  "Boudewijn I": ["King of Belgium", "1934-1993"],
  "Bradley Miller": ["activist", "1956-"],
  "Brenda Ueland": ["writer", "1891-1985"],
  "Brendan Francis Behan": ["playwright", "1923-1964"],
  "Brendan Gill": ["writer and preservationist", "1914-1997"],
  "Brigid Brophy": ["writer", "1929-1995"],
  "Bruce Springsteen": ["musician", "1949-"],
  "Buddha": ["founder of Buddhism", "c. 566-480 BCE"],
  "C. Northcote Parkinson": ["author and historian", "1909-1993"],
  "C.P. Snow": ["scientist and writer", "1905-1980"],
  "Cabu (pen name of Jean Cabut)": ["cartoonist and co-founder of Charlie Hebdo", "1938-2015"],
  "Calvin Coolidge": ["United States president", "1872-1933"],
  "Calvin Trillin": ["writer", "1935-"],
  "Carl G. Jung": ["psychiatrist", "1875-1961"],
  "Carl Jung": ["psychiatrist", "1875-1961"],
  "Carl Sagan": ["astronomer and writer", "1934-1996"],
  "Carl Sandburg": ["poet and biographer", "1878-1967"],
  "Carl Schurz": ["revolutionary, statesman and reformer", "1829-1906"],
  "Carl Van Doren": ["professor, writer and critic", "1885-1950"],
  "Carl von Clausewitz": ["general and military theorist", "1780-1831"],
  "Carlo Dossi": ["author and diplomat", "1849-1910"],
  "Carlos Castaneda": ["mystic and author", "1925-1998"],
  "Carlos Fuentes": ["novelist and essayist", "1928-2012"],
  "Carlos Santana": ["musician", "1947-"],
  "Carson McCullers": ["writer", "1917-1967"],
  "Cary Grant": ["actor", "1904-1986"],
  "Cathy Ladman": ["comedian, writer and actress", "1955-"],
  "Cato the Elder": ["statesman and writer", "234-149 BCE"],
  "Cesar Chavez": ["farm worker and activist", "1927-1993"],
  "Cesare Beccaria": ["philosopher and politician", "1738-1794"],
  "Charan Singh": ["mystic", "1916-1990"],
  "Charlemagne": ["King of the Franks", "742-814"],
  "Charles A. Beard": ["historian", "1874-1948"],
  "Charles A. Lindbergh": ["aviator and author", "1902-1974"],
  "Charles Augustin Sainte-Beuve": ["literary critic", "1804-1869"],
  "Charles Babbage": ["mathematician and computer scientist", "1791-1871"],
  "Charles Baudelaire": ["poet", "1821-1867"],
  "Charles Bukowski": ["writer", "1920-1994"],
  "Charles Buxton": ["brewer, philanthropist, writer, politician", "1823-1871"],
  "Charles Caleb Colton": ["author and clergyman", "1780-1832"],
  "Charles Darwin": ["naturalist and author", "1809-1882"],
  "Charles de Lint": ["writer and folk musician", "1951-"],
  "Charles de Montesquieu": ["philosopher and writer", "1689-1755"],
  "Charles Dickens": ["novelist", "1812-1870"],
  "Charles Dudley Warner": ["editor and author", "1829-1900"],
  "Charles Evans Hughes": ["jurist and statesman", "1862-1948"],
  "Charles F. Kettering": ["inventor and engineer", "1876-1958"],
  "Charles John Darling": ["lawyer, judge and politician", "1849-1936"],
  "Charles Lamb": ["essayist and critic", "1775-1834"],
  "Charles Mayo": ["physician and founder of the Mayo Clinic", "1865-1939"],
  "Charles Peguy": ["poet and essayist", "1873-1914"],
  "Charles Reznikoff": ["poet", "1894-1976"],
  "Charles Schulz": ["cartoonist", "1922-2000"],
  "Charles Simic": ["poet", "1938-"],
  "Charles W. Eliot": ["educator", "1834-1926"],
  "Charlie Chaplin": ["actor, director and composer", "1889-1977"],
  "Chief Joseph": ["leader of the Nez Perce Nation", "1840-1904"],
  "Chinua Achebe": ["writer and professor", "1930-2013"],
  "Chris Evert": ["tennis player", "1954-"],
  "Chris Hedges": ["journalist, author and war correspondent", "1956-"],
  "Christian Bovee": ["lawyer and author", "1820-1904"],
  "Christina Rossetti": ["poet", "1830-1894"],
  "Christine Stevens": ["activist", "1918-2002"],
  "Christion Morgenstern": ["writer", "1871-1914"],
  "Christopher Hitchens": ["author and journalist", "1949-2011"],
  "Christopher Morley": ["journalist, novelist, essayist, poet", "1890-1957"],
  "Chuang Tzu": ["philosopher", "c. 4th century BCE"],
  "Clara Lucas Balfour": ["social activist", "1808-1878"],
  "Clarence Darrow": ["lawyer and author", "1857-1938"],
  "Clarence Day": ["writer", "1874-1935"],
  "Claude Levi-Strauss": ["anthropologist", "1908-2009"],
  "Claude Monet": ["painter", "1840-1926"],
  "Claude Pepper": ["senator and representative", "1900-1989"],
  "Clifton Fadiman": ["editor and critic", "1904-1999"],
  "Cole Porter": ["composer and songwriter", "1893-1964"],
  "Colette": ["author", "1873-1954"],
  "Colin Wilson": ["writer, philosopher and novelist", "1931-2013"],
  "Comte Roger de Bussy-Rabutin": ["writer", "1618-1693"],
  "Confucius": ["philosopher and teacher", "c. 551-478 BCE"],
  "Constantin Brancusi": ["sculptor", "1876-1957"],
  "Cornel West": ["author and philosopher", "1953-"],
  "Cornelius Conway Felton": ["educator", "1807-1862"],
  "Cory Doctorow": ["author and journalist", "1971-"],
  "Crowfoot": ["Native American warrior and orator", "1821-1890"],
  "Cullen Hightower": ["salesman and writer", "1923-"],
  "Cyril Connolly": ["critic and editor", "1903-1974"],
  "Czeslaw Milosz": ["poet, novelist and Nobel laureate", "1911-2004"],
  "Dag Hammarskjold": ["Secretary General of the United Nations and Nobel laureate", "1905-1961"],
  "Dalai Lama": ["", "1935-"],
  "Dale Carnegie": ["author and educator", "1888-1955"],
  "Dale Spender": ["writer", "1943-"],
  "Dan Barker": ["former preacher and musician", "1949-"],
  "Dana Gioia": ["poet, critic and translator", "1950-"],
  "Dandamis": ["sage", "4th century BCE"],
  "Daniel Defoe": ["novelist and journalist", "1659?-1731"],
  "Daniel Dennett": ["philosopher, writer and professor", "1942-"],
  "Daniel J. Boorstin": ["historian, professor, attorney, writer", "1914-2004"],
  "Dante Alighieri": ["poet", "1265-1321"],
  "Daphne du Maurier": ["novelist", "1907-1989"],
  "Dario Fo": ["actor, playwright, theater director, Nobel laureate", "1926-2016"],
  "Darryl F. Zanuck": ["movie producer, director and actor", "1902-1979"],
  "Dave Barry": ["author and columnist", "1947-"],
  "David Cole": ["professor, author and correspondent", "1958-"],
  "David Foster Wallace": ["novelist, essayist and short story writer", "1962-2008"],
  "David Grayson": ["journalist and author", "1870-1946"],
  "David Hume": ["philosopher, economist and historian", "1711-1776"],
  "David Lloyd George": ["British prime minister", "1863-1945"],
  "David McCord": ["poet", "1897-1997"],
  "David S. Landes": ["author and professor of economics and history", "1924-"],
  "De la Rochefoucauld": ["French writer", "1613-1680"],
  "Dean Acheson": ["statesman and lawyer", "1893-1971"],
  "Denis Diderot": ["philosopher", "1713-1784"],
  "Dennis Potter": ["dramatist", "1935-1994"],
  "Derek Bok": ["lawyer and educator", "1930-"],
  "Desiderius Erasmus": ["philosopher, humanist and theologian", "1466-1536"],
  "Desmond Tutu": ["clergyman", "1931-"],
  "Diane Ackerman": ["writer", "1948-"],
  "Diane Arbus": ["photographer", "1923-1971"],
  "Diane Wakoski": ["poet", "1937-"],
  "Dietrich Bonhoeffer": ["theologian and writer", "1906-1945"],
  "Dinah Maria Mulock Craik": ["poet and novelist", "1826-1887"],
  "Dinos Christianopoulos": ["poet", "1931-"],
  "Diogenes": ["philosopher", "412?-323 BCE"],
  "Dominique Bouhours": ["grammarian", "1632-1702"],
  "Don Marquis": ["humorist and poet", "1878-1937"],
  "Donald Knuth": ["computer scientist", "1938-"],
  "Donna Reed": ["actress", "1921-1986"],
  "Doris Lessing": ["novelist, poet, playwright, Nobel laureate", "1919-2013"],
  "Dorothy Parker": ["author", "1893-1967"],
  "Doug Larson": ["columnist", "1926-"],
  "Douglas Adams": ["author", "1952-2001"],
  "Douglas Hofstadter": ["professor of cognitive science", "1945-"],
  "Douglas MacArthur": ["general", "1880-1964"],
  "Douglas Mattern": ["peace activist", "1933-"],
  "Douglas William Jerrold": ["playwright and humorist", "1803-1857"],
  "Dr. Seuss": ["author and illustrator", "1904-1991"],
  "Duke Ellington": ["jazz pianist, composer and conductor", "1899-1974"],
  "Dwight D. Eisenhower": ["United States president", "1890-1969"],
  "E.M. Forster": ["novelist", "1879-1970"],
  "E.B. White": ["writer", "1899-1985"],
  "E.E. Cummings": ["poet", "1894-1962"],
  "E.F. Schumacher": ["economist and author", "1911-1977"],
  "E.L. Doctorow": ["writer", "1931-"],
  "E.O. Wilson": ["biologist, naturalist and author", "1929-"],
  "E.W. Howe": ["novelist and editor", "1853-1937"],
  "Earl Warren": ["jurist", "1891-1974"],
  "Earl Wilson": ["columnist", "1907-1987"],
  "Ed Krebs": ["photographer", "1951-"],
  "Eddie Cantor": ["actor", "1892-1964"],
  "Edgar Allan Poe": ["poet and short-story writer", "1809-1849"],
  "Edgar Guest": ["poet", "1881-1959"],
  "Edgar Quinet": ["historian", "1803-1875"],
  "Edgar Wayburn": ["environmentalist and doctor", "1906-"],
  "Edgard Varese": ["composer", "1885-1965"],
  "Edith Cavell": ["nurse and humanitarian", "1865-1915"],
  "Edith Hamilton": ["educator and writer", "1867-1963"],
  "Edith Sitwell": ["poet", "1887-1964"],
  "Edith Wharton": ["novelist", "1862-1937"],
  "Edmond de Goncourt": ["writer, critic and publisher", "1822-1896"],
  "Edmund Burke": ["statesman and writer", "1729-1797"],
  "Edmund Hillary": ["mountaineer and explorer", "1919-2008"],
  "Edmund Spenser": ["poet", "1552-1599"],
  "Edmund Wilson": ["critic", "1895-1972"],
  "Edsger Dijkstra": ["computer scientist", "1930-2002"],
  "Eduardo Galeano": ["journalist and novelist", "1940-"],
  "Edward Abbey": ["naturalist and author", "1927-1989"],
  "Edward Bulwer-Lytton": ["writer", "1803-1873"],
  "Edward de Bono": ["consultant, writer and speaker", "1933-"],
  "Edward Dyer": ["courtier and poet", "c. 1540-1607"],
  "Edward Everett Hale": ["author", "1822-1909"],
  "Edward Gibbon": ["historian", "1737-1794"],
  "Edward H. Simpson": ["statistician", "1922-"],
  "Edward Hopper": ["painter", "1882-1967"],
  "Edward R. Murrow": ["journalist", "1908-1965"],
  "Edward Sapir": ["anthropologist and linguist", "1884-1939"],
  "Edwin Arlington Robinson": ["poet", "1869-1935"],
  "Edwin Hubbel Chapin": ["minister and orator", "1814-1880"],
  "Edwin Way Teale": ["naturalist and author", "1899-1980"],
  "Elbert Hubbard": ["author, editor and printer", "1856-1915"],
  "Eleanor Roosevelt": ["diplomat, author and lecturer", "1884-1962"],
  "Eli Khamarov": ["author", "1948-"],
  "Elie Wiesel": ["writer and Nobel laureate", "1928-"],
  "Eliezer S. Yudkowsky": ["researcher", "1979-"],
  "Elisabeth Kübler-Ross": ["psychiatrist and author", "1926-2004"],
  "Elizabeth Barrett Browning": ["poet", "1806-1861"],
  "Elizabeth Bowen": ["novelist", "1899-1973"],
  "Elizabeth Charles": ["writer", "1828-1896"],
  "Elizabeth Drew": ["author", "1887-1965"],
  "Elizabeth Rundle Charles": ["writer", "1828-1896"],
  "Ella Wheeler Wilcox": ["poet", "1850-1919"],
  "Ellen DeGeneres": ["comedian, television host and actress", "1958-"],
  "Ellen Glasgow": ["novelist", "1874-1945"],
  "Elsa Schiaparelli": ["fashion designer", "1890-1973"],
  "Emile Chartier": ["philosopher", "1868-1951"],
  "Emile de Girardin": ["journalist and politician", "1802-1881"],
  "Emile Herzog": ["writer", "1885-1967"],
  "Emile Zola": ["writer", "1840-1902"],
  "Emily Bronte": ["novelist", "1818-1848"],
  "Emily Carr": ["artist and writer", "1871-1945"],
  "Emily Dickinson": ["poet", "1830-1886"],
  "Emily Kimbrough": ["author and broadcaster", "1899-1989"],
  "Emily Post": ["author and columnist", "1872-1960"],
  "Emma Goldman": ["social activist", "1869-1940"],
  "Emma Lazarus": ["poet and playwright", "1849-1887"],
  "Emo Philips": ["comedian", "1956-"],
  "Enrico Fermi": ["physicist and Nobel laureate", "1901-1954"],
  "Epictetus": ["philosopher", "c. 60-120"],
  "Epicurus": ["philosopher", "c. 341-270 BCE"],
  "Erasmus Darwin": ["physician, scientist, reformer, poet, grandfather of Charles Darwin", "1731-1802"],
  "Eric Hoffer": ["philosopher and author", "1902-1983"],
  "Eric Idle": ["comedian, actor and author", "1943-"],
  "Eric S. Raymond": ["programmer and writer", "1957-"],
  "Eric Sevareid": ["journalist", "1912-1992"],
  "Erica Jong": ["writer", "1942-"],
  "Erich Fromm": ["psychoanalyst and author", "1900-1980"],
  "Erich Heller": ["essayist", "1911-1990"],
  "Erma Bombeck": ["author", "1927-1996"],
  "Ernest Hemingway": ["author, journalist and Nobel laureate", "1899-1961"],
  "Ernest Weekley": ["lexicographer", "1865-1954"],
  "Erno Paasilinna": ["essayist and journalist", "1935-2000"],
  "Esther Warner Dendel": ["writer and artist", "1910-2002"],
  "Ethan Allen": ["revolutionary", "1738-1789"],
  "Euripides": ["playwright", "c. 480-406 BCE"],
  "Eve Merriam": ["poet and writer", "1916-1992"],
  "Evelyn Waugh": ["novelist", "1903-1966"],
  "Everett Dean Martin": ["columnist, preacher and philosopher", "1880-1941"],
  "Ezra Pound": ["poet", "1885-1972"],
  "F. Scott Fitzgerald": ["novelist", "1896-1940"],
  "Faith Baldwin": ["novelist", "1893-1978"],
  "Fannie Hurst": ["writer", "1889-1968"],
  "Federico Fellini": ["film director and writer", "1920-1993"],
  "Federico García Lorca": ["poet, playwright and painter", "1898-1936"],
  "Felix Adler": ["professor, lecturer and reformer", "1851-1933"],
  "Ferdinand de Saussure": ["linguist", "1857-1913"],
  "Flannery O'Connor": ["writer", "1925-1964"],
  "Florence Luscomb": ["architect and suffragist", "1887-1985"],
  "Florence Scovel Shinn": ["writer, artist and teacher", "1871-1940"],
  "Fran Lebowitz": ["author", "1950-"],
  "Francis Bacon": ["essayist, philosopher and statesman", "1561-1626"],
  "Francis Hutcheson": ["philosopher", "1694-1746"],
  "Francis Picabia": ["painter and poet", "1879-1953"],
  "Francis Quarles": ["poet", "1592-1644"],
  "Francisco Goya": ["painter and printmaker", "1746-1828"],
  "François de La Rochefoucauld": ["moralist", "1613-1680"],
  "Francois Gautier": ["journalist", "1950-"],
  "Francoise Sagan": ["playwright and novelist", "1935-2004"],
  "Frank A. Clark": ["writer", "1911-"],
  "Frank H. Westheimer": ["chemistry professor", "1912-2007"],
  "Frank Lloyd Wright": ["architect", "1867-1959"],
  "Frank William Leahy": ["football coach", "1908-1973"],
  "Frank X. Barron": ["veteran and professor of psychology", "1922-2002"],
  "Frank Zappa": ["composer, musician and film director", "1940-1993"],
  "Franklin D. Roosevelt": ["32nd US President", "1882-1945"],
  "Franklin P. Adams": ["columnist", "1881-1960"],
  "Franklin P. Jones": ["businessman", "1887-1929"],
  "Franz Kafka": ["novelist", "1883-1924"],
  "Fred Astaire": ["dancer, actor, singer, musician, choreographer", "1899-1987"],
  "Fred Brooks": ["computer scientist", "1931-"],
  "Fred Menger": ["chemistry professor", "1937-"],
  "Fred Rogers": ["television host, songwriter and author", "1928-2003"],
  "Fred Thompson": ["US senator, lawyer, writer, actor", "1942-"],
  "Frederick Douglass": ["abolitionist, editor and orator", "1817-1895"],
  "Frederick Saunders": ["librarian and essayist", "1807-1902"],
  "Freeman Dyson": ["physicist", "1923-"],
  "Freya Stark": ["explorer and writer", "1893-1993"],
  "Friedrich Nietzsche": ["philosopher", "1844-1900"],
  "Fyodor Dostoyevsky": ["novelist", "1821-1881"],
  "G.K. Chesterton": ["essayist and novelist", "1874-1936"],
  "Gabriel García Márquez": ["novelist, journalist and Nobel laureate", "1927-2014"],
  "Gabriel Laub": ["author", "1928-1998"],
  "Gabriel Legouve": ["poet", "1764-1812"],
  "Gale D. Webbe": ["clergyman and author", "1909-2000"],
  "Galileo Galilei": ["physicist and astronomer", "1564-1642"],
  "Garrison Keillor": ["radio host and author", "1942-"],
  "Gary Hector": ["author and journalist", "1951-"],
  "Gary Zukav": ["author", "1942-"],
  "Pierre-Marc-Gaston de Lévis": ["politician and aphorist", "1764-1830"],
  "Gene Fowler": ["journalist and author", "1890-1960"],
  "Geoffrey Madan": ["writer", "1895-1947"],
  "Georg Brandes": ["critic and scholar", "1842-1927"],
  "Georg Christoph Lichtenberg": ["scientist and philosopher", "1742-1799"],
  "Georg Wilhelm Friedrich Hegel": ["philosopher", "1770-1831"],
  "George Bernard Shaw": ["dramatist, critic, novelist, Nobel laureate", "1856-1950"],
  "George Carlin": ["comedian, actor and author", "1937-2008"],
  "George Crabbe": ["poet and naturalist", "1754-1832"],
  "George D. Aiken": ["US senator", "1892-1984"],
  "George Eliot (Mary Ann Evans)": ["novelist", "1819-1880"],
  "George Gamow": ["physicist and cosmologist", "1904-1968"],
  "George Gordon Byron": ["poet", "1788-1824"],
  "George H. Lorimer": ["editor", "1868-1937"],
  "George Jean Nathan": ["author and editor", "1882-1958"],
  "George Leslie Brook": ["English professor and author", "1910-1987"],
  "George Marshall": ["US Army Chief, Secretary of State, Secretary of Defense, Nobel laureate", "1880-1959"],
  "George Matthew Adams": ["newspaper columnist", "1878-1962"],
  "George McGovern": ["senator and author", "1922-2012"],
  "George Orwell": ["novelist", "1903-1950"],
  "George Polya": ["professor of mathematics", "1887-1985"],
  "George Pope Morris": ["journalist and poet", "1802-1864"],
  "George Sand [pen name of Amantine-Aurore-Lucile Dupin]": ["novelist", "1804-1876"],
  "George Santayana": ["philosopher", "1863-1952"],
  "George Sewell": ["physician and writer", "1690-1736"],
  "George Steiner": ["professor and writer", "1929-"],
  "George T. Angell": ["reformer", "1823-1909"],
  "George W. Crane": ["physician", "1901-1995"],
  "George Washington Carver": ["scientist", "1864-1943"],
  "George Washington": ["United States president", "1732-1799"],
  "George Wharton James": ["journalist, author and speaker", "1858-1923"],
  "George Will": ["columnist and author", "1941-"],
  "Georges Clemenceau": ["statesman", "1841-1929"],
  "Gerry Spence": ["lawyer", "1929-"],
  "Gertrude Stein": ["novelist, poet and playwright", "1874-1946"],
  "Giacomo Leopardi": ["poet, essayist and philosopher", "1798-1837"],
  "Gil Bailie": ["author and lecturer", "1944-"],
  "Gilbert Highet": ["writer", "1906-1978"],
  "Ginni Bly": ["poet", "1945-"],
  "Giorgos Seferis": ["writer, diplomat and Nobel laureate", "1900-1971"],
  "Gladys Bronwyn Stern": ["writer", "1890-1973"],
  "Glenn T. Seaborg": ["scientist and Nobel laureate", "1912-1999"],
  "Gloria Steinem": ["activist and editor", "1934-"],
  "Golda Meir": ["Israeli Prime Minister", "1898-1978"],
  "Gore Vidal": ["author", "1925-2012"],
  "Grace Hopper": ["computer scientist and US Navy Rear Admiral", "1906-1992"],
  "Grace Moore": ["actress and singer", "1898-1947"],
  "Graham Greene": ["novelist and journalist", "1904-1991"],
  "Gretel Ehrlich": ["novelist, poet and essayist", "1946-"],
  "Gulzar": ["poet, lyricist and film director", "1934-"],
  "Gustave Flaubert": ["novelist", "1821-1880"],
  "Guy de Maupassant": ["short story writer and novelist", "1850-1893"],
  "Gwendolyn Brooks": ["poet", "1917-2000"],
  "H. Jackson Brown, Jr": ["writer", "1940-"],
  "H.G. Wells": ["writer", "1866-1946"],
  "H.L. Mencken": ["writer, editor and critic", "1880-1956"],
  "H.L.F. von Helmholtz": ["physiologist and physicist", "1821-1894"],
  "H.R. McMaster": ["Brigadier General, US Army and Iraq War veteran", "1962-"],
  "H.W. Fowler": ["lexicographer", "1858-1933"],
  "Hafez": ["poet", "1315-1390"],
  "Hal Borland": ["author and journalist", "1900-1978"],
  "Hal Clement": ["science fiction author", "1922-2003"],
  "Hanif Kureishi": ["author", "1954-"],
  "Hank Ketcham": ["comic artist", "1920-2001"],
  "Hannah Arendt": ["historian and philosopher", "1906-1975"],
  "Hannah More": ["writer", "1745-1833"],
  "Hannah Senesh": ["poet, playwright and paratrooper", "1921-1944"],
  "Hans Hofmann": ["painter", "1880-1966"],
  "Harper Lee": ["writer", "1926-2016"],
  "Harriet Beecher Stowe": ["abolitionist and novelist", "1811-1896"],
  "Harriet Lerner": ["psychologist", "1944-"],
  "Harry Crews": ["novelist and playwright", "1935-2012"],
  "Harry Emerson Fosdick": ["preacher and author", "1878-1969"],
  "Harry S Truman": ["33rd US president", "1884-1972"],
  "Harry Truman": ["33rd US president", "1884-1972"],
  "Hart Crane": ["poet", "1899-1932"],
  "Hartley Shawcross": ["barrister, politician and prosecutor at the Nuremberg War Crimes tribunal", "1902-2003"],
  "Haruki Murakami": ["writer", "1949-"],
  "Havelock Ellis": ["physician, writer and social reformer", "1859-1939"],
  "Hector Berlioz": ["composer", "1803-1869"],
  "Heinrich Heine": ["poet, journalist and essayist", "1797-1856"],
  "Helder Camara": ["archbishop", "1909-1999"],
  "Helen Keller": ["author and lecturer", "1880-1968"],
  "Helena Petrovna Hahn Blavatsky": ["Russian-born theosophist", "1831-1891"],
  "Henny Youngman": ["comedian and violinist", "1906-1998"],
  "Henri Frederic Amiel": ["philosopher and writer", "1821-1881"],
  "Henri Matisse": ["artist", "1869-1954"],
  "Henrik Ibsen": ["playwright", "1828-1906"],
  "Henry Adams": ["historian and teacher", "1838-1918"],
  "Henry Beston": ["naturalist and author", "1888-1968"],
  "Henry Brooks Adams": ["historian", "1838-1918"],
  "Henry Clay": ["statesman and orator", "1777-1852"],
  "Henry David Thoreau": ["naturalist and author", "1817-1862"],
  "Henry Fielding": ["author", "1707-1754"],
  "Henry Ford": ["industrialist", "1863-1947"],
  "Henry George": ["economist, journalist and philosopher", "1839-1897"],
  "Henry M. Wriston": ["11th president of Brown University", "1889-1978"],
  "Henry Maudsley": ["psychiatrist", "1835-1918"],
  "Henry Miller": ["novelist", "1891-1980"],
  "Henry Peter Brougham": ["lord chancellor", "1778-1868"],
  "Henry Theodore Tuckerman": ["author and critic", "1813-1871"],
  "Henry Thomas Buckle": ["historian", "1821-1862"],
  "Henry van Dyke": ["poet", "1852-1933"],
  "Henry Wadsworth Longfellow": ["poet", "1807-1882"],
  "Henry Ward Beecher": ["preacher and writer", "1813-1887"],
  "Henry Winkler": ["actor", "1945-"],
  "Heraclitus": ["philosopher", "c. 540-470 BCE"],
  "Herbert Alexander Simon": ["economist and Nobel laureate", "1916-2001"],
  "Herbert Hoover": ["United States president", "1874-1964"],
  "Herbert J. Muller": ["educator, historian and author", "1905-1980"],
  "Herbert Samuel": ["politician and diplomat", "1870-1963"],
  "Herbert Spencer": ["philosopher", "1820-1903"],
  "Herbert V. Prochnow": ["banker", "1897-1998"],
  "Herman Melville": ["novelist and poet", "1819-1891"],
  "Hermann G&ouml;ring": ["Nazi military leader", "1893-1946"],
  "Hermann Göring": ["Nazi military leader", "1893-1946"],
  "Hermann Hesse": ["novelist, poet and Nobel laureate", "1877-1962"],
  "Herta Müller": ["novelist, poet and Nobel laureate", "1953-"],
  "Hilaire Belloc": ["writer and poet", "1870-1953"],
  "Hillary Clinton": ["Secretary of State and senator", "1947-"],
  "Hippocrates": ["physician", "460-377 BCE"],
  "Hippolyte Taine": ["critic and historian", "1828-1893"],
  "Hisham Matar": ["author", "1970-"],
  "Holmes Rolston III": ["professor of philosophy", "1932-"],
  "Honore de Balzac": ["novelist", "1799-1850"],
  "Horace Mann": ["educational reformer", "1796-1859"],
  "Horace Walpole": ["novelist and essayist", "1717-1797"],
  "Horace": ["poet and satirist", "65-8 BCE"],
  "Horacio Verbitsky": ["journalist", "1942-"],
  "Hosea Ballou": ["preacher", "1771-1852"],
  "Howard Zinn": ["historian, professor, author, playwright, social activist", "1922-2010"],
  "HP Lovecraft": ["short-story writer and novelist", "1890-1937"],
  "Hubert Horatio Humphrey": ["US Vice President", "1911-1978"],
  "Hubert Humphrey": ["US Vice President", "1911-1978"],
  "Ibn Gabirol": ["poet and philosopher", "c. 1022-1058"],
  "Igor Stravinsky": ["composer", "1882-1971"],
  "Immanuel Kant": ["philosopher", "1724-1804"],
  "Indira Gandhi": ["prime minister of India", "1917-1984"],
  "Ingrid Bengis": ["writer and teacher", "1944-"],
  "Ingrid Newkirk": ["animal rights activist", "1949-"],
  "Iris Murdoch": ["writer", "1919-1999"],
  "Irvin S. Cobb": ["author and journalist", "1876-1944"],
  "Isaac Asimov": ["scientist and writer", "1920-1992"],
  "Isaac Babel": ["author", "1894-1940"],
  "Isaac Bashevis Singer": ["writer and Nobel laureate", "1904-1991"],
  "Isaac Disraeli": ["writer", "1766-1848"],
  "Isaac Newton": ["physicist, mathematician and philosopher", "1642-1727"],
  "Isak Dinesen (pen name of Karen Blixen)": ["author", "1885-1962"],
  "Isamu Noguchi": ["sculptor and architect", "1904-1988"],
  "Ivan Goncharov": ["novelist", "1812-1891"],
  "Ivan Illich": ["philosopher and priest", "1926-2002"],
  "Ivan Turgenev": ["novelist and playwright", "1818-1883"],
  "J. Krishnamurti": ["author, speaker and philosopher", "1895-1986"],
  "J. Michael Straczynski": ["author", "1954-"],
  "J. Robert Oppenheimer": ["theoretical physicist", "1904-1967"],
  "J. William Fulbright": ["US Senator", "1905-1995"],
  "J.B. Phillips": ["writer and clergyman", "1906-1982"],
  "J.B. Priestley": ["author", "1894-1984"],
  "J.D. Salinger": ["writer", "1919-"],
  "J.K. Rowling": ["author", "1965-"],
  "J.M. Barrie": ["novelist and playwright", "1860-1937"],
  "J.R.R. Tolkien": ["novelist and philologist", "1892-1973"],
  "J.S. Buckminster": ["clergyman and editor", "1784-1812"],
  "Jack Gilbert": ["poet", "1925-2012"],
  "Jack Kerouac": ["author", "1922-1969"],
  "Jack Kevorkian": ["pathologist, euthanasia activist, painter, author, composer", "1928-2011"],
  "Jack Lynch": ["professor and author", "1967-"],
  "Jacob A. Riis": ["journalist and social reformer", "1849-1914"],
  "Jacqueline Kennedy Onassis": ["former first lady", "1929-1994"],
  "Jacques Barzun": ["professor and writer", "1907-"],
  "Jacques Deval": ["writer and director", "1895-1972"],
  "Jalaluddin Rumi": ["poet and mystic", "1207-1273"],
  "James Albert Michener": ["novelist", "1907-1997"],
  "James Anthony Froude": ["author and editor", "1818-1894"],
  "James Baldwin": ["writer", "1924-1987"],
  "James Boswell": ["biographer and lawyer", "1740-1795"],
  "James Branch Cabell": ["novelist, essayist and critic", "1879-1958"],
  "James Cromwell": ["actor", "1940-"],
  "James Earl Jones": ["actor", "1931-"],
  "James Fenton": ["poet and professor", "1949-"],
  "James Freeman Clarke": ["preacher and author", "1810-1888"],
  "James Howell": ["writer", "c. 1594-1666"],
  "James Jones": ["novelist", "1921-1977"],
  "James Kern Feibleman": ["philosopher and psychiatrist", "1904-1987"],
  "James M. Barrie": ["novelist, short-story writer and playwright", "1860-1937"],
  "James Madison": ["United States president", "1751-1836"],
  "James Marston Fitch": ["historic preservationist", "1909-2000"],
  "James Matthew Barrie": ["author", "1860-1937"],
  "James McNeill Whistler": ["painter", "1834-1903"],
  "James Randi": ["magician and skeptic", "1928-"],
  "James Reston": ["journalist", "1909-1995"],
  "James Richardson": ["poet and professor", "1950-"],
  "James Runcieman Sutherland": ["professor and writer", "1900-1996"],
  "James Russell Lowell": ["poet, editor and diplomat", "1819-1891"],
  "James Thurber": ["writer and cartoonist", "1894-1961"],
  "Jan de Hartog": ["playwright and novelist", "1914-2002"],
  "Jane Austen": ["novelist", "1775-1817"],
  "Jane Smiley": ["novelist", "1949-"],
  "Jane Welsh Carlyle": ["letter writer", "1801-1866"],
  "Janis Joplin": ["singer-songwriter", "1943-1970"],
  "Janos Arany": ["poet", "1817-1882"],
  "Jascha Heifetz": ["violinist", "1901-1987"],
  "Jean Anouilh": ["dramatist", "1910-1987"],
  "Jean Arp": ["artist and poet", "1887-1948"],
  "Jean Baptiste Henri Lacordaire": ["preacher, journalist and activist", "1802-1861"],
  "Jean Baudrillard": ["sociologist and philosopher", "1929-2007"],
  "Jean Cocteau": ["writer, artist and filmmaker", "1889-1963"],
  "Jean de la Bruyere": ["essayist and moralist", "1645-1696"],
  "Jean de la Fontaine": ["poet and fabulist", "1621-1695"],
  "Jean Jacques Rousseau": ["philosopher and author", "1712-1778"],
  "Jean Paul Richter": ["writer", "1763-1825"],
  "Jean Piaget": ["psychologist", "1896-1980"],
  "Jean Rostand": ["biologist and philosopher", "1894-1977"],
  "Jean Toomer": ["poet and novelist", "1894-1967"],
  "Jean-Baptiste Alphonse Karr": ["novelist and journalist", "1808-1890"],
  "Jean-Luc Godard": ["film director", "1930-"],
  "Jean-Paul Sartre": ["writer and philosopher", "1905-1980"],
  "Jeanne-Marie Roland": ["revolutionary", "1754-1793"],
  "Jefferson Davis": ["confederate president", "1808-1889"],
  "Jello Biafra": ["musician", "1958-"],
  "Jeremy Bentham": ["jurist and philosopher", "1748-1832"],
  "Jeremy Irons": ["actor", "1948-"],
  "Jerome E. Singer": ["psychology professor", "1934-2010"],
  "Jerome K. Jerome": ["humorist and playwright", "1859-1927"],
  "Jerry Coyne": ["biology professor", "1949-"],
  "Jerry Rubin": ["activist and author", "1938-1994"],
  "Jerry Seinfeld": ["comedian", "1954-"],
  "Jesse Jackson": ["clergyman and civil rights leader", "1941-"],
  "Jessica Mitford": ["author, journalist and civil rights activist", "1917-1996"],
  "Jimi Hendrix": ["musician, singer and songwriter", "1942-1970"],
  "Jimmy Carter": ["United States president and Nobel laureate", "1924-"],
  "Jimmy Stewart": ["actor", "1908-1997"],
  "Joan Baez": ["musician", "1941-"],
  "Joanna Macy": ["writer and teacher", "1929-"],
  "Joey Adams": ["comedian", "1911-1999"],
  "Johann Christoph Friedrich von Schiller": ["poet and dramatist", "1759-1805"],
  "Johann Gottfried Seume": ["author", "1763-1810"],
  "Johann Gottfried Von Herder": ["critic and poet", "1744-1803"],
  "Johann Peter Eckermann": ["poet", "1792-1854"],
  "Johann Wolfgang von Goethe": ["poet, dramatist, novelist, philosopher", "1749-1832"],
  "Johannes Kepler": ["astronomer", "1571-1630"],
  "John Adams": ["United States president", "1735-1826"],
  "John Andrew Holmes": ["politician", "1773-1843"],
  "John Arbuthnot": ["writer and physician", "1667-1735"],
  "John Ayto": ["lexicographer", "1949-"],
  "John Barrington Wain": ["writer", "1925-1994"],
  "John Brunner": ["science fiction writer", "1934-1995"],
  "John Buchan": ["poet, novelist and politician", "1875-1940"],
  "John Bunyan": ["preacher and author", "1628-1688"],
  "John Burroughs": ["naturalist and writer", "1837-1921"],
  "John Cage": ["composer", "1912-1992"],
  "John Churton Collins": ["literary critic", "1848-1908"],
  "John Ciardi": ["poet and translator", "1916-1986"],
  "John Clare": ["poet", "1793-1864"],
  "John Cleese": ["comic actor", "1939-"],
  "John Constable": ["painter", "1776-1837"],
  "John Danforth": ["priest, ambassador and senator", "1936-"],
  "John Donne": ["poet", "1573-1631"],
  "John Dryden": ["poet and dramatist", "1631-1700"],
  "John Erskine": ["novelist, poet and essayist", "1879-1951"],
  "John F. Kennedy": ["United States president", "1917-1963"],
  "John Galsworthy": ["author and Nobel laureate", "1867-1933"],
  "John Gay": ["poet and dramatist", "1685-1732"],
  "John Gilmore": ["software engineer and activist", "1957-"],
  "John Gray": ["author", "1951-"],
  "John Greenleaf Whittier": ["poet", "1807-1892"],
  "John Henry Jowett": ["preacher", "1864-1923"],
  "John Irving": ["novelist", "1942-"],
  "John Keats": ["poet", "1795-1821"],
  "John Kenneth Galbraith": ["economist", "1908-2006"],
  "John Lennon": ["musician", "1940-1980"],
  "John Leonard": ["critic", "1939-2008"],
  "John Locke": ["philosopher", "1632-1704"],
  "John Maynard Keynes": ["economist", "1883-1946"],
  "John McWhorter": ["linguist, author and commentator", "1965-"],
  "John Milton": ["poet", "1608-1674"],
  "John Morley": ["statesman and writer", "1838-1923"],
  "John Muir": ["naturalist, explorer and writer", "1838-1914"],
  "John N. Bahcall": ["astrophysicist", "1935-2005"],
  "John Neal": ["author and critic", "1793-1876"],
  "John O'Hara": ["journalist", "1905-1970"],
  "John Perkins": ["economist and author", "1945-"],
  "John Powell": ["composer", "1963-"],
  "John R. Searle": ["philosophy professor", "1932-"],
  "John Ralston Saul": ["essayist, novelist and critic", "1947-"],
  "John Ray": ["naturalist", "1627-1705"],
  "John Ruskin": ["author, art critic and social reformer", "1819-1900"],
  "John Selden": ["historian and politician", "1584-1654"],
  "John Singleton Mosby": ["Confederate colonel in the American Civil War", "1833-1916"],
  "John Steinbeck": ["novelist and Nobel laureate", "1902-1968"],
  "John Stuart Mill": ["philosopher and economist", "1806-1873"],
  "John Updike": ["writer", "1932-2009"],
  "John Vance Cheney": ["poet", "1848-1922"],
  "John W. Foster": ["clergyman", "1770-1843"],
  "John W. Gardner": ["author and educator", "1912-2002"],
  "John Webster": ["playwright", "c. 1580-1634"],
  "John Witherspoon": ["clergyman and theologian", "1723-1794"],
  "Jonas Salk": ["medical researcher and developer of polio vaccine", "1914-1995"],
  "Jonathan Kozol": ["writer", "1936-"],
  "Jonathan Swift": ["satirist", "1667-1745"],
  "Jonathan Zittrain": ["professor of Internet law", "1969-"],
  "Jorge Luis Borges": ["writer", "1899-1986"],
  "Jose Bergamin": ["author", "1895-1983"],
  "Jose Ortega Y Gasset": ["philosopher and essayist", "1883-1955"],
  "Joseph Addison": ["essayist and poet", "1672-1719"],
  "Joseph Brodsky": ["poet and Nobel laureate", "1940-1996"],
  "Joseph Conrad": ["novelist", "1857-1924"],
  "Joseph Fort Newton": ["minister and attorney", "1876-1950"],
  "Joseph Freiherr von Eichendorff": ["poet, novelist and playwright", "1788-1857"],
  "Joseph Heller": ["novelist", "1923-1999"],
  "Joseph Joubert": ["moralist and essayist", "1754-1824"],
  "Joseph Prescott": ["aphorist", "1913-2001"],
  "Joseph Roux": ["priest and writer", "1834-1886"],
  "Joseph Wood Krutch": ["writer and naturalist", "1893-1970"],
  "Josh Billings": ["columnist and humorist", "1818-1885"],
  "Joshua Reynolds": ["painter", "1723-1792"],
  "Josiah Gilbert Holland": ["novelist and poet", "1819-1881"],
  "Joss Whedon": ["writer and film director", "1964-"],
  "José Martí": ["revolutionary and poet", "1853-1895"],
  "Joyce Carol Oates": ["writer", "1938-"],
  "Juan Ramon Jimenez": ["poet and Nobel laureate", "1881-1958"],
  "Judy Garland": ["actress and singer", "1922-1969"],
  "Jules Henri Poincaré": ["mathematician, physicist and philosopher", "1854-1912"],
  "Jules Renard": ["author", "1864-1910"],
  "Jules Verne": ["science fiction author", "1828-1905"],
  "Julie Burchill": ["writer and journalist", "1959-"],
  "Justin Trudeau": ["Prime Minister of Canada", "1971-"],
  "Juvenal": ["poet", "c. 60-140"],
  "k.d. lang": ["singer", "1961-"],
  "Kabir": ["reformer and poet", "late 15th century"],
  "Kalidasa": ["dramatist", "c. 4th century"],
  "Kara Vallow": ["artist", "1967-"],
  "Karen Armstrong": ["author", "1944-"],
  "Karen Sunde": ["playwright", "1942-"],
  "Karin Slaughter": ["novelist", "1971-"],
  "Karl A. Menninger": ["psychiatrist", "1893-1990"],
  "Karl Kraus": ["writer", "1874-1936"],
  "Karl Menninger": ["psychiatrist", "1893-1990"],
  "Karl Popper": ["philosopher and a professor", "1902-1994"],
  "Karl Viktor von Bonstetten": ["author", "1745-1832"],
  "Karol Bunsch": ["novelist", "1898-1987"],
  "Katharine Hepburn": ["actress", "1907-2003"],
  "Kathleen Norris": ["novelist and columnist", "1880-1966"],
  "Keith Preston": ["journalist and author", "1884-1927"],
  "Kenji Miyazawa": ["poet and story writer", "1896-1933"],
  "Kenko Yoshida": ["essayist", "1283-1352"],
  "Kenneth G. Wilson": ["author and professor", "1923-2003"],
  "Kenneth Patchen": ["poet and novelist", "1911-1972"],
  "Kenneth Tynan": ["critic and writer", "1927-1980"],
  "Khalil Gibran": ["mystic, poet and artist", "1883-1931"],
  "Kin Hubbard": ["humorist", "1868-1930"],
  "Kiran Bedi": ["police officer and social activist", "1949-"],
  "Konrad Adenauer": ["statesman", "1876-1967"],
  "Konrad Lorenz": ["ethologist and Nobel laureate", "1903-1989"],
  "Kurt Vonnegut, Jr": ["novelist", "1922-2007"],
  "L. Ron Hubbard": ["science fiction author who later started the Church of Scientology", "1911-1986"],
  "L.P. Hartley": ["writer", "1895-1972"],
  "Lady Dorothy Nevill": ["writer, hostess, horticulturist, plant collector", "1826-1913"],
  "Lady Mary Wortley Montagu": ["author", "1689-1762"],
  "Lana Turner": ["actress", "1921-1995"],
  "Langston Hughes": ["poet and novelist", "1902-1967"],
  "Lao Tzu": ["philosopher", "6th century BCE"],
  "Larry Wall": ["programmer and creator of Perl", "1954-"],
  "Laura Ingalls Wilder": ["author", "1867-1957"],
  "Laurence J Peter": ["educator and author", "1919-1990"],
  "Laurence J. Peter": ["educator and author", "1919-1990"],
  "Laurence Sterne": ["novelist and clergyman", "1713-1768"],
  "Laurens van der Post": ["explorer and writer", "1906-1996"],
  "Lawrence Durrell": ["novelist, poet and playwright", "1912-1990"],
  "Lawrence Ferlinghetti": ["poet and painter", "1919-"],
  "Lawrence Lessig": ["professor and activist", "1961-"],
  "Lawrence M. Krauss": ["theoretical physicist", "1954-"],
  "Lazare Nicolas Marguerite Carnot": ["statesman and engineer", "1753-1823"],
  "Learned Hand": ["jurist", "1872-1961"],
  "Lech Walesa": ["human rights activist, Polish president and Nobel laureate", "1943-"],
  "Lee Iacocca": ["automobile executive", "1924-"],
  "Lenny Bruce": ["comedian and social critic", "1925-1966"],
  "Leo Buscaglia": ["author, speaker and professor", "1924-1998"],
  "Leo Longanesi": ["journalist and editor", "1905-1957"],
  "Leo Rosten": ["author", "1908-1997"],
  "Leo Szilard": ["physicist", "1898-1964"],
  "Leo Tolstoy": ["novelist and philosopher", "1828-1910"],
  "Leon Trotsky": ["revolutionary and writer", "1879-1940"],
  "Leonard Cohen": ["musician", "1934-"],
  "Leonard Matlovich": ["a gay Vietnam Veteran", "1943-1988"],
  "Leonardo da Vinci": ["painter, engineer, musician, scientist", "1452-1519"],
  "Lewis Carroll": ["mathematician and writer", "1832-1898"],
  "Lewis Grizzard": ["humorist", "1946-1994"],
  "Lewis H. Lapham": ["editor and writer", "1935-"],
  "Lewis Mumford": ["writer and philosopher", "1895-1990"],
  "Lewis Thomas": ["physician and author", "1913-1993"],
  "Lillian Hellman": ["playwright", "1905-1984"],
  "Lillie Devereux Blake": ["novelist, essayist and reformer", "1833-1913"],
  "Lin Yutang": ["writer and translator", "1895-1976"],
  "Linus Pauling": ["chemist, peace activist, author, educator, Nobel laureate", "1901-1994"],
  "Lionel Strachey": ["writer and translator", "1864-1927"],
  "Lloyd Alexander": ["novelist", "1924-2007"],
  "Logan Pearsall Smith": ["essayist", "1865-1946"],
  "Lois McMaster Bujold": ["writer", "1949-"],
  "Lord Acton (John Emerich Edward Dalberg-Acton)": ["historian", "1834-1902"],
  "Lord Acton": ["historian", "1834-1902"],
  "Lord Byron": ["poet", "1788-1824"],
  "Lord Chesterfield": ["statesman and writer", "1694-1773"],
  "Lorraine Hansberry": ["playwright and painter", "1930-1965"],
  "Louis Brandeis": ["lawyer, judge and writer", "1856-1941"],
  "Louis de Bernieres": ["novelist", "1954-"],
  "Louis de Bonald": ["philosopher and politician", "1754-1840"],
  "Louis Dembitz Brandeis": ["lawyer, judge and writer", "1856-1941"],
  "Louis Kronenberger": ["writer", "1904-1980"],
  "Louis L'Amour": ["novelist", "1908-1988"],
  "Louis Nizer": ["lawyer", "1902-1994"],
  "Louis Pasteur": ["chemist and bacteriologist", "1822-1895"],
  "Louis Sullivan": ["architect", "1856-1924"],
  "Louis XVIII": ["king", "1755-1824"],
  "Louise Erdrich": ["author", "1954-"],
  "Luc de Clapiers, marquis de Vauvenargues": ["moralist and essayist", "1715-1747"],
  "Lucius Annaeus Seneca": ["writer and philosopher", "3 BCE-65 CE"],
  "Ludwig Feuerbach": ["philosopher", "1804-1872"],
  "Ludwig van Beethoven": ["composer", "1770-1827"],
  "Ludwig Wittgenstein": ["philosopher", "1889-1951"],
  "Luther Burbank": ["horticulturist", "1849-1926"],
  "Lydia Maria Child": ["activist, novelist and journalist", "1802-1880"],
  "Lyndon B. Johnson": ["36th US president", "1908-1973"],
  "Lysander Spooner": ["lawyer", "1808-1887"],
  "M. Scott Peck": ["psychiatrist and author", "1936-2005"],
  "Machado de Assis": ["writer", "1839-1908"],
  "Madame Anne Sophie Swetchine": ["mystic", "1782-1857"],
  "Madame de Stael": ["writer", "1766-1817"],
  "Madeleine L'Engle": ["writer", "1918-2007"],
  "Mae West": ["actress, playwright, singer, screenwriter, comedian", "1893-1980"],
  "Magdalena Samozwaniec": ["writer", "1894-1972"],
  "Mahatma Gandhi": ["civil rights leader", "1869-1948"],
  "Malcolm De Chazal": ["writer and painter", "1902-1981"],
  "Malcolm S. Forbes": ["entrepreneur", "1919-1990"],
  "Manal al-Sharif": ["human rights activist", "1979-"],
  "Mandell Creighton": ["historian", "1843-1901"],
  "Marc Barasch": ["author, editor and activist", "1949-"],
  "Marcel Duchamp": ["artist", "1887-1968"],
  "Marcel Proust": ["novelist", "1871-1922"],
  "Marcus Aurelius": ["philosopher, writer and Roman emperor", "121-180"],
  "Marcus Tullius Cicero": ["statesman, orator and writer", "106-43 BCE"],
  "Mardy Grothe": ["psychologist and author", "1942-"],
  "Margaret Atwood": ["novelist and poet", "1939-"],
  "Margaret Chittenden": ["writer", "1935-"],
  "Margaret Courtney": ["poet", "1822-1862"],
  "Margaret Fuller": ["author, critic and women's rights advocate", "1810-1850"],
  "Margaret Halsey": ["novelist", "1910-1997"],
  "Margaret Mead": ["anthropologist", "1901-1978"],
  "Margaret Millar": ["novelist", "1915-1994"],
  "Margaret Mitchell": ["novelist", "1900-1949"],
  "Margaret Visser": ["writer and broadcaster", "1940-"],
  "Marguerite Guardiner": ["writer", "1789-1849"],
  "Marguerite Yourcenar": ["novelist", "1903-1987"],
  "Maria Montessori": ["educator", "1870-1952"],
  "Marianne Moore": ["poet", "1887-1972"],
  "Marie Curie": ["scientist and Nobel laureate", "1867-1934"],
  "Marie von Ebner-Eschenbach": ["writer", "1830-1916"],
  "Marilyn Monroe": ["actress", "1926-1962"],
  "Mario Cuomo": ["Governor of New York", "1932-"],
  "Mark Abley": ["journalist", "1955-"],
  "Mark Helprin": ["novelist", "1947-"],
  "Mark Twain": ["author and humorist", "1835-1910"],
  "Marshall McLuhan": ["educator and philosopher", "1911-1980"],
  "Martin Amis": ["novelist", "1949-"],
  "Martin Gardner": ["mathematician and writer", "1914-2010"],
  "Martin Luther King, Jr": ["civil-rights leader", "1929-1968"],
  "Martin Niemoller": ["pastor, initial supporter of Hitler and concentration camp survivor", "1892-1984"],
  "Martin Rees": ["cosmologist and astrophysicist", "1942-"],
  "Marvin Kitman": ["author and media critic", "1929-"],
  "Mary Evans": ["actress", "1888-1976"],
  "Mary Karr": ["poet and memoirist", "1955-"],
  "Mary Renault": ["novelist", "1905-1983"],
  "Mary Roberts Rinehart": ["novelist", "1876-1958"],
  "Mary Wollstonecraft Shelley": ["author", "1797-1851"],
  "Mary Wollstonecraft": ["reformer and writer", "1759-1797"],
  "Marya Mannes": ["writer", "1904-1990"],
  "Matsuo Basho": ["poet", "1644-1694"],
  "Matt Cartmill": ["anthropology professor and author", "1943-"],
  "Matthew Henry": ["minister", "1662-1714"],
  "Matthew Stanley Quay": ["senator", "1833-1904"],
  "Maureen Duffy": ["poet, playwright and novelist", "1933-"],
  "Maurice Barres": ["novelist and politician", "1862-1923"],
  "Maurice Maeterlinck": ["poet, dramatist and Nobel laureate", "1862-1949"],
  "Maury Maverick": ["attorney and congressman", "1895-1954"],
  "Max Beerbohm": ["writer, critic and caricaturist", "1872-1956"],
  "Max Eastman": ["journalist and poet", "1883-1969"],
  "Max Ehrmann": ["writer and lawyer", "1872-1945"],
  "Max Frisch": ["architect, playwright and novelist", "1911-1991"],
  "Max Weinreich": ["linguist and author", "1894-1969"],
  "Maxim Gorky": ["author", "1868-1936"],
  "May Sarton": ["poet and novelist", "1912-1995"],
  "Maya Angelou": ["poet", "1928-2014"],
  "Mehmet Murat Ildan": ["writer", "1965-"],
  "Meister Eckhart": ["theologian", "c. 1260-1327"],
  "Mencius": ["philosopher", "c. 380-289 BCE"],
  "Michael Pollan": ["author and journalism professor", "1955-"],
  "Michel Colucci": ["comedian and actor", "1944-1986"],
  "Michel de Montaigne": ["essayist", "1533-1592"],
  "Michelangelo Buonarroti": ["sculptor, painter, architect, poet", "1475-1564"],
  "Michio Kaku": ["physicist", "1947-"],
  "Mignon McLaughlin": ["journalist and author", "1913-1983"],
  "Miguel de Cervantes": ["writer", "1547-1616"],
  "Miguel de Unamuno": ["philosopher and writer", "1864-1936"],
  "Milan Kundera": ["novelist, playwright and poet", "1929-"],
  "Milton Friedman": ["economist and Nobel laureate", "1912-2006"],
  "Mistinguett": ["singer", "1875-1956"],
  "Mizuta Masahide": ["poet and samurai", "1657-1723"],
  "Mohamed ElBaradei": ["diplomat and Nobel laureate", "1942-"],
  "Moliere": ["actor and playwright", "1622-1673"],
  "Montesquieu": ["philosopher, lawyer and writer", "1689-1755"],
  "Mortimer J. Adler": ["philosopher, educator and author", "1902-2001"],
  "Moses ben Maimon": ["rabbi and philosopher", "1135-1204"],
  "Moshe Dayan": ["military leader and politician", "1915-1981"],
  "Mother Teresa": ["saint", "1910-1997"],
  "Multatuli (pen name of Eduard Douwes Dekker)": ["novelist", "1820-1887"],
  "Munshi Premchand": ["novelist and poet", "1880-1936"],
  "Muriel Rukeyser": ["poet and activist", "1913-1980"],
  "Murray Edelman": ["professor and author", "1919-2001"],
  "Mustafa Kemal Ataturk": ["founder and the first president of Turkey", "1881-1938"],
  "Myrtle Reed": ["author", "1874-1911"],
  "Nadine Gordimer": ["novelist and Nobel laureate", "1923-2014"],
  "Naguib Mahfouz": ["writer and Nobel laureate", "1911-2006"],
  "Napoleon Bonaparte": ["emperor of France", "1769-1821"],
  "Napoleon Hill": ["author", "1883-1970"],
  "Natalie Clifford Barney": ["poet, playwright and novelist", "1876-1972"],
  "Nathaniel Branden": ["psychotherapist", "1930-"],
  "Nathaniel Hawthorne": ["writer", "1804-1864"],
  "Neil deGrasse Tyson": ["astrophysicist and author", "1958-"],
  "Neil Gaiman": ["novelist and short story writer", "1960-"],
  "Neil Postman": ["professor and author", "1931-"],
  "Nelson Mandela": ["activist, South African president and Nobel laureate", "1918-2013"],
  "Niccolo Machiavelli": ["political philosopher and author", "1469-1527"],
  "Nicholas Boileau-Despreaux": ["poet", "1636-1711"],
  "Nicholas Sparks": ["author", "1965-"],
  "Nicolas Boileau-Despréaux": ["poet and critic", "1636-1711"],
  "Nicolas Caussin": ["preacher and moralist", "1583-1651"],
  "Nicolas de Chamfort": ["writer", "1741-1794"],
  "Nida Fazli": ["poet", "1938-2016"],
  "Niels Bohr": ["physicist and Nobel laureate", "1885-1962"],
  "Niels Henrik David Bohr": ["chemist and physicist", "1885-1962"],
  "Nikki Giovanni": ["poet and professor", "1943-"],
  "Nikola Tesla": ["electrical engineer and inventor", "1856-1943"],
  "Nikolai Berdyaev": ["philosopher", "1874-1948"],
  "Nikos Kazantzakis": ["poet and novelist", "1883-1957"],
  "Nizar Qabbani": ["poet and diplomat", "1923-1998"],
  "Noah Webster": ["lexicographer", "1758-1843"],
  "Noam Chomsky": ["linguistics professor and political activist", "1928-"],
  "Nora Ephron": ["novelist", "1941-"],
  "Norman Angell": ["lecturer, author, MP, Nobel laureate", "1872-1967"],
  "Norman Cousins": ["author and editor", "1915-1990"],
  "Norman Douglas": ["novelist", "1868-1952"],
  "Norman Mailer": ["author", "1923-2007"],
  "Norman R. Augustine": ["industrialist", "1935-"],
  "Norman Thomas": ["socialist and social reformer", "1884-1968"],
  "Norman Vincent Peale": ["minister", "1898-1993"],
  "Northrop Frye": ["writer and critic", "1912-1991"],
  "O. Henry": ["short-story writer", "1862-1910"],
  "Octavio Paz": ["poet, diplomat and Nobel laureate", "1914-1998"],
  "Og Mandino": ["author", "1923-1996"],
  "Ogden Nash": ["poet", "1902-1971"],
  "Olive Schreiner": ["author", "1855-1920"],
  "Oliver Goldsmith": ["writer and physician", "1730-1774"],
  "Oliver Herford": ["writer and illustrator", "1863-1935"],
  "Oliver Sacks": ["neurologist and writer", "1933-"],
  "Oliver Wendell Holmes, Jr": ["jurist", "1841-1935"],
  "Oliver Wendell Holmes, Sr": ["writer and physician", "1809-1894"],
  "Omar Khayyam": ["poet, mathematician, philosopher, astronomer, physician", "1048-1131"],
  "Oprah Winfrey": ["talk show host and philanthropist", "1954-"],
  "Oscar Hammerstein II": ["lyricist", "1895-1960"],
  "Oscar Levant": ["composer", "1906-1972"],
  "Oscar Wilde": ["writer", "1854-1900"],
  "Otto von Bismarck": ["statesman", "1815-1898"],
  "Ouida [pen name of Marie Louise de la Ramee]": ["novelist", "1839-1908"],
  "Owen Barfield": ["author", "1898-1997"],
  "P.C. Hodgell": ["writer and professor", "1951-"],
  "P.D. James": ["novelist", "1920-2014"],
  "P.J. O'Rourke": ["writer", "1947-"],
  "P.L. Travers": ['author and creator of the "Mary Poppins" series', "1899-1996"],
  "Pablo Casals": ["cellist, conductor and composer", "1876-1973"],
  "Pablo Neruda": ["poet, diplomat and Nobel laureate", "1904-1973"],
  "Pablo Picasso": ["painter and sculptor", "1881-1973"],
  "Paddy Chayefsky": ["playwright", "1923-1981"],
  "Pamela Hansford Johnson": ["poet and novelist", "1912-1981"],
  "Patrick Henry": ["revolutionary", "1736-1799"],
  "Patti Page": ["singer", "1927-2013"],
  "Paul Auster": ["novelist and poet", "1947-"],
  "Paul Celan": ["poet and translator", "1920-1970"],
  "Paul Dirac": ["theoretical physicist", "1902-1984"],
  "Paul Dudley White": ["physician", "1886-1973"],
  "Paul Fussell": ["historian, author and professor", "1924-2012"],
  "Paul Klee": ["painter", "1879-1940"],
  "Paul McCartney": ["singer-songwriter, composer, poet, activist", "1942-"],
  "Paul Rusesabagina": ["humanitarian", "1954-"],
  "Paul Theroux": ["novelist", "1941-"],
  "Paul Valery": ["poet and philosopher", "1871-1945"],
  "Pearl S. Buck": ["novelist and Nobel laureate", "1892-1973"],
  "Pedro Calderon de la Barca": ["poet and dramatist", "1600-1681"],
  "Penelope Lively": ["writer", "1933-"],
  "Percy Bysshe Shelley": ["poet", "1792-1822"],
  "Pericles": ["statesman", "430 BCE"],
  "Peter De Vries": ["editor and novelist", "1910-1993"],
  "Peter Drucker": ["management consultant, professor and writer", "1909-2005"],
  "Peter Golkin": ["museum spokesman", "1966-"],
  "Peter Medawar": ["scientist and Nobel laureate", "1915-1987"],
  "Peter Mere Latham": ["physician and educator", "1789-1875"],
  "Peter Singer": ["philosopher and professor of bioethics", "1946-"],
  "Peter Ustinov": ["actor, writer and director", "1921-2004"],
  "Peter W. Hall": ["judge", "1948-"],
  "Peyton C. March": ["general", "1864-1955"],
  "Phil Ochs": ["folksinger", "1940-1976"],
  "Phil Zimmermann": ["cryptographer", "1954-"],
  "Philip Dormer Stanhope": ["statesman and writer", "1694-1773"],
  "Philip of Macedon": ["king and father of Alexander the Great", "382-336 BCE"],
  "Philip Roth": ["novelist", "1933-"],
  "Phillips Brooks": ["bishop and orator", "1835-1893"],
  "Phyllis Bottome": ["novelist", "1884-1963"],
  "Pierre Bayle": ["philosopher and writer", "1647-1706"],
  "Pierre Beaumarchais": ["playwright", "1732-1799"],
  "Pierre Carlet de Chamblain de Marivaux": ["dramatist and novelist", "1688-1763"],
  "Pierre-Auguste Renoir": ["artist", "1841-1919"],
  "Piet Hein": ["poet and scientist", "1905-1996"],
  "Pietro Aretino": ["satirist and dramatist", "1492-1556"],
  "Plato": ["philosopher", "427?-347? BCE"],
  "Plutarch": ["biographer and philosopher", "c. 46-120"],
  "Polybius": ["historian", "c. 205-123 BCE"],
  "Publilius Syrus": ["writer", "c. 1st century BCE"],
  "Publius Cornelius Tacitus": ["historian", "c. 55-120"],
  "PZ Myers": ["biology professor", "1957-"],
  "Quintilian (Marcus Fabius Quintilianus)": ["rhetorician", "c. 35-100"],
  "R. Buckminster Fuller": ["engineer, designer and architect", "1895-1983"],
  "R.K. Narayan": ["writer", "1906-2001"],
  "Rabindranath Tagore": ["philosopher, author, songwriter, painter, educator, composer, Nobel laureate", "1861-1941"],
  "Rainer Maria Rilke": ["poet and novelist", "1875-1926"],
  "Ralph Nader": ["activist, author, speaker, attorney", "1934-"],
  "Ralph Waldo Emerson": ["writer and philosopher", "1803-1882"],
  "Raoul Vaneigem": ["writer and philosopher", "1934-"],
  "Ray Bradbury": ["science-fiction writer", "1920-2012"],
  "Raymond Chandler": ["writer", "1888-1959"],
  "Raymond Thornton Chandler": ["writer", "1888-1959"],
  "RD Laing": ["psychiatrist and author", "1927-1989"],
  "Rebecca West": ["author and journalist", "1892-1983"],
  "Red Skelton": ["comedian", "1913-1997"],
  "Reinhold Niebuhr": ["theologian", "1892-1971"],
  "Rembrandt": ["painter and etcher", "1606-1669"],
  "Rene Descartes": ["philosopher and mathematician", "1596-1650"],
  "Richard Armour": ["author", "1906-1989"],
  "Richard Bach": ["writer", "1936-"],
  "Richard Ben Sapir": ["novelist", "1936-1987"],
  "Richard Brinsley Sheridan": ["playwright", "1751-1816"],
  "Richard C. Trench": ["poet", "1807-1886"],
  "Richard Crashaw": ["poet", "1613-1649"],
  "Richard Dawkins": ["biologist and author", "1941-"],
  "Richard Feynman": ["physicist and Nobel laureate", "1918-1988"],
  "Richard Francis Burton": ["explorer and writer", "1821-1890"],
  "Richard Harding Davis": ["journalist and author", "1864-1916"],
  "Richard Hofstadter": ["historian", "1916-1970"],
  "Richard Jeni": ["comedian and actor", "1957-2007"],
  "Richard Steele": ["author and editor", "1672-1729"],
  "Richard Whately": ["philosopher, reformer, theologian, economist", "1787-1863"],
  "Rita Dove": ["poet", "1952-"],
  "Rita Mae Brown": ["writer", "1944-"],
  "Rita Rudner": ["comedienne", "1955-"],
  "Robert A. Heinlein": ["science-fiction author", "1907-1988"],
  "Robert Anton Wilson": ["novelist", "1932-2007"],
  "Robert Benchley": ["humorist, drama critic and actor", "1889-1945"],
  "Robert Blatchford": ["author", "1851-1943"],
  "Robert Bly": ["poet", "1926-"],
  "Robert Brault": ["writer", "1938-"],
  "Robert Burchfield": ["lexicographer", "1923-2004"],
  "Robert Burns": ["poet", "1759-1796"],
  "Robert Cecil": ["British prime minister", "1830-1903"],
  "Robert Ezra Park": ["sociologist", "1864-1944"],
  "Robert Frost": ["poet", "1874-1963"],
  "Robert Fulghum": ["author", "1937-"],
  "Robert G. Ingersoll": ["lawyer and orator", "1833-1899"],
  "Robert Graves": ["poet and novelist", "1895-1985"],
  "Robert Green Ingersoll": ["lawyer and orator", "1833-1899"],
  "Robert H. Jackson": ["US Supreme Court justice", "1892-1954"],
  "Robert H. Goddard": ["engineer, professor, physicist, inventor", "1882-1945"],
  "Robert Hayden": ["poet and educator", "1913-1980"],
  "Robert King Merton": ["sociologist", "1910-2003"],
  "Robert Louis Stevenson": ["novelist, essayist and poet", "1850-1894"],
  "Robert Lynd": ["writer", "1879-1949"],
  "Robert M. Hutchins": ["educator", "1899-1977"],
  "Robert M. Pirsig": ["author and philosopher", "1928-"],
  "Robert M. Sapolsky": ["neuroscientist and author", "1957-"],
  "Robert Maynard Hutchins": ["educator", "1899-1977"],
  "Robert Musil": ["novelist", "1880-1942"],
  "Robert Neelly Bellah": ["sociologist and author", "1927-2013"],
  "Robert Orben": ["magician and author", "1927-"],
  "Robert Owen": ["social reformer", "1771-1858"],
  "Robert Penn Warren": ["novelist and poet", "1905-1989"],
  "Robert Quillen": ["journalist and cartoonist", "1887-1948"],
  "Robert Redford": ["actor, director, producer, environmentalist", "1936-"],
  "Robert Service": ["writer", "1874-1958"],
  "Robert Southey": ["poet", "1774-1843"],
  "Robert W. Burchfield": ["lexicographer", "1923-2004"],
  "Robert Wright": ["author and journalist", "1957-"],
  "Robertson Davies": ["writer", "1913-1995"],
  "Robin Ince": ["comedian, actor and writer", "1969-"],
  "Rodney Dangerfield": ["comedian", "1921-2004"],
  "Roger Baldwin": ["civil rights advocate", "1884-1981"],
  "Roger Ebert": ["film critic", "1942-2013"],
  "Roger Miller": ["musician", "1936-1992"],
  "Roland Barthes": ["literary critic and philosopher", "1915-1980"],
  "Rollo May": ["psychologist", "1909-1994"],
  "Roman Jakobson": ["linguist", "1896-1982"],
  "Ronald Reagan": ["United States president", "1911-2004"],
  "Ronald Ross": ["doctor and Nobel laureate", "1857-1932"],
  "Ronald Searle": ["artist", "1920-2011"],
  "Rose Bird": ["Chief Justice of California Supreme Court", "1936-1999"],
  "Rose Franken": ["author and playwright", "1895-1988"],
  "Ross Macdonald": ["novelist", "1915-1983"],
  "Roy Amara": ["engineer and futurist", "1925-"],
  "Rudolf Arnheim": ["psychologist and author", "1904-2007"],
  "Rudyard Kipling": ["author and Nobel laureate", "1865-1936"],
  "Rukmini Devi Arundale": ["dancer and choreographer", "1904-1986"],
  "Rumi": ["poet and mystic", "1207-1273"],
  "Ruskin Bond": ["author", "1934-"],
  "Russell Baker": ["columnist and author", "1925-"],
  "Russell Hoban": ["author", "1925-"],
  "Rutherford B. Hayes": ["19th US president", "1822-1893"],
  "Saadi": ["poet", "1213-1291"],
  "Salman Rushdie": ["writer", "1947-"],
  "Salvador Dali": ["painter", "1904-1989"],
  "Sam Ewing": ["baseball player", "1949-"],
  "Sam Harris": ["author", "1967-"],
  "Samuel Adams": ["revolutionary", "1722-1803"],
  "Samuel Beckett": ["author", "1906-1989"],
  "Samuel Butler": ["writer", "1835-1902"],
  "Samuel Goldwyn": ["film producer", "1879-1974"],
  "Samuel Grafton": ["writer", "1907-1997"],
  "Samuel Johnson": ["lexicographer", "1709-1784"],
  "Samuel Lover": ["songwriter, composer, novelist, artist", "1797-1868"],
  "Samuel Taylor Coleridge": ["poet and philosopher", "1772-1834"],
  "Sara Teasdale": ["poet", "1884-1933"],
  "Sarah Vowell": ["author and journalist", "1969-"],
  "Saul Bellow": ["writer and Nobel laureate", "1915-2005"],
  "Scott Adams": ["cartoonist", "1957-"],
  "Sebastien-Roch Nicolas de Chamfort": ["writer", "1741-1794"],
  "Shakespeare": ["poet and dramatist", "1564-1616"],
  "Shakti Gawain": ["teacher and author", "1948-"],
  "Shel Silverstein": ["writer", "1930-1999"],
  "Sid Caesar": ["actor and writer", "1922-"],
  "Sigmund Freud": ["neurologist and founder of psychoanalysis", "1856-1939"],
  "Silvio Garattini": ["doctor, researcher, professor, founder of the European Organization for Research on Treatment of Cancer", "1928-"],
  "Simone de Beauvoir": ["author and philosopher", "1908-1986"],
  "Simone Weil": ["philosopher, mystic and activist", "1909-1943"],
  "Socrates": ["philosopher", "469?-399 BCE"],
  "Soichiro Honda": ["industrialist", "1906-1991"],
  "Sophia Loren": ["actress", "1934-"],
  "Sophocles": ["dramatist", "495?-406 BCE"],
  "Søren Kierkegaard": ["philosopher", "1813-1855"],
  "Stanisław J. Lec": ["poet and aphorist", "1909-1966"],
  "Stanley Baldwin": ["statesman", "1867-1947"],
  "Stanley Marion Garn": ["anthropologist", "1922-"],
  "Stendhal (Marie-Henri Beyle)": ["novelist", "1783-1842"],
  "Stephen Crane": ["writer", "1871-1900"],
  "Stephen Hawking": ["theoretical physicist", "1942-2018"],
  "Stephen Jay Gould": ["paleontologist, biologist and author", "1941-2002"],
  "Stephen King": ["novelist", "1947-"],
  "Stephen Leacock": ["economist and humorist", "1869-1944"],
  "Stephen Roberts": ["database architect", "1967-"],
  "Stephen Swid": ["executive", "1941-"],
  "Steve Allen": ["television host, musician, actor, comedian, writer", "1921-2000"],
  "Steve Biko": ["anti-apartheid activist", "1946-1977"],
  "Steve Jobs": ["entrepreneur and inventor", "1955-2011"],
  "Steve Ruggeri": ["former hunter and activist", "1949-1998"],
  "Steven Wright": ["comedian", "1955-"],
  "Stewart Brand": ["writer and editor", "1938-"],
  "Stewart I. Udall": ["politician", "1920-2010"],
  "Studs Terkel": ["author and broadcaster", "1912-2008"],
  "Sun Tzu": ["general", "6th century BCE"],
  "Sunjoy Shekhar": ["writer and editor", "1969-"],
  "Susan B. Anthony": ["reformer and suffragist", "1820-1906"],
  "Susan Ertz": ["author", "1894-1985"],
  "Susan Sontag": ["author and critic", "1933-2004"],
  "Susanne Langer": ["philosopher", "1895-1985"],
  "Suzanne Necker": ["author", "1739-1794"],
  "Sydney J. Harris": ["journalist and author", "1917-1986"],
  "Sydney Smith": ["writer and clergyman", "1771-1845"],
  "Sylvia Plath": ["poet", "1932-1963"],
  "T.S. Eliot": ["poet", "1888-1965"],
  "Taisen Deshimaru": ["Zen teacher", "1914-1982"],
  "Tallulah Bankhead": ["actress", "1903-1968"],
  "Tennessee Williams": ["dramatist", "1911-1983"],
  "Terry Pratchett": ["novelist", "1948-2015"],
  "Terry Tempest Williams": ["naturalist and author", "1955-"],
  "Theodor Adorno": ["philosopher and composer", "1903-1969"],
  "Theodore Dreiser": ["author", "1871-1945"],
  "Theodore M. Hesburgh": ["educator", "1917-"],
  "Theodore Roethke": ["poet", "1908-1963"],
  "Theodore Roosevelt": ["United States president", "1858-1919"],
  "Theodore Rubin": ["psychiatrist and writer", "1923-"],
  "Theophile Gautier": ["writer", "1811-1872"],
  "Thiruvalluvar": ["poet", "c. 30 BCE"],
  "Thomas Babington Macaulay": ["author and statesman", "1800-1859"],
  "Thomas Berger": ["writer", "1924-"],
  "Thomas Brackett Reed": ["politician", "1839-1902"],
  "Thomas Carlyle": ["essayist and historian", "1795-1881"],
  "Thomas Chandler Haliburton": ["author, judge and politician", "1796-1865"],
  "Thomas De Quincey": ["writer", "1785-1859"],
  "Thomas Edison": ["inventor", "1847-1931"],
  "Thomas Hardy": ["novelist and poet", "1840-1928"],
  "Thomas Henry Huxley": ["biologist and writer", "1825-1895"],
  "Thomas Hobbes": ["philosopher", "1588-1679"],
  "Thomas Huxley": ["biologist and writer", "1825-1895"],
  "Thomas J. Watson": ["industrialist", "1874-1956"],
  "Thomas Jefferson Caruthers": ["educator and author", "1885-1971"],
  "Thomas Jefferson": ["United States president", "1743-1826"],
  "Thomas Mann": ["novelist and Nobel laureate", "1875-1955"],
  "Thomas Merton": ["monk and writer", "1915-1968"],
  "Thomas Michael Disch": ["science fiction author and poet", "1940-2008"],
  "Thomas Paine": ["philosopher and writer", "1737-1809"],
  "Thomas Pynchon": ["writer", "1937-"],
  "Thomas Szasz": ["author and professor of psychiatry", "1920-2012"],
  "Thomas Wolfe": ["novelist", "1900-1938"],
  "Thornton Wilder": ["writer", "1897-1975"],
  "Thurgood Marshall": ["US Supreme Court Justice", "1908-1993"],
  "Tim Berners-Lee": ["inventor of the World Wide Web", "1955-"],
  "Timothy Leary": ["psychologist and writer", "1920-1996"],
  "Titus Maccius Plautus": ["dramatist", "c. 254-184 BCE"],
  "Tom Lehrer": ["singer-songwriter and mathematician", "1928-"],
  "Tom Robbins": ["writer", "1936-"],
  "Tom Wolfe": ["author and journalist", "1931-"],
  "Toni Morrison": ["novelist, editor, professor, Nobel laureate", "1931-"],
  "Tony Kushner": ["playwright", "1956-"],
  "Truman Capote": ["author", "1924-1984"],
  "Tzvetan Todorov": ["philosopher", "1939-"],
  "Ulysses S. Grant": ["United States president", "1822-1885"],
  "Umberto Eco": ["philosopher and novelist", "1932-2016"],
  "Upton Sinclair": ["novelist and reformer", "1878-1968"],
  "Ursula K. Le Guin": ["author", "1929-"],
  "Vaclav Havel": ["writer and Czech Republic president", "1936-"],
  "Van Wyck Brooks": ["writer and critic", "1886-1963"],
  "Victor Hugo": ["poet, novelist and dramatist", "1802-1885"],
  "Vikram Chandra": ["novelist", "1961-"],
  "Viktor Frankl": ["author, neurologist and psychiatrist", "1905-1997"],
  "Vilhelm Ekelund": ["poet", "1880-1949"],
  "Vince Poscente": ["Olympian", "1961-"],
  "Vincent van Gogh": ["artist", "1853-1890"],
  "Virginia Satir": ["psychotherapist and author", "1916-1988"],
  "Virginia Woolf": ["writer", "1882-1941"],
  "Vladimir Nabokov": ["novelist", "1899-1977"],
  "Voltaire": ["philosopher and writer", "1694-1778"],
  "W. Somerset Maugham": ["writer", "1874-1965"],
  "W.H. Auden": ["poet", "1907-1973"],
  "Walker Percy": ["author", "1916-1990"],
  "Wallace Stevens": ["poet", "1879-1955"],
  "Walt Kelly": ["cartoonist", "1913-1973"],
  "Walt Whitman": ["poet", "1819-1892"],
  "Walter Bagehot": ["economist and journalist", "1826-1877"],
  "Walter Benjamin": ["critic and philosopher", "1892-1940"],
  "Walter Linn": ["businessman", "1878-1961"],
  "Walter Lippmann": ["journalist", "1889-1974"],
  "Walter Savage Landor": ["writer", "1775-1864"],
  "Walter Scott": ["novelist and poet", "1771-1832"],
  "Wangari Muta Maathai": ["activist and Nobel laureate", "1940-"],
  "Washington Irving": ["writer", "1783-1859"],
  "Wendell Berry": ["farmer and author", "1934-"],
  "Werner Makowski": ["banker", "1929-"],
  "Wernher von Braun": ["rocket engineer", "1912-1977"],
  "Wilhelm Stekel": ["physician and psychologist", "1868-1940"],
  "Will Cuppy": ["journalist", "1884-1949"],
  "Will Durant": ["historian", "1885-1981"],
  "Will Rogers": ["humorist", "1879-1935"],
  "William Arthur Ward": ["college administrator and writer", "1921-1994"],
  "William Bell": ["singer and songwriter", "1939-"],
  "William Beveridge": ["economist and reformer", "1879-1963"],
  "William Blake": ["poet, engraver and painter", "1757-1827"],
  "William Butler Yeats": ["poet, dramatist, essayist, Nobel laureate", "1865-1939"],
  "William C. Dement": ["professor of psychiatry", "1928-"],
  "William Carlos Williams": ["poet and physician", "1883-1963"],
  "William Cobbett": ["journalist, pamphleteer and farmer", "1763-1835"],
  "William Congreve": ["dramatist", "1670-1729"],
  "William Cowper": ["poet", "1731-1800"],
  "William Edward Burghardt Du Bois": ["educator and writer", "1868-1963"],
  "William Ellery Channing": ["clergyman and writer", "1780-1842"],
  "William Empson": ["literary critic and poet", "1906-1984"],
  "William Faulkner": ["novelist", "1897-1962"],
  "William Feather": ["author, editor and publisher", "1889-1981"],
  "William Godwin": ["philosopher and novelist", "1756-1836"],
  "William Golding": ["novelist, playwright, poet, Nobel laureate", "1911-1993"],
  "William H. Gass": ["writer and professor", "1924-"],
  "William Hazlitt": ["essayist", "1778-1830"],
  "William Henry Channing": ["clergyman and reformer", "1810-1884"],
  "William Henry Hudson": ["author and naturalist", "1841-1922"],
  "William Henry Seward": ["Secretary of State, governor and senator", "1801-1872"],
  "William Howard Taft": ["United States president", "1857-1930"],
  "William James": ["psychologist and philosopher", "1842-1910"],
  "William Kingdon Clifford": ["mathematician and philosopher", "1845-1879"],
  "William Least Heat-Moon": ["travel writer", "1939-"],
  "William Makepeace Thackeray": ["novelist", "1811-1863"],
  "William Orville Douglas": ["US Supreme Court Justice", "1898-1980"],
  "William Penn": ["Quaker and founder of Pennsylvania", "1644-1718"],
  "William Pitt": ["British prime minister", "1759-1806"],
  "William Proxmire": ["US senator and reformer", "1915-2005"],
  "William R. Inge": ["clergyman, scholar and author", "1860-1954"],
  "William Saroyan": ["writer", "1908-1981"],
  "William Shakespeare": ["playwright and poet", "1564-1616"],
  "William Shenstone": ["poet", "1714-1763"],
  "William Somerset Maugham": ["writer", "1874-1965"],
  "William Styron": ["novelist", "1925-2006"],
  "William Tecumseh Sherman": ["Union General in the American Civil War", "1820-1891"],
  "William Watson": ["poet", "1858-1935"],
  "William Wiley": ["artist", "1937-"],
  "William Wordsworth": ["poet", "1770-1850"],
  "William Zinsser": ["writer and editor", "1922-2015"],
  "Winston Churchill": ["politician and statesman", "1874-1965"],
  "Wole Soyinka": ["playwright, poet and Nobel laureate", "1934-"],
  "Wolfgang Amadeus Mozart": ["composer and musician", "1756-1791"],
  "Wolfgang Pauli": ["physicist and Nobel laureate", "1900-1958"],
  "Woodrow Wilson": ["United States president and Nobel laureate", "1856-1924"],
  "Woody Allen": ["author, actor and filmmaker", "1935-"],
  "Xenophanes": ["philosopher and poet", "c. 570-475 BCE"],
  "Ximenes Doudan": ["journalist", "1800-1872"],
  "Yahia Lababidi": ["writer", "1973-"],
  "Yasutani Roshi": ["Zen master", "1885-1973"],
  "Yehuda Bauer": ["professor", "1926-"],
  "Yip Harburg": ["lyricist", "1896-1981"],
  "Yousuf Karsh": ["portrait photographer", "1908-2002"],
  "Zelda Fitzgerald": ["novelist", "1900-1948"],
  "Zora Neale Hurston": ["folklorist and writer", "1891-1960"],
};
